import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  IntegerFormInput,
  RadioGroup,
} from "../../../../components";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import {
  downloadFileFromBlob,
  formatDateISO,
  formatNumber,
} from "../../../../coreUtils";
import { RadioItem } from "../../../../components/RadioGroup";
import { GerenciamentoNfEntradaGrid } from "./components/GerenciamentoNfEntradaGrid";
import { ExportarDetEntradasModal } from "./components/ExportarDetEntradasModal";
import { ImprimirDetEntradasModal } from "./components/ImprimirDetEntradasModal";
import { docPrintReport } from "../../../../pdf";
import GerenciamentoNfEntradaService from "../../../../services/compras/GerenciamentoNfEntradaService";

export const GerenciamentoNfEntrada = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [fornecedor, setFornecedor] = useState(null);
  const [numeroNf, setNumeroNf] = useState(null);
  const [imprNomeProd, setImprNomeProd] = useState("CAD");
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = (agrup, imprItens) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      fornecedor: fornecedor,
      numero_nf: numeroNf,
      agrupador: agrup,
      imprimir_itens: imprItens,
      impr_nome_prod: imprNomeProd,
    };

    return GerenciamentoNfEntradaService.listar(params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados("N", true);
    setDados(ok ? ret.dados : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const exportarDominio = async () => {
    const [ok, ret] = await buscarDados("N", true);

    if (ok) {
      const formatarNumero = (v) =>
        v ? parseFloat(v).toFixed(2).replace(".", ",") : "";
      const content = ret.dados
        .reduce((acc, cab) => {
          return [
            ...acc,
            ...cab.itens.map((item) => {
              const enderFornec = [
                cab.endereco_fornecedor,
                cab.numero_fornecedor,
                cab.complemento_fornecedor,
                cab.bairro_fornecedor,
              ]
                .filter((e) => !["", 0, null, undefined].includes(e))
                .map((e) => e.toString().trim())
                .join(", ")
                .replace("\n", " ");

              return [
                cab.cpf_cnpj_fornecedor, // CPF/CNPJ
                cab.nome_fornecedor, // Razão Social
                cab.uf_fornecedor, // UF
                cab.cidade_fornecedor, // Município
                enderFornec, // Endereço
                cab.numero, // Número Documento
                cab.serie, // Série
                cab.emissao, // Data {OU lancamento???}
                "0", // Situação (0- Regular / 2- Cancelada)
                "0", // Acumulador
                item.cfop, // CFOP
                formatarNumero(item.vlr_tot_bruto), // Valor Produtos
                formatarNumero(item.vlr_desconto), // Valor Descontos
                formatarNumero(item.vlr_total), // Valor Contábil
                formatarNumero(item.base_calc_icms), // Base de Calculo ICMS
                formatarNumero(item.aliq_icms), // Alíquota ICMS
                formatarNumero(item.vlr_icms), // Valor ICMS
                formatarNumero("0.00"), // Outras ICMS
                formatarNumero("0.00"), // Isentas ICMS
                formatarNumero(item.base_calc_ipi), // Base de Calculo IPI
                formatarNumero(item.aliq_ipi), // Alíquota IPI
                formatarNumero(item.vlr_ipi), // Valor IPI
                formatarNumero("0.00"), // Outras IPI
                formatarNumero("0.00"), // Isentas IPI
                item.id_produto, // Código do Item
                formatarNumero(item.quantidade), // Quantidade
                formatarNumero(item.vlr_unit), // Valor Unitário
                item.sit_trib_pis, // CST PIS/COFINS ???
                formatarNumero(item.base_calc_pis), // Base de Calculo PIS/COFINS ???
                formatarNumero(item.aliq_pis), // Aíquota PIS
                formatarNumero(item.vlr_pis), // Valor PIS
                formatarNumero(item.aliq_cofins), // Alíquota COFINS
                formatarNumero(item.vlr_cofins), // Valor COFINS
              ]
                .map((e) => (e ?? "").toString().trim())
                .join(";");
            }),
          ];
        }, [])
        .join("\r\n");
      downloadFileFromBlob(
        new Blob([content], { type: "text/plain" }),
        "arquivo.txt"
      );
    }
    return ok;
  };

  const exportarTexto = async () => {
    const [ok, ret] = await buscarDados("N", true);
    if (ok) {
      const formatarCampo = (valor, tamanho, tipo, align) => {
        let res = (valor ?? "").toString();
        if (tipo === "num") {
          res = formatNumber(res, true, 2);
        } else if (tipo === "text") {
          res = res.trim().substring(0, tamanho);
        } else {
          res = res.trim();
        }

        if (align === "right") {
          res = res.padStart(tamanho, " ");
        } else if (align === "left") {
          res = res.padEnd(tamanho, " ");
        } else {
          res = res
            .padStart(res.length + Math.floor((tamanho - res.length) / 2), " ")
            .padEnd(tamanho, " ");
        }

        return res;
      };

      const formatarLinha = (row, columns) =>
        columns
          .reduce(
            (acc, col) => [
              ...acc,
              formatarCampo(
                row[col.dataField],
                col.tamanho,
                col.tipo,
                col.align
              ),
            ],
            []
          )
          .join("");

      const formatarDados = (dados, columns) =>
        dados.reduce(
          (acc, row) => [...acc, formatarLinha(row, columns)],
          [
            columns
              .reduce(
                (acc, col) => [
                  ...acc,
                  formatarCampo(col.text, col.tamanho, "text", col.align),
                ],
                []
              )
              .join(""),
          ]
        );

      const formatarDadosDetalhes = (
        dados,
        columns,
        columnsDetalhe,
        campoDetalhe
      ) =>
        dados.reduce(
          (acc, row) => [
            ...acc,
            formatarLinha(row, columns),
            ...formatarDados(row[campoDetalhe], columnsDetalhe),
          ],
          [
            columns
              .reduce(
                (acc, col) => [
                  ...acc,
                  formatarCampo(col.text, col.tamanho, "text", col.align),
                ],
                []
              )
              .join(""),
          ]
        );

      const columns = [
        {
          tamanho: 8,
          dataField: "id",
          text: "ID",
          tipo: "cod",
          align: "left",
        },
        {
          tamanho: 50,
          dataField: "nome_fornecedor",
          text: "Nome do Fornecedor",
          tipo: "text",
          align: "left",
        },
        {
          tamanho: 15,
          dataField: "numero",
          text: "Número",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 7,
          dataField: "serie",
          text: "Série",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 14,
          dataField: "emissao",
          text: "Emissão",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 14,
          dataField: "lancamento",
          text: "Lançamento",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 12,
          dataField: "vlr_tot_prod",
          text: "Produtos",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 10,
          dataField: "vlr_frete",
          text: "Frete",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 10,
          dataField: "vlr_tot_ipi",
          text: "IPI",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 12,
          dataField: "vlr_total",
          text: "Total",
          tipo: "num",
          align: "right",
        },
      ];

      const columnsDetalhe = [
        {
          tamanho: 7,
          dataField: "id_produto",
          text: "Prod",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 50,
          dataField: "nome_produto",
          text: "Descrição do Produto",
          tipo: "text",
          align: "left",
        },
        {
          tamanho: 18,
          dataField: "referencia",
          text: "Referência",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 6,
          dataField: "unidade",
          text: "UN",
          tipo: "cod",
          align: "center",
        },
        {
          tamanho: 12,
          dataField: "vlr_unit",
          text: "Unitário",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 11,
          dataField: "quantidade",
          text: "Qtde",
          tipo: "num",
          align: "center",
        },
        {
          tamanho: 12,
          dataField: "vlr_tot_bruto",
          text: "Produtos",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 12,
          dataField: "vlr_icms",
          text: "ICMS",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 12,
          dataField: "vlr_icms_st",
          text: "ICMS ST",
          tipo: "num",
          align: "right",
        },
        {
          tamanho: 12,
          dataField: "vlr_total",
          text: "Total",
          tipo: "num",
          align: "right",
        },
      ];

      const content = formatarDadosDetalhes(
        ret.dados,
        columns,
        columnsDetalhe,
        "itens"
      ).join("\r\n");
      downloadFileFromBlob(
        new Blob([content], { type: "text/plain" }),
        "arquivo.txt"
      );
    }
  };

  const imprimir = async (agrup, imprItens) => {
    const [ok, ret] = await buscarDados(agrup, imprItens);
    if (ok) {
      await docPrintReport(
        "/relatorios/compras/detalhamento_entradas/",
        {
          dados: ret,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          agrupador: agrup,
          imprimir_itens: imprItens,
        },
        "0115"
      );
    }
    return ok;
  };

  return (
    <PageContainer
      title="Gerenciamento de Notas Fiscais de Entrada"
      number="0115"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={3}
            label="Fornecedor"
            isSearchable
            isClearable
            isConcatField
            concatModelName="fornecedor"
            onChange={(s) => setFornecedor(s?.value)}
            defaultOptions
          />
          <IntegerFormInput
            md={2}
            label="Número NF"
            defaultValue={numeroNf}
            onChange={setNumeroNf}
          />
        </Row>
        <Row>
          <RadioGroup
            label="Considerar Nome do Produto"
            value={imprNomeProd}
            onChange={setImprNomeProd}
          >
            <RadioItem label="Conforme Cadastro" value="CAD" />
            <RadioItem label="Conforme Fornecedor NF Entrada" value="NFE" />
          </RadioGroup>
          <BotaoPesquisar loading={loading} onClick={carregarDados} />
          <ImprimirDetEntradasModal imprimir={imprimir} />
          <ExportarDetEntradasModal
            exportarDominio={exportarDominio}
            exportarTexto={exportarTexto}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Qtd de NF"
          value={totais?.qtd_notas}
          checkFloat
        />
        <CardTotaisItem label="Valor Produtos" value={totais?.vlr_produtos} />
        <CardTotaisItem label="Frete" value={totais?.vlr_frete} />
        <CardTotaisItem label="Desconto" value={totais?.vlr_desconto} />
        <CardTotaisItem label="Total" value={totais?.vlr_total} />
      </CardTotais>
      <GerenciamentoNfEntradaGrid dados={dados} />
    </PageContainer>
  );
};
