import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import {
  dateFromLocaleString,
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";
import { iconeAlterar } from "../../../../../components/cadastro";

export const GerencContasReceberGrid = ({
  dados,
  selected,
  utilizaOrganizacao,
  onSelect,
  onSelectAll,
  mostrarDetalhes,
  alterar,
}) => {
  const columns = [
    {
      dataField: "id_organizacao",
      text: "O",
      align: "center",
      sortable: true,
      hidden: !utilizaOrganizacao,
    },
    {
      dataField: "id",
      text: "ID",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente, 31),
      sortable: true,
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "parcela",
      text: "P",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Nº",
      align: "center",
      sortable: true,
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      style: (c, row) => {
        if (["", null, undefined].includes(c)) {
          return {};
        }

        let dataVcto = dateFromLocaleString(c);
        let hoje = new Date();
        dataVcto.setHours(0, 0, 0, 0);
        hoje.setHours(0, 0, 0, 0);
        dataVcto = dataVcto.getTime();
        hoje = hoje.getTime();

        let color;

        if (row.recebido === false) {
          if (dataVcto < hoje) {
            color = "red";
          } else if (dataVcto === hoje) {
            color = "orange";
          }
        }

        return { color: color };
      },
    },
    {
      dataField: "recebimento",
      text: "Recebimento",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "vlr_orig",
      text: "Orig",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_acresc",
      text: "Acrésc",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_deduc",
      text: "Deduc",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_recebido",
      text: "Recebido",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_receber",
      text: "Receber",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar, {
      disabled: (c, row) => row.recebido,
      disabledHint: "Este título já foi recebido e não pode ser alterado",
    }),
    {
      dataField: "act",
      text: "",
      align: "center",
      dummy: true,
      selectOnClick: false,
      cellContentTag: null,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];
  return (
    <Table
      data={dados}
      columns={columns}
      multiselect
      selected={selected}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      showRegisterCount={false}
      onRowDoubleClick={alterar}
      selectColumnPosition="right"
      clearSelectionOnUpdate
      rowSelect={false}
      paginated={false}
    />
  );
};
