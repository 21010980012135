import React from "react";
import { useState } from "react";
import { Row } from "reactstrap";
import { Divider, Table } from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";
import { TableDelete } from "../../../../../../components/TableDelete";
import { formatNumber } from "../../../../../../coreUtils";
import RemessaBancariaService from "../../../../../../services/bancario/RemessaBancariaService";

const ReabrirTituloModal = ({ id, numTitulo, nomeCliente, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const reabrirRemessa = async () => {
    setLoading(true);
    const [ok] = await RemessaBancariaService.reabrirTitulo(id);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <TableDelete onClick={toggle} />
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="sm"
          title="Reabertura de Título da Remessa"
          onConfirm={reabrirRemessa}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center px-2">
            <p style={{ paddingInline: "5px" }}>
              <b>
                Tem certeza de que deseja reabrir individualmente o título da
                remessa?
              </b>
              <br />
              Número: {numTitulo}
              <br />
              Cliente: {nomeCliente}
            </p>
          </Row>
        </ModalBase>
      )}
    </>
  );
};

const initState = {
  titulos_remessa: [],
  titulos_excluidos: [],
};

export const DetalhamentoRemessaModal = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState(initState);

  const limparDados = () => setDados(initState);

  const buscarDados = async () => {
    const [ok, ret] = await RemessaBancariaService.buscarTitulosRemessa(id);
    if (ok) {
      setDados(ret);
    } else {
      setDados(initState);
    }
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      limparDados();
    }
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      dataField: "numero",
      text: "Número / Parcela",
      align: "center",
      formatter: (c, row) => `${c}-${row.parcela}`,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Nro.",
      align: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
    },

    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
    },
    {
      dataField: "vlr_receber",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "det",
      dummy: true,
      align: "center",
      formatter: (c, row) => (
        <ReabrirTituloModal
          id={row.id}
          numTitulo={`${row.numero}-${row.parcela}`}
          idRemessa={id}
          nomeCliente={row.nome_cliente}
          notifyEvent={buscarDados}
        />
      ),
    },
  ];

  const columnsTitulosExc = [
    {
      dataField: "numero",
      text: "Número / Parcela",
      align: "center",
      formatter: (c, row) => `${c}-${row.parcela}`,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Nro.",
      align: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
    },

    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
    },
    {
      dataField: "vlr_receber",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "det",
      dummy: true,
      align: "center",
      formatter: (c, row) => (
        <ReabrirTituloModal
          id={row.id_rec_mov}
          numTitulo={`${row.numero}-${row.parcela}`}
          idRemessa={id}
          nomeCliente={row.nome_cliente}
          notifyEvent={buscarDados}
        />
      ),
    },
  ];

  return (
    <>
      <div id={`det-rem-${id}`} onClick={() => toggle()} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {`Detalhamento da Remessa ${id}`}
          </h5>
        }
        autoFocus
        hideCancelButton
      >
        <Table
          columns={columns}
          data={dados?.titulos_remessa ?? []}
          keyField="id"
          pageSize={10}
        />
        {(dados?.titulos_excluidos?.length ?? 0) > 0 && (
          <>
            <Divider>Títulos Excluídos</Divider>
            <Table
              columns={columnsTitulosExc}
              data={dados.titulos_excluidos ?? []}
              keyField="id_rec_mov"
              pageSize={5}
            />
          </>
        )}
      </ModalBase>
    </>
  );
};
