import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_plano_cta_inclusao: null,
  id_plano_cta_taxa_adm: null,
  id_plano_cta_compensacao: null,
  taxacao_automatica_caixa: false,
  id_plano_cta_taxa_antecipacao: null,
};

const fieldsToNull = [
  "id_plano_cta_inclusao",
  "id_plano_cta_taxa_adm",
  "id_plano_cta_compensacao",
  "id_plano_cta_taxa_antecipacao",
];

export const cartaoRecSlice = createSlice({
  name: "cartao_rec_slice",
  initialState: initialState,
  reducers: {
    setupCartaoRec: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        state[key] = value;
      });
      return state;
    },
    setIdPlanoCtaInclusao: (state, action) => {
      state.id_plano_cta_inclusao = action.payload;
    },
    setIdPlanoCtaTaxaAdm: (state, action) => {
      state.id_plano_cta_taxa_adm = action.payload;
    },
    setIdPlanoCtaCompensacao: (state, action) => {
      state.id_plano_cta_compensacao = action.payload;
    },
    setTaxacaoAutomaticaCaixa: (state, action) => {
      state.taxacao_automatica_caixa = action.payload;
    },
    setIdPlanoCtaTaxaAntecipacao: (state, action) => {
      state.id_plano_cta_taxa_antecipacao = action.payload;
    },
  },
});

export const {
  setupCartaoRec,
  setIdPlanoCtaInclusao,
  setIdPlanoCtaTaxaAdm,
  setIdPlanoCtaCompensacao,
  setTaxacaoAutomaticaCaixa,
  setIdPlanoCtaTaxaAntecipacao,
} = cartaoRecSlice.actions;

export default cartaoRecSlice.reducer;
