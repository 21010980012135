import { apiDelete, apiGet, apiPost } from "../../api";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { routesBaseListaPrecos } from "../../pages/cadastro/vendas/lista_precos/ListaPrecos";

const CadastroListaPrecosService = {
  listar: async (params) =>
    await apiGetV2(`${routesBaseListaPrecos}/listar/`, params),
  buscarClientes: async (idCab) =>
    await apiGet(`${routesBaseListaPrecos}/clientes/listar/${idCab}/`),
  incluirCliente: async (payload) =>
    await apiPost(`${routesBaseListaPrecos}/clientes/incluir/`, payload),
  excluirCliente: async (id) =>
    await apiDelete(`${routesBaseListaPrecos}/clientes/excluir/${id}/`),
  para: {
    excluir: async (idRegistro) =>
      await apiDeleteV2(`${routesBaseListaPrecos}/para/excluir/${idRegistro}/`),
    listar: async (idListaPreco) =>
      await apiGetV2(`${routesBaseListaPrecos}/para/listar/${idListaPreco}/`),
    incluir: async (payload) =>
      await apiPostV2(`${routesBaseListaPrecos}/para/incluir/`, payload),
  },
  de: {
    alterar: async (payload) =>
      await apiPutV2(`${routesBaseListaPrecos}/de/alterar/`, payload),
    excluir: async (idRegistro) =>
      await apiDeleteV2(`${routesBaseListaPrecos}/de/excluir/${idRegistro}/`),
    listar: async (idListaPreco) =>
      await apiGetV2(`${routesBaseListaPrecos}/de/listar/${idListaPreco}/`),
    incluir: async (payload) =>
      await apiPostV2(`${routesBaseListaPrecos}/de/incluir/`, payload),
  },
};

export default CadastroListaPrecosService;
