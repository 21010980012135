import React, { useLayoutEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  ComboBox,
  FixedField,
  FormButton,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import PesqProduto from "../../../../components/form/pesq_produto/PesqProduto";
import { useState } from "react";
import { useRef } from "react";
import CadastroProdutosService from "../../../../services/cadastro/CadastroProdutosService";
import {
  formatNumber,
  sumDataField,
  viewDownloadPDF,
} from "../../../../coreUtils";
import { ImpressaoEtiquetasGrid } from "./components/ImpressaoEtiquetasGrid";

import { printLabel } from "../../../../utils/printing";
import { imprimirEtqL19 } from "./layouts/etq19";
import { EtiquetasNfEntradaModal } from "./components/EtiquetasNfEntradaModal";
import ImpressaoEtiquetasProdutoService from "../../../../services/cadastro/ImpressaoEtiquetasProdutoService";
import { imprimirEtqL30 } from "./layouts/etq30";
import { imprimirEtqL32 } from "./layouts/etq32";
import { imprimirEtqL33 } from "./layouts/etq33";
import { imprimirEtqL13 } from "./layouts/etq13";
import { showError, showWarning } from "../../../../components/AlertaModal";
import moment from "moment";
import { imprimirEtqL34 } from "./layouts/etq34";
import { imprimirEtqL35 } from "./layouts/etq35";
import { ImprimirLayout36Modal } from "./components/ImprimirLayout36Modal";
import { imprimirEtqL37 } from "./layouts/etq37";
import { ImprimirLayout38Modal } from "./components/ImprimirLayout38Modal";
import { imprimirEtqL24 } from "./layouts/etq24";

const layouts = [
  // {
  //   label:
  //     "01 = Impressora ZEBRA - 3 colunas (3,40x2,30cm) - Cód.Barras, Nome produto, Preço de venda",
  //   value: 1,
  // },
  // {
  //   label:
  //     "02 = Impressora ELGIN - Gondola (7,50x3,50cm)   - Cód.Barras, Nome do produto, Localização",
  //   value: 2,
  // },
  // {
  //   label:
  //     "03 = Impressora ELGIN - 2 colunas (5,00x2,50cm) - Cód.Barras, Id produto, Nome do Produto",
  //   value: 3,
  // },
  // {
  //   label:
  //     "04 = Impressora ELGIN - Gondola (7,50x3,50cm)   - Cód.Barras, Nome do produto, Valor atacado, Valor venda",
  //   value: 4,
  // },
  // {
  //   label:
  //     "05 = Folha A4 Adesiva - 2 Colunas, 8 Linhas     - Nome, UN, Cód. Barras, Preço de Venda",
  //   value: 5,
  // },
  // {
  //   label:
  //     "06 = Folha A4 Adesiva - 3 Colunas, 10 Linhas    - Nome, UN, Cód. Barras, Referência, Nro",
  //   value: 6,
  // },
  // {
  //   label:
  //     "07 = Folha A4 Adesiva - 3 Colunas, 11 Linhas    - Nome, UN, Cód. Barras, Preço de Venda",
  //   value: 7,
  // },
  // {
  //   label:
  //     "08 = Folha A4 Adesiva - 4 Colunas (4X1,5 cm)    - Nome, Cód. Barras, Referência",
  //   value: 8,
  // },
  // {
  //   label:
  //     "09 = Folha A4 Adesiva - 4 Colunas               - Nome, Cód. Barras, Preço de Venda",
  //   value: 9,
  // },
  // {
  //   label:
  //     "10 = Folha A4 Adesiva - 5 Colunas; 13 Linhas    - Cód. Barras, Nome, Referência, Preço de Venda",
  //   value: 10,
  // },
  // {
  //   label:
  //     "11 = Folha A4 Adesiva - 2 Colunas, 11 Linhas    - Nome, Preço de venda",
  //   value: 11,
  // },
  // {
  //   label:
  //     "12 = Folha A4 Adesiva - 3 Colunas, 10 Linhas    - UN, Cód. Barras, Preço de Venda",
  //   value: 12,
  // },
  {
    label:
      "13 = Folha A4 Adesiva - 5 Colunas, 13 Linhas    - Nome, UN, Cód. Barras, Preço de Venda",
    value: 13,
  },
  // {
  //   label:
  //     "14 = Impressora ARGOX - Gondola                 - Nome do Produto e Preço de Venda Centralizado",
  //   value: 14,
  // },
  // {
  //   label:
  //     "15 = Impressora ZEBRA - 3 colunas (3,00x2,50cm) - Cód.Barras, Nome produto, Preço de venda)",
  //   value: 15,
  // },
  // {
  //   label:
  //     "16 = Impressora ARGOX - 3 Colunas (3,30x2,00cm) - Cód Barras, Nome e ID Produto, Preço de venda e Tamanho se houver)",
  //   value: 16,
  // },
  // {
  //   label:
  //     "17 = Impressora ARGOX - 2 colunas (5,00x2,50cm) - Nome Produto, Preço, Código de Barras, Código do Produto",
  //   value: 17,
  // },
  // {
  //   label:
  //     "18 = Folha A4 Adesiva - 3 colunas, 10 linhas    - Nome, UN, Código de Barras, Código Produto, Preço de Venda",
  //   value: 18,
  // },
  {
    label: "19 = Impressora ZEBRA - 2 Colunas - Furada com preço destacável ",
    value: 19,
  },
  // {
  //   label:
  //     "20 = Impressora Elgin L42 Pro - Gondola (9,00x3,00cm) - Cód.Barras, Nome do produto, Preço de Venda",
  //   value: 20,
  // },
  // {
  //   label:
  //     "21 = Folha A4 Adesiva - 3 colunas, 10 linhas  - Nome, UN, Codigo de barras, Codigo do produto, preco venda em 90 graus",
  //   value: 21,
  // },
  // {
  //   label:
  //     "22 = Impressora Elgin L42 - Gondola (9,00x3,00cm) - Cód.Barras, Nome do produto, Preço de Venda",
  //   value: 22,
  // },
  // {
  //   label:
  //     "23 = Impressora Elgin L42 Pro - 2 Colunas (4,00x2,50cm) - Cód.Barras, Cód.Produto Nome do produto, Preço de Venda",
  //   value: 23,
  // },
  {
    label:
      "24 = Folha A4 Adesiva - 3 colunas, 10 linhas  - Nome, UN, Codigo de barras, Codigo do produto, preco venda, tamanho, cor em 90 graus",
    value: 24,
  },
  // {
  //   label:
  //     "25 = Folha A4 Adesiva - 5 Colunas; 13 Linhas    - Nome, Tam, Cor, Cód. Barras, cod interno, Preço de Venda",
  //   value: 25,
  // },
  // {
  //   label:
  //     "26 = Impressora ELGIN L42 PRO - 2 Colunas - Furada com preço destacável",
  //   value: 26,
  // },
  // {
  //   label:
  //     "27 = Impressora ELGIN L42 - 3 colunas - Nome do produto, Cód.Barras, Preço de Venda",
  //   value: 27,
  // },
  // {
  //   label:
  //     "28 = Impressora ARGOX - 2 colunas (3,90x4,00cm) - Nome Produto, Fabricante, Referência, Preço, Código de Barras, Código do Produto",
  //   value: 28,
  // },
  // {
  //   label:
  //     "29 = Impressora ZEBRA - EPL2 - GONDOLA - [Nome, Código de Barras, Preço] ",
  //   value: 29,
  // },
  {
    label: "30 = Impressora L42 PRO - 2 Colunas - [QRCode, ID]",
    value: 30,
  },
  // {
  //   label:
  //     "31 = Impressora Elgin L42 - Gondola (9,00x3,00cm) - Cód.Barras, Nome Completo do produto, Preço de Venda",
  //   value: 31,
  // },
  {
    label:
      "32 = Impressora Elgin L42 Pro - 2 Colunas (5,00x2,50cm) - Cód.Barras, Cód.Produto Nome do produto, Referência",
    value: 32,
  },
  {
    label:
      "33 = Impressora Elgin L42 Pro - Gondola (9,00x3,00cm) - Cód.Barras, Cód.Produto Nome do produto, Referência - ZPL",
    value: 33,
  },
  {
    label:
      "34 = Folha A4 Adesiva - 5 Colunas, 13 Linhas - Nome, UN, Tamanho, Cód. Barras, Preço de Venda",
    value: 34,
  },
  {
    label:
      "35 = Impressora Zebra - 1 Coluna (4,00x6,00) - Nome, Cód. Barras, Preço de Venda, Nome Empresa",
    value: 35,
  },
  {
    label:
      "36 = Impressora Zetex Z50X - 1 Coluna (10,00x15,00) - Dados da Empresa, Nome do Produto, Frases de Perigo/Precaução",
    value: 36,
  },
  {
    label:
      "37 = Folha A4 Adesiva - 5 Colunas, 13 Linhas - Nome, Referência, UN, Cód. Barras",
    value: 37,
  },
  {
    label: "38 = Folha A4 Adesiva - 2 Colunas, 5 Linhas - Dados da Nota Fiscal",
    value: 38,
  },
];

export const ImpressaoEtiquetas = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [dadosProd, setDadosProd] = useState({});
  const [qtdEtiquetas, setQtdEtiquetas] = useState(null);
  const [layout, setLayout] = useState(null);
  const [etiquetas, setEtiquetas] = useState([]);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [layout36Open, setLayout36Open] = useState(false);
  const [layout38Open, setLayout38Open] = useState(false);

  const pesqProdRef = useRef();
  const qtdEtiquetasRef = useRef();
  const layoutRef = useRef();

  const handleSelectProduto = async (id, nome, referencia) => {
    if (id) {
      setIdProduto(id);
      pesqProdRef.current.setId(String(id));
      pesqProdRef.current.setDescricao(nome);
      pesqProdRef.current.setReferencia(String(referencia));
      const [ok, ret] = await CadastroProdutosService.buscarDadosImprEtq(id);

      if (ok) {
        setDadosProd(ret);
      }
    } else {
      setDadosProd({});
      setIdProduto(null);
      pesqProdRef.current.clear();
    }
  };

  const addProduto = () => {
    setEtiquetas([...etiquetas, { ...dadosProd, qtd_etiquetas: qtdEtiquetas }]);
    setQtdEtiquetas(null);
    setIdProduto(null);
    setDadosProd({});
    pesqProdRef.current.clear();
    pesqProdRef.current.focus();
  };

  const deleteProduto = (index) => {
    setEtiquetas(etiquetas.filter((e, i) => i !== index));
  };

  const setQtdEtiqProd = (r, v, index) => {
    setEtiquetas(
      etiquetas.map((e, i) => (i === index ? { ...e, qtd_etiquetas: v } : e))
    );
  };

  const imprimir = async () => {
    if (layout === 36) {
      toggleLayout36();
      return;
    } else if (layout === 38) {
      toggleLayout38();
      return;
    }

    if ([0, null, undefined].includes(layout)) {
      showWarning("Por favor, selecione o Layout de Impressão");
      if (layoutRef.current) {
        layoutRef.current.setFocus();
      }
      return;
    }

    setLoadingImprimir(true);

    const gerarImpressao = async () => {
      switch (layout) {
        case 13:
          return await imprimirEtqL13(etiquetas);
        case 19:
          return imprimirEtqL19(etiquetas);
        case 24:
          return await imprimirEtqL24(etiquetas);
        case 30:
          return imprimirEtqL30(etiquetas);
        case 32:
          return imprimirEtqL32(etiquetas);
        case 33:
          return imprimirEtqL33(etiquetas);
        case 34:
          return imprimirEtqL34(etiquetas);
        case 35:
          return imprimirEtqL35(etiquetas);
        case 37:
          return imprimirEtqL37(etiquetas);
        default:
          return [false, `Layout ${layout} não implementado`];
      }
    };

    const [ok, ret] = await gerarImpressao();

    if (ok) {
      const layoutsZpl = [19, 30, 32, 33, 35];
      const layoutsPDF = [13, 24, 34, 37];

      if (layoutsZpl.includes(layout)) {
        const [okParams, paramsImpr] =
          await ImpressaoEtiquetasProdutoService.buscarParams();

        if (okParams) {
          await printLabel(ret, paramsImpr?.camin_impressora_etiqueta ?? "");
        }
      } else if (layoutsPDF.includes(layout)) {
        viewDownloadPDF(
          ret,
          `etq_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
        );
      }
    } else {
      if (ret) {
        showError(
          `Falha ao gerar Impressão de Etiquetas: ${ret}`,
          "ErrEtqFront01"
        );
      }
    }
    setLoadingImprimir(false);
  };

  const importarProdutos = (prods) => {
    prods = prods.map((e) => ({
      ...e,
      cor: e.cor_produto,
      nome: e.nome_produto,
      unidade: e.unidade_produto,
      qtd_etiquetas: parseInt(e.quantidade),
    }));
    setEtiquetas([...etiquetas, ...prods]);
  };

  const buscarLayoutPadrao = async () => {
    const [ok, ret] = await ImpressaoEtiquetasProdutoService.buscarParams();
    if (ok) {
      if (layouts.map((e) => e.value).includes(ret.layout_etiqueta)) {
        setLayout(ret.layout_etiqueta);
      }
    }
  };

  const toggleLayout36 = () => setLayout36Open(!layout36Open);

  const toggleLayout38 = () => setLayout38Open(!layout38Open);

  useLayoutEffect(() => {
    buscarLayoutPadrao();
  }, []);

  return (
    <PageContainer title="Impressão de Etiquetas" number="0073" canGoBack>
      <Card body>
        <Row>
          <EtiquetasNfEntradaModal importarProdutos={importarProdutos} />
        </Row>
        <Row>
          <PesqProduto
            md={7}
            onConfirm={handleSelectProduto}
            ref={pesqProdRef}
            selectNextField={() => qtdEtiquetasRef.current.focus()}
            onChangeDescricao={() => {}}
            autoFocus
          />
          <FixedField label="Tamanho" value={dadosProd?.tamanho} />
          <FixedField label="Cor" value={dadosProd?.cor} />
          <FixedField
            label="Estoque"
            value={
              dadosProd?.estoque
                ? formatNumber(dadosProd?.estoque, true, 2, true)
                : ""
            }
          />
        </Row>
        <Row>
          <FixedField
            label="Código de Barras"
            value={dadosProd?.codigo_barras}
          />
          <FixedField
            label="Preço"
            value={
              dadosProd?.preco_venda
                ? formatNumber(dadosProd?.preco_venda, true, 2)
                : ""
            }
          />
          <FixedField label="Fabricante" value={dadosProd?.fabricante} />
          <IntegerFormInput
            md={2}
            colClassName="ml-auto"
            label="Qtd. Etiquetas"
            defaultValue={qtdEtiquetas}
            onChange={setQtdEtiquetas}
            ref={qtdEtiquetasRef}
          />
          <FormButton
            md="auto"
            color="success"
            disabled={
              [0, null, undefined].includes(idProduto) ||
              [0, null, undefined].includes(qtdEtiquetas)
            }
            disabledHint={
              [0, null, undefined].includes(idProduto)
                ? "Selecione um Produto"
                : "Informe a Quantidade de Etiquetas"
            }
            onClick={addProduto}
          >
            Adicionar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <Row style={{ justifyContent: "space-between" }}>
          <ComboBox
            md={8}
            label="Layout"
            options={layouts}
            defaultValue={layout}
            onChange={(s) => setLayout(s?.value)}
            ref={layoutRef}
          />
          <FixedField
            label="Qtd. Total de Etiquetas"
            value={sumDataField(etiquetas, "qtd_etiquetas")}
            horizontal
            divStyle={{ height: "max-content", marginTop: "1.2rem" }}
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={![36, 38].includes(layout) && etiquetas.length === 0}
            disabledHint="Adicione ao menos uma etiqueta"
          >
            F9 - Imprimir
          </FormButton>
          <ImprimirLayout36Modal
            isOpen={layout36Open}
            toggle={toggleLayout36}
          />
          <ImprimirLayout38Modal
            isOpen={layout38Open}
            toggle={toggleLayout38}
          />
        </Row>
        <ImpressaoEtiquetasGrid
          etiquetas={etiquetas}
          deleteProduto={deleteProduto}
          setQtdEtiqProd={setQtdEtiqProd}
        />
      </Card>
    </PageContainer>
  );
};
