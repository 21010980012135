import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import EmprestimoColaboradorService from "../../../../services/frente_venda_mobile/EmprestimoColaboradorService";
import { EmprestimoColaboradorGrid } from "./components/EmprestimoColaboradorGrid";
import { IncluirEmprestimoModal } from "./components/IncluirEmprestimoModal";

const mostrarOptions = [
  { label: "Todos", value: "ALL" },
  { label: "Somente Abertos", value: "ABE" },
  { label: "Somente Fechados", value: "FEC" },
];

export const EmprestimoColaborador = ({}) => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [idColaborador, setIdColaborador] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [loading, setLoading] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const params = {
      id_colaborador: idColaborador,
      mostrar: mostrar,
    };

    setDados(await EmprestimoColaboradorService.listar(params));

    setLoading(false);
  };

  return (
    <PageContainer title="Empréstimos de Colaboradores" number="0052" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            label="Colaborador"
            md={4}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isSearchable
            isClearable
            onChange={(s) => setIdColaborador(s?.value)}
          />
          <ComboBox
            label="Mostrar"
            md={2}
            options={mostrarOptions}
            onChange={(s) => setMostrar(s?.value)}
            defaultValue={mostrarOptions[0].value}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <IncluirEmprestimoModal notifyEvent={carregarDados} />
        </Row>
      </Card>
      <Card body>
        <EmprestimoColaboradorGrid data={dados} />
      </Card>
    </PageContainer>
  );
};
