import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  Divider,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import { Label, Row } from "reactstrap";
import { RadioItem } from "../../../../../components/RadioGroup";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../components/AlertaModal";
import { formatNumber, roundFloat } from "../../../../../coreUtils";
import { apiGetV2, apiPostV2 } from "../../../../../apiV2";

export const LancAjusteEstoqueModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tipo, setTipo] = useState("ROTA");
  const [idDestino, setIdDestino] = useState(null);
  const [natureza, setNatureza] = useState("-");
  const [historico, setHistorico] = useState("");
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [lancamentos, setLancamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    utilizaEstoqueRealAdmProntaEntrega,
    setUtilizaEstoqueRealAdmProntaEntrega,
  ] = useState(false);
  const [estAtualProduto, setEstAtualProduto] = useState(0);

  const pesqProdRef = useRef();
  const quantidadeRef = useRef();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const limparDadosProduto = () => {
    setIdProduto(null);
    setNomeProduto("");
    setQuantidade(0);
    setEstAtualProduto(0);
  };

  const limparDados = () => {
    setTipo("ROTA");
    setIdDestino(null);
    setNatureza(null);
    setHistorico("");
    setLancamentos([]);
    limparDadosProduto();
  };

  const handleSetTipo = (v) => {
    setTipo(v);
    setIdDestino(null);
    if (v === "ROTA") {
      setNatureza("-");
    } else {
      setNatureza(null);
    }
  };

  const buscarEstoqueProduto = async (idProduto) => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/produto/buscar_qtd_estoque/${idProduto}/`
    );

    if (ok) {
      setEstAtualProduto(parseFloat(ret.qtd_estoque_real_adm));
    }
  };

  const handleSelectProduto = (
    id,
    nome,
    referencia,
    precoVenda,
    unidade,
    custoUe,
    cfop,
    clasFiscal,
    curinga
  ) => {
    if (idProduto !== id) {
      setIdProduto(id);
      setNomeProduto(nome);
      if (pesqProdRef.current) {
        pesqProdRef.current.setDescricao(nome);
        pesqProdRef.current.setId(String(id));
        pesqProdRef.current.setReferencia(String(referencia));
      }

      if (id) {
        buscarEstoqueProduto(id);
      } else {
        setEstAtualProduto(0);
      }
    }
  };

  const selectNextFieldProduto = () =>
    quantidadeRef.current && quantidadeRef.current.focus();

  const handleAddProduto = () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, selecione um Produto");
      return;
    }

    if (lancamentos.findIndex((e) => e.id_produto === idProduto) > -1) {
      showWarning("O Produto informado já existe entre os insumos.");
      return;
    }

    if ([0, null, undefined].includes(quantidade)) {
      showWarning("Por favor, informe a quantidade do produto");
      return;
    }

    const payload = {
      id_produto: idProduto,
      nome_produto: nomeProduto,
      quantidade: quantidade,
    };
    setLancamentos([...lancamentos, payload]);
    limparDadosProduto();
    if (pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const handleDeleteProduto = (id) =>
    setLancamentos(lancamentos.filter((e) => e.id_produto !== id));

  const handleAlterarProduto = (coluna, novoValor, row) => {
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), 2);
    const novoValorTest = roundFloat(parseFloat(novoValor), 2);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    }

    setLancamentos(
      lancamentos.map((e) => (e.id_produto === row.id_produto ? row : e))
    );
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idDestino) && tipo !== "ERAD") {
      showWarning("Por favor, informe o Destino do Lançamento");
      return;
    }

    if ([0, null, undefined].includes(natureza)) {
      showWarning("Por favor, informe a Natureza do Lançamento");
      return;
    }

    if (lancamentos.length === 0) {
      showWarning("Por favor, informe ao menos um Produto");
      return;
    }

    const payload = {
      tipo: tipo,
      id_destino: idDestino,
      natureza: natureza,
      historico: historico,
      lancamentos: lancamentos.map((e) => ({
        id_produto: e.id_produto,
        quantidade: e.quantidade,
      })),
    };

    setLoading(true);
    const [ok] = await apiPostV2(
      "/pronta_entrega/rota_estoque_inter/incluir_lancamento/",
      payload
    );
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      alwaysShowEditor: true,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteProduto(row.id_produto)} />
      ),
    },
  ];

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("tela/lanc_est_pronta_entrega/");
    if (ok) {
      setUtilizaEstoqueRealAdmProntaEntrega(
        ret.utiliza_estoque_real_adm_pronta_entrega
      );
      if (!ret.permite_ajuste_estoque) {
        showWarning(
          "Seu perfil não está habilitado a efetuar Ajustes de Estoque."
        );
        toggle();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle}>
        Ajuste de Estoque
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Lançamento de Ajuste de Estoque"
        number="0085_2"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        onBeforeOpen={buscarParams}
      >
        <Row>
          <RadioGroup label="Tipo" value={tipo} onChange={handleSetTipo}>
            <RadioItem label="Estoque da Rota" value="ROTA" />
            <RadioItem label="Estoque Intermediário" value="EINT" />
            <RadioItem
              label="Estoque Real ADM"
              value="ERAD"
              className={!utilizaEstoqueRealAdmProntaEntrega ? "d-none" : ""}
            />
          </RadioGroup>
        </Row>
        {["ROTA", "EINT"].includes(tipo) && (
          <Row>
            {tipo === "ROTA" ? (
              <AsyncComboBox
                key="rota"
                isConcatField
                concatModelName="rota_mob"
                isSearchable
                md={6}
                label="Rota"
                onChange={(s) => setIdDestino(s?.value)}
                defaultValue={idDestino}
                defaultOptions
              />
            ) : (
              <AsyncComboBox
                key="estoque_inter"
                isConcatField
                concatModelName="rota_estoque_inter"
                isSearchable
                md={6}
                label="Estoque Intermediário"
                onChange={(s) => setIdDestino(s?.value)}
                defaultValue={idDestino}
                defaultOptions
              />
            )}
          </Row>
        )}
        <Row>
          <RadioGroup
            label="Natureza"
            value={natureza}
            onChange={setNatureza}
            disabled={tipo === "ROTA"}
          >
            <RadioItem label="Adição" value="+" />
            <RadioItem label="Subtração" value="-" />
          </RadioGroup>
          {tipo === "ROTA" && (
            <Label className="mt-4">
              Lançamentos de ADIÇÃO de estoque devem ser feitos através do
              pedido de recarga, no APP de Venda Direta.
            </Label>
          )}
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Histórico"
            value={historico}
            onChange={(e, v) => setHistorico(v)}
          />
        </Row>
        <Divider>Produtos</Divider>
        <Row>
          <PesqProduto
            mdIdent={3}
            mdDesc={7}
            md={8}
            onConfirm={handleSelectProduto}
            ref={pesqProdRef}
            selectNextField={selectNextFieldProduto}
            onChangeDescricao={setNomeProduto}
          />
          {tipo === "ERAD" && (
            <NumberInput
              md={2}
              label="Estoque Atual"
              value={estAtualProduto}
              disabled
            />
          )}
        </Row>
        <Row className="mb-2">
          <NumberInput
            md={3}
            label="Quantidade"
            value={quantidade}
            onChange={setQuantidade}
            ref={quantidadeRef}
          />
          <FormButton md="auto" color="info" onClick={handleAddProduto}>
            Incluir
          </FormButton>
        </Row>
        <Table
          data={lancamentos}
          columns={columns}
          paginated={false}
          pageSize={8}
          keyField="id_produto"
        />
      </ModalBase>
    </>
  );
};
