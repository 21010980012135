import React from "react";
import { Col, Row } from "reactstrap";
import {
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setImprimir,
  setLayoutImpressao,
  setLimitarImprNomeProd,
  setMsgFixa1,
  setMsgFixa2,
  setMsgFixa3,
  setMsgFixa4,
  setNroCaracImprNomeProd,
  setTransfOrcVdaPedMaisVez,
} from "../store/orcamento_slice";
import { useEffect } from "react";
import { BsTextarea } from "react-icons/bs";

const layoutsOrcamento = [
  { label: "1 - Folha A4 - Impressão padrão", value: 1 },
  { label: "2 - Matricial - Impressão padrão", value: 2 },
  {
    label: "3 - Folha A4 - Imprime CNPJ/IE da empresa no cabeçalho",
    value: 3,
  },
  {
    label: "4 - Folha A4 - Imprime o valor do desconto no item",
    value: 4,
  },
  {
    label: "5 - Folha A4 - Imprime Proposta no cabeçalho e sem ambiente",
    value: 5,
  },
  {
    label:
      "6 - Bonina 80mm - Impressão padrão para impressoras térmicas de cupom",
    value: 6,
  },
  {
    label:
      "7 - Folha A4 - Cabeçalho na primeira página, subtotal do ambiente antes dos itens",
    value: 7,
  },
  {
    label: "8 - Folha A4 - Orçamento para mecânica",
    value: 8,
  },
  {
    label: "9 - Folha A4 - Fotos dos Produtos",
    value: 9,
  },
  {
    label:
      "10 - Folha A4 - Ambientes, nome e obs. do item concatenados, detalhar preços ou não",
    value: 10,
  },
  {
    label: "100 - Bobina 80mm - Impressão para impressoras térmicas - Web",
    value: 100,
  },
];

export const PersonOrcamento = () => {
  const store = useSelector((state) => state.orcamento);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!store.limitar_impr_nome_prod) {
      dispatch(setNroCaracImprNomeProd(0));
    }
  }, [store.limitar_impr_nome_prod]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Imprimir"
          value={store.imprimir}
          onChange={(v) => dispatch(setImprimir(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={10}
          label="Layout de Impressão"
          options={layoutsOrcamento}
          defaultValue={store.layout_impressao}
          onChange={(s) => dispatch(setLayoutImpressao(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir que o Sistema Tranforme um Orçamento em Venda / Pedido mais de UMA vez"
          checked={store.transf_orc_vda_ped_mais_vez}
          onChange={() =>
            dispatch(
              setTransfOrcVdaPedMaisVez(!store.transf_orc_vda_ped_mais_vez)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Delimitar Tamanho da Descrição do Produto"
          checked={store.limitar_impr_nome_prod}
          onChange={() =>
            dispatch(setLimitarImprNomeProd(!store.limitar_impr_nome_prod))
          }
        />
        <IntegerFormInput
          md={3}
          mdInput={5}
          label="Número de Caracteres do Nome do Produto"
          defaultValue={
            store.limitar_impr_nome_prod ? store.nro_carac_impr_nome_prod : null
          }
          onChange={(v) => dispatch(setNroCaracImprNomeProd(v))}
          disabled={!store.limitar_impr_nome_prod}
        />
      </Row>
      <Row>
        <Col className="no-gutters">
          <TextInput
            type="textarea"
            md={12}
            label="Mensagens Fixas"
            value={store.msg_fixa_1}
            onChange={(e, v) => dispatch(setMsgFixa1(v))}
            forceUppercase = {false}
          />
          <TextInput
            md={12}
            value={store.msg_fixa_2}
            onChange={(e, v) => dispatch(setMsgFixa2(v))}
            forceUppercase = {false}
            maxLength={180}
          />
          <TextInput
            md={12}
            value={store.msg_fixa_3}
            onChange={(e, v) => dispatch(setMsgFixa3(v))}
            forceUppercase = {false}
            maxLength={180}
          />
          <TextInput
            md={12}
            value={store.msg_fixa_4}
            onChange={(e, v) => dispatch(setMsgFixa4(v))}
            forceUppercase = {false}
            maxLength={180}
          />
        </Col>
      </Row>
    </>
  );
};
