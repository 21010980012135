import { apiGet, apiPost, api } from "../../api";
import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost, docPrintReport } from "../../pdf";

const processaRetorno = (ret) => {
  if (ret.data.success) {
    return [true, ret.data];
  } else {
    return [false, ret.data.msg];
  }
};

const processaErro = (err) => {
  return [false, err.message];
};

const apiPostReceber = async (url, payload) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret))
    .catch((err) => processaErro(err));

const URL_BASE = "/caixa_loja";

const CaixaLojaService = {
  abrirCaixa: (payload) => apiPost(`${URL_BASE}/abrir_caixa/`, payload),
  aplicarDesconto: (payload) =>
    apiPutV2(`${URL_BASE}/aplicar_desconto/`, payload),
  buscarCaixa: () => apiGet(`${URL_BASE}/buscar_caixa/`),
  buscarDadosMovReceber: (idMov) =>
    apiGetV2(`${URL_BASE}/buscar_dados_mov_receber/${idMov}/`, {}),
  buscarSaldoFecUltCaixa: () =>
    apiGet(`${URL_BASE}/buscar_saldo_fec_ult_caixa/`),
  faturar: (payload) => apiPostReceber(`${URL_BASE}/faturar_nf/`, payload),
  fecharCaixa: (payload) => apiPutV2(`${URL_BASE}/fechar_caixa/`, payload),
  imprimirFechamento: async (idCaixa) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE}/buscar_dados_impr_fechamento/${idCaixa}/`
    );

    if (!ok) return;

    const dados = {
      layout: 1,
      nome_empresa: localStorage.getItem("nome_empresa"),
      id: idCaixa,
      ...ret,
    };

    const arq = await docPost("/caixa/fechamento/", dados, {
      errorMessage: "Erro ao Imprimir Fechamento de Caixa",
    });

    if (!arq) return;

    const fileName = `Fechamento_Caixa_${idCaixa}`;
    viewDownloadPDF(arq, fileName);
  },
  imprimirSangria: async (idMovCaixa) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE}/buscar_dados_impr_sangria/${idMovCaixa}/`
    );
    if (ok) {
      await docPrintReport("/caixa/sangria/", ret, "0063_7");
    }
  },
  imprimirRetirada: async (idMovCaixa) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE}/buscar_dados_impr_retirada/${idMovCaixa}/`
    );
    if (ok) {
      const payload = {
        layout: 1,
        ...ret,
      };
      await docPrintReport("/caixa/retirada/", payload, "0063_8");
    }
  },
  processarRecebimentoTefNovamente: (idMovCaixa) =>
    apiPostReceber(`${URL_BASE}/processar_recebimento_tef_novamente/`, {
      id_mov_caixa_loja: idMovCaixa,
    }),
  sangria: (payload) => apiPostV2(`${URL_BASE}/sangria/`, payload),
  reforcoCaixa: (payload) => apiPost(`${URL_BASE}/reforco_caixa/`, payload),
  reabrirVenda: (payload) => apiPutV2(`${URL_BASE}/reabrir_venda/`, payload),
  receber: (payload) => apiPostReceber(`${URL_BASE}/receber/`, payload),
  retirada: (payload) => apiPostV2(`${URL_BASE}/retirada/`, payload),
  verifTemRecTefPendente: (idMovCaixa) =>
    apiGetV2(`${URL_BASE}/verif_tem_rec_tef_pendente/${idMovCaixa}/`),
  verifPodeAplicarDesconto: (idMovCaixa) =>
    apiGetV2(`${URL_BASE}/verif_pode_aplicar_desconto/${idMovCaixa}/`),
};

export default CaixaLojaService;
