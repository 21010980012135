import React, { useEffect, useState } from "react";
import { Row, Spinner } from "reactstrap";
import { BsSearch } from "react-icons/bs";
import SearchInput from "./SearchInput";
import { ModalBase } from "./ModalBase";
import { apiGet, urlParams } from "../api";
import { debounce, defaultDebounceTime } from "../coreUtils";
import { Table } from "./Table";
import { toastr } from "react-redux-toastr";

export const PesqModal = ({
  onChange = () => {},
  icon: Icon = BsSearch,
  route = "",
  isMulti,
  value, // Criar forma de selecionar o valor informado na grade e rolar a listagem até ele
  defaultOptions,
  title,
  keyField = "id",
  labelField = "nome",
  queryParam = "pista",
  terc,
  tercText = "",
  modalSize = "md",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pista, setPista] = useState("");
  const [loadingPista, setLoadingPista] = useState(false);
  const [selected, setSelected] = useState(null);

  const toggle = async () => {
    if (!isOpen) {
      await loadOptions();
    } else {
      setOptions([]);
      setPista("");
      setSelected(null);
    }
    setIsOpen(!isOpen);
  };

  const onConfirm = () => {
    if ([0, null, undefined].includes(selected)) {
      toastr.warning("Atenção", "Por favor, selecione um item da lista.");
      return false;
    }
    onChange(selected);
    toggle();
  };

  useEffect(() => {
    if (defaultOptions) {
      loadOptions();
    }
  }, []);

  const loadOptions = async (p = pista) => {
    setLoading(true);
    const ret = await apiGet(`${route}${urlParams({ [queryParam]: p })}`);
    setOptions(ret);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await loadOptions(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const columns = [
    { dataField: keyField, text: "#", align: "center", headerAlign: "center" },
    {
      dataField: labelField,
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    ...(terc
      ? [
          {
            dataField: terc,
            text: tercText,
            align: "center",
            headerAlign: "center",
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        className="react-select-shortcut"
        style={{
          marginTop: "auto",
          marginBottom: "0.35rem",
          height: "1.95rem",
        }}
        onClick={toggle}
      >
        {loading ? (
          <Spinner size="sm" color="light" />
        ) : (
          <Icon size={18} color="white" />
        )}
      </div>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size={modalSize}
        title={`Pesquisa de ${title}`}
        onConfirm={onConfirm}
        footerActions
      >
        <Row className="mb-2">
          <SearchInput
            hideLabel
            md={10}
            autoFocus
            onChange={handlePista}
            loading={loadingPista}
          />
        </Row>
        <Table
          keyField={keyField}
          data={options}
          columns={columns}
          paginated={false}
          onSelect={setSelected}
          multiselect={isMulti}
          onRowDoubleClick={onConfirm}
        />
      </ModalBase>
    </>
  );
};
