import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { GerenciamentoCobrancaBancariaService } from "../../../../services/financeiro/GerenciamentoCobrancaBancariaService";
import { GerencCobrancaBancGrid } from "./components/GerencCobrancaBancGrid";
import { GerarBoletosModal } from "./components/GerarBoletosModal";
import { ImprimirBoletosModal } from "../boleto/components/ImprimirBoletosModal";
import { DetalhesCobrancaBancModal } from "./components/DetalhesCobrancaBancModal";
import { EmailBoletosModal } from "../boleto/components/EmailBoletosModal";
import { ConfirmaCancelarBoletoModal } from "./components/ConfirmaCancelarBoletoModal";

const filtrarPorOptions = [
  { label: "Emissão", value: "EMI" },
  { label: "Vencimento", value: "VCT" },
  { label: "Recebimento", value: "RCB" },
  { label: "Ocorrência Boleto", value: "OCB" },
];

export const SIGLA_MOSTRAR_PENDENTES = "PEN";
export const SIGLA_MOSTRAR_LIQUIDADOS = "LIQ";

const mostrarOptions = [
  { label: "Pendentes", value: SIGLA_MOSTRAR_PENDENTES },
  { label: "Liquidados", value: SIGLA_MOSTRAR_LIQUIDADOS },
];

const ocorrenciasOptions = [
  { label: "Criação do boleto", value: "REGIS" },
  { label: "Rejeição do boleto", value: "REJEI" },
  { label: "Atualização de dados do boleto", value: "ALTER" },
  { label: "Erro na atualização de dados do boleto", value: "ERRAL" },
  { label: "Boleto cancelado", value: "CANCL" },
  { label: "Erro no cancelamento do boleto", value: "ERRCA" },
  { label: "Pagamento do boleto", value: "PAGMT" },
  { label: "Estorno de pagamento do boleto", value: "ESTOR" },
  { label: "Erro no pagamento do boleto", value: "ERRPG" },
];

const __totaisInitialState = { vlr_receber: 0, vlr_recebido: 0 };

export const GerencCobrancaBanc = () => {
  const [idCliente, setIdCliente] = useState(undefined);
  const [idTipoCliente, setIdTipoCliente] = useState(undefined);
  const [nroBoleto, setNroBoleto] = useState(undefined);
  const [nroOrigem, setNroOrigem] = useState(undefined);
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [ocorrenciaMostrar, setOcorrenciaMostrar] = useState(undefined);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().setDate(new Date().getDate() - 3))
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [somenteBoletos, setSomenteBoletos] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [lastSelectedLayout, setLastSelectedLayout] = useState(null);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState(__totaisInitialState);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);

  const handleDateInput = (dIni, dFim) => {
    setDataIni(dIni);
    setDataFim(dFim);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_cliente: idCliente,
        id_tipo_cliente: idTipoCliente,
        nro_boleto: nroBoleto,
        nro_origem: nroOrigem,
        tipo_periodo: filtrarPor,
        data_ini: dataIni,
        data_fim: dataFim,
        mostrar: mostrar,
        tipo_ocorrencia: ocorrenciaMostrar,
        somente_boletos: somenteBoletos,
      };

      const [ok, ret] = await GerenciamentoCobrancaBancariaService.listar(
        params
      );
      if (ok) {
        setSelected([]);
        setDados(ret.titulos);
        setTotais(ret.totais);
      }
    } finally {
      setLoading(false);
    }
  };

  const limparDados = () => {
    setDados([]);
    setTotais(__totaisInitialState);
    setSelected([]);
  };

  useEffect(() => {
    limparDados();
    carregarDados();
  }, [mostrar]);

  useEffect(() => {
    if (filtrarPor !== "OCB") {
      setOcorrenciaMostrar(undefined);
    }
  }, [filtrarPor]);

  const handleSelect = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, row] : selected.filter((e) => e.id !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados : []);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);
  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const cancelarBoleto = (id, layoutBoleto) => {
    setLastSelected(id);
    setLastSelectedLayout(layoutBoleto);
    setTimeout(() => {
      toggleCancelar();
    }, 1);
  };

  return (
    <PageContainer
      title="Gerenciamento de Integração de Cobrança Bancária"
      number="0087"
    >
      <Card body>
        <Row className="mb-2">
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            md={2}
            label="Tipo de Cliente"
            isConcatField
            concatModelName="tipo_cliente"
            isSearchable
            onChange={(s) => setIdTipoCliente(s?.value)}
            defaultOptions
          />
          <TextInput
            label="Nº Boleto"
            value={nroBoleto}
            onChange={(e, v) => setNroBoleto(v)}
            md={2}
          />
          <IntegerFormInput
            label="Nº Origem"
            onChange={setNroOrigem}
            defaultValue={nroOrigem}
            md={2}
          />
          <ComboBox
            label="Mostrar"
            md={2}
            onChange={(s) => setMostrar(s?.value)}
            options={mostrarOptions}
            defaultValue={mostrar}
          />
        </Row>
        <Row className="mb-2">
          <ComboBox
            label="Filtrar Por"
            md={2}
            onChange={(s) => setFiltrarPor(s?.value)}
            options={filtrarPorOptions}
            defaultValue={filtrarPor}
          />
          <FiltroPeriodoDatas onChange={handleDateInput} defaultOption="3D" />
          {filtrarPor === "OCB" && (
            <ComboBox
              label="Ocorrência"
              md="3"
              onChange={(s) => setOcorrenciaMostrar(s?.value)}
              options={ocorrenciasOptions}
              defaultValue={ocorrenciaMostrar}
              isClearable
            />
          )}
          <FormCheckbox
            label="Somente Boletos"
            md="auto"
            defaultChecked={false}
            onChange={() => setSomenteBoletos(!somenteBoletos)}
            name="somente_boletos"
          />
          <BotaoPesquisar loading={loading} onClick={carregarDados} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Valor a Receber"
          value={totais?.vlr_receber}
          checkFloat
        />
        <CardTotaisItem
          label="Valor Recebido"
          value={totais?.vlr_recebido}
          checkFloat
        />
      </CardTotais>
      <Card body>
        <Row className="mb-2">
          <GerarBoletosModal selected={selected} notifyAction={carregarDados} />
          <ImprimirBoletosModal
            selected={selected}
            notifyAction={carregarDados}
            disabledButtons={selected.length === 0}
          />
          <EmailBoletosModal
            duplics={selected.map((e) => e.id)}
            notifyAction={carregarDados}
            disabledButtons={selected.length === 0}
          />
        </Row>
        <GerencCobrancaBancGrid
          dados={dados}
          mostrar={mostrar}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          mostrarDetalhes={mostrarDetalhes}
          cancelarBoleto={cancelarBoleto}
        />
        <DetalhesCobrancaBancModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          selected={lastSelected}
        />
        <ConfirmaCancelarBoletoModal
          isOpen={cancelarOpen}
          toggle={toggleCancelar}
          selected={lastSelected}
          selectedLayout={lastSelectedLayout}
          notifyAction={carregarDados}
        />
      </Card>
    </PageContainer>
  );
};
