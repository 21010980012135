import React, { useState } from "react";
import { Row } from "reactstrap";
import { AsyncComboBox, FormButton } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { BoletoService } from "../../../../../services/bancario/BoletoService";

export const GerarBoletosModal = ({ selected = [], notifyAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [conta, setConta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    // Limpa conta bancária apenas na abertura do modal
    if (!isOpen) setConta(null);
    setIsOpen(!isOpen);
  };

  const onClickGerarConta = () => {
    gerarBoletos(selected, conta);
    toggle();
  };

  const gerarBoletos = async (duplics, conta_banc_id) => {
    setIsLoading(true);
    for (var i = 0; i < duplics.length; i++) {
      await BoletoService.gerarRegistroBoleto({
        id_conta_banc: ![0, null, undefined].includes(duplics[i].id_conta)
          ? duplics[i].id_conta
          : conta_banc_id,
        id_duplicata: duplics[i].id,
      });
    }
    notifyAction("print");
    setIsLoading(false);
  };

  const onClickGerar = () => {
    if (selected.some((item) => [0, null, undefined].includes(item.id_conta))) {
      toggle();
    } else {
      gerarBoletos(selected);
    }
  };

  return (
    <React.Fragment>
      <FormButton
        onClick={onClickGerar}
        disabled={selected.length === 0}
        color="info"
        loading={isLoading}
        padded={false}
        md="auto"
        hint="Atenção: os boletos não serão impressos automaticamente"
      >
        Gerar Boleto
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Geração/Registro de boletos"
        number="0087_1"
        footerActions={
          <FormButton
            md="auto"
            color="success"
            padded={false}
            divClassName="pr-0"
            onClick={onClickGerarConta}
            disabled={!conta}
            disabledHint="Selecione uma conta bancária"
          >
            Confirmar
          </FormButton>
        }
        hideCancelButton
      >
        <Row form>
          <AsyncComboBox
            label="Conta Bancária"
            isConcatField
            concatModelName="conta_banc"
            isSearchable
            isClearable
            onChange={(s) => setConta(s?.value)}
            required
            defaultOptions
            md={12}
            autoFocus
          />
        </Row>
      </ModalBase>
    </React.Fragment>
  );
};
