import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/jornada/roteirizacao/cadastro";

const RoteirizacaoService = {
  buscarRoteirizDia: async (rota, dia) =>
    await apiGetV2(`${URL_BASE}/listar_dia/${rota}/${dia}/`),
  addRoteirizacao: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: async (id) => await apiDeleteV2(`${URL_BASE}/excluir/${id}/`),
  alteraOrdem: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_ordem/`, payload),
  gerarPorCidade: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_por_cidade/`, payload),
};

export default RoteirizacaoService;
