import React from "react";
import { PageContainer } from "../../components";
import { Avisos } from "./components/avisos/Avisos";

export const Home = ({}) => {
  return (
    <PageContainer title="Início">
      <Avisos />
    </PageContainer>
  );
};
