import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  Table,
  TableDelete,
} from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import FormaRecClienteService from "../../../../../services/cadastro/FormaRecClienteService";

const moedas = [
  { label: "Dinheiro", value: "DINH" },
  { label: "Boleto", value: "BOLE" },
];

export const FormaRecClienteModal = ({
  isOpen,
  toggle,
  idCliente,
  nomeCliente,
}) => {
  const [moeda, setMoeda] = useState("DINH");
  const [condPag, setCondPag] = useState(null);
  const condPagRef = useRef();
  const [dados, setDados] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const limparDados = () => {
    setDados([]);
    setMoeda("DINH");
    setCondPag(null);
  };

  const carregarDados = async () => {
    const [ok, ret] = await FormaRecClienteService.listar(idCliente);
    setDados(ok ? ret : []);
  };

  const removerRegistro = async (idReg) => {
    const [ok] = await FormaRecClienteService.excluir(idReg);
    if (ok) {
      carregarDados();
    }
  };

  const handleSubmit = async () => {
    const payload = {
      id_cliente: idCliente,
      moeda: moeda,
      id_cond_pag: condPag,
    };

    setLoadingAdd(true);
    const [ok] = await FormaRecClienteService.incluir(payload);
    if (ok) {
      setCondPag(null);
      if (condPagRef.current) condPagRef.current.clearValue();
      carregarDados();
    }
    setLoadingAdd(false);
  };

  const handleSelMoeda = (selected) => {
    setMoeda(selected.value);
    if (selected.value === "DINH") setCondPag(null);
  };

  const columns = [
    {
      dataField: "moeda",
      text: "Moeda",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "id_cond_pag__descricao",
      text: "Condição de Pagamento",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "id_cond_pag__parcelas",
      text: "Parcelas",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => removerRegistro(row.id)}
          disabled={row.padrao}
          disabledHint="Formas de recebimento padrão não podem ser excluídas"
        />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title={
        <>
          <div> FORMAS DE RECEBIMENTO</div>
          <div style={{ fontSize: "0.75rem" }}> Cliente: {nomeCliente}</div>
        </>
      }
      number="0022_1"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row>
        <ComboBox
          md={4}
          label="Moeda"
          options={moedas}
          onChange={handleSelMoeda}
          autoFocus
          defaultValue={moeda}
        />
        {moeda !== "DINH" && (
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="cond_pag"
            defaultOptions
            md={5}
            label="Condição de pagamento"
            onChange={(s) => setCondPag(s?.value)}
            ref={condPagRef}
          />
        )}
        <FormButton
          md={3}
          color="info"
          onClick={handleSubmit}
          loading={loadingAdd}
          disabled={condPag == null && moeda !== "DINH"}
          disabledHint="Informe a condição de pagamento no campo à esquerda"
        >
          Adicionar
        </FormButton>
      </Row>
      <Table columns={columns} data={dados} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
