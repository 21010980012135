import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsCalculator, BsListOl } from "react-icons/bs";
import {
  cadastroRegraTributacaoRoute,
  cadastroGrupoRegraTributacaoRoute,
  cadastroTipoDocumentoRoute,
  cadastroNumeracaoNfeRoute,
  cadastroCfopRoute,
} from "../../../routes/modules/cadastro";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiListNumbers } from "react-icons/pi";
import { TbAbacus } from "react-icons/tb";

export const Tributacao = () => {
  return (
    <PageContainer title="Cadastros - Tributação">
      <MenuGroup>
        <MenuGroupButton
          label="Grupo de Tributação"
          icon={BsListOl}
          pathname={cadastroGrupoRegraTributacaoRoute.path}
          nroTela={cadastroGrupoRegraTributacaoRoute.nroTela}
        />
        <MenuGroupButton
          label="Numeração de NFe"
          icon={PiListNumbers}
          pathname={cadastroNumeracaoNfeRoute.path}
          nroTela={cadastroNumeracaoNfeRoute.nroTela}
        />
        <MenuGroupButton
          label="Regra de Tributação"
          icon={BsCalculator}
          pathname={cadastroRegraTributacaoRoute.path}
          nroTela={cadastroRegraTributacaoRoute.nroTela}
        />
        <MenuGroupButton
          label="Tipo de Documento"
          icon={IoDocumentTextOutline}
          pathname={cadastroTipoDocumentoRoute.path}
          nroTela={cadastroTipoDocumentoRoute.nroTela}
        />
        <MenuGroupButton
          label="CFOP"
          icon={TbAbacus}
          pathname={cadastroCfopRoute.path}
          nroTela={cadastroCfopRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
