import React from "react";
import { Table } from "../../../../../components";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "item",
    text: "Item",
    align: "center",
    fixedColWidth: true,
    colWidth: "50px",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "tipo",
    text: "T",
    align: "center",
    fixedColWidth: true,
    colWidth: "40px",
    formatter: (c) => <ProdServIcone value={c} />,
    sortable: true,
  },
  {
    dataField: "id_item",
    text: "# Item",
    align: "center",
    fixedColWidth: true,
    colWidth: "70px",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    sortable: true,
  },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    fixedColWidth: true,
    colWidth: "80px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_item",
    text: "V. Item",
    align: "right",
    fixedColWidth: true,
    colWidth: "80px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    fixedColWidth: true,
    colWidth: "60px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_desc",
    text: "Desconto",
    align: "right",
    fixedColWidth: true,
    colWidth: "80px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_acre",
    text: "Acrés.",
    align: "right",
    fixedColWidth: true,
    colWidth: "80px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    fixedColWidth: true,
    colWidth: "90px",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const TransfVendaItensGrid = ({ itens }) => {
  return (
    <Table data={itens} columns={columns} paginated={false} pageSize={8} />
  );
};
