import moment from "moment";
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { isValidDate } from "../coreUtils";
import { DatePicker } from "./DatePicker";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { CgChevronDown } from "react-icons/cg";

// NÃO USAR esta constante na função tirarDia
const hoje = new Date();

const mesAtu = hoje.getMonth();

const anoAtu = hoje.getFullYear();

const primDiaMesAnt = new Date(anoAtu, mesAtu - 1, 1);
const ultDiaMesAnt = new Date(anoAtu, mesAtu, 0);

const primDiaMesAtu = new Date(anoAtu, mesAtu, 1);
const ultDiaMesAtu = new Date(anoAtu, mesAtu + 1, 0);

const primDiaProxMes = new Date(anoAtu, mesAtu + 1, 1);
const ultDiaProxMes = new Date(anoAtu, mesAtu + 2, 0);

const DropdownOptions = ({ options, onChange, disabled }) => {
  return (
    <UncontrolledDropdown
      direction="down"
      onToggle={(e) => e.stopPropagation()}
      className="pr-2"
    >
      <DropdownToggle
        className="form-control"
        style={{
          background: "none",
          borderColor: "rgb(194 201 207)",
          marginTop: "1.2rem",
          cursor: "pointer",
          boxShadow: "none",
          paddingInline: "0.25rem",
          paddingTop: "0.2rem",
        }}
        disabled={disabled}
      >
        <CgChevronDown size={25} color="rgb(194 201 207)" />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((e) => (
          <DropdownItem
            onClick={() => onChange(e.value)}
            disabled={e.isDisabled}
            style={{ color: e.isDisabled ? "silver" : "inherit" }}
          >
            {e.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const FiltroPeriodoDatas = forwardRef(
  (
    {
      onChange,
      label = "Período",
      defaultOption = "HOJE",
      defaultStart = new Date(),
      defaultEnd = new Date(),
      minStart,
      maxEnd,
      disabled,
    },
    ref
  ) => {
    const [dataIniInt, setDataIniInt] = useState(defaultStart);
    const [dataFimInt, setDataFimInt] = useState(defaultEnd);

    const dataMenosDias = (data, dias) => {
      let df = new Date(data.toISOString());

      df.setDate(df.getDate() - dias);

      return df;
    };

    const options = [
      {
        label: "Hoje",
        value: "HOJE",
      },
      {
        label: "Ontem",
        value: "ONTEM",
        isDisabled:
          minStart instanceof Date &&
          dataFimInt instanceof Date &&
          minStart.getTime() > dataMenosDias(dataFimInt, 1),
      },
      {
        label: "3 Dias",
        value: "3D",
        isDisabled:
          minStart instanceof Date &&
          dataFimInt instanceof Date &&
          minStart.getTime() > dataMenosDias(dataFimInt, 3),
      },
      {
        label: "7 Dias",
        value: "7D",
        isDisabled:
          minStart instanceof Date &&
          dataFimInt instanceof Date &&
          minStart.getTime() > dataMenosDias(dataFimInt, 7),
      },
      {
        label: "15 Dias",
        value: "15D",
        isDisabled:
          minStart instanceof Date &&
          dataFimInt instanceof Date &&
          minStart.getTime() > dataMenosDias(dataFimInt, 15),
      },
      {
        label: "30 Dias",
        value: "30D",
        isDisabled:
          minStart instanceof Date &&
          dataFimInt instanceof Date &&
          minStart.getTime() > dataMenosDias(dataFimInt, 30),
      },
      {
        label: "Mês Atual",
        value: "MESATU",
        isDisabled:
          (maxEnd instanceof Date &&
            maxEnd.getTime() < primDiaMesAtu.getTime()) ||
          (minStart instanceof Date &&
            minStart.getTime() > ultDiaMesAtu.getTime()),
      },
      {
        label: "Mês Anterior",
        value: "MESANT",
        isDisabled:
          (maxEnd instanceof Date &&
            maxEnd.getTime() < primDiaMesAnt.getTime()) ||
          (minStart instanceof Date &&
            minStart.getTime() > ultDiaMesAnt.getTime()),
      },
      {
        label: "Mês Anterior + Atual",
        value: "MESATUANT",
        isDisabled:
          (maxEnd instanceof Date &&
            maxEnd.getTime() < primDiaMesAnt.getTime()) ||
          (minStart instanceof Date &&
            minStart.getTime() > ultDiaMesAtu.getTime()),
      },
      {
        label: "Próximo Mês",
        value: "PROXMES",
        isDisabled:
          (maxEnd instanceof Date &&
            maxEnd.getTime() < primDiaProxMes.getTime()) ||
          (minStart instanceof Date &&
            minStart.getTime() > ultDiaProxMes.getTime()),
      },
    ];

    const handleDataIni = (value) => {
      var _dataIni = moment.isMoment(value) ? value.toDate() : value;
      setDataIniInt(_dataIni);
      onChange(_dataIni, dataFimInt);
    };

    const handleDataFim = (value) => {
      var _dataFim = moment.isMoment(value) ? value.toDate() : value;
      setDataFimInt(_dataFim);
      onChange(dataIniInt, _dataFim);
    };

    const handleSelect = (value) => {
      let _dataFim = dataFimInt;
      if (
        !(dataFimInt instanceof Date) &&
        ["3D", "7D", "15D", "30D"].includes(value)
      ) {
        _dataFim = new Date();
      }
      let _dataIni =
        dataFimInt instanceof Date
          ? new Date(dataFimInt.getTime())
          : new Date();

      switch (value) {
        case "HOJE":
          _dataIni = new Date();
          _dataFim = new Date();
          break;
        case "ONTEM":
          let ontem = new Date();
          ontem.setDate(ontem.getDate() - 1);

          _dataIni = ontem;
          _dataFim = ontem;
          break;
        case "3D":
          _dataIni = dataMenosDias(_dataFim, 3);
          break;
        case "7D":
          _dataIni = dataMenosDias(_dataFim, 7);
          break;
        case "15D":
          _dataIni = dataMenosDias(_dataFim, 15);
          break;
        case "30D":
          _dataIni = dataMenosDias(_dataFim, 30);
          break;
        case "MESATU":
          _dataFim = ultDiaMesAtu;
          _dataIni = primDiaMesAtu;
          break;
        case "MESANT":
          _dataFim = ultDiaMesAnt;
          _dataIni = primDiaMesAnt;
          break;
        case "MESATUANT":
          _dataFim = ultDiaMesAtu;
          _dataIni = primDiaMesAnt;
          break;
        case "PROXMES":
          _dataFim = ultDiaProxMes;
          _dataIni = primDiaProxMes;
          break;
        case null:
        case undefined:
          _dataFim = null;
          _dataIni = null;
          break;
        default:
          return;
      }

      if (!isValidDate(_dataIni)) {
        _dataIni = null;
      } else {
        if (
          minStart instanceof Date &&
          _dataIni.getTime() < minStart.getTime()
        ) {
          _dataIni = new Date(minStart.toISOString());
        }
      }

      if (!isValidDate(_dataFim)) {
        _dataFim = null;
      } else {
        if (maxEnd instanceof Date && _dataFim.getTime() > maxEnd.getTime()) {
          _dataFim = new Date(maxEnd.toISOString());
        }
      }

      setDataFimInt(_dataFim);
      setDataIniInt(_dataIni);
      onChange(_dataIni, _dataFim);
    };

    useImperativeHandle(ref, () => ({
      updateDataIni: (dataIni) => handleDataIni(dataIni),
      updateDataFim: (dataFim) => handleDataFim(dataFim),
      selecionarPeriodo: (periodo) => handleSelect(periodo),
    }));

    useEffect(() => {
      if (defaultOption) {
        handleSelect(defaultOption);
      }
    }, [defaultOption]);

    return (
      <>
        <DatePicker
          label={label ? `${label} - Início` : "Início"}
          name="data_ini"
          value={dataIniInt}
          onChange={handleDataIni}
          inputId="in-data-ini"
          minDate={minStart}
          maxDate={maxEnd}
          disabled={disabled}
          divClassName="pr-0"
        />
        <DatePicker
          label="Fim"
          name="data_fim"
          value={dataFimInt}
          onChange={handleDataFim}
          inputId="in-data-fim"
          minDate={minStart}
          maxDate={maxEnd}
          disabled={disabled}
        />
        <DropdownOptions
          options={options}
          onChange={handleSelect}
          disabled={disabled}
        />
      </>
    );
  }
);
