import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preco_prod_utilizado: "VAREJO",
  identifica_produto_revenda: false,
  id_conta_banc_pix: null,
  perc_desc_max_sobre_total_dinheiro: 0,
  perc_desc_max_sobre_total_pix: 0,
  id_plano_contas_pix: null,
  id_plano_contas_boleto: null,
  id_plano_contas_dinheiro: null,
  bloq_pedido_cli_outro_vend: false,
  utiliza_flex: false,
  verifica_estoque_antes_pedido: false,
  layout_impr_ped_app: 1,
  bloquear_boleto_se_inadimplente: false,
  dias_bloquear_boleto: 0,
  organiza_clientes_por: "REPRESENTANTE",
};

const fieldsToNull = [
  "id_conta_banc_pix",
  "id_plano_contas_pix",
  "id_plano_contas_boleto",
  "id_plano_contas_dinheiro",
];

export const appPedidoVendaSlice = createSlice({
  name: "app_pedido_venda_slice",
  initialState: initialState,
  reducers: {
    setupAppPedidoVenda: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }
        if (
          [
            "perc_desc_max_sobre_total_dinheiro",
            "perc_desc_max_sobre_total_pix",
          ].includes(key)
        ) {
          value = parseFloat(value);
        }
        state[key] = value;
      });
      return state;
    },
    setPrecoProdUtilizado: (state, action) => {
      state.preco_prod_utilizado = action.payload;
    },
    setIdentificaProdutoRevenda: (state, action) => {
      state.identifica_produto_revenda = action.payload;
    },
    setIdContaBancPix: (state, action) => {
      state.id_conta_banc_pix = action.payload;
    },
    setPercDescMaxSobreTotalDinheiro: (state, action) => {
      state.perc_desc_max_sobre_total_dinheiro = action.payload;
    },
    setPercDescMaxSobreTotalPix: (state, action) => {
      state.perc_desc_max_sobre_total_pix = action.payload;
    },
    setIdPlanoContasPix: (state, action) => {
      state.id_plano_contas_pix = action.payload;
    },
    setIdPlanoContasBoleto: (state, action) => {
      state.id_plano_contas_boleto = action.payload;
    },
    setIdPlanoContasDinheiro: (state, action) => {
      state.id_plano_contas_dinheiro = action.payload;
    },
    setBloqPedidoCliOutroVend: (state, action) => {
      state.bloq_pedido_cli_outro_vend = action.payload;
    },
    setUtilizaFlex: (state, action) => {
      state.utiliza_flex = action.payload;
    },
    setVerificaEstoqueAntesPedido: (state, action) => {
      state.verifica_estoque_antes_pedido = action.payload;
    },
    setLayoutImprPedApp: (state, action) => {
      state.layout_impr_ped_app = action.payload;
    },
    setBloquearBoletoSeInadimplente: (state, action) => {
      state.bloquear_boleto_se_inadimplente = action.payload;
    },
    setDiasBloquearBoleto: (state, action) => {
      state.dias_bloquear_boleto = action.payload;
    },
    setOrganizaClientesPor: (state, action) => {
      state.organiza_clientes_por = action.payload;
    },
  },
});

export const {
  setupAppPedidoVenda,
  setPrecoProdUtilizado,
  setIdentificaProdutoRevenda,
  setIdContaBancPix,
  setPercDescMaxSobreTotalDinheiro,
  setPercDescMaxSobreTotalPix,
  setIdPlanoContasPix,
  setIdPlanoContasBoleto,
  setIdPlanoContasDinheiro,
  setBloqPedidoCliOutroVend,
  setUtilizaFlex,
  setVerificaEstoqueAntesPedido,
  setLayoutImprPedApp,
  setBloquearBoletoSeInadimplente,
  setDiasBloquearBoleto,
  setOrganizaClientesPor,
} = appPedidoVendaSlice.actions;

export default appPedidoVendaSlice.reducer;
