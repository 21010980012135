import React, { useEffect, useState } from "react";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  PhoneNumberInput,
  TextInput,
} from "../../../../../../components";
import { MODAL_ACTIONS, formatValueFromAPI } from "../../../../../../coreUtils";
import CadastroClienteService from "../../../../../../services/cadastro/CadastroClienteService";
import { Label, Row } from "reactstrap";
import { showWarning } from "../../../../../../components/AlertaModal";

export const CompradorClienteModal = ({ idCliente, nomeCliente }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [nomeComprador, setNomeComprador] = useState("");
  const [email, setEmail] = useState("");
  const [fone1, setFone1] = useState("");
  const [fone2, setFone2] = useState("");
  const [segundaManha, setSegundaManha] = useState(false);
  const [segundaTarde, setSegundaTarde] = useState(false);
  const [segundaNoite, setSegundaNoite] = useState(false);
  const [tercaManha, setTercaManha] = useState(false);
  const [tercaTarde, setTercaTarde] = useState(false);
  const [tercaNoite, setTercaNoite] = useState(false);
  const [quartaManha, setQuartaManha] = useState(false);
  const [quartaTarde, setQuartaTarde] = useState(false);
  const [quartaNoite, setQuartaNoite] = useState(false);
  const [quintaManha, setQuintaManha] = useState(false);
  const [quintaTarde, setQuintaTarde] = useState(false);
  const [quintaNoite, setQuintaNoite] = useState(false);
  const [sextaManha, setSextaManha] = useState(false);
  const [sextaTarde, setSextaTarde] = useState(false);
  const [sextaNoite, setSextaNoite] = useState(false);

  const limparDadosComprador = () => {
    setAction(MODAL_ACTIONS.ADD);
    setNomeComprador("");
    setEmail("");
    setFone1("");
    setFone2("");
  };

  const limparDados = () => {
    limparDadosComprador();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroClienteService.comprador.buscar(idCliente);
    if (ok) {
      setNomeComprador(ret.nome);
      setEmail(ret.email);
      setFone1(ret.fone1);
      setFone2(ret.fone2);
      setSegundaManha(ret.seg_manha);
      setSegundaTarde(ret.seg_tarde);
      setSegundaNoite(ret.seg_noite);
      setTercaManha(ret.ter_manha);
      setTercaTarde(ret.ter_tarde);
      setTercaNoite(ret.ter_noite);
      setQuartaManha(ret.qua_manha);
      setQuartaTarde(ret.qua_tarde);
      setQuartaNoite(ret.qua_noite);
      setQuintaManha(ret.qui_manha);
      setQuintaTarde(ret.qui_tarde);
      setQuintaNoite(ret.qui_noite);
      setSextaManha(ret.sex_manha);
      setSextaTarde(ret.sex_tarde);
      setSextaNoite(ret.sex_noite);
    } else {
      toggle(); // fecha se houve erro ao carregar
    }
  };

  const handleSubmit = async () => {
    const payload = {
      id_cliente: idCliente,
      nome: nomeComprador,
      email: email,
      fone1: fone1,
      fone2: fone2,
      seg_manha: segundaManha,
      seg_tarde: segundaTarde,
      seg_noite: segundaNoite,
      ter_manha: tercaManha,
      ter_tarde: tercaTarde,
      ter_noite: tercaNoite,
      qua_manha: quartaManha,
      qua_tarde: quartaTarde,
      qua_noite: quartaNoite,
      qui_manha: quintaManha,
      qui_tarde: quintaTarde,
      qui_noite: quintaNoite,
      sex_manha: sextaManha,
      sex_tarde: sextaTarde,
      sex_noite: sextaNoite,
    };

    const [ok] = await CadastroClienteService.comprador.alterar(payload);
    if (ok) {
      toggle();
    }
  };

  useEffect(() => {
    if (
      nomeComprador === "" &&
      email === "" &&
      fone1 === "" &&
      fone2 === "" &&
      segundaManha === false &&
      segundaTarde === false &&
      segundaNoite === false &&
      tercaManha === false &&
      tercaTarde === false &&
      tercaNoite === false &&
      quartaManha === false &&
      quartaTarde === false &&
      quartaNoite === false &&
      quintaManha === false &&
      quintaTarde === false &&
      quintaNoite === false &&
      sextaManha === false &&
      sextaTarde === false &&
      sextaNoite === false
    ) {
      setAction(MODAL_ACTIONS.ADD);
    }
  }, [
    nomeComprador,
    email,
    fone1,
    fone2,
    segundaManha,
    segundaTarde,
    segundaNoite,
    tercaManha,
    tercaTarde,
    tercaNoite,
    quartaManha,
    quartaTarde,
    quartaNoite,
    quintaManha,
    quintaTarde,
    quintaNoite,
    sextaManha,
    sextaTarde,
    sextaNoite,
  ]);

  return (
    <>
      <FormButton md="auto" onClick={toggle} padded={false} color="primary">
        Comprador
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Comprador do cliente"
        number="0040_7"
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
        onConfirm={handleSubmit}
        
      >
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, idCliente)}
            horizontal
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Nome do comprador"
            value={nomeComprador}
            onChange={(e, v) => setNomeComprador(v)}
            maxLength={80}
          />
        </Row>

        <Row>
          <PhoneNumberInput
            md={4}
            label="Fone 1"
            value={fone1}
            onChange={setFone1}
            maxLength={18}
          />
          <PhoneNumberInput
            md={4}
            label="Fone 2"
            value={fone2}
            onChange={setFone2}
            maxLength={18}
          />
        </Row>
        <Row className="mb-2">
          <TextInput
            md={12}
            label="E-Mail"
            value={email}
            onChange={(e, v) => setEmail(v)}
            maxLength={200}
          />
        </Row>
        <Row>
          <Label style={{ marginLeft: "16px" }}>Horários de atendimento:</Label>
        </Row>
        <Row>
          <Label
            style={{ paddingTop: "26px", marginLeft: "16px", width: "80px" }}
          >
            Segunda-feira:
          </Label>
          <FormCheckbox
            label={"Manhã"}
            onChange={() => setSegundaManha(!segundaManha)}
            checked={segundaManha}
          />
          <FormCheckbox
            label={"Tarde"}
            onChange={() => setSegundaTarde(!segundaTarde)}
            checked={segundaTarde}
          />
          <FormCheckbox
            label={"Noite"}
            onChange={() => setSegundaNoite(!segundaNoite)}
            checked={segundaNoite}
          />
        </Row>
        <Row>
          <Label
            style={{ paddingTop: "26px", marginLeft: "16px", width: "80px" }}
          >
            Terça-feira:
          </Label>
          <FormCheckbox
            label={"Manhã"}
            onChange={() => setTercaManha(!tercaManha)}
            checked={tercaManha}
          />
          <FormCheckbox
            label={"Tarde"}
            onChange={() => setTercaTarde(!tercaTarde)}
            checked={tercaTarde}
          />
          <FormCheckbox
            label={"Noite"}
            onChange={() => setTercaNoite(!tercaNoite)}
            checked={tercaNoite}
          />
        </Row>
        <Row>
          <Label
            style={{ paddingTop: "26px", marginLeft: "16px", width: "80px" }}
          >
            Quarta-feira:
          </Label>
          <FormCheckbox
            label={"Manhã"}
            onChange={() => setQuartaManha(!quartaManha)}
            checked={quartaManha}
          />
          <FormCheckbox
            label={"Tarde"}
            onChange={() => setQuartaTarde(!quartaTarde)}
            checked={quartaTarde}
          />
          <FormCheckbox
            label={"Noite"}
            onChange={() => setQuartaNoite(!quartaNoite)}
            checked={quartaNoite}
          />
        </Row>
        <Row>
          <Label
            style={{ paddingTop: "26px", marginLeft: "16px", width: "80px" }}
          >
            Quinta-feira:
          </Label>
          <FormCheckbox
            label={"Manhã"}
            onChange={() => setQuintaManha(!quintaManha)}
            checked={quintaManha}
          />
          <FormCheckbox
            label={"Tarde"}
            onChange={() => setQuintaTarde(!quintaTarde)}
            checked={quintaTarde}
          />
          <FormCheckbox
            label={"Noite"}
            onChange={() => setQuintaNoite(!quintaNoite)}
            checked={quintaNoite}
          />
        </Row>
        <Row>
          <Label
            style={{ paddingTop: "26px", marginLeft: "16px", width: "80px" }}
          >
            Sexta-feira:
          </Label>
          <FormCheckbox
            label={"Manhã"}
            onChange={() => setSextaManha(!sextaManha)}
            checked={sextaManha}
          />
          <FormCheckbox
            label={"Tarde"}
            onChange={() => setSextaTarde(!sextaTarde)}
            checked={sextaTarde}
          />
          <FormCheckbox
            label={"Noite"}
            onChange={() => setSextaNoite(!sextaNoite)}
            checked={sextaNoite}
          />
        </Row>
      </ModalBase>
    </>
  );
};
