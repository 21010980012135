import { MdOutlinePointOfSale } from "react-icons/md";
import { DevolucaoVenda } from "../../pages/vendas/gerenciamento/devolucao_venda/DevolucaoVenda";
import { DevolverVenda } from "../../pages/vendas/gerenciamento/devolucao_venda/devolver_venda/DevolverVenda";
import { GerenciamentoVendas } from "../../pages/vendas/gerenciamento/gerenciamento_vendas/GerenciamentoVendas";
import { VendasGerenciamento } from "../../pages/vendas/gerenciamento/VendasGerenciamento";
import { FrenteVendaMenu } from "../../pages/vendas/frente_venda/FrenteVendaMenu";
import { FrenteVenda } from "../../pages/vendas/frente_venda/frente_venda/FrenteVenda";
import { Orcamentos } from "../../pages/vendas/orcamentos/Orcamentos";
import { GerencOrcamentos } from "../../pages/vendas/orcamentos/gerenc_orcamentos/GerencOrcamentos";
import { ImportacaoPlanilhaBroker } from "../../pages/vendas/gerenciamento/importacao_planilha_broker/ImportacaoPlanilhaBroker";
import { GerenciamentoPremiacoes } from "../../pages/vendas/gerenciamento/gerenciamento_premiacoes/GerenciamentoPremiacoes";

export const devolverVendaRoute = {
  path: "/vendas/devolucao/devolver_venda",
  name: "Devolução",
  id: "AcSkWeb0059",
  nroTela: "0059",
  component: DevolverVenda,
};

export const gerenciamentoVendasRoute = {
  path: "/vendas/gerenciamento/gerenciamento_vendas",
  name: "Gerenciamento de Vendas",
  id: "AcSkWeb0067",
  nroTela: "0067",
  component: GerenciamentoVendas,
};

export const devolucaoVendaRoute = {
  path: "/vendas/gerenciamento/devolucao",
  name: "Devolução",
  id: "AcSkWeb0059",
  nroTela: "0059",
  component: DevolucaoVenda,
};

export const importacaoVendasBroker = {
  path: "/vendas/gerenciamento/importacao_vendas_broker",
  name: "Importação de Vendas Broker",
  id: "AcSkWeb0102",
  nroTela: "0102",
  component: ImportacaoPlanilhaBroker,
};

export const gerenciamentoPremiacoesRoute = {
  path: "/vendas/gerenciamento/gerenciamento_premiacoes",
  name: "Gerenciamento de Premiações",
  id: "AcSkWeb0106",
  nroTela: "0106",
  component: GerenciamentoPremiacoes,
};

export const vendasGerenciamentoRoutes = [
  gerenciamentoVendasRoute,
  devolucaoVendaRoute,
  importacaoVendasBroker,
  gerenciamentoPremiacoesRoute,
];

export const frenteVendaRoute = {
  path: "/vendas/frente_venda/frente_venda",
  name: "Frente de Venda",
  id: "AcSkWeb0079",
  nroTela: "0079",
  component: FrenteVenda,
};

export const gerencOrcamentosRoute = {
  path: "/vendas/orcamentos/gerenciamento",
  name: "Gerenciamento de Orçamentos",
  id: "AcSkWeb0083",
  nroTela: "0083",
  component: GerencOrcamentos,
};

export const orcamentosRoutes = [gerencOrcamentosRoute];

const vendasRoutes = {
  path: "/vendas",
  name: "Vendas",
  icon: MdOutlinePointOfSale,
  children: [
    {
      path: "/vendas/frente_venda",
      name: "Frente de Venda",
      component: FrenteVendaMenu,
      canGoBack: false,
    },
    {
      path: "/vendas/gerenciamento",
      name: "Gerenciamento",
      component: VendasGerenciamento,
      canGoBack: false,
    },
    {
      path: "/vendas/orcamentos",
      name: "Orçamentos",
      component: Orcamentos,
      canGoBack: false,
    },
  ],
};

export default vendasRoutes;
