import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import {
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import {
  setAceitaCredSemCpfCnpj,
  setAplicaDiferencaParcela,
  setImprCarne,
  setImprNotaPromissoria,
  setLancarParcelasPor,
  setLayoutImprCarne,
  setLayoutImprPromissoria,
  setMsgCarne,
  setNroViasCarne,
  setPlanoContasPadrao,
} from "../store/crediario_slice";

const layoutsImprCarne = [
  { label: "1 - Folha A4 - Duas Colunas, impressão com EAN", value: 1 },
  { label: "2 - Folha A4 - Duas colunas de parcelas", value: 2 },
  { label: "3 - Bobina 8cm largura, com EAN, 1 coluna", value: 3 },
];

const layoutsImprPromissoria = [
  { label: "1 - Jato de tinta - Impressão padrão", value: 1 },
  {
    label:
      "2 - Bonina 80mm - Impressão padrão para impressoras térmicas de cupom",
    value: 2,
  },
];

export const PersonCrediario = () => {
  const store = useSelector((state) => state.crediario);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <Col className="no-gutters">
          <RadioGroup
            label="Geração Automática de Parcelas - Aplcar Diferença de Valores Na"
            value={store.aplica_diferenca_parcela}
            onChange={(v) => dispatch(setAplicaDiferencaParcela(v))}
          >
            <RadioItem label="Primeira Parcela" value="P" />
            <RadioItem label="Última Parcela" value="U" />
          </RadioGroup>
          <span>
            Geral - Utilizado em Todo o Sistema na Geração de Sugestão do Contas
            a Receber
          </span>
        </Col>
      </Row>
      <Row>
        <RadioGroup
          label="Imprimir Nota Promissória no Recebimento do Caixa Loja"
          value={store.impr_nota_promissoria}
          onChange={(v) => dispatch(setImprNotaPromissoria(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Layout de Impressão da Nota Promissória"
          options={layoutsImprPromissoria}
          defaultValue={store.layout_impr_promissoria}
          onChange={(s) => dispatch(setLayoutImprPromissoria(s?.value))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Imprimir Carnê no Recebimento do Caixa Loja"
          value={store.impr_carne}
          onChange={(v) => dispatch(setImprCarne(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Layout de Impressão Carnê"
          options={layoutsImprCarne}
          defaultValue={store.layout_impr_carne}
          onChange={(s) => dispatch(setLayoutImprCarne(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Aceitar Inclusão de Crediários sem CPF/CNPJ do Cliente no Caixa Loja"
          checked={store.aceita_cred_sem_cpf_cnpj}
          onChange={() =>
            dispatch(setAceitaCredSemCpfCnpj(!store.aceita_cred_sem_cpf_cnpj))
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão - Caixa Loja"
          concatModelName="plano_contas"
          value={store.plano_contas_padrao}
          onChange={(s) => dispatch(setPlanoContasPadrao(s ?? 0))}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Mensagem do Carnê"
          value={store.msg_carne}
          onChange={(e, v) => dispatch(setMsgCarne(v))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Lançar Parcelas de Crediário Por"
          value={store.lancar_parcelas_por}
          onChange={(v) => dispatch(setLancarParcelasPor(v))}
        >
          <RadioItem label="Quantidade de Parcelas" value="QTD" />
          <RadioItem label="Condição de Pagamento" value="CON" />
        </RadioGroup>
      </Row>
      <Row>
        <IntegerFormInput
          md={3}
          label="Nº de Vias do Carnê"
          defaultValue={store.nro_vias_carne}
          onChange={(v) => dispatch(setNroViasCarne(v))}
        />
      </Row>
    </>
  );
};
