import React, { useState } from "react";
import { BsJournalCheck } from "react-icons/bs";
import { Row } from "reactstrap";
import {
  IconButton,
  NumberInput,
  Table,
  TextInput,
} from "../../../../../components";
import { FixedField } from "../../../../../components/FixedField";
import { ModalBase } from "../../../../../components/ModalBase";
import { TableCheck } from "../../../../../components/TableCheck";
import { formatNumber } from "../../../../../coreUtils";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";
import { ClassifCli } from "../../../../../components/ClassifCli";

export const LiberarPedidoModal = ({ idPedido, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(false);

  const buscarParametros = async () => {
    setParams(await CentralPedidosService.buscarParametrosLiberacao());
  };

  const buscarDados = async () => {
    let ret = await CentralPedidosService.buscarDadosLiberacao(idPedido);
    setDados(ret);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
      buscarParametros();
    } else {
      setDados([]);
    }
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (await CentralPedidosService.liberar(idPedido)) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  const columns = [
    {
      dataField: "id_produto",
      text: "# Produto",
      align: "center",
      sort: true,
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
    },
    ...(params.identifica_produto_revenda
      ? [
          {
            dataField: "revenda",
            text: "Revenda",
            align: "center",
            editable: false,
            formatter: (cell) => <TableCheck value={cell} />,
          },
        ]
      : []),
    {
      dataField: "vlr_custo_unit",
      text: "Custo",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unit.",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "perc_desc",
      text: "% Desc",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_desc",
      text: "Vlr. Desc",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "perc_margem",
      text: "% Margem",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_prod",
      text: "Tot. Prod.",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_icms_st",
      text: "ICMS ST",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_ipi",
      text: "IPI",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
  ];

  return (
    <>
      <IconButton
        icon={BsJournalCheck}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
        color="#51a86c"
        hint="Liberar Pedido"
        tooltipPlacement="left"
        size={15}
      />
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
          title="LIBERAÇÃO DE PEDIDO"
          number="0034_3"
          autoFocus
          onConfirm={handleSubmit}
          loadingConfirm={loading}
        >
          <Row className="mb-3">
            <TextInput
              md={5}
              label="Cliente"
              value={`${dados.id_cliente} - ${dados.nome_cliente}`}
              disabled
            />
            <ClassifCli value={dados.classif_cli_cliente} />
            <TextInput
              md={2}
              label="Fone"
              value={dados.fone1_cliente}
              disabled
            />
            <TextInput
              md={2}
              label="Fone 2"
              value={dados.fone2_cliente}
              disabled
            />
          </Row>
          <Row className="mb-3">
            <TextInput
              md={4}
              label="Endereço"
              value={dados.endereco_cliente}
              disabled
            />
            <TextInput
              md={1}
              label="Nro."
              value={dados.numero_cliente}
              disabled
            />
            <TextInput
              md={2}
              label="Bairro"
              value={dados.bairro_cliente}
              disabled
            />
            <TextInput
              md={3}
              label="Cidade"
              value={dados.nome_cidade_cliente}
              disabled
            />
          </Row>
          <Row className="mb-3">
            <TextInput md={2} label="Emissão" value={dados.emissao} disabled />
            <TextInput
              md={2}
              label="Forma de Pagamento"
              value={dados.forma_pag}
              disabled
            />
            <TextInput
              md={2}
              label="Condição de Pagamento"
              value={dados.cond_pag}
              disabled
            />
            <TextInput
              md={3}
              label="Vendedor"
              value={dados.nome_vendedor}
              disabled
            />
          </Row>
          <Row className="mb-3">
            <NumberInput
              label="Produtos"
              value={dados.vlr_tot_prod}
              disabled
              decimalPlaces={4}
            />
            <NumberInput
              label="Desconto"
              value={dados.vlr_tot_desconto}
              disabled
            />
            <NumberInput
              label="ICMS ST"
              value={dados.vlr_tot_icms_st}
              disabled
            />
            <NumberInput label="IPI" value={dados.vlr_tot_ipi} disabled />
            <NumberInput label="Frete" value={dados.vlr_frete} disabled />
            <NumberInput
              label="Desconto Sobre Total"
              value={dados.vlr_desc_sob_total}
              disabled
            />
            <NumberInput label="Total" value={dados.vlr_total} disabled />
          </Row>
          <Row className="mb-3">
            <FixedField label="Obs." value={dados.obs} />
          </Row>
          <Table
            columns={columns}
            data={dados.itens ?? []}
            keyField="id_produto"
            fixedSize={false}
            paginated={false}
            pageSize={(dados.itens ?? []).length}
            showRegisterCount={false}
          />
        </ModalBase>
      )}
    </>
  );
};
