import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DateTime from "react-datetime";
import InputMask from "react-input-mask";
import { Col, FormGroup } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { HintLabel } from "./HintLabel";
import classNames from "classnames";

export const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      label,
      md = "auto",
      name,
      autoFocus,
      disabled,
      inputId,
      minDate,
      maxDate,
      additionalButton,
      onBlur,
      style,
      required = false,
      divClassName,
      calendarPlacement = "bottom",
      tabIndex,
      hint,
    },
    ref
  ) => {
    const [canShowCalendar, setCanShowCalendar] = useState(false);
    const [internalValue, setInternalValue] = useState(value);
    const [valid, setValid] = useState(true);
    const [internalId] = useState(
      inputId ?? "dp-" + Math.floor(Math.random() * Date.now())
    );
    const innerRef = useRef(null);
    const inputRef = useRef(null);

    const __renderInput = (props, _, __) => {
      return (
        <InputMask mask="99/99/9999" maskChar={null} {...props} name={name} />
      );
    };

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(
          inputRef.current && inputRef.current.getInputDOMNode().focus(),
          35
        ),
    }));

    const onBlurInternal = (v) => {
      if (typeof v === "string") {
        let fullDate = moment(v, "DD/MM/YYYY");
        if (fullDate.isValid()) {
          if (
            fullDate.format("YYYY-MM-DD") !==
            moment(internalValue).format("YYYY-MM-DD")
          ) {
            setInternalValue(fullDate);
            setValid(true);
            if (onChange) onChange(fullDate);
            if (onBlur) onBlur(fullDate);
          }
        } else if (v !== "") {
          setValid(false);
          if (onBlur) onBlur(v);
        }
      } else {
        if (onBlur) onBlur(v);
      }
    };

    const onChangeInternal = (v) => {
      setInternalValue(v);
      if (onChange) onChange(v);
    };

    const validation = (currentDate) => {
      const minDateValid =
        minDate instanceof Date ? currentDate?.isAfter(moment(minDate)) : true;
      const maxDateValid =
        maxDate instanceof Date ? currentDate?.isBefore(moment(maxDate)) : true;

      return minDateValid && maxDateValid;
    };

    return (
      <Col md={md} className={divClassName}>
        <FormGroup>
          <HintLabel
            for={internalId}
            label={label}
            required={required}
            hintText={hint}
          />
          <div style={{ display: "flex", width: "100%" }}>
            <DateTime
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              value={internalValue}
              onChange={onChangeInternal}
              renderInput={__renderInput}
              className={classNames(
                calendarPlacement &&
                  calendarPlacement !== "bottom" &&
                  `rdt-picker-place-${calendarPlacement}`
              )}
              isValidDate={
                minDate instanceof Date || maxDate instanceof Date
                  ? validation
                  : undefined
              }
              inputProps={{
                id: internalId,
                className: `form-control${valid ? "" : " is-invalid"}`,
                autoFocus: autoFocus,
                disabled: disabled,
                autoComplete: "off",
                onBlur: (e) =>
                  innerRef.current?.state.open === false &&
                  onBlurInternal(e.target.value),
                onKeyDown: (e) => {
                  handleFocus(e);
                  if (["Enter", "ArrowUp"].includes(e.key))
                    innerRef.current.closeCalendar();
                },
                onMouseDown: () =>
                  !innerRef.current?.state.open && setCanShowCalendar(true),
                onMouseUp: () =>
                  innerRef.current?.state.open && setCanShowCalendar(false),
                onFocus: () =>
                  innerRef.current?.state.open &&
                  !canShowCalendar &&
                  innerRef.current.closeCalendar(),
                ref: inputRef,
                style: { textAlign: "center", ...style },
                tabIndex: tabIndex,
              }}
              closeOnSelect
              onBlur={onBlurInternal}
              onFocus={() => setValid(true)}
              ref={innerRef}
            />
            {additionalButton && additionalButton}
          </div>
        </FormGroup>
      </Col>
    );
  }
);
