import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { FormButton, ModalBase } from "../../../../../components";
import GerenciamentoCartaoService from "../../../../../services/financeiro/GerenciamentoCartaoService";

export const RecalcularTaxasCartoesModal = ({ selected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = async () => {
    setLoading(true);

    const payload = {
      cartoes_recalcular: selected.map((e) => ({ id_cartao: e })),
    };

    const [ok, ret] = await GerenciamentoCartaoService.recalcularTaxas(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        color="warning"
        disabled={selected.length === 0}
        onClick={toggle}
        disabledHint={selected.length === 0 && "Selecione ao menos um cartão"}
        divClassName="ml-auto"
      >
        Recalcular Taxas
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Confirmação"
        number="0049_3"
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <Col>
            <p>
              O sistema irá excluir as taxas existentes no cartão e recalcular
              conforme a taxação cadastrada para a bandeira e meio de pagamento.
              <br />
              <br />
              Você tem certeza que deseja continuar?
            </p>
          </Col>
        </Row>
      </ModalBase>
    </>
  );
};
