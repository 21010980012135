import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import Axios from "axios";

const kClientId = "xc3BYHQIbFFQL00nhwuSBL10ULggkYOK6vfivM1X";
const kClientSecret =
  "MUi14fA8i8pYiDhk3oymVgzL4MRGkWBCT5c0TFCzqaLy0xnsb5MvY5K8KlGCdfmIfC" +
  "EEmPjJc3Gmz8mqXAKF71v1AtzyeSCGD2R9Jco2JVMFe4hP1IrndHTuzrz5ZPBG";

let api = axios.create({
  baseURL: Cookies.get("api-address"),
  headers: {
    "database-name": Cookies.get("database-name"),
    Authorization: `Bearer ${Cookies.get("access-token")}`,
  },
});

export const updateAPI = (apiAddress, dbName, accessToken) => {
  api = axios.create({
    baseURL: apiAddress,
    headers: {
      "database-name": dbName,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  setupInterceptors(api);
};

// Atualiza token caso necessário
const refreshToken = async (error) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh-token"),
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/token/`, data, {
        timeout: 5000,
        auth: { username: kClientId, password: kClientSecret },
      })
      .then(async (res) => {
        Cookies.set("access-token", res.data.access_token);
        Cookies.set("refresh-token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

const setupInterceptors = (apiClient) => {
  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const access_token = Cookies.get("access-token");
      if ([403, 401].includes(error.response.status) && access_token) {
        await refreshToken(error);
        const new_access_token = Cookies.get("access-token");
        error.config.headers["Authorization"] = `Bearer ${new_access_token}`;
        error.config.baseURL = Cookies.get("api-address");
        return Axios.request(error.config);
      }
      return Promise.reject(error);
    }
  );
};

setupInterceptors(api);

export { api, kClientId, kClientSecret };

export const urlParams = (params) =>
  ![null, undefined, {}].includes(params)
    ? "?" +
      new URLSearchParams(
        Object.keys(params)
          .filter((key) => ![null, undefined, ""].includes(params[key]))
          .reduce((obj, key) => {
            if (params[key] instanceof Date) {
              params[key] = params[key].toISOString().split("T")[0];
            }
            return Object.assign(obj, {
              [key]: params[key],
            });
          }, {})
      ).toString()
    : "";

export const revokeToken = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access-token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const verificaVazio = (data) => {
  if (data.length > 0) {
    return data;
  } else {
    toastr.info("Aviso", "Não existem dados para os filtros selecionados.");
    return [];
  }
};

// ret - Resultado da requisição
// returnType - Tipo padrão a ser retornado em caso de erro
// reportEmpty - Exibe mensagem caso o retorno seja uma array vazia
// returnDataSuccess - Retorna dados em caso de sucesso

// Para retornar dados e exibir mensagem, returnType deve ser falsy e returnDataSuccess deve ser true
// Para apenas retornar dados, returnType deve ser truthy e returnDataSuccess deve ser falsy
// Para apenas exibir mensagem, returnType deve ser falsy e returnDataSuccess deve ser falsy

const processaRetorno = (ret, returnType, reportEmpty, returnDataSuccess) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (returnType && !returnDataSuccess) {
      return reportEmpty ? verificaVazio(ret.data.res) : ret.data.res;
    } else {
      toastr.success("Sucesso", ret.data.msg);
      return returnDataSuccess ? ret.data.res : true;
    }
  } else {
    toastr.error("Erro", ret.data.msg);
    return returnType ?? false;
  }
};

// Propriedades:
// err - Erro gerado no .catch() da requisição
// returnType - Tipo padrão a ser retornado em caso de erro
const processaErro = (err, returnType) => {
  toastr.error("Erro", err.message);
  return returnType ?? false;
};

export const apiGet = async (
  url,
  returnType = [],
  reportEmpty = false,
  returnDataSuccess = false
) =>
  await api
    .get(url)
    .then((ret) =>
      processaRetorno(ret, returnType, reportEmpty, returnDataSuccess)
    )
    .catch((err) => processaErro(err, returnType));

export const apiPost = async (
  url,
  payload,
  returnType,
  returnDataSuccess = false
) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret, returnType, false, returnDataSuccess))
    .catch((err) => processaErro(err, returnType));

export const apiPut = async (
  url,
  payload,
  returnType,
  returnDataSuccess = false
) =>
  await api
    .put(url, payload)
    .then((ret) => processaRetorno(ret, returnType, returnDataSuccess))
    .catch((err) => processaErro(err));

export const apiDelete = async (url) =>
  await api
    .delete(url)
    .then((ret) => processaRetorno(ret))
    .catch((err) => processaErro(err));

export const buscarLogoBase64 = async (noDefault = true) =>
  await api
    .get("/cadastro/empresa/logo/", {
      responseType: "blob",
      headers: { "Cache-Control": "no-cache" },
    })
    .then(
      (res) =>
        new Promise((resolve, reject) => {
          if (noDefault && res.headers["default-logo"] === "1") {
            reject(null);
          }
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(res.data);
        })
    )
    .catch(() => null);
