import React, { useState } from "react";
import { Row } from "reactstrap";
import { FormButton } from "../../../../../../components";
import IntegerInput from "../../../../../../components/IntegerInput";
import { ModalBase } from "../../../../../../components/ModalBase";
import RoteirizacaoService from "../../../../../../services/cadastro/RoteirizacaoService";

export const AlterarOrdemModal = ({ selected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ordem, setOrdem] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (ordem && ordem !== null) {
      let data = {
        id: selected,
        nova_posicao: ordem,
      };

      setLoading(true);
      const [ok] = await RoteirizacaoService.alteraOrdem(data);
      if (ok) {
        toggle();
        notifyEvent();
        setOrdem(1);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <FormButton
        padded={false}
        divClassName="pr-0"
        md="auto"
        color="warning"
        onClick={toggle}
        disabled={!selected}
      >
        Alterar Ordem
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Ordem"
        number="0010_11"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row
          className="justify-content-center"
          style={{ marginTop: "-20px", marginBottom: "-20px" }}
        >
          <p className="mt-4">
            Selecione a nova posição do cliente na roteirização:
          </p>
          <IntegerInput
            md={2}
            name="ordem"
            min={1}
            onChange={(v) => setOrdem(parseInt(v))}
            autoFocus
          />
        </Row>
      </ModalBase>
    </>
  );
};
