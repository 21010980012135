const settings = {
  AUTENTICADOR: "https://auth.skillsoft.com.br",
  API_ADDRESS: "http://localhost:5001",
  WS_TEF: "wss://ws.tef.skillsoft.com.br",
  WS_TEF_DEV: "wss://ws.tef.skillsoft.com.br",
  DB_NAME_DEV: "acoriana_guilherme",
  AMBIENTE_DEV: false,
  API_PLACAS: "https://api-placas.skillsoft.com.br",
};

export default settings;
