import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import {
  initialState,
  setStatus,
  setup,
} from "../store/cadastroContaBancSlice";
import { TabDadosGerais } from "./TabDadosGerais";
import { TabInfoCobranca } from "./TabInfoCobranca";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleContaBanc, routesBaseContaBanc } from "../ContaBanc";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroContaBancModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc);
  const [messageCount, setMessageCount] = useState(1);
  const [maxMessageCount, setMaxMessageCount] = useState(4);
  const [temInfoSacaAval, setTemInfoSacaAval] = useState(false);

  const limparDados = () => {
    let initState = { ...initialState };
    dispatch(setup(initState));
    setMessageCount(1);
    setTemInfoSacaAval(false);
  };

  const coalescePayload = (object) =>
    Object.entries(object).forEach(([key, value]) => {
      if ([null, undefined].includes(value) && initialState[key])
        object[key] = initialState[key];
    });

  const assignIfMatch = (base, target) =>
    Object.keys(base).forEach((key) => {
      if (target && target[key]) base[key] = target[key];
    });

  const fetchData = (res) => {
    if (res.cobranca) {
      res.cobranca.posto = res.posto;
      res.cobranca.perc_multa = parseFloat(res.cobranca.perc_multa);
      res.cobranca.perc_juros = parseFloat(res.cobranca.perc_juros);
      res.cobranca.vlr_juros = parseFloat(res.cobranca.vlr_juros);
      res.cobranca.vlr_multa = parseFloat(res.cobranca.vlr_multa);

      let msgCount = 1;

      if (res.cobranca.mensagem_2 !== "") {
        msgCount = 2;
      }
      if (res.cobranca.mensagem_3 !== "") {
        msgCount = 3;
      }
      if (res.cobranca.mensagem_4 !== "") {
        msgCount = 4;
      }
      setMessageCount(msgCount);

      if (res.cobranca.nome_avalista && res.cobranca.nome_avalista !== "")
        setTemInfoSacaAval(true);
    }

    let data = JSON.parse(JSON.stringify(initialState));

    data.banco = res.banco;
    data.agencia = res.agencia ?? "";
    data.nro_conta = res.nro_conta ?? "";
    data.endereco = res.endereco ?? "";
    data.nro_ender = res.nro_ender ?? "";
    data.bairro = res.bairro ?? "";
    data.cidade = res.cidade;
    data.chave_pix = res.chave_pix;
    data.nome_beneficiario_pix = res.nome_beneficiario_pix;
    data.cep = res.cep ?? "";
    data.gerente = res.gerente ?? "";
    data.fone = res.fone ?? "";
    data.considera_fluxo_cxa = res.considera_fluxo_cxa;
    data.status = res.status;
    data.cobranca.api_authorization_code =
      res.integracao_api?.api_authorization_code ?? "";
    data.cobranca.api_client_id = res.integracao_api?.api_client_id ?? "";
    data.cobranca.api_client_secret =
      res.integracao_api?.api_client_secret ?? "";
    data.api_certif_config = res.integracao_api?.api_certif_config ?? false;
    data.api_key_config = res.integracao_api?.api_key_config ?? false;

    assignIfMatch(data.cobranca, res.cobranca);

    dispatch(setup(data));
  };

  const submitPayload = (action) => {
    if ([null, undefined].includes(store.cobranca.seq_ult_nosso_nro)) {
      showWarning("Sequência do Nosso Número não foi informada ou é inválida.");
      return false;
    }
    if ([null, undefined].includes(store.cobranca.nro_ult_rem)) {
      showWarning("Número da última remessa não foi informado ou é inválido.");
      return false;
    }

    let payload = { ...store };

    payload.id_cidade = payload.cidade;
    payload.id_banco = payload.banco;

    delete payload.cidade;
    delete payload.banco;
    delete payload.arq_certificado_api;
    delete payload.arq_key_api;
    delete payload.api_certif_config;
    delete payload.api_key_config;

    coalescePayload(payload);

    if (action === MODAL_ACTIONS.EDIT) {
      let cobrEdit = { ...payload.cobranca };
      delete cobrEdit.seq_ult_nosso_nro;
      payload.cobranca = cobrEdit;
    }

    payload =
      action === MODAL_ACTIONS.ADD ? payload : { ...payload, id: selected };

    const formData = new FormData();
    formData.append("json", JSON.stringify(payload));
    if (store.arq_certificado_api) {
      debugger;
      formData.append(
        "api_certificado",
        store.arq_certificado_api.file,
        store.arq_certificado_api.file.name
      );
    }
    if (store.arq_key_api) {
      formData.append(
        "api_key",
        store.arq_key_api.file,
        store.arq_key_api.file.name
      );
    }

    return formData;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleContaBanc}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: store.status,
        toggle: () => dispatch(setStatus(!store.status)),
      }}
      routesBase={routesBaseContaBanc}
      number="0045_1"
      selected={selected}
      notifyEvent={notifyEvent}
      editMethod="POST"
    >
      <TabController contentStyle={{ height: "65vh", overflow: "auto" }}>
        <TabBody title="Dados Gerais">
          <TabDadosGerais />
        </TabBody>
        <TabBody title="Integração de Cobrança">
          <TabInfoCobranca
            action={action}
            selected={selected}
            messageCount={messageCount}
            setMessageCount={setMessageCount}
            maxMessageCount={maxMessageCount}
            setMaxMessageCount={setMaxMessageCount}
            temInfoSacaAval={temInfoSacaAval}
            setTemInfoSacaAval={setTemInfoSacaAval}
          />
        </TabBody>
      </TabController>
    </ModalCadastroV2>
  );
};
