import React, { useState } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  ComboBox,
  FormButton,
  SearchInput,
  TextInput,
} from "../../../../../components";
import { PageContainer } from "../../../../../components/PageContainer";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
  useStateWithRef,
} from "../../../../../coreUtils";
import GrupoRegraTributacaoService from "../../../../../services/cadastro/GrupoRegraTributacaoService";
import { FINALIDADES_REGRA_TRIB } from "../../regra_tributacao/components/modal/CamposChaves";
import { RegrasAddGrid } from "./components/RegrasAddGrid";
import { RegrasGrupoGrid } from "./components/RegrasGrupoGrid";

export const CadastroGrupoRegraTrib = ({ location }) => {
  const selected = location.state?.selected;
  const action = location.state?.action ?? MODAL_ACTIONS.ADD;
  const [pista, setPista] = useState("");
  const [finalidade, setFinalidade] = useState("");
  const [descricao, setDescricao] = useState("");
  const [regrasAdd, setRegrasAdd, regrasAddRef] = useStateWithRef([]);
  const [regrasGrupo, setRegrasGrupo, regrasGrupoRef] = useStateWithRef([]);
  const [selectedAdd, setSelectedAdd, selectedAddRef] = useStateWithRef([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const buscarFiltrarRegras = async (p) => {
    setSelectedAdd([]);
    return await GrupoRegraTributacaoService.buscarRegrasSel(
      selected,
      p,
      finalidade
    );
  };

  const carregarRegrasAdd = async () => {
    setLoading(true);
    setRegrasAdd(await buscarFiltrarRegras(pista));
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setRegrasAdd(await buscarFiltrarRegras(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleSetFinalidade = (v) => {
    setFinalidade(v);
    setRegrasAdd([]);
    setSelectedAdd([]);
  };

  const handleSelectAdd = (id, row, isSelect) => {
    if (!loading) {
      setSelectedAdd(
        isSelect
          ? [...selectedAddRef.current, id]
          : selectedAddRef.current.filter((item) => {
              return item !== id;
            })
      );
    }
  };

  const handleOnSelectAll = (isSelect) =>
    setSelectedAdd(isSelect ? regrasAdd.map((e) => e.id) : []);

  const handleDelete = (id) =>
    setRegrasGrupo(regrasGrupoRef.current.filter((e) => e.id !== id));

  const addRegras = () => {
    const regrasSelecionadas = regrasAddRef.current.filter((e) =>
      selectedAddRef.current.includes(e.id)
    );

    setRegrasGrupo([...regrasGrupoRef.current, ...regrasSelecionadas]);
    setSelectedAdd([]);
  };

  const carregarDadosAlteracao = async () => {
    const ret = await GrupoRegraTributacaoService.buscar(selected);
    setDescricao(ret.descricao);
    setRegrasGrupo(
      ret.regras.map((e) => ({
        id: e.id_regra_trib,
        descricao: e.id_regra_trib__descricao,
        cfop: e.id_regra_trib__cfop,
        dentro_estabelecimento: e.id_regra_trib__dentro_estabelecimento,
        finalidade_oper: e.id_regra_trib__finalidade_oper,
        ativo: e.id_regra_trib__ativo,
      }))
    );
  };

  const handleSubmit = async () => {
    const payload = {
      descricao: descricao,
      regras_mov: regrasGrupoRef.current.map((e) => ({ id_regra_trib: e.id })),
    };

    if (
      action === MODAL_ACTIONS.ADD
        ? await GrupoRegraTributacaoService.incluir(payload)
        : await GrupoRegraTributacaoService.alterar({
            ...payload,
            id: selected,
          })
    ) {
      setRedirect(true);
    }
  };

  useEffect(() => {
    if (action === MODAL_ACTIONS.EDIT) {
      carregarDadosAlteracao();
    }
  }, []);

  return (
    <PageContainer
      title={`${
        action === MODAL_ACTIONS.ADD ? "Inclusão de " : "Alteração de "
      } Grupo de Regras de Tributação`}
      number="0047_1"
      canGoBack
    >
      {redirect && (
        <Redirect
          to={{
            pathname: "/cadastro/tributacao/grupo_regra/",
            state: { refresh: true },
          }}
        />
      )}
      <Card body>
        <Row>
          <TextInput
            label="Descrição"
            md={8}
            onChange={(e, v) => setDescricao(v)}
            value={descricao}
            maxLength={80}
          />
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <Row className="mb-2">
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome da Regra"
          />
          <ComboBox
            md={3}
            label="Finalidade"
            options={FINALIDADES_REGRA_TRIB}
            isClearable
            onChange={(s) => handleSetFinalidade(s?.value)}
          />
          <BotaoPesquisar loading={loading} onClick={carregarRegrasAdd} />
          <FormButton
            md="auto"
            color="light"
            onClick={addRegras}
            disabled={selectedAdd?.length === 0}
            disabledHint="Selecione uma ou mais regras da lista"
            divClassName="ml-auto"
          >
            Adicionar
          </FormButton>
        </Row>
        <RegrasAddGrid
          data={regrasAdd.filter(
            (e) => !regrasGrupoRef.current.map((f) => f.id).includes(e.id)
          )}
          selected={selectedAdd}
          onSelect={handleSelectAdd}
          onSelectAll={handleOnSelectAll}
        />
      </Card>
      <RegrasGrupoGrid data={regrasGrupo} handleDelete={handleDelete} />
    </PageContainer>
  );
};
