import React, { useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  NumberInput,
  RadioGroup,
  Table,
} from "../../../../../components";
import FormButton from "../../../../../components/FormButton";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalExcluir } from "../../../../../components/ModalExcluir";
import { formatNumber } from "../../../../../coreUtils";
import CadastroListaPrecosService from "../../../../../services/cadastro/CadastroListaPrecosService";
import { AlterarItemListaDe } from "./AlterarItemListaDe";
import { optionsListaPrecoDe } from "../ListaPrecos";
import { RadioItem } from "../../../../../components/RadioGroup";

export const DetalhesListaDe = ({
  idCab,
  nomeLista,
  tipoListaDe,
  tipoListaPara,
  definirTipoListaDe,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [de, setDe] = useState(tipoListaDe);
  const [idDe, setIdDe] = useState(null);
  const [natureza, setNatureza] = useState(null);
  const [percDescAcre, setPercDescAcre] = useState(null);
  const [precoUnit, setPrecoUnit] = useState(null);
  const [precoVenda, setPrecoVenda] = useState(0);
  const [precoMinimo, setPrecoMinimo] = useState(0);
  const [itensDeLista, setItensListaDe] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [alterouAlgo, setAlterouAlgo] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    setDe(tipoListaDe);
  }, [tipoListaDe]);

  const descricaoItens =
    {
      PRODUTO: "Produtos",
      GRUPO: "Grupos",
      FABRICANTE: "Fabricantes",
      ALL: "Ajuste de Percentual",
    }[tipoListaDe] ?? "Itens";

  const columns = [
    {
      dataField: "id_de",
      text: "#",
      align: "center",
      sortable: true,
      hidden: tipoListaDe === "ALL",
    },
    {
      dataField: "desc_de",
      text: "Tipo",
      align: "left",
    },
    {
      dataField: "nome_de",
      text: "Nome",
      align: "left",
      sortable: true,
      hidden: tipoListaDe === "ALL",
    },
    {
      dataField: "desc_natureza",
      text: "Natureza",
      align: "left",
      sortable: true,
      hidden: tipoListaDe === "PRODUTO" && tipoListaPara === "CLIENTE",
    },
    {
      dataField: "perc_desc_acre",
      text: "% Desc/Acre",
      align: "right",
      formatter: (c) => `${formatNumber(c, true, 2)}%`,
      sortable: true,
      hidden: tipoListaDe === "PRODUTO" && tipoListaPara === "CLIENTE",
    },
    {
      dataField: "preco_venda",
      text: "Preço de Venda (R$)",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      hidden: tipoListaDe !== "PRODUTO" || tipoListaPara !== "CLIENTE",
    },
    {
      dataField: "act_edit",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <AlterarItemListaDe
          notifyEvent={buscarDados}
          tipoListaPara={tipoListaPara}
          dadosItem={row}
        />
      ),
    },
    {
      dataField: "act_delete",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <ModalExcluir
          notifyEvent={() => {
            setAlterouAlgo(true);
            buscarDados();
          }}
          route={`/cadastro/lista_preco/de/excluir/${row.id}/`}
          selected={row.id}
          title={`${descricaoItens} da Lista de Preços`}
          number="0013_41"
          table
          message={`Tem certeza de que deseja excluir o registro da lista ${nomeLista}?`}
        />
      ),
    },
  ];

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    }
    setIsOpen(!isOpen);
    setAlterouAlgo(false);
    setItensListaDe([]);
    if (isOpen && alterouAlgo) {
      notifyEvent();
    }
  };

  const handleSubmit = async () => {
    let data = {
      id_cab: idCab,
      de: de,
      id_de: idDe,
      natureza: natureza,
      perc_desc_acre: percDescAcre,
      preco_venda: precoVenda,
    };

    setLoadingAdd(true);
    try {
      if (await CadastroListaPrecosService.de.incluir(data)) {
        setPrecoUnit(0);
        setPrecoVenda(0);
        setPercDescAcre(0);
        buscarDados();
        definirTipoListaDe(de);
        setAlterouAlgo(true);
      }
    } finally {
      setLoadingAdd(false);
    }
    selectRef.current.clearValue();
    selectRef.current.setFocus();
  };

  const buscarDados = async () => {
    const [ok, ret] = await CadastroListaPrecosService.de.listar(idCab);
    if (ok) {
      setItensListaDe(ret);
      if (ret?.length === 0) {
        definirTipoListaDe(null);
      }
    }
  };

  const labelDe =
    {
      ALL: "Todos os Produtos",
      PRODUTO: "Produto",
      GRUPO: "Grupo",
      FABRICANTE: "Fabricante",
    }[de] ?? "Selecione o Tipo";

  const concatModelNameDe = {
    ALL: null,
    PRODUTO: "produto",
    GRUPO: "grupo",
    FABRICANTE: "fabricante",
  }[de];

  const calcularPrecoVenda = () => {
    let vlrAjuste = (precoUnit ?? 0) * ((percDescAcre ?? 0) / 100);
    if (natureza === "DESCONTO") {
      vlrAjuste *= -1;
    }
    setPrecoVenda(precoUnit + vlrAjuste);
  };

  useEffect(() => {
    if (de === "PRODUTO") {
      calcularPrecoVenda();
    }
  }, [percDescAcre, de, idDe, natureza]);

  return (
    <React.Fragment>
      <FormButton
        md="auto"
        color="primary"
        onClick={toggle}
        disabled={
          [0, null, undefined].includes(idCab) ||
          [null, undefined, ""].includes(tipoListaPara)
        }
      >
        {descricaoItens} da Lista
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={<>{descricaoItens} da Lista de Preços</>}
        number="0013_4"
      >
        <Row className="mb-1">
          <ComboBox
            md={4}
            label="Tipo"
            options={optionsListaPrecoDe}
            onChange={(s) => {
              setDe(s?.value);
              setIdDe(null);
              if (s?.value !== "PRODUTO") {
                setPrecoVenda(null);
                setPrecoMinimo(null);
                setPrecoUnit(null);
              } else {
                setNatureza(null);
                setPercDescAcre(null);
              }
            }}
            defaultValue={de}
            isSearchable={false}
            isClearable
            isDisabled={tipoListaDe}
          />
          <AsyncComboBox
            label={labelDe}
            isConcatField
            concatModelName={concatModelNameDe}
            isClearable
            isSearchable
            md={8}
            onChange={(s) => {
              setIdDe(s?.value);
              if (de === "PRODUTO") {
                setPrecoMinimo(s?.preco_min_permitido);
                setPrecoUnit(s?.preco_venda);
              }
            }}
            autoFocus
            defaultOptions={de !== "PRODUTO"}
            ref={selectRef}
            disabled={!de || de === "ALL"}
          />
        </Row>
        <Row className="mb-2">
          {(de !== "PRODUTO" || tipoListaPara !== "CLIENTE") && (
            <>
              <RadioGroup
                label="Natureza"
                value={natureza}
                onChange={setNatureza}
                disabled={!de}
              >
                <RadioItem label="Desconto" value="DESCONTO" />
                <RadioItem label="Acréscimo" value="ACRESCIMO" />
              </RadioGroup>
              <NumberInput
                label="% de Desc/Acre"
                md={2}
                onChange={setPercDescAcre}
                value={percDescAcre}
                isPercentage
                disabled={!de || !natureza}
              />
            </>
          )}
          {de === "PRODUTO" && (
            <>
              <NumberInput
                label="Preço Mínimo"
                md={2}
                value={precoMinimo}
                disabled
              />
              <NumberInput
                label="Preço de Venda"
                md={2}
                onChange={setPrecoVenda}
                value={precoVenda}
                disabled={tipoListaPara !== "CLIENTE"}
              />
            </>
          )}
          <FormButton
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingAdd}
          >
            Adicionar
          </FormButton>
        </Row>
        <Table data={itensDeLista} columns={columns} paginated={false} />
      </ModalBase>
    </React.Fragment>
  );
};
