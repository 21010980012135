import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AsyncComboBox,
  DatePicker,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../../components";
import { Col, Row } from "reactstrap";
import OrdemServicoService from "../../../../../../services/ordem_servico/OrdemServicoService";
import moment from "moment";
import { formatDateISO } from "../../../../../../coreUtils";

const AlterarTecnicoHoraItemModal = forwardRef(
  ({ notifyEvent, automotivo, identificaTecnico }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const [idItem, setIdItem] = useState(null);
    const [tipoItem, setTipoItem] = useState(null);
    const [idTecnico, setIdTecnico] = useState(null);
    const [horasServico, setHorasServico] = useState(null);
    const [horasVendidas, setHorasVendidas] = useState(null);
    const [dataDoServ, setDataDoServ] = useState(null);
    const [horaIni, setHoraIni] = useState(null);
    const [horaFim, setHoraFim] = useState(null);

    const tecnicoRef = useRef();

    const carregarDados = async (_idItem, _tipoItem) => {
      if (_idItem && _tipoItem) {
        const ret = await OrdemServicoService.buscarDadosItem(
          _idItem,
          _tipoItem
        );
        if (ret) {
          setIdTecnico(ret.id_tecnico);
          setHorasServico(parseFloat(ret.horas_servico));
          setHorasVendidas(parseFloat(ret.horas_vendidas));
          setDataDoServ(
            moment(ret.data_item, "DD/MM/YYYY").isValid()
              ? moment(ret.data_item, "DD/MM/YYYY").toDate()
              : ret.data_item
          );
          setHoraIni(ret.hora_ini_item);
          setHoraFim(ret.hora_fim_item);
        }
      }
    };

    useEffect(() => {
      if (!idTecnico && tecnicoRef.current) {
        tecnicoRef.current.clearValue();
      }
    }, [idTecnico]);

    const toggleInternal = (notify) => {
      setIsOpen(!isOpen);
      if (notify) {
        notifyEvent();
      }
    };

    useImperativeHandle(ref, () => ({
      open: (_idItem, _tipoItem) => {
        carregarDados(_idItem, _tipoItem).then(() => {
          setIsOpen(true);
        });
        setIdItem(_idItem);
        setTipoItem(_tipoItem);
      },
    }));

    const handleSubmit = async () => {
      const payload = {
        id_item: idItem,
        tipo: tipoItem,
        id_tecnico: idTecnico ?? null,
        horas_servico: horasServico ?? 0,
        horas_vendidas: horasVendidas ?? 0,
        data_do_serv: formatDateISO(dataDoServ),
        hora_ini: horaIni !== "" ? horaIni : null,
        hora_fim: horaIni !== "" ? horaFim : null,
      };

      const [ok, _] = await OrdemServicoService.alterarTecnicoHorasItem(
        payload
      );
      if (ok) {
        toggleInternal(true);
      }
    };

    if (!isOpen) {
      return <></>;
    }

    return (
      <ModalBase
        isOpen={true}
        toggle={toggleInternal}
        size="md"
        title="Alterar Item"
        onConfirm={handleSubmit}
        onClose={() => {
          setTipoItem(null);
          setIdItem(null);
          setIdTecnico(null);
          setHorasServico(null);
          setHorasVendidas(null);
          setDataDoServ(null);
          setHoraIni(null);
          setHoraFim(null);
          tecnicoRef.current.clearValue();
        }}
      >
        {identificaTecnico && (
          <Row>
            <AsyncComboBox
              md={12}
              isConcatField
              concatModelName="tecnico"
              label="Técnico"
              isSearchable
              onChange={(s) => setIdTecnico(s?.value)}
              defaultValue={idTecnico}
              defaultOptions
              ref={tecnicoRef}
            />
          </Row>
        )}
        {tipoItem === "S" && (
          <Row>
            <DatePicker
              md={4}
              label="Data do Trabalho"
              value={dataDoServ}
              onChange={(v) => {
                setDataDoServ(moment.isMoment(v) ? v.toDate() : v);
              }}
            />

            {!automotivo ? (
              <Col md={4}>
                <Row>
                  <TextInput
                    type="time"
                    md={6}
                    label="Hora Início"
                    value={horaIni}
                    onChange={(_, v) => setHoraIni(v)}
                  />
                  <TextInput
                    type="time"
                    md={6}
                    label="Hora Fim"
                    value={horaFim}
                    onChange={(_, v) => setHoraFim(v)}
                  />
                </Row>
              </Col>
            ) : (
              <NumberInput
                md={4}
                label="Horas Serviço"
                value={horasServico}
                onChange={setHorasServico}
              />
            )}
            <NumberInput
              md={4}
              label="Horas Vendidas"
              value={horasVendidas}
              onChange={setHorasVendidas}
            />
          </Row>
        )}
      </ModalBase>
    );
  }
);

export default AlterarTecnicoHoraItemModal;
