import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerFormInput,
  TextInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../../components";
import NumberInput from "../../../../../components/NumberInput";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleRota, routesBaseRota } from "../RotasRoteirizacao";
import { RadioItem } from "../../../../../components/RadioGroup";

export const CadastroRotaModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nomeRota, setNome] = useState("");
  const [idVeiculosEmpr, setIdVeiculosEmpr] = useState([]);
  const [idContaBanc, setIdContaBanc] = useState(0);
  const [idContaBancRecebPix, setIdContaBancRecebPix] = useState(null);
  const [idColaborador, setIdColaborador] = useState(null);
  const [idCentroCustoDespesa, setIdCentroCustoDespesa] = useState(0);
  const [idCentroCustoReceita, setIdCentroCustoReceita] = useState(0);
  const [serieNfe, setSerieNfe] = useState(0);
  const [ultimoNroNfeEmitido, setUltimoNroNfeEmitido] = useState(0);
  const [idClienteGenerico, setIdClienteGenerico] = useState(0);
  const [vlrFlexInicioMes, setVlrFlexInicioMes] = useState(0);
  const [vlrBonificaInicioMes, setVlrBonificaInicioMes] = useState(0);
  const [idFornecComissao, setIdFornecComissao] = useState(0);
  const [idLayoutImpressora, setIdLayoutImpressora] = useState(0);
  const [idPlanoContaPadRecebimentoPix, setIdPlanoContaPadRecebimentoPix] =
    useState(0);
  const [
    idPlanoContaPadRecebimentoCredRota,
    setIdPlanoContaPadRecebimentoCredRota,
  ] = useState(0);
  const [
    emiteNfeRemessaDestinatarioAmbulante,
    setEmiteNfeRemessaDestinatarioAmbulante,
  ] = useState(false);
  const [idClienteDestinatarioAmbulante, setIdClienteDestinatarioAmbulante] =
    useState(null);
  const [trabalhaEstoqueIntermediario, setTrabalhaEstoqueIntermediario] =
    useState(false);
  const [idRotaEstoqueMestre, setIdRotaEstoqueMestre] = useState(null);
  const [redutorVlrTroca, setRedutorVlrTroca] = useState(0);
  const [zerarEstoquePor, setZerarEstoquePor] = useState("NAO");

  const [dadosCarregados, setDadosCarregados] = useState(false);

  // Parâmetros
  const [
    vincularMultiplosVeiculosPorRota,
    setVincularMultiplosVeiculosPorRota,
  ] = useState(false);
  const [permiteRecebimentoEmRotativo, setPermiteRecebimentoEmRotativo] =
    useState(false);
  const [existeRedutorVlrTroca, setExisteRedutorVlrTroca] = useState(false);

  const veiculosRef = useRef();

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setIdVeiculosEmpr([]);
    setIdContaBanc(0);
    setIdContaBancRecebPix(null);
    setIdColaborador(null);
    setIdCentroCustoDespesa(0);
    setIdCentroCustoReceita(0);
    setSerieNfe(0);
    setUltimoNroNfeEmitido(0);
    setIdClienteGenerico(0);
    setVlrFlexInicioMes(0);
    setVlrBonificaInicioMes(0);
    setIdFornecComissao(0);
    setIdLayoutImpressora(0);
    setIdPlanoContaPadRecebimentoPix(0);
    setIdPlanoContaPadRecebimentoCredRota(null);
    setEmiteNfeRemessaDestinatarioAmbulante(false);
    setIdClienteDestinatarioAmbulante(null);
    setDadosCarregados(false);
    setTrabalhaEstoqueIntermediario(false);
    setIdRotaEstoqueMestre(null);
    setRedutorVlrTroca(0);
    setZerarEstoquePor("NAO");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setIdVeiculosEmpr(data.veiculos?.map((e) => e.id_veiculo_empr) ?? []);
    setIdContaBanc(data.id_conta_banc);
    setIdContaBancRecebPix(data.id_conta_banc_receb_pix);
    setIdColaborador(data.id_colaborador);
    setAtivo(data.ativo);
    setIdCentroCustoDespesa(data.id_centro_custo_despesa);
    setIdCentroCustoReceita(data.id_centro_custo_receita);
    setSerieNfe(data.serie);
    setUltimoNroNfeEmitido(data.ult_nro_nfe_emitido);
    setIdClienteGenerico(data.id_cliente_generico);
    setIdFornecComissao(data.id_fornec_comissao);
    setVlrFlexInicioMes(parseFloat(data.vlr_flex_inicio_mes));
    setVlrBonificaInicioMes(parseFloat(data.vlr_bonifica_inicio_mes));
    setIdLayoutImpressora(data.id_layout_impressora);
    setIdPlanoContaPadRecebimentoPix(data.id_plano_conta_pad_recebimento_pix);
    setIdPlanoContaPadRecebimentoCredRota(
      data.id_plano_conta_pad_recebimento_cred_rota
    );
    setEmiteNfeRemessaDestinatarioAmbulante(
      data.emite_nf_remessa_dest_ambulante
    );
    setIdClienteDestinatarioAmbulante(
      data.id_cliente_dest_ambulante_nf_remessa
    );
    setTrabalhaEstoqueIntermediario(data.trabalha_estoque_intermediario);
    setIdRotaEstoqueMestre(data.id_rota_estoque_mestre);
    setRedutorVlrTroca(parseFloat(data.redutor_vlr_troca));
    if (data.trabalha_estoque_intermediario === true) {
      setZerarEstoquePor("DIA");
    } else {
      setZerarEstoquePor(data.zerar_estoque_por);
    }
    setDadosCarregados(true);
  };

  const validate = () => {
    const invalidArray = [0, null, undefined];

    if (["", null, undefined].includes(nomeRota)) {
      toastr.warning("Nome inválido", "Por favor, informe o nome da rota.");
      return false;
    } else if ([null, undefined].includes(idColaborador)) {
      toastr.warning(
        "Vendedor inválido",
        "Verifique o vendedor e tente novamente."
      );
      return false;
    } else if (idVeiculosEmpr.length === 0) {
      toastr.warning(
        "Veículo inválido",
        "Verifique o veículo e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idContaBanc)) {
      toastr.warning(
        "Conta Bancária inválida",
        "Verifique a conta bancária e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idCentroCustoDespesa)) {
      toastr.warning(
        "C.C. de despesas inválido",
        "Verifique o C.C. de despesa e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idCentroCustoReceita)) {
      toastr.warning(
        "C.C. de receitas inválido",
        "Verifique o C.C. de receita e tente novamente."
      );
      return false;
    } else if ([0, null, undefined, ""].includes(serieNfe)) {
      toastr.warning("Série inválida", "Verifique a série e tente novamente.");
      return false;
    } else if ([null, undefined, ""].includes(ultimoNroNfeEmitido)) {
      toastr.warning(
        "Último nº NF-e inválido",
        "Verifique o último nº da NF-e e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idClienteGenerico)) {
      toastr.warning(
        "Cliente genérico inválido",
        "Verifique o cliente genérico e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idFornecComissao)) {
      toastr.warning(
        "Fornecedor da Comissão inválido",
        "Verifique o fornecedor da comissão e tente novamente."
      );
      return false;
    } else if (invalidArray.includes(idLayoutImpressora)) {
      toastr.warning(
        "Layout da impressora inválido",
        "Verifique o layout impressora e tente novamente."
      );
      return false;
    }
    return true;
  };

  const submitPayload = (action) => {
    if (!validate()) return false;

    let data = {
      nome: nomeRota,
      id_colaborador: idColaborador,
      veiculos: idVeiculosEmpr.map((e) => ({ id_veiculo: e })),
      serie: serieNfe,
      ult_nro_nfe_emitido: ultimoNroNfeEmitido,
      id_conta_banc: idContaBanc,
      id_c_cust_desp: idCentroCustoDespesa,
      id_c_cust_rece: idCentroCustoReceita,
      id_cliente_gener: idClienteGenerico,
      id_fornec_comissao: idFornecComissao,
      id_layout_impressora: idLayoutImpressora,
      vlr_flex_ini_mes: Number.parseFloat(String(vlrFlexInicioMes)),
      vlr_bonif_ini_mes: Number.parseFloat(String(vlrBonificaInicioMes)),
      id_plano_conta_receb_pix: idPlanoContaPadRecebimentoPix,
      emite_nf_remessa_dest_ambulante: emiteNfeRemessaDestinatarioAmbulante,
      id_cliente_dest_ambulante_nf_remessa: idClienteDestinatarioAmbulante,
      trabalha_estoque_intermediario: trabalhaEstoqueIntermediario,
      id_rota_estoque_mestre: idRotaEstoqueMestre,
      id_conta_banc_receb_pix: idContaBancRecebPix,
      id_plano_conta_pad_recebimento_cred_rota:
        idPlanoContaPadRecebimentoCredRota,
      redutor_vlr_troca: redutorVlrTroca,
      zerar_estoque_por: zerarEstoquePor,
    };

    return action === MODAL_ACTIONS.ADD
      ? { ...data }
      : { ...data, id: selected, ativo: ativo };
  };

  useEffect(() => {
    if (!emiteNfeRemessaDestinatarioAmbulante) {
      setIdClienteDestinatarioAmbulante(null);
    }
  }, [emiteNfeRemessaDestinatarioAmbulante]);

  const onOpen = (_, params) => {
    setVincularMultiplosVeiculosPorRota(
      params.vincula_multiplos_veiculos_por_rota
    );
    setPermiteRecebimentoEmRotativo(params.permite_recebimento_cred_rotativo);
    setExisteRedutorVlrTroca(params.existe_redutor_vlr_troca);
  };

  useEffect(() => {
    if (!isOpen) setDadosCarregados(false);
  }, [isOpen]);

  useLayoutEffect(() => {
    const setarVeiculos = async () => {
      if (vincularMultiplosVeiculosPorRota && isOpen && dadosCarregados) {
        const options = (await veiculosRef.current.fetchOptions()) ?? [];
        if (options.length > 0) {
          const listaValores = [];
          idVeiculosEmpr.forEach((idVeiculo) => {
            const option = options.find((e) => e.value === idVeiculo);
            if (option) {
              listaValores.push(option);
            }
          });
          veiculosRef.current.setValue(listaValores);
        }
      }
    };

    setarVeiculos();
  }, [vincularMultiplosVeiculosPorRota && isOpen && dadosCarregados]);

  useEffect(() => {
    if (!trabalhaEstoqueIntermediario) {
      setIdRotaEstoqueMestre(null);
      setZerarEstoquePor("NAO");
    } else {
      setZerarEstoquePor("DIA");
    }
  }, [trabalhaEstoqueIntermediario]);

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRota}
      size="xl"
      onClose={limparDados}
      onOpen={onOpen}
      paramsName="cad_rota"
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseRota}
      number="0010_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Nome da Rota"
          name="nome"
          value={nomeRota}
          onChange={(_, v) => setNome(v)}
          maxLength={100}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Vendedor"
          name="vendedor"
          onChange={(s) => setIdColaborador(s?.value)}
          isSearchable
          isClearable
          isConcatField
          defaultOptions
          concatModelName="colaborador"
          defaultValue={idColaborador}
        />
        <AsyncComboBox
          md={6}
          label="Veículo"
          name="veiculo"
          ref={veiculosRef}
          onChange={(s) => {
            if (vincularMultiplosVeiculosPorRota) {
              if (s instanceof Array) {
                setIdVeiculosEmpr(s?.map((e) => e.value) ?? []);
              }
            } else {
              if (s) {
                setIdVeiculosEmpr([s.value]);
              } else {
                setIdVeiculosEmpr([]);
              }
            }
          }}
          isSearchable
          isConcatField
          concatModelName="veiculo_empresa"
          defaultOptions
          defaultValue={
            vincularMultiplosVeiculosPorRota ? null : idVeiculosEmpr[0]
          }
          isMulti={vincularMultiplosVeiculosPorRota}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Conta Bancária para Emissão do Boleto"
          name="conta_bancaria"
          isConcatField
          concatModelName="conta_banc"
          defaultOptions
          isSearchable
          isClearable
          onChange={(s) => setIdContaBanc(s?.value)}
          defaultValue={idContaBanc}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Centro de Custo de Despesa"
          mode="centro_custo"
          onChange={setIdCentroCustoDespesa}
          value={idCentroCustoDespesa}
        />
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Centro de Custo de Receita"
          mode="centro_custo"
          onChange={setIdCentroCustoReceita}
          value={idCentroCustoReceita}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Conta Bancária para Recebimetno em PIX"
          name="conta_bancaria"
          isConcatField
          concatModelName="conta_banc"
          defaultOptions
          isSearchable
          isClearable
          onChange={(s) => setIdContaBancRecebPix(s?.value)}
          defaultValue={idContaBancRecebPix}
        />
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas de Recebimentos em PIX"
          mode="plano_contas"
          onChange={setIdPlanoContaPadRecebimentoPix}
          value={idPlanoContaPadRecebimentoPix}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Cliente Genérico da Rota"
          name="cliente_generico"
          isConcatField
          concatModelName="cliente"
          isSearchable
          isClearable
          defaultValue={idClienteGenerico}
          onChange={(s) => setIdClienteGenerico(s?.value)}
        />
        {permiteRecebimentoEmRotativo && (
          <PesqPlanoCtaCentroCusto
            md={6}
            label="Plano de Contas de Recebimentos em Rotativo"
            mode="plano_contas"
            onChange={setIdPlanoContaPadRecebimentoCredRota}
            value={idPlanoContaPadRecebimentoCredRota}
          />
        )}
      </Row>
      <Row>
        <IntegerFormInput
          md={2}
          label="Série NF-e"
          name="serie"
          defaultValue={serieNfe}
          onChange={setSerieNfe}
        />
        <IntegerFormInput
          md={2}
          label="Número da Última NF-e"
          name="ultimo_numero_nfe"
          defaultValue={ultimoNroNfeEmitido}
          onChange={setUltimoNroNfeEmitido}
        />
        <AsyncComboBox
          label="Layout Impressora"
          md={4}
          name="layout_impressora"
          isConcatField
          concatModelName="layout_impressoras_app"
          defaultOptions
          isSearchable
          onChange={(s) => setIdLayoutImpressora(s?.value)}
          defaultValue={idLayoutImpressora}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Fornecedor - Comissão"
          md={8}
          name="fornecedor_comis"
          isConcatField
          concatModelName="fornecedor"
          defaultOptions
          isSearchable
          isClearable
          onChange={(s) => setIdFornecComissao(s?.value)}
          defaultValue={idFornecComissao}
        />
      </Row>
      <Row>
        <NumberInput
          label="Flex - Início do Mês"
          md={2}
          name="flex_ini_mes"
          onChange={(v) => setVlrFlexInicioMes(v)}
          value={vlrFlexInicioMes}
        />
        <NumberInput
          label="Bonificação - Início do Mês"
          md={2}
          name="bonif_ini_mes"
          onChange={(v) => setVlrBonificaInicioMes(v)}
          value={vlrBonificaInicioMes}
        />
        {existeRedutorVlrTroca === true && (
          <NumberInput
            label="% Comissão sobre Troca (Redutor)"
            md={3}
            onChange={(v) => setRedutorVlrTroca(v)}
            value={redutorVlrTroca}
            isPercentage
            hint="Este redutor é utilizado para empresas que cobram o valor de troca dos seus vendedores"
          />
        )}
      </Row>
      <Row>
        <FormCheckbox
          label="Emitir NF-es de Remessa para Destinatário Ambulante"
          name="nfe_remessa_ambulante"
          checked={emiteNfeRemessaDestinatarioAmbulante}
          onChange={() =>
            setEmiteNfeRemessaDestinatarioAmbulante(
              !emiteNfeRemessaDestinatarioAmbulante
            )
          }
          md={4}
        />
        {emiteNfeRemessaDestinatarioAmbulante && (
          <AsyncComboBox
            md={4}
            label="Cliente Destinatário Ambulante"
            name="cliente_dest_ambulante"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            defaultValue={idClienteDestinatarioAmbulante}
            onChange={(s) => setIdClienteDestinatarioAmbulante(s?.value)}
          />
        )}
      </Row>
      <Row className="mb-2">
        <FormCheckbox
          label="Trabalha com Estoque Intermediário"
          checked={trabalhaEstoqueIntermediario}
          onChange={() => {
            setTrabalhaEstoqueIntermediario(!trabalhaEstoqueIntermediario);
          }}
        />
        {trabalhaEstoqueIntermediario && (
          <AsyncComboBox
            label="Estoque Intermediário Utilizado"
            md={4}
            isConcatField
            concatModelName="rota_estoque_inter"
            defaultOptions
            isSearchable
            onChange={(s) => setIdRotaEstoqueMestre(s?.value ?? null)}
            defaultValue={idRotaEstoqueMestre}
          />
        )}
      </Row>
      <Row>
        <RadioGroup
          label="Zerar Estoque Por"
          value={zerarEstoquePor}
          onChange={(v) => setZerarEstoquePor(v)}
          disabled={trabalhaEstoqueIntermediario}
          disabledHint="Por definição, rotas que trabalham com estoque intermediário zeram o estoque por dia"
        >
          <RadioItem label="Por Duração de Jornada" value="DUR" />
          <RadioItem label="Por Dia" value="DIA" />
          <RadioItem label="Não Zerar" value="NAO" />
        </RadioGroup>
      </Row>
    </ModalCadastroV2>
  );
};
