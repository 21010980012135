import React, { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../../../../../components/ModalBase";
import RemessaBancariaService from "../../../../../../services/bancario/RemessaBancariaService";

export const ConfirmarReabRemessaModal = ({ id, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const reabrirRemessa = async () => {
    setLoading(true);
    const [ok] = await RemessaBancariaService.reabrirRemessa(id);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <div id={`reab-rem-${id}`} onClick={() => toggle()} />
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="sm"
          title="Reabertura de Remessa"
          onConfirm={reabrirRemessa}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center">
            <p style={{ textAlign: "center" }}>
              Tem certeza de que deseja reabrir a remessa {id}?
            </p>
          </Row>
        </ModalBase>
      )}
    </>
  );
};
