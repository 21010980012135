import React from "react";
import { Card, Col, Row, UncontrolledTooltip } from "reactstrap";
import {
  FixedField,
  FormButton,
  LabelButton,
  PageContainer,
} from "../../../components";
import { useEffect } from "react";
import { CaixaLojaGrid } from "./components/CaixaLojaGrid";
import { useState } from "react";
import CaixaLojaService from "../../../services/financeiro/CaixaLojaService";
import { formatNumber } from "../../../coreUtils";
import { BsCurrencyDollar } from "react-icons/bs";
import { receberCaixaLojaRoute } from "../../../routes/modules/financeiro";
import { AbrirCaixaLojaModal } from "./components/AbrirCaixaLojaModal";
import { FecharCaixaLojaModal } from "./components/FecharCaixaLojaModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toastr } from "react-redux-toastr";
import { DetalheOSModal } from "./components/DetalheOSModal";
import { DetalheVendaModal } from "./components/DetalheVendaModal";
import { CreditoRotativoModal } from "./components/CreditoRotativoModal";
import { SangriaCaixaModal } from "./components/SangriaCaixaModal";
import { ReforcoCaixaModal } from "./components/ReforcoCaixaModal";
import { RetiradaCaixaModal } from "./components/RetiradaCaixaModal";
import { SenhaModal } from "../../../components/SenhaModal";
import { VerifProcPagTefNovamenteModal } from "./components/VerifProcPagTefNovamenteModal";
import { TentarProcPagTefNovamenteModal } from "./components/TentarProcPagTefNovamenteModal";

export const CaixaLoja = () => {
  const history = useHistory();
  const [saldoAbertIgualUltFec, setSaldoAbertIgualUltFec] = useState(false);
  const [mostraSaldoFechamento, setMostraSaldoFechamento] = useState(false);
  const [saldoAbertUltCaixa, setSaldoAbertUltCaixa] = useState(0);
  const [caixaAberto, setCaixaAberto] = useState(false);
  const [dadosCaixa, setDadosCaixa] = useState({});
  const [movsPendentes, setMovsPendentes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(null);
  const [idOrigemSel, setIdOrigemSel] = useState(null);
  const [valorSelected, setValorSelected] = useState(null);

  const [detalheOSOpen, setDetalheOSOpen] = useState(false);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);
  const [sangriaOpen, setSangriaOpen] = useState(false);
  const [reforcoOpen, setReforcoOpen] = useState(false);
  const [retiradaOpen, setRetiradaOpen] = useState(false);
  const [senhaReabrirVendaOpen, setSenhaReabrirVendaOpen] = useState(false);

  const [
    verifProcPagTefNovamenteModalOpen,
    setVerifProcPagTefNovamenteModalOpen,
  ] = useState(false);
  const [tentarProcTefNovamenteModalOpen, setTentarProcTefNovamenteModalOpen] =
    useState(false);

  const buscarCaixa = async () => {
    setLoading(true);
    const ret = await CaixaLojaService.buscarCaixa();
    if (ret?.dados_caixa) {
      setCaixaAberto(ret.caixa_aberto);
      setDadosCaixa(ret.dados_caixa);
      setMovsPendentes(ret.movs_pendentes);

      const parametros = ret.parametros;

      setSaldoAbertIgualUltFec(
        parametros.saldo_abertura_igual_ultimo_fechamento
      );
      setMostraSaldoFechamento(parametros.calcula_e_mostra_saldo_no_fechamento);

      if (
        ret.caixa_aberto === false &&
        parametros.saldo_abertura_igual_ultimo_fechamento
      ) {
        const ret2 = await CaixaLojaService.buscarSaldoFecUltCaixa();
        setSaldoAbertUltCaixa(parseFloat(ret2.ultimo_saldo_fec));
      }
    }
    setMounted(true);
    setLoading(false);
  };

  const receber = async () => {
    if ([0, null, undefined].includes(selected)) {
      toastr.warning(
        "Atenção",
        "Por favor, selecione um movimento na listagem."
      );
      return false;
    }
    if (parseFloat(valorSelected) === 0) {
      // Apenas fecha o movimento com valor zero
      const payload = {
        id_mov_receber: selected,
        vlr_troco_dinh: 0,
        vlr_troco_cred: 0,
        moedas_rec: [],
      };

      const [ok, _] = await CaixaLojaService.receber(payload);
      if (ok) {
        buscarCaixa();
      }
    } else {
      const [ok, ret] = await CaixaLojaService.verifTemRecTefPendente(selected);
      if (ok) {
        if (ret.tem_recs_pendentes) {
          setVerifProcPagTefNovamenteModalOpen(true);
        } else {
          history.push(receberCaixaLojaRoute.path, { id: selected });
        }
      }
    }
  };

  const reabrirVenda = async (senha) => {
    const payload = {
      id_mov_cxa_loja: selected,
      senha: senha,
    };
    const [ok] = await CaixaLojaService.reabrirVenda(payload);
    if (ok) {
      buscarCaixa();
    }
    return ok;
  };

  const toggleDetalheOS = () => {
    setDetalheOSOpen(!detalheOSOpen);
  };

  const toggleDetalheVenda = () => {
    setDetalheVendaOpen(!detalheVendaOpen);
  };

  const abrirDetalhes = (row) => {
    if (row.origem === "AOS") {
      setIdOrigemSel(row.id_origem_adiantamento);
    } else {
      setIdOrigemSel(row.nro_origem);
    }
    setTimeout(() => {
      if (["VOS", "AOS"].includes(row.origem)) {
        toggleDetalheOS();
      } else if (row.origem === "VFX") {
        toggleDetalheVenda();
      }
    }, 1);
  };

  const solicSenhaReabrirVenda = (idMovCxLoja) => {
    setSelected(idMovCxLoja);
    setTimeout(() => {
      toggleSenhaReabrirVenda();
    }, 1);
  };

  const toggleSangria = () => {
    if (sangriaOpen) {
      buscarCaixa();
    }
    setSangriaOpen(!sangriaOpen);
  };
  const toggleReforco = () => {
    if (reforcoOpen) {
      buscarCaixa();
    }
    setReforcoOpen(!reforcoOpen);
  };
  const toggleRetirada = () => {
    if (retiradaOpen) {
      buscarCaixa();
    }
    setRetiradaOpen(!retiradaOpen);
  };

  const toggleSenhaReabrirVenda = () =>
    setSenhaReabrirVendaOpen(!senhaReabrirVendaOpen);

  const handleSelect = (s, _, row) => {
    setSelected(s);
    setValorSelected(row?.valor);
  };

  useEffect(() => {
    buscarCaixa();
  }, []);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (caixaAberto) {
        if (e.key === "F9") {
          receber();
        } else if (e.key === "F7") {
          e.preventDefault();
          toggleSangria();
        }
      } else {
        if (e.key === "F4") {
          document.getElementById("bt-abrir-caixa").click();
        }
      }
    }
  };

  const toggleVerifProcPagTefNovamente = (continuar) => {
    if (continuar === true) {
      setTentarProcTefNovamenteModalOpen(true);
    }

    setVerifProcPagTefNovamenteModalOpen(!verifProcPagTefNovamenteModalOpen);
  };

  const toggleTentarProcPagTefNovamente = () => {
    setTentarProcTefNovamenteModalOpen(!tentarProcTefNovamenteModalOpen);
  };

  return (
    <PageContainer
      title="Caixa Loja"
      number="0063"
      loading={loading && !mounted}
      onKeyDown={onKeyDown}
      canGoBack
    >
      {caixaAberto ? (
        <>
          <Card body>
            <Row>
              <Col md={10}>
                <Row>
                  <FixedField
                    md={2}
                    label="Nro. Caixa"
                    value={dadosCaixa?.id}
                  />
                  <FixedField
                    md={2}
                    label="Saldo Abertura"
                    value={formatNumber(dadosCaixa?.saldo_abe ?? 0, true, 2)}
                    disabled
                  />
                  <FixedField
                    md={3}
                    label="Abertura"
                    value={`${dadosCaixa?.data_abertura} - ${dadosCaixa.hora_abertura}`}
                  />
                  <FixedField
                    label="Operador"
                    value={dadosCaixa.nome_operador}
                  />
                  {dadosCaixa.aviso_sangria && (
                    <Col md={3} className="no-gutters ml-auto">
                      <div
                        style={{
                          backgroundColor: "red",
                          paddingInline: "0.4rem",
                          borderRadius: "0.5rem",
                          height: "max-content",
                        }}
                        id="aviso-limite-sangria"
                      >
                        <label
                          style={{
                            color: "white",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          AVISO: Saldo em caixa excedeu o valor em dinheiro
                        </label>
                      </div>
                      <UncontrolledTooltip target="aviso-limite-sangria">
                        Providencie uma sangria de caixa
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={4}></Col>
                  <FecharCaixaLojaModal
                    notifyEvent={buscarCaixa}
                    saldoDinh={parseFloat(dadosCaixa?.saldo_dinh ?? 0)}
                    mostraSaldoFechamento={mostraSaldoFechamento}
                    idCaixa={dadosCaixa?.id}
                  />
                </Row>
              </Col>
              {mostraSaldoFechamento && (
                <Col md={2} className="pl-0">
                  <FixedField
                    label="Saldo Dinheiro"
                    value={formatNumber(dadosCaixa?.saldo_dinh, true, 2)}
                    divClassName="ml-auto"
                    align="right"
                    labelStyle={{ fontSize: "0.85rem" }}
                    textStyle={{ fontSize: "2rem" }}
                  />
                </Col>
              )}
            </Row>
          </Card>
          <Card body>
            <Row>
              <Col md={9}>
                <Row>
                  <LabelButton md={3} onClick={toggleSangria}>
                    F7 - Sangria
                  </LabelButton>
                  <SangriaCaixaModal
                    isOpen={sangriaOpen}
                    toggle={toggleSangria}
                    idCaixa={dadosCaixa?.id}
                    saldoDinheiro={parseFloat(dadosCaixa?.saldo_dinh ?? 0)}
                    nomeOperador={dadosCaixa?.nome_operador}
                    mostraSaldoFechamento={mostraSaldoFechamento}
                  />
                  <LabelButton md={3}>F10 - Crediário</LabelButton>
                  <LabelButton md={3} onClick={buscarCaixa}>
                    F5 - Atualizar
                  </LabelButton>
                </Row>
                <Row>
                  <LabelButton md={3} onClick={toggleReforco}>
                    Reforço
                  </LabelButton>
                  <ReforcoCaixaModal
                    isOpen={reforcoOpen}
                    toggle={toggleReforco}
                    idCaixa={dadosCaixa?.id}
                    saldoDinheiro={parseFloat(dadosCaixa?.saldo_dinh ?? 0)}
                    nomeOperador={dadosCaixa?.nome_operador}
                    mostraSaldoFechamento={mostraSaldoFechamento}
                  />
                  <CreditoRotativoModal notifyEvent={buscarCaixa} />
                  <LabelButton md={3}>Abrir Gaveta</LabelButton>
                </Row>
                <Row>
                  <LabelButton md={3} onClick={toggleRetirada}>
                    F8 - Retirada
                  </LabelButton>
                  <RetiradaCaixaModal
                    isOpen={retiradaOpen}
                    toggle={toggleRetirada}
                    idCaixa={dadosCaixa?.id}
                    saldoDinheiro={parseFloat(dadosCaixa?.saldo_dinh ?? 0)}
                    nomeOperador={dadosCaixa?.nome_operador}
                    mostraSaldoFechamento={mostraSaldoFechamento}
                  />
                  <LabelButton md={3}>Contas a Pagar</LabelButton>
                </Row>
              </Col>
              <Col
                md={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FormButton
                  padded={false}
                  divClassName="ml-auto"
                  md="auto"
                  color="success"
                  className="py-2"
                  onClick={receber}
                  disabled={!selected}
                  divStyle={{ marginTop: "0.35rem" }}
                >
                  <BsCurrencyDollar
                    size={22}
                    style={{ marginTop: "-0.3rem" }}
                  />
                  <span
                    style={{
                      marginBottom: "0",
                      marginLeft: "0.5rem",
                      fontSize: "1.1rem",
                      fontWeight: 600,
                    }}
                  >
                    F9 - Receber
                  </span>
                </FormButton>
              </Col>
            </Row>
          </Card>
          <Card body>
            <CaixaLojaGrid
              dados={movsPendentes}
              setSelected={handleSelect}
              abrirDetalhes={abrirDetalhes}
              solicSenhaReabrirVenda={solicSenhaReabrirVenda}
            />
          </Card>
          <DetalheOSModal
            isOpen={detalheOSOpen}
            toggle={toggleDetalheOS}
            idOS={idOrigemSel}
          />
          <DetalheVendaModal
            isOpen={detalheVendaOpen}
            toggle={toggleDetalheVenda}
            idVenda={idOrigemSel}
          />
          <SenhaModal
            isOpen={senhaReabrirVendaOpen}
            toggle={toggleSenhaReabrirVenda}
            title="Reabertura de Venda"
            onConfirm={reabrirVenda}
          />
          <VerifProcPagTefNovamenteModal
            isOpen={verifProcPagTefNovamenteModalOpen}
            toggle={toggleVerifProcPagTefNovamente}
            idMovCaixa={selected}
          />
          <TentarProcPagTefNovamenteModal
            isOpen={tentarProcTefNovamenteModalOpen}
            toggle={toggleTentarProcPagTefNovamente}
            notifyEvent={buscarCaixa}
            idMovCxaLoja={selected}
          />
        </>
      ) : (
        <>
          <div style={{ height: "calc(70vh)", display: "flex" }}>
            <Card body className="my-auto">
              <Row className="mb-4" style={{ justifyContent: "center" }}>
                <Col>
                  <div
                    style={{
                      padding: "1rem",
                      backgroundColor: "red",
                      width: "100%",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <h4
                      style={{
                        marginBottom: 0,
                        color: "white",
                        fontWeight: 600,
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                    >
                      Não Existe Caixa Aberto <br /> para o seu Usuário
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <AbrirCaixaLojaModal
                  notifyEvent={buscarCaixa}
                  saldoAbertIgualUltFec={saldoAbertIgualUltFec}
                  saldoAbertUltCaixa={saldoAbertUltCaixa}
                />
              </Row>
            </Card>
          </div>
        </>
      )}
    </PageContainer>
  );
};
