import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap/lib";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import { VendasAbaixoMargLucroGrid } from "./components/VendasAbaixoMargLucroGrid";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

export const VendasAbaixoMargLucro = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [cliente, setCliente] = useState(null);
  const [vendedor, setVendedor] = useState(null);
  const [dados, setDados] = useState([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => setDados([]);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      id_vendedor: vendedor,
    };

    return await apiGetV2(
      `/relatorios/venda_desconto/vendas_abaixo_marg_lucro/`,
      params
    );
  };

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        dados: ret,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };
      await docPrintReport(
        "/relatorios/venda/vendas_abaixo_marg_lucro/",
        payload,
        "5038_1"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim, cliente, vendedor]);

  return (
    <PageContainer
      title="Vendas Abaixo do Lucro Mínimo"
      number="5038"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            md={5}
            onChange={(selected) => setCliente(selected?.value)}
            isSearchable
            isClearable
          />
          <AsyncComboBox
            label="Vendedor"
            md={4}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isSearchable
            isClearable
            onChange={(s) => setVendedor(s?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <VendasAbaixoMargLucroGrid data={dados} />
      </Card>
    </PageContainer>
  );
};
