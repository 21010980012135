import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import { Badge, DropdownItem } from "reactstrap";
import { useSelector } from "react-redux";

export const FrenteVendaGrid = ({
  dados,
  excluirItem,
  recalcularValoresGrade,
  atualizarDadosItem,
  alterarObservItem,
  idOrcamentoAlterando,
}) => {
  const params = useSelector((state) => state.paramsFrenteVenda);
  const utilizaAmbiente = params.utiliza_ambiente;

  const columns = [
    { dataField: "item", text: "I", align: "center" },
    {
      dataField: "tipo",
      text: "T",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "abrevia_ambiente",
      text: "Amb",
      align: "center",
      hidden: !utilizaAmbiente,
    },
    {
      dataField: "id_prod_serv",
      text: "Código",
      align: "center",
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      hidden: params.utiliza_cor_prod || params.utiliza_tamanho_prod,
    },
    {
      dataField: "tam_cor",
      text: "Tam | Cor",
      align: "center",
      formatter: (_, row) => {
        const data = [];

        if (params.utiliza_tamanho_prod && row.tamanho_prod) {
          data.push(row.tamanho_prod);
        }
        if (params.utiliza_cor_prod && row.cor_prod) {
          data.push(row.cor_prod);
        }

        return data.join(" | ");
        // return (
        //   <div
        //     style={{
        //       display: "flex",
        //       width: "100%",
        //       justifyContent: "space-around",
        //     }}
        //   >
        //     {data.map((e) => (
        //       <span>{e}</span>
        //     ))}
        //   </div>
        // );
      },
      hidden: !params.utiliza_cor_prod && !params.utiliza_tamanho_prod,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      editable: (_, row) => row.curinga,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "text",
      alwaysShowEditor: true,
    },
    {
      dataField: "local",
      text: "Local",
      align: "center",
      hidden: params.informar_perc_comis_frente_venda,
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "vlr_unit",
      text: "Unitário",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: (_, row) => {
        return row.lista_preco !== true;
      },
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
      editable: (_, row) => {
        return row.lista_preco !== true;
      },
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_comis",
      text: "% Comis.",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
      editable: (_, row) => {
        return params.informar_perc_comis_frente_venda && row.tipo === "P";
      },
      hidden: !params.informar_perc_comis_frente_venda,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      colWidth: "50px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_tot_comis",
      text: "V. Comis.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      hidden: !params.informar_perc_comis_frente_venda,
      colWidth: "50px",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          {row.tipo === "P" && (
            <DropdownItem
              onClick={() => alterarObservItem(row.tipo, row.id, row.observ)}
            >
              Observação
            </DropdownItem>
          )}
          <hr />
          <DropdownItem onClick={() => excluirItem(row.tipo, row.id)}>
            Excluir
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      pageSize={10}
      footerElements={
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {idOrcamentoAlterando && (
            <Badge style={{ paddingTop: "6px" }} color="warning">
              Alterando orçamento nº {idOrcamentoAlterando}
            </Badge>
          )}
        </div>
      }
    />
  );
};
