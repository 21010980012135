import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ModalBase, RadioGroup } from "../../../../../components";
import { RadioItem } from "../../../../../components/RadioGroup";
import { Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { LuXOctagon } from "react-icons/lu";
import { BsCheckLg } from "react-icons/bs";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";
import { toastr } from "react-redux-toastr";

const STATUS = {
  LOADING: 1,
  ERROR: 2,
  SUCCESS: 3,
};

export const FaturarNfModal = forwardRef(
  ({ notifyEvent, idMovCxaLoja }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modeloNFe, setModeloNFe] = useState(null);

    const [status, setStatus] = useState(STATUS.LOADING);
    const [msgStatus, setMsgStatus] = useState("");

    // 1 - Perguntar se deseja emitir NF-e
    // 2 - Perguntar modelo NF-e
    // 3 - Imprimir/Concluído
    const [estagio, setEstagio] = useState(1);

    const toggleInternal = (emiteAuto, modelo) => {
      if (!isOpen) {
        if (emiteAuto) {
          if (modelo !== "PERG" && modelo) {
            faturar(modelo);
            setEstagio(3);
          } else {
            setEstagio(2);
          }
        } else {
          setEstagio(1);
          if (modelo !== "PERG" && modelo) {
            setModeloNFe(modelo);
          }
        }
      }
      setIsOpen(!isOpen);
    };

    const faturar = async (modelo) => {
      setStatus(STATUS.LOADING);
      const payload = {
        id_mov_cxa: idMovCxaLoja,
        modelo: modelo,
      };

      const [ok, ret] = await CaixaLojaService.faturar(payload);
      if (ok) {
        if (ret.res.nf_emitida) {
          // Imprimir NFC-e
          setMsgStatus("Gerando Impressão");
          if (modelo === "NFCE") {
            await NotaFiscalConsumidorService.imprimir(ret.res.id_nfe);
          } else {
            await NotaFiscalService.imprimir(ret.res.id_nfe);
          }
          setStatus(STATUS.SUCCESS);
          setMsgStatus("NF Faturada com Sucesso!");
          setTimeout(notifyEvent, 2500);
        } else {
          setStatus(STATUS.ERROR);
          setMsgStatus(
            "Nota Fiscal faturada, mas ocorreu um erro durante a emissão. " +
              "Por favor, faça a emissão manualmente. Erro: " +
              ret.res.msg_erro_emissao
          );
        }
      } else {
        setMsgStatus(
          "Ocorreu um erro durante o faturamento da Nota Fiscal. " +
            "Por favor, realize o faturamento manualmente. Erro: " +
            ret
        );
        setStatus(STATUS.ERROR);
      }
    };

    useImperativeHandle(ref, () => ({
      toggle: toggleInternal,
    }));

    const handleSubmit = async () => {
      if (estagio === 1) {
        if (modeloNFe === null) {
          setEstagio(2);
        } else {
          setEstagio(3);
          await faturar(modeloNFe);
        }
      } else if (estagio === 2) {
        if (!["NFE", "NFCE"].includes(modeloNFe)) {
          toastr.warning(
            "Atenção",
            "Por favor, selecione o modelo da Nota Fiscal"
          );
          return;
        }

        setEstagio(3);
        await faturar(modeloNFe);
      }
    };

    return (
      <ModalBase
        isOpen={isOpen}
        toggle={
          estagio === 3 && status === STATUS.ERROR ? toggleInternal : null
        }
        size="md"
        title="Recebimento do Caixa Loja"
        number="0063_18"
        cancelButtonText={
          estagio === 1 ? "Não" : estagio === 2 ? "Cancelar" : "Fechar"
        }
        hideCancelButton={estagio === 3 && status !== STATUS.ERROR}
        confirmButtonText={estagio === 1 ? "Sim" : "Confirmar"}
        onConfirm={estagio !== 3 ? handleSubmit : null}
        onCancel={notifyEvent}
      >
        {estagio === 1 && (
          <>
            <h5 style={{ textAlign: "center" }}>
              Você deseja gerar a Nota Fiscal do recebimento agora?
            </h5>
          </>
        )}
        {estagio === 2 && (
          <>
            <Row>
              <RadioGroup
                label="Selecione o modelo da NF que você deseja gerar"
                value={modeloNFe}
                onChange={setModeloNFe}
                divClassName="m-auto"
              >
                <RadioItem label="NF-e" value="NFE" />
                <RadioItem label="NFC-e" value="NFCE" />
              </RadioGroup>
            </Row>
          </>
        )}
        {estagio === 3 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {status === STATUS.LOADING && (
                <Spinner
                  size="lg"
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "0.8rem solid #ccc",
                    borderRightColor: "transparent",
                  }}
                  color="silver"
                />
              )}
              {status === STATUS.SUCCESS && (
                <BsCheckLg
                  size={30}
                  color="green"
                  style={{ width: "150px", height: "150px" }}
                />
              )}
              {status === STATUS.ERROR && (
                <LuXOctagon
                  size={30}
                  color="red"
                  style={{ width: "150px", height: "150px" }}
                />
              )}
            </div>

            <h4 style={{ textAlign: "center" }}>
              {msgStatus}
              {status === STATUS.LOADING ? "..." : ""}
            </h4>
          </>
        )}
      </ModalBase>
    );
  }
);
