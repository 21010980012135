import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import { toastr } from "react-redux-toastr";
import { UncontrolledTooltip } from "reactstrap";
import { DataTable } from "../../../../../../components/DataTable";
import { TableCheck } from "../../../../../../components/TableCheck";
import { formatNumber } from "../../../../../../coreUtils";

export const EntregarPedidosGrid = ({
  data,
  atualizarVlrEntrega,
  identificaProdutoRevenda,
}) => {
  const _columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      editable: false,
      formatter: (cell) => {
        if (cell === "P") {
          return "Produto";
        } else {
          return "Serviço";
        }
      },
    },
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    ...(identificaProdutoRevenda
      ? [
          {
            dataField: "revenda",
            text: "Revenda",
            align: "center",
            headerAlign: "center",
            editable: false,
            formatter: (cell) => <TableCheck value={cell} />,
          },
        ]
      : []),
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      editable: false,
      formatter: (cell) => parseFloat(cell),
    },
    {
      dataField: "vlr_final",
      text: "V. Item",
      align: "right",
      headerAlign: "right",
      editable: false,
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "V. Total",
      align: "right",
      headerAlign: "right",
      editable: false,
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "qt_entregue",
      text: "Qt. Entregue",
      align: "center",
      headerAlign: "center",
      editable: false,
      formatter: (cell) => parseFloat(cell),
    },
    {
      dataField: "vlr_entregue",
      text: "V. Entregue",
      align: "right",
      headerAlign: "right",
      editable: false,
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "qt_saldo_dis",
      text: "Qt. Restante",
      align: "center",
      headerAlign: "center",
      editable: false,
      formatter: (cell) => parseFloat(cell),
    },
    {
      dataField: "vlr_pend_entrega",
      text: "V. Restante",
      align: "right",
      headerAlign: "right",
      editable: false,
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "qt_entregar",
      text: "Qt. Entregar",
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "12%" };
      },
      editable: (c, row) => parseFloat(row.qt_saldo_dis) > 0,
      editCellStyle: () => ({ padding: 0, verticalAlign: "middle" }),
      formatter: (c, row) =>
        parseFloat(row.qt_saldo_dis) > 0 ? (
          formatNumber(c, true, 2, true)
        ) : (
          <>
            <div id={`item-ped-${row.id}`}>{c}</div>
            <UncontrolledTooltip target={`item-ped-${row.id}`}>
              O item foi totalmente entregue
            </UncontrolledTooltip>
          </>
        ),
      editorStyle: { textAlign: "center", width: "50%", marginInline: "auto" },
      validator: (newValue, row) => {
        if (newValue.toString().match(/^[0-9]+([\.|,]?[0-9]+)?$/)) {
          const newValueFloat = parseFloat(newValue.replace(",", "."));
          const qtdSaldoDis = parseFloat(row.qt_saldo_dis);
          if (newValueFloat > qtdSaldoDis) {
            toastr.warning(
              "Atenção",
              "A quantidade informada não pode ser superior à restante"
            );
            return { valid: false, message: "" };
          }

          return true;
        } else {
          toastr.warning("Atenção", "Por favor, informe um valor válido");
          return { valid: false, message: "" };
        }
      },
    },
  ];

  return (
    <DataTable
      columns={_columns}
      data={data}
      noWrap
      cellEdit={cellEditFactory({
        mode: "click",
        afterSaveCell: (oldValue, newValue, row, column) => {
          row.qt_entregar = parseFloat(newValue.toString().replace(",", "."));
          atualizarVlrEntrega();
        },
        blurToSave: true,
        autoSelectText: true,
        timeToCloseMessage: 0,
      })}
      readOnly
      hideCount
    />
  );
};
