import React, { useState } from "react";
import { Col, Label, Row } from "reactstrap";
import {
  DragAndDropArquivos,
  FormButton,
  PasswordInput,
  TextInput,
} from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { ModalGerarAuthCode } from "./ModalGerarAuthCode";
import {
  setApiClientId,
  setApiClientSecret,
  setArqCertificadoApi,
  setArqKeyApi,
} from "../store/cadastroContaBancSlice";
import { BsX } from "react-icons/bs";

const rowClassName = "mb-2";

const DragDropCert = ({
  certificado,
  handleSetCertificado,
  certificadoConfigurado,
  descCampo,
  accept = "*",
}) => (
  <>
    {certificado ? (
      <Col md="auto">
        <h5 className="mb-1">{descCampo}:</h5>
        <span>
          <strong>{certificado?.file?.name}</strong>
          <BsX
            size={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleSetCertificado(null)}
          />
        </span>
      </Col>
    ) : (
      <DragAndDropArquivos
        md="auto"
        divClassName="no-gutters"
        text={
          !certificadoConfigurado ? (
            <>Selecionar {descCampo}</>
          ) : (
            <>Atualizar {descCampo}</>
          )
        }
        onDrop={handleSetCertificado}
        accept={accept}
        style={{
          padding: "0.5rem 1rem",
          marginTop: "0",
          color: "black",
          cursor: "pointer",
          borderColor: "#ddd",
          marginLeft: "2px",
        }}
        textStyle={{
          marginTop: "0",
          marginBottom: "0",
          color: "white",
        }}
      />
    )}
  </>
);

export const DadosCobrancaAPI = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const storeRoot = useSelector((state) => state.cadastroContaBanc);
  const dispatch = useDispatch();

  const [modalGerarAuthCodeIsOpen, setModalGerarAuthCodeIsOpen] =
    useState(false);

  const toggleModalGerarAuthorizationCode = () =>
    setModalGerarAuthCodeIsOpen(!modalGerarAuthCodeIsOpen);

  const certificadoConfigurado = storeRoot.api_certif_config;
  const chaveConfigurada = storeRoot.api_key_config;

  return (
    <>
      {store.layout === "BTG" && (
        <>
          <Row className={rowClassName}>
            <FormButton
              color="warning"
              md={4}
              onClick={toggleModalGerarAuthorizationCode}
            >
              Gerar Authorization Code
            </FormButton>
            <TextInput
              label="Authorization Code"
              md={6}
              value={store.api_authorization_code}
              disabled
              forceUppercase={false}
            />
          </Row>
          <ModalGerarAuthCode
            toggle={toggleModalGerarAuthorizationCode}
            isOpen={modalGerarAuthCodeIsOpen}
          />
        </>
      )}
      {store.layout === "INT" && (
        <>
          <Row className={rowClassName}>
            <TextInput
              label="Client ID da Integração"
              md={6}
              value={store.api_client_id}
              onChange={(e, v) => {
                dispatch(setApiClientId(v));
              }}
              forceUppercase={false}
            />
            <PasswordInput
              label="Client Secret da Integração"
              md={6}
              value={store.api_client_secret}
              onChange={(v) => dispatch(setApiClientSecret(v))}
            />
          </Row>
          <Row className={rowClassName}>
            <Col md="6">
              <Row>
                <Col md="auto" style={{ display: "flex", paddingRight: "0px" }}>
                  <Row className="no-gutters">
                    <Label
                      className="mb-0 no-gutters"
                      style={{
                        fontWeight: "bold",
                        color: certificadoConfigurado ? "blue" : "#495057",
                        alignSelf: "center",
                        margin: "0 !important",
                        padding: "0px",
                      }}
                    >
                      {certificadoConfigurado
                        ? "Certificado Configurado"
                        : "Importe o Certificado da Integração"}
                    </Label>
                  </Row>
                </Col>

                <DragDropCert
                  certificado={storeRoot.arq_certificado_api}
                  handleSetCertificado={(f) => {
                    if (f === null) {
                      dispatch(setArqCertificadoApi(null));
                      return;
                    }
                    var reader = new FileReader();

                    reader.addEventListener("load", async function (e) {
                      const res = {
                        file: f,
                        base64: e.target?.result,
                      };

                      dispatch(setArqCertificadoApi(res));
                    });

                    reader.readAsDataURL(f);
                  }}
                  certificadoConfigurado={certificadoConfigurado}
                  descCampo="Certificado"
                  accept=".crt"
                />
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md="auto" style={{ display: "flex", paddingRight: "0px" }}>
                  <Row className="no-gutters">
                    <Label
                      className="mb-0 no-gutters"
                      style={{
                        fontWeight: "bold",
                        color: chaveConfigurada ? "blue" : "#495057",
                        alignSelf: "center",
                        margin: "0 !important",
                        padding: "0px",
                      }}
                    >
                      {chaveConfigurada
                        ? "Chave Configurada"
                        : "Importe a Chave da Integração"}
                    </Label>
                  </Row>
                </Col>
                <DragDropCert
                  certificado={storeRoot.arq_key_api}
                  handleSetCertificado={(f) => {
                    if (f === null) {
                      dispatch(setArqKeyApi(null));
                      return;
                    }
                    var reader = new FileReader();

                    reader.addEventListener("load", async function (e) {
                      const res = {
                        file: f,
                        base64: e.target?.result,
                      };

                      dispatch(setArqKeyApi(res));
                    });

                    reader.readAsDataURL(f);
                  }}
                  certificadoConfigurado={chaveConfigurada}
                  descCampo="Chave"
                  accept=".key"
                />
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
