import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { DropdownItem, Label } from "reactstrap";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";

export const GerenciamentoVendasGrid = ({
  dados,
  onSelect,
  selected,
  estornarVenda,
  mostrarDetalhes,
}) => {
  const columns = [
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
          <DropdownItem
            onClick={() => GerenciamentoVendasService.imprimir(row.id)}
          >
            Imprimir
          </DropdownItem>
          <DropdownItem onClick={() => estornarVenda(row.id)}>
            Estornar
          </DropdownItem>
        </MoreDropdown>
      ),
      footer: "",
    },
    {
      dataField: "id",
      text: "Nº",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
      sortable: true,
    },
    {
      dataField: "nome_vendedor",
      text: "Vendedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_colab_vendedor),
      sortable: true,
      hideSortIcon: true,
    },
    {
      dataField: "emissao",
      text: "Data",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_tot_bruto",
      text: "Tot, Bruto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_tot_acre",
      text: "Acrésc,",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_desc",
      text: "Desc.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_frete",
      text: "Frete",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_devol",
      text: "Devol.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_tot_prod",
      text: "Produtos",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_tot_liq",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      multiselect
      onSelect={onSelect}
      selected={selected}
      footerElements={
        <Label style={{ fontSize: "11px" }}>
          Clique nos cabeçalhos das colunas para ordenar as informações da
          grade.
        </Label>
      }
    />
  );
};
