import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  ModalBase,
} from "../../../../../components";
import DuracoesJornadaService from "../../../../../services/jornada/duracoes/DuracoesJornadaService";
import { formatDateISO } from "../../../../../coreUtils";
import moment from "moment";

export const NovaDuracaoModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState();
  const [idRotaMob, setIdRotaMob] = useState(null);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdRotaMob(null);
    setDataIni(null);
    setDataFim(null);
  };

  const toggle = () => setIsOpen(!isOpen);

  const gravarDados = async () => {
    setLoading(true);

    const payload = {
      id_rota_mob: idRotaMob,
      data_inicio: formatDateISO(dataIni),
      data_encerramento: formatDateISO(dataFim),
    };

    const [ok] = await DuracoesJornadaService.adicionar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    const invalidOpts = [null, undefined, 0];
    if (invalidOpts.includes(idRotaMob)) {
      toastr.warning("Atenção", "Por favor, informe a Rota");
      return false;
    }
    if (invalidOpts.includes(dataIni)) {
      toastr.warning("Atenção", "Por favor, informe a Data de Início");
      return false;
    }
    if (invalidOpts.includes(dataFim)) {
      toastr.warning("Atenção", "Por favor, informe a Data de Fim");
      return false;
    }

    gravarDados();
  };

  return (
    <>
      <FormButton
        color="info"
        md="auto"
        onClick={() => toggle()}
        padded={false}
      >
        Nova Duração
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Nova Duração de Jornada"
        number="0023_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={6}
            label="Rota"
            onChange={(s) => setIdRotaMob(s?.value)}
            autoFocus
          />

          <DatePicker
            label="Início"
            md={3}
            value={dataIni}
            onChange={(v) => setDataIni(moment.isMoment(v) ? v.toDate() : v)}
          />
          <DatePicker
            label="Fim"
            md={3}
            value={dataFim}
            onChange={(v) => setDataFim(moment.isMoment(v) ? v.toDate() : v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
