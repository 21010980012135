import React, { useState } from "react";
import { ModalBase, TextInput } from "../../../../../components";
import { Row } from "reactstrap";

export const ObservItemModal = ({
  isOpen,
  toggle,
  selectedObserv,
  handleSubmit,
  notifyEvent,
}) => {
  const [observ, setObserv] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setObserv("");
  };

  const onClosed = () => {
    limparDados();
    notifyEvent();
  };

  const onConfirm = async () => {
    setLoading(true);
    if (await handleSubmit(observ)) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Observação do Item"
      number="0030_2"
      onClosed={onClosed}
      onConfirm={onConfirm}
      loadingConfirm={loading}
      onBeforeOpen={() => setObserv(selectedObserv)}
      bodyStyle={{ padding: "0.5rem" }}
    >
      <TextInput
        md={12}
        type="textarea"
        value={observ}
        onChange={(e, v) => setObserv(v)}
        rows={3}
        forceUppercase={false}
        colClassName="no-gutters"
        frmGroupClassName="mb-0"
        inputStyle={{ fontSize: "14px" }}
      />
    </ModalBase>
  );
};
