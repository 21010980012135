import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IconButton,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../components";
import {
  setUtilizaRomaneio,
  setGeraEstoqueIncManualNfe,
  setLayoutRelatorioNfContab,
  setUsaPrecoAtacado,
  setPriorizarPesqProd,
  setIdPlanoContasPadLocacao,
  setUtilizaCentroCusto,
  setFormaSistemaTribut,
  setResponsPorEnvioDeEmail,
  setFormaGeracaoPdfWeb,
  setUtilizaOrganizacao,
  setIdOrganizacaoPadrao,
} from "../store/geral_slice";
import { RadioItem } from "../../../../components/RadioGroup";
import { SenhaModal } from "../../../../components/SenhaModal";
import { apiPostV2 } from "../../../../apiV2";
import {
  ControlePersonContext,
  setLiberaGeraEstoqueIncManualNfe,
} from "../store_controle";
import { AiOutlineLock } from "react-icons/ai";

const layoutsRelatorioNfContab = [
  {
    label: "1 - Padrão - Tipo de Documento, Valor da Nota, Status da Nota",
    value: 1,
  },
  { label: "2 - Ordem Numérica - Geral, Status da Nota", value: 2 },
];

const PersonGeral = ({ controle, controleDispatch }) => {
  const store = useSelector((state) => state.geral);
  const dispatch = useDispatch();

  const [senhaOpen, setSenhaOpen] = useState(false);

  const toggleSenha = () => setSenhaOpen(!senhaOpen);

  const verificarSenha = async (senha) => {
    const payload = { senha: senha };
    const [ok] = await apiPostV2("/sistema/verificar_senha_sys/", payload, {
      successMessage: false,
      errorMessageType: "toastr",
    });
    if (ok) {
      controleDispatch(setLiberaGeraEstoqueIncManualNfe(true));
    }
    return ok;
  };

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Romaneio"
          checked={store.utiliza_romaneio}
          onChange={() => dispatch(setUtilizaRomaneio(!store.utiliza_romaneio))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gerar Movimentação de Estoque na Inclusão Manual da NFe e NFCe"
          checked={store.gera_estoque_inc_manual_nfe}
          onChange={() =>
            dispatch(
              setGeraEstoqueIncManualNfe(!store.gera_estoque_inc_manual_nfe)
            )
          }
          disabled={!controle.libera_gera_estoque_inc_manual_nfe}
        />
        {!controle.libera_gera_estoque_inc_manual_nfe && (
          <IconButton
            icon={AiOutlineLock}
            size={18}
            onClick={toggleSenha}
            hint="Clique para Desbloquear"
            tooltipPlacement="right"
          />
        )}
        <SenhaModal
          isOpen={senhaOpen}
          toggle={toggleSenha}
          title="Gerenciamento do Sistema"
          onConfirm={verificarSenha}
        />
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Layout do Relátorio de Notas Contábil"
          options={layoutsRelatorioNfContab}
          defaultValue={store.layout_relatorio_nf_contab}
          onChange={(s) => dispatch(setLayoutRelatorioNfContab(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Preços de Atacado e Varejo"
          checked={store.usa_preco_atacado}
          onChange={() =>
            dispatch(setUsaPrecoAtacado(!store.usa_preco_atacado))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Priorizar Pesquisa de Produtos por"
          value={store.priorizar_pesq_prod}
          onChange={(v) => dispatch(setPriorizarPesqProd(v))}
        >
          <RadioItem label="Código" value="COD" />
          <RadioItem label="Referência" value="REF" />
        </RadioGroup>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Locação - Plano de contas Padrão para Títulos Gerados a Partir da Locação"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasPadLocacao(v))}
          value={store.id_plano_contas_pad_locacao}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Financeiro - Utilizar Centro de Custo"
          checked={store.utiliza_centro_custo}
          onChange={() =>
            dispatch(setUtilizaCentroCusto(!store.utiliza_centro_custo))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Forma de Tributação"
          value={store.forma_sistema_tribut}
          onChange={(v) => dispatch(setFormaSistemaTribut(v))}
        >
          <RadioItem label="CFOP" value="CFOP" />
          <RadioItem label="Regra de Tributação" value="R_TRIB" />
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Responsabilidade pelo Envio de E-mails"
          value={store.respons_por_envio_de_email}
          onChange={(v) => dispatch(setResponsPorEnvioDeEmail(v))}
        >
          <RadioItem label="Skillsoft" value="SKILL" />
          <RadioItem label="Empresa" value="CLIGE" />
          {/* <RadioItem label="Empresa - Setores" value="CLIST" /> */}
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Geração de Impressões em PDF do Sistema Web"
          value={store.forma_geracao_pdf_web}
          onChange={(v) => dispatch(setFormaGeracaoPdfWeb(v))}
        >
          <RadioItem label="Visualizar" value="VISUALIZAR" />
          <RadioItem label="Baixar" value="DOWNLOAD" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={store.utiliza_organizacao}
          label="Utiliza Organização"
          checked={store.utiliza_organizacao}
          onChange={() =>
            dispatch(setUtilizaOrganizacao(!store.utiliza_organizacao))
          }
        />
        {store.utiliza_organizacao === true && (
          <AsyncComboBox
            label="Organização Padrão"
            md={3}
            isConcatField
            concatModelName="organizacao"
            defaultValue={store.id_organizacao_padrao}
            onChange={(s) => dispatch(setIdOrganizacaoPadrao(s?.value))}
            defaultOptions
          />
        )}
      </Row>
    </>
  );
};

export default connect(
  (store) => ({ controle: store }),
  (dispatch) => ({ controleDispatch: dispatch }),
  null,
  {
    context: ControlePersonContext,
  }
)(PersonGeral);
