import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  ComboBox,
  Divider,
  FormCheckbox,
  NumberInput,
} from "../../../../../../components";
import {
  setDeducVlrIcmsBcPisCofins,
  setPercCofins,
  setPercPis,
  setSitTribCofins,
  setSitTribPis,
} from "../../store/cadCfopSlice";

const csts_pis = [
  {
    label: "PIS 01 - Operação Tributável (BC= Valor Operação Alíq. Normal)",
    value: "01",
  },
  {
    label:
      "PIS 02 - Operação Tributável (BC= Valor Operação Alíq. Diferenciada)",
    value: "02",
  },
  {
    label:
      "PIS 03 - Operação Tributável (BC = Qtd Vendida X Alíq. por Unidade)",
    value: "03",
  },
  {
    label: "PIS 04 - Operação Tributável - Tribut. Monofásica (Alíq. Zero)",
    value: "04",
  },
  { label: "PIS 06 - Operação Tributável - Alíquota Zero", value: "06" },
  { label: "PIS 07 - Operação Isenta da Contribuição", value: "07" },
  { label: "PIS 08 - Operação sem Incidência da Contribuição", value: "08" },
  { label: "PIS 09 - Operação com Suspensão da Contribuição", value: "09" },
  { label: "PIS 49 - Outras Operações de Saída", value: "49" },
  {
    label:
      "PIS 50 - Operação com Direito a Crédito (Vinc. Exc. a Receita Trib. no Mercado Interno)",
    value: "50",
  },
  {
    label:
      "PIS 51 - Operação com Direito a Crédito (Vinc. Exc. a Rec. Não Trib. no Mercado Interno)",
    value: "51",
  },
  {
    label:
      "PIS 52 - Operação com Direito a Crédito (Vinculada Exc. a Receita de Exportação)",
    value: "52",
  },
  {
    label:
      "PIS 53 - Operação com Direito a Crédito (Vinc. a Rec. Trib. e Não Trib. no Merc. Interno)",
    value: "53",
  },
  {
    label:
      "PIS 54 - Operação com Direito a Crédito (Vinc. a Rec. Trib. no Merc. Int. e de Exp.)",
    value: "54",
  },
  {
    label:
      "PIS 55 - Operação com Direito a Crédito (Vinc. a Rec. Não Trib. no Merc. Int. e de Exp.)",
    value: "55",
  },
  {
    label:
      "PIS 56 - Operação com Direito a Crédito (Vinc. a Rec. Trib. e Não Trib. no Merc. Int. e Exp.)",
    value: "56",
  },
  {
    label:
      "PIS 60 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Trib. no Merc. Interno)",
    value: "60",
  },
  {
    label:
      "PIS 61 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Não Trib. no Merc. Interno)",
    value: "61",
  },
  {
    label:
      "PIS 62 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Trib. no Merc. Exportação)",
    value: "62",
  },
  {
    label:
      "PIS 63 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Trib. e Não Trib. no Merc. Int.)",
    value: "63",
  },
  {
    label:
      "PIS 64 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Trib. no Merc. Int. e Exp.)",
    value: "64",
  },
  {
    label:
      "PIS 65 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Não Trib. no Merc. Int. e Exp.)",
    value: "65",
  },
  {
    label:
      "PIS 66 - Crédito Presumido (Oper. de Aq. Vinc . a Rec. Trib. e Não Trib. no Merc. Int. e Ext.)",
    value: "66",
  },
  { label: "PIS 67 - Crédito Predumido (Outras Operações)", value: "67" },
  {
    label: "PIS 70 - Operação de Aquisição sem Direito a Crédito",
    value: "70",
  },
  { label: "PIS 71 - Operação de Aquisição com Isenção", value: "71" },
  { label: "PIS 72 - Operação de Aquisição com Suspensão", value: "72" },
  { label: "PIS 73 - Operação de Aquisição a Alíquota Zero", value: "73" },
  {
    label: "PIS 74 - Operação de Aquisição sem Incidência da Contribuição",
    value: "74",
  },
  {
    label: "PIS 75 - Operação de Aquisição Por Subtituição Tributária",
    value: "75",
  },
  { label: "PIS 98 - Outras Operações de Entrada", value: "98" },
  { label: "PIS 99 - Outras Operações", value: "99" },
];

const csts_cofins = [
  {
    label: "COFINS 01 - Operação Tributável (BC= Valor Operação Alíq. Normal)",
    value: "01",
  },
  {
    label:
      "COFINS 02 - Operação Tributável (BC= Valor Operação Alíq. Diferenciada)",
    value: "02",
  },
  {
    label:
      "COFINS 03 - Operação Tributável (BC = Qtd Vendida X Alíq. por Unidade)",
    value: "03",
  },
  {
    label: "COFINS 04 - Operação Tributável - Tribut. Monofásica (Alíq. Zero)",
    value: "04",
  },
  { label: "COFINS 06 - Operação Tributável - Alíquota Zero", value: "06" },
  { label: "COFINS 07 - Operação Isenta da Contribuição", value: "07" },
  { label: "COFINS 08 - Operação sem Incidência da Contribuição", value: "08" },
  { label: "COFINS 09 - Operação com Suspensão da Contribuição", value: "09" },
  { label: "COFINS 49 - Outras Operações de Saída", value: "49" },
  {
    label:
      "COFINS 50 - Operação com Direito a Crédito (Vinc. Exc. a Receita Trib. no Mercado Interno)",
    value: "50",
  },
  {
    label:
      "COFINS 51 - Operação com Direito a Crédito (Vinc. Exc. a Rec. Não Trib. no Mercado Interno)",
    value: "51",
  },
  {
    label:
      "COFINS 52 - Operação com Direito a Crédito (Vinculada Exc. a Receita de Exportação)",
    value: "52",
  },
  {
    label:
      "COFINS 53 - Operação com Direito a Crédito (Vinc. a Rec. Trib. e Não Trib. no Merc. Interno)",
    value: "53",
  },
  {
    label:
      "COFINS 54 - Operação com Direito a Crédito (Vinc. a Rec. Trib. no Merc. Int. e de Exp.)",
    value: "54",
  },
  {
    label:
      "COFINS 55 - Operação com Direito a Crédito (Vinc. a Rec. Não Trib. no Merc. Int. e de Exp.)",
    value: "55",
  },
  {
    label:
      "COFINS 56 - Operação com Direito a Crédito (Vinc. a Rec. Trib. e Não Trib. no Merc. Int. e Exp.)",
    value: "56",
  },
  {
    label:
      "COFINS 60 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Trib. no Merc. Interno)",
    value: "60",
  },
  {
    label:
      "COFINS 61 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Não Trib. no Merc. Interno)",
    value: "61",
  },
  {
    label:
      "COFINS 62 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Trib. no Merc. Exportação)",
    value: "62",
  },
  {
    label:
      "COFINS 63 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Trib. e Não Trib. no Merc. Int.)",
    value: "63",
  },
  {
    label:
      "COFINS 64 - Crédito Presumido (Operação de Aquisição Vinc. a Rec. Trib. no Merc. Int. e Exp.)",
    value: "64",
  },
  {
    label:
      "COFINS 65 - Crédito Presumido (Oper. de Aquisição Vinc. a Rec. Não Trib. no Merc. Int. e Exp.)",
    value: "65",
  },
  {
    label:
      "COFINS 66 - Crédito Presumido (Oper. de Aq. Vinc . a Rec. Trib. e Não Trib. no Merc. Int. e Ext.)",
    value: "66",
  },
  { label: "COFINS 67 - Crédito Predumido (Outras Operações)", value: "67" },
  {
    label: "COFINS 70 - Operação de Aquisição sem Direito a Crédito",
    value: "70",
  },
  { label: "COFINS 71 - Operação de Aquisição com Isenção", value: "71" },
  { label: "COFINS 72 - Operação de Aquisição com Suspensão", value: "72" },
  { label: "COFINS 73 - Operação de Aquisição a Alíquota Zero", value: "73" },
  {
    label: "COFINS 74 - Operação de Aquisição sem Incidência da Contribuição",
    value: "74",
  },
  {
    label: "COFINS 75 - Operação de Aquisição Por Subtituição Tributária",
    value: "75",
  },
  { label: "COFINS 98 - Outras Operações de Entrada", value: "98" },
  { label: "COFINS 99 - Outras Operações", value: "99" },
];

export const TabPisCofinsCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  return (
    <>
      <Divider className="mt-4">PIS/COFINS</Divider>
      <Row>
        <ComboBox
          label="Situação Tributária - PIS"
          isSearchable={false}
          md={10}
          options={csts_pis}
          defaultOptions
          onChange={(selected) => dispatch(setSitTribPis(selected?.value))}
          defaultValue={store.sitTribPis}
          divClassName="pr-0"
        />
        <NumberInput
          label="Alíq. PIS"
          md={2}
          onChange={(v) => dispatch(setPercPis(v))}
          value={store.percPis}
          isPercentage
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <ComboBox
          label="Situação Tributária - COFINS"
          isSearchable={false}
          md={10}
          options={csts_cofins}
          defaultOptions
          onChange={(selected) => dispatch(setSitTribCofins(selected?.value))}
          defaultValue={store.sitTribCofins}
          divClassName="pr-0"
        />
        <NumberInput
          label="Alíq. COFINS"
          md={2}
          onChange={(v) => dispatch(setPercCofins(v))}
          value={store.percCofins}
          isPercentage
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Deduzir valor de ICMS da base de cálculo do PIS/COFINS"
          checked={store.deducVlrIcmsBcPisCofins}
          onChange={() =>
            dispatch(setDeducVlrIcmsBcPisCofins(!store.deducVlrIcmsBcPisCofins))
          }
          name="deduc_vlr_icms_bc_pis_cofins"
        />
      </Row>
    </>
  );
};
