import React, { Children, cloneElement, useState } from "react";
import {
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { formatNumber } from "../coreUtils";

export const CardTotaisItem = ({
  label,
  value,
  roundTo = 2,
  checkFloat = false,
  hideValues,
  className,
  emphasisNegative = false,
  redGreen,
  hintText = "",
  isPercentage,
  style = {},
  format = true,
  hidden = false,
  trailing,
}) => {
  const [spanID] = useState("ctt-" + Math.floor(Math.random() * Date.now()));
  let color = "inherit";
  if (emphasisNegative && (value ?? 0) < 0) {
    color = "red";
  }

  if (redGreen) {
    if (parseFloat(value) > 0) {
      color = "green";
    } else {
      color = "red";
    }
  }

  if (hidden) {
    style["display"] = "none";
  }

  return (
    <ListGroupItem className={className} style={style}>
      <span>{label}:</span>
      <strong>
        <span
          id={spanID}
          style={{
            color: color,
            ...(hideValues ? { filter: "blur(5px)", userSelect: "none" } : {}),
          }}
        >
          {format
            ? formatNumber(
                !hideValues ? value ?? 0 : 9999.99,
                true,
                roundTo,
                checkFloat
              )
            : value}
          {isPercentage && " %"}
          {trailing && trailing}
        </span>
      </strong>
      {hintText && !hidden && (
        <UncontrolledTooltip placement="top" target={spanID}>
          {hintText}
        </UncontrolledTooltip>
      )}
    </ListGroupItem>
  );
};

export const CardTotais = ({
  children,
  md = 12,
  hideValues,
  cardClassName,
}) => {
  return (
    <Card body className={cardClassName}>
      <Row>
        <Col md={md}>
          <ListGroup horizontal>
            {Children.map(children, (e) =>
              cloneElement(e, {
                hideValues:
                  hideValues !== undefined ? hideValues : e.props.hideValues,
              })
            )}
          </ListGroup>
        </Col>
      </Row>
    </Card>
  );
};
