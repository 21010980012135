import React, { useState } from "react";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";
import { FixedField, ModalBase, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
  },
  {
    dataField: "qtd_solic",
    text: "Quantidade",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
];

export const DetalhesSolicRecargaModal = ({ isOpen, toggle, selected }) => {
  const [idRotaMob, setIdRotaMob] = useState(null);
  const [nomeRotaMob, setNomeRotaMob] = useState(null);
  const [expedidoJorn, setExpedidoJorn] = useState(null);
  const [dataHoraSolicitacao, setDataHoraSolicitacao] = useState(null);
  const [dataHoraExpedido, setDataHoraExpedido] = useState(null);
  const [itens, setItens] = useState([]);

  const limparDados = () => {
    setIdRotaMob(null);
    setNomeRotaMob(null);
    setExpedidoJorn(null);
    setDataHoraSolicitacao(null);
    setDataHoraExpedido(null);
    setItens([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await SolicitacaoRecargaService.buscar(selected);
    if (ok) {
      setIdRotaMob(ret.id_rota_mob);
      setExpedidoJorn(ret.expedido_jorn);
      setDataHoraSolicitacao(ret.data_hora_solicitacao);
      setDataHoraExpedido(ret.data_hora_expedido);
      setNomeRotaMob(ret.nome_rota_mob);
      setItens(ret.itens);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`Detalhes da Solicitação de Recarga Nº ${selected}`}
      number="0104_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Row className="mb-2" style={{ justifyContent: "space-between" }}>
        <FixedField
          label="Rota"
          value={formatValueFromAPI(nomeRotaMob, idRotaMob)}
          horizontal
        />
        <FixedField label="Solicitado" value={dataHoraSolicitacao} horizontal />
        <FixedField
          label="Expedido"
          value={expedidoJorn ? "Sim" : "Não"}
          horizontal
        />
        <FixedField label="Expedição" value={dataHoraExpedido} horizontal />
      </Row>
      <Table data={itens} columns={columns} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
