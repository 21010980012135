import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DateTime from "react-datetime";
import InputMask from "react-input-mask";
import { handleFocus } from "../coreUtils";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

export const TableDatePicker = forwardRef(
  (
    {
      value,
      onChange,
      name,
      autoFocus,
      inputId,
      minDate,
      maxDate,
      onBlur,
      className,
      endEdit,
    },
    ref
  ) => {
    const [canShowCalendar, setCanShowCalendar] = useState(false);
    const [internalValue, setInternalValue] = useState(value);
    const [valid, setValid] = useState(true);
    const [internalId] = useState(
      inputId ?? "dp-" + Math.floor(Math.random() * Date.now())
    );
    const [callOnBlur, setCallOnBlur] = useState(false);
    const innerRef = useRef(null);
    const inputRef = useRef(null);

    const __renderInput = (props, _, __) => {
      return (
        <InputMask mask="99/99/9999" maskChar={null} {...props} name={name} />
      );
    };

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(
          inputRef.current && inputRef.current.getInputDOMNode().focus(),
          35
        ),
    }));

    const onBlurInternal = (v) => {
      if (typeof v === "string") {
        let fullDate = moment(v, "DD/MM/YYYY");
        if (fullDate.isValid()) {
          if (
            fullDate.format("YYYY-MM-DD") !==
            moment(internalValue).format("YYYY-MM-DD")
          ) {
            setInternalValue(fullDate);
            setValid(true);
            if (callOnBlur) {
              if (onChange) onChange(fullDate);
              if (onBlur) onBlur(fullDate);
            }
          }
        } else if (v !== "") {
          setValid(false);
          if (callOnBlur) {
            if (onBlur) onBlur(v);
          }
        }
      } else {
        if (callOnBlur) {
          if (onBlur) onBlur(v);
        }
      }
      endEdit();
    };

    const handleOnChange = (value) => {
      setInternalValue(value);
      if (onChange) {
        setCallOnBlur(true);
        onChange(value);
      }
    };

    const validation = (currentDate) => {
      const minDateValid =
        minDate instanceof Date ? currentDate?.isAfter(moment(minDate)) : true;
      const maxDateValid =
        maxDate instanceof Date ? currentDate?.isBefore(moment(maxDate)) : true;

      return minDateValid && maxDateValid;
    };

    return (
      <DateTime
        dateFormat="DD/MM/YYYY"
        timeFormat={false}
        value={internalValue}
        onChange={handleOnChange}
        renderInput={__renderInput}
        isValidDate={
          minDate instanceof Date || maxDate instanceof Date
            ? validation
            : undefined
        }
        inputProps={{
          id: internalId,
          className: `table-input${valid ? "" : " is-invalid"} ${
            className ? className : ""
          }`,
          autoFocus: autoFocus,
          onBlur: (e) =>
            innerRef.current?.state.open === false &&
            onBlurInternal(e.target.value),
          onKeyDown: (e) => {
            handleFocus(e);
            if (["Enter", "ArrowUp"].includes(e.key)) {
              innerRef.current.closeCalendar();
            }
          },
          ref: inputRef,
          style: { textAlign: "center" },
        }}
        closeOnSelect
        onBlur={onBlurInternal}
        onFocus={() => setValid(true)}
        ref={innerRef}
      />
    );
  }
);
