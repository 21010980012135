import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { MdOutlinePayments } from "react-icons/md";
import { BsBank, BsPiggyBank } from "react-icons/bs";
import {
  cadastroBancoRoute,
  cadastroCondPagRoute,
  cadastroContaBancRoute,
  cadastroMeioPagtoRoute,
} from "../../../routes/modules/cadastro";
import { HiOutlineCreditCard } from "react-icons/hi2";

export const Financeiro = () => {
  return (
    <PageContainer title="Cadastros - Financeiro">
      <MenuGroup>
        <MenuGroupButton
          label="Condição de Pagamento"
          icon={MdOutlinePayments}
          pathname={cadastroCondPagRoute.path}
          nroTela={cadastroCondPagRoute.nroTela}
        />
        <MenuGroupButton
          label="Conta Bancária"
          icon={BsPiggyBank}
          pathname={cadastroContaBancRoute.path}
          nroTela={cadastroContaBancRoute.nroTela}
        />
        <MenuGroupButton
          label="Meio de Pagamento"
          icon={HiOutlineCreditCard}
          pathname={cadastroMeioPagtoRoute.path}
          nroTela={cadastroMeioPagtoRoute.nroTela}
        />
        <MenuGroupButton
          label="Banco"
          icon={BsBank}
          pathname={cadastroBancoRoute.path}
          nroTela={cadastroBancoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
