import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { ModalBase } from "../../ModalBase";
import { Table } from "../../Table";
import SearchInput from "../../SearchInput";
import CadastroProdutosService from "../../../services/cadastro/CadastroProdutosService";
import {
  MODAL_ACTIONS,
  debounce,
  formatarValor,
  naturalSort,
  userPodeVisHistorVendaCompra,
} from "../../../coreUtils";
import TextInput from "../../TextInput";
import { incluirAlterarProdutoRoute } from "../../../routes/modules/cadastro";
import FormButton from "../../FormButton";
import { FormCheckbox } from "../../FormCheckbox";
import { ComprasVendasProdutoModal } from "../../../pages/compras/gerenciamento/entrada_nf_xml/produtos/components/ComprasVendasProdutoModal";
import { connect } from "react-redux";
import { ContextMenu } from "../../../redux/reducers/menuReducer";

const hintNaoLiberado =
  "Seu perfil ou plano não permite acesso ao Cadastro de Produtos";

const ModalPesqProduto = ({
  isOpen,
  toggle,
  selectProd,
  pistaPadrao,
  onClose,
  sistAutomotivo,
  utilizaCorProd,
  utilizaTamanhoProd,
  utilizaPrecoAtacado,
  tipoVendaCliente,
  menus,
}) => {
  const cadastroLiberado =
    (menus ?? []).find((e) => e.nroTela === "0029") !== undefined;
  const [pista, setPista] = useState("");
  const [mostrarCusto, setMostrarCusto] = useState(false);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [somenteEmEstoque, setSomenteEmEstoque] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedNome, setSelectedNome] = useState("");
  const [selectedReferencia, setSelectedReferencia] = useState("");
  const [selectedPrecoVenda, setSelectedPrecoVenda] = useState(0);
  const [selectedUnidade, setSelectedUnidade] = useState("");
  const [selectedObserv, setSelectedObserv] = useState("");
  const [selectedRefAux1, setSelectedRefAux1] = useState("");
  const [selectedRefAux2, setSelectedRefAux2] = useState("");
  const [selectedRefAux3, setSelectedRefAux3] = useState("");
  const [selectedRefAux4, setSelectedRefAux4] = useState("");
  const [selectedLocal, setSelectedLocal] = useState("");
  const [selectedCuringa, setSelectedCuringa] = useState(false);
  const [selectedCusto, setSelectedCusto] = useState(0);
  const [selectedCfop, setSelectedCfop] = useState("");
  const [selectedClasFiscal, setSelectedClasFiscal] = useState("");
  const [selectedQuantidade, setSelectedQuantidade] = useState(0);
  const [selectedCodBar, setSelectedCodBar] = useState("");
  const [selectedQtdCasasDecimaisQtd, setSelectedQtdCasasDecimaisQtd] =
    useState(2);
  const [selectedQtdCasasDecimaisVlrs, setSelectedQtdCasasDecimaisVlrs] =
    useState(2);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [selectedCor, setSelectedCor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [similares, setSimilares] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [tableId] = useState("table-" + Math.floor(Math.random() * Date.now()));

  const [comprasVendasOpen, setComprasVendasOpen] = useState(false);

  const internalToggle = (selecionouProd) => {
    setProdutos([]);
    setSimilares([]);
    setAplicacoes([]);

    toggle(selecionouProd);
    setPista("");
    setMostrarCusto(false);
    setMostrarInativos(false);
    setSomenteEmEstoque(false);
  };

  const styleVendaCliente = (tipoVenda) =>
    utilizaPrecoAtacado &&
    tipoVendaCliente === tipoVenda && {
      fontWeight: "bold",
      color: "#002a57",
    };

  const _columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      colWidth: "90px",
      hidden: !sistAutomotivo,
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome do Produto",
      align: "left",
      colWidth: "330px",
      sortable: true,
    },
    {
      dataField: "tamanho",
      text: "Tam",
      align: "center",
      colWidth: "50px",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaTamanhoProd,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "center",
      colWidth: "80px",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaCorProd,
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      colWidth: "30px",
      colHeaderStyle: { paddingInline: 0 },
      style: { paddingInline: 0 },
      sortable: true,
    },
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      colWidth: "160px",
      sortable: true,
    },
    {
      dataField: "nome_fabricante",
      text: "Fabricante",
      align: "left",
      colWidth: utilizaCorProd && utilizaTamanhoProd ? "135px" : "160px",
      sortable: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !mostrarCusto,
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "50px",
      style: (c) => parseFloat(c ?? 0) <= 0 && { color: "red" },
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_reserva",
      text: "Reserva",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "preco_venda",
      text: !utilizaPrecoAtacado || sistAutomotivo ? "Preço" : "Valor",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      style: styleVendaCliente("V"),
    },
    {
      dataField: "preco_atacado",
      text: "Atacado",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !utilizaPrecoAtacado || sistAutomotivo,
      style: styleVendaCliente("A"),
    },
  ];

  const _columnsSimilar = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      colWidth: "90px",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome do Produto - Similar",
      align: "left",
      colWidth: "330px",
      sortable: true,
    },
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      colWidth: "160px",
      sortable: true,
    },
    {
      dataField: "nome_fabricante",
      text: "Fabricante",
      align: "left",
      colWidth: "160px",
      sortable: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !mostrarCusto,
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "50px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_reserva",
      text: "Reserva",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "preco_venda",
      text: !utilizaPrecoAtacado ? "Preço" : "Valor",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      style: styleVendaCliente("V"),
    },
    {
      dataField: "preco_atacado",
      text: "Atacado",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !utilizaPrecoAtacado,
      style: styleVendaCliente("A"),
    },
  ];

  const _columnsAplicacoes = [
    { dataField: "nome_aplic", text: "Modelo", align: "left" },
    { dataField: "ano_ini_aplic", text: "De", align: "center" },
    { dataField: "ano_fim_aplic", text: "Até", align: "center" },
  ];

  const handlePistaChange = debounce(setPista, 1500);

  const handleSelectProduto = (value, checked, row) => {
    if (checked) {
      setSelected(value);
      setSelectedNome(row.nome);
      setSelectedReferencia(row.referencia);
      setSelectedPrecoVenda(parseFloat(row.preco_venda));
      setSelectedUnidade(row.unidade);
      setSimilares(row?.similares ?? []);
      setAplicacoes(row?.aplicacoes ?? []);
      setSelectedObserv(row?.observ);
      setSelectedRefAux1(row?.refer_aux_1);
      setSelectedRefAux2(row?.refer_aux_2);
      setSelectedRefAux3(row?.refer_aux_3);
      setSelectedRefAux4(row?.refer_aux_4);
      setSelectedLocal(row?.local);
      setSelectedCuringa(row?.curinga);
      setSelectedCusto(parseFloat(row?.custo_ue));
      setSelectedCfop(row?.id_cfop);
      setSelectedClasFiscal(row?.clas_fiscal);
      setSelectedQuantidade(parseFloat(row?.quantidade));
      setSelectedCodBar(row?.cod_bar);
      setSelectedQtdCasasDecimaisQtd(row?.qtd_casas_decimais_qtd ?? 2);
      setSelectedQtdCasasDecimaisVlrs(row?.qtd_casas_decimais_vlrs ?? 2);
      setSelectedTamanho(row?.tamanho);
      setSelectedCor(row?.cor);
    } else {
      setSelected(null);
      setSelectedNome("");
      setSelectedReferencia("");
      setSelectedPrecoVenda(0);
      setSelectedUnidade("");
      setSimilares([]);
      setAplicacoes([]);
      setSelectedObserv("");
      setSelectedRefAux1("");
      setSelectedRefAux2("");
      setSelectedRefAux3("");
      setSelectedRefAux4("");
      setSelectedCuringa(false);
      setSelectedCusto(0);
      setSelectedCfop("");
      setSelectedClasFiscal("");
      setSelectedQuantidade(0);
      setSelectedCodBar("");
      setSelectedQtdCasasDecimaisQtd(2);
      setSelectedQtdCasasDecimaisVlrs(2);
      setSelectedTamanho(null);
      setSelectedCor(null);
    }
  };

  const handleSelectSimilar = (value, checked, row) => {
    if (checked) {
      setSelected(value);
      setSelectedNome(row.nome);
      setSelectedReferencia(row.referencia);
      setSelectedPrecoVenda(parseFloat(row.preco_venda));
      setSelectedUnidade(row.unidade);
      setSelectedObserv(row?.observ);
      setSelectedRefAux1(row?.refer_aux_1);
      setSelectedRefAux2(row?.refer_aux_2);
      setSelectedRefAux3(row?.refer_aux_3);
      setSelectedRefAux4(row?.refer_aux_4);
      setSelectedLocal(row?.local);
      setSelectedCuringa(row?.curinga);
      setSelectedCusto(row?.custo);
      setSelectedCfop(row?.id_cfop);
      setSelectedClasFiscal(row?.clas_fiscal);
      setSelectedQuantidade(parseFloat(row?.quantidade));
      setSelectedCodBar(row?.cod_bar);
      setSelectedQtdCasasDecimaisQtd(row?.qtd_casas_decimais_qtd ?? 2);
      setSelectedQtdCasasDecimaisVlrs(row?.qtd_casas_decimais_vlrs ?? 2);
      setSelectedTamanho(null);
      setSelectedCor(null);
    }
  };

  const carregarDados = useCallback(async () => {
    setLoading(true);

    const params = {
      pista: pista,
      mostrar_inativos: mostrarInativos,
      somente_em_estoque: somenteEmEstoque,
    };

    const getFunc = sistAutomotivo
      ? CadastroProdutosService.listarAutomotivo
      : CadastroProdutosService.listar;

    const [ok, ret] = await getFunc(params);

    setProdutos(ok ? ret : []);
    setLoading(false);
  }, [pista, sistAutomotivo, mostrarInativos, somenteEmEstoque]);

  useEffect(() => {
    if (isOpen) {
      carregarDados();
    }
  }, [carregarDados, isOpen]);

  useEffect(() => {
    setPista(pistaPadrao);
  }, [pistaPadrao]);

  const handleConfirm = () => {
    internalToggle(true);
    if (selectProd) {
      selectProd(
        selected,
        selectedNome,
        selectedReferencia,
        selectedPrecoVenda,
        selectedUnidade,
        selectedCuringa,
        selectedCusto,
        selectedCfop,
        selectedClasFiscal,
        selectedQuantidade,
        selectedCodBar,
        selectedQtdCasasDecimaisQtd,
        selectedQtdCasasDecimaisVlrs,
        selectedTamanho,
        selectedCor
      );
    }
  };

  const incluirAlterarProduto = (action) => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);
    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: action,
      selected: selected,
    });

    const func = async (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      await carregarDados();

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const toggleComprasVendas = () => setComprasVendasOpen(!comprasVendasOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={() => internalToggle(false)}
        size="xl"
        centered={false}
        title="Pesquisa de Produtos"
        number="PesqProd"
        onConfirm={handleConfirm}
        onClosed={onClose}
        confirmButtonText="Importar"
        onKeyDown={(e) => {
          if (e.key === "F9") {
            handleConfirm();
          } else if (e.key === "F2" && selected) {
            toggleComprasVendas();
          }
        }}
        returnFocusAfterClose={false}
        footerActions={
          <div style={{ display: "flex", margin: "auto" }}>
            {userPodeVisHistorVendaCompra() && (
              <FormButton
                color="primary"
                onClick={toggleComprasVendas}
                disabled={[0, null, undefined].includes(selected)}
                disabledHint="Selecione um produto"
                divClassName="mx-5"
                padded={false}
              >
                F2 - Compra / Venda
              </FormButton>
            )}
            <FormButton
              padded={false}
              onClick={() => incluirAlterarProduto(MODAL_ACTIONS.ADD)}
              md="auto"
              color="info"
              disabled={!cadastroLiberado}
              disabledHint={hintNaoLiberado}
            >
              Cadastrar Produto
            </FormButton>
            <FormButton
              padded={false}
              onClick={() => incluirAlterarProduto(MODAL_ACTIONS.EDIT)}
              md="auto"
              color="warning"
              disabled={
                !cadastroLiberado || [0, null, undefined].includes(selected)
              }
              disabledHint={
                !cadastroLiberado
                  ? hintNaoLiberado
                  : "Selecione um produto da lista"
              }
            >
              Alterar Cadastro do Produto
            </FormButton>
          </div>
        }
      >
        <Row className="mb-2">
          <SearchInput
            label="Pista"
            onChange={handlePistaChange}
            loading={loading}
            value={pistaPadrao}
          />
          <FormCheckbox
            label="Mostrar Custo"
            checked={mostrarCusto}
            onChange={() => setMostrarCusto(!mostrarCusto)}
            tabIndex={100}
          />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
          />
          <FormCheckbox
            label="Com Estoque"
            checked={somenteEmEstoque}
            onChange={() => setSomenteEmEstoque(!somenteEmEstoque)}
          />
        </Row>
        <Table
          id={tableId}
          columns={_columns}
          data={produtos}
          paginated={false}
          pageSize={12}
          onSelect={handleSelectProduto}
          clipText
          clearSelectionOnUpdate
          onRowEnterPress={handleConfirm}
          onRowDoubleClick={handleConfirm}
        />
        {sistAutomotivo && (
          <>
            <Table
              columns={_columnsSimilar}
              data={similares}
              paginated={false}
              pageSize={3}
              fixedSize
              onSelect={handleSelectSimilar}
              clipText
              clearSelectionOnUpdate
              showRegisterCount={false}
              onRowEnterPress={handleConfirm}
              onRowDoubleClick={handleConfirm}
            />
            <Col className="mt-2">
              <Row>
                <Col
                  md="auto"
                  className="pl-0 pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Referências</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux1}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux2}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux3}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selectedRefAux4}
                />
                <Col
                  md="auto"
                  className="pl-0 ml-auto pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Local</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selectedLocal}
                />
              </Row>
            </Col>
          </>
        )}
        <Row>
          <TextInput
            md={7}
            type="textarea"
            value={selectedObserv}
            rows={5}
            label="Observação"
            disabled
          />
          {!sistAutomotivo && (
            <TextInput
              label="Local"
              md={2}
              type="text"
              colClassName="pl-1 pr-0"
              disabled
              className="text-center"
              value={selectedLocal}
            />
          )}
          {sistAutomotivo && (
            <Col md={5}>
              <Label>Aplicações</Label>
              <Table
                columns={_columnsAplicacoes}
                data={aplicacoes}
                paginated={false}
                pageSize={3}
                fixedSize
                clipText
                showRegisterCount={false}
              />
            </Col>
          )}
        </Row>
      </ModalBase>
      <ComprasVendasProdutoModal
        isOpen={comprasVendasOpen}
        toggle={toggleComprasVendas}
        selected={selected}
      />
    </>
  );
};

export default connect((store) => ({ menus: store.menus }), null, null, {
  context: ContextMenu,
})(ModalPesqProduto);
