import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  Divider,
  FormButton,
  MaskedInput,
  Table,
  TableDelete,
} from "../../../../../../components";
import { RadioGroup, RadioItem } from "../../../../../../components/RadioGroup";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";

export const DocumentosMDFe = ({ documentos, addDoc, deleteDoc }) => {
  const [tipo, setTipo] = useState("NFE");
  const [chaveAcesso, setChaveAcesso] = useState("");
  const [cidade, setCidade] = useState(null);
  const [nomeCidade, setNomeCidade] = useState("");
  const cidadeRef = useRef();

  const handleAddDoc = () => {
    if (chaveAcesso.length !== 44) {
      toastr.warning(
        "Atenção",
        "A Chave de Acesso deve possuir 44 caracteres.\n" +
          chaveAcesso.length -
          44 +
          "caracter(es) faltando."
      );
      return false;
    }

    if (
      documentos.some(
        (e) =>
          e.tp_documento === tipo &&
          e.chave_acesso.toString() === chaveAcesso.toString()
      )
    ) {
      toastr.warning(
        "Atenção",
        "Já existe um documento do mesmo tipo com a Chave de Acesso informada."
      );
      return false;
    }
    const payload = {
      tp_documento: tipo,
      chave_acesso: chaveAcesso,
      id_cidade_dest: cidade,
      cidade_dest: nomeCidade,
    };

    addDoc(payload);

    setTipo("NFE");
    setChaveAcesso("");
    setCidade(null);
    setNomeCidade("");
    cidadeRef.current.clearValue();
  };

  const columns = [
    {
      dataField: "tp_documento",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "chave_acesso",
      text: "Chave de Acesso",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "cidade_dest",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cidade_dest),
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => (
        <TableDelete onClick={() => deleteDoc(row.chave_acesso)} />
      ),
    },
  ];

  const handleSelectCidade = (s) => {
    setCidade(s?.value ?? 0);
    setNomeCidade(s?.label ?? "");
  };

  return (
    <div className="mb-3">
      <Row className="mb-3">
        <RadioGroup label="Tipo" onChange={setTipo} value={tipo}>
          <RadioItem label="NFe" value="NFE" />
          <RadioItem label="CTe" value="CTE" />
        </RadioGroup>
        <MaskedInput
          mask="99999999999999999999999999999999999999999999"
          maskChar={null}
          md={5}
          label="Chave de Acesso"
          value={chaveAcesso}
          onChange={(e, v) => setChaveAcesso(v)}
        />
        <AsyncComboBox
          md={3}
          isConcatField
          concatModelName="cidade"
          isClearable
          defaultOptions
          label="Cidade"
          isSearchable
          onChange={handleSelectCidade}
          ref={cidadeRef}
        />
        <FormButton
          onClick={handleAddDoc}
          color="success"
          md="auto"
          disabled={!tipo || chaveAcesso.length !== 44 || !cidade}
          disabledHint={
            chaveAcesso.length !== 44
              ? `A Chave de Acesso deve conter 44 caracteres. Faltam ${44 -
                  chaveAcesso.length}.`
              : !cidade && "Informe a Cidade de Destino do Documento"
          }
        >
          Adicionar Documento
        </FormButton>
      </Row>
      <Table
        columns={columns}
        data={documentos}
        pageSize={5}
        fixedSize={false}
      />
    </div>
  );
};
