import moment from "moment";
import React from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../../../components/PageContainer";

import { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  MaskedInput,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { Divider } from "../../../../../components/Divider";
import { FixedField } from "../../../../../components/FixedField";
import {
  formatDateISO,
  formatValueFromAPI,
  roundFloat,
} from "../../../../../coreUtils";
import GerarNfVendaOsService from "../../../../../services/faturamento/GerarNfVendaOsService";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";
import UteisService from "../../../../../services/uteis/UteisService";
import { EntregarParcelasGrid } from "./components/EntregarParcelasGrid";
import { EntregarPedidosGrid } from "./components/EntregarPedidosGrid";
import { HintLabel } from "../../../../../components/HintLabel";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { centralPedidosRoute } from "../../../../../routes/modules/pedidos";

export const EntregarPedido = ({ location }) => {
  const history = useHistory();
  const idPedido = location.state.id;
  const [params, setParams] = useState({});
  const [infoPedido, setInfoPedido] = useState({});
  const [loading, setLoading] = useState(false);
  const [listaItens, setListaItens] = useState([]);
  const [transportadora, setTransportadora] = useState(null);
  const [qtdeVolume, setQtdeVolume] = useState(0);
  const [placa, setPlaca] = useState(null);
  const [ufVeiculo, setUfVeiculo] = useState(null);
  const [nroVolume, setNroVolume] = useState("");
  const [marca, setMarca] = useState("");
  const [especie, setEspecie] = useState("");
  const [obs, setObs] = useState("");
  const [dataEntrega, setDataEntrega] = useState(new Date());
  const [condPag, setCondPag] = useState(null);
  const [pagamento, setPagamento] = useState([]);
  const [emitirNFeAgora, setEmitirNFeAgora] = useState(false);
  const [mostrarDescontoNFe, setMostrarDescontoNFe] = useState(false);
  const [vlrEntrega, setVlrEntrega] = useState(0);
  const [loadingParcelas, setLoadingParcelas] = useState(false);

  const buscarParametros = async () => {
    setParams(await CentralPedidosService.buscarParametrosLiberacao());
  };

  const carregarDados = async () => {
    let ret = await CentralPedidosService.listarItensEntrega(idPedido);
    if (ret?.pedido) {
      ret.itens.forEach((item) => {
        item["qt_entregar"] = 0;
      });
      setListaItens(ret.itens);
      setInfoPedido(ret.pedido);
      setCondPag(ret.pedido.pagamento.id_cond_pag);
    } else {
      history.goBack();
    }
  };

  const verificaEntregaTotal = () =>
    !listaItens.some(
      (e) => parseFloat(e.qt_entregar) !== parseFloat(e.qt_saldo_dis)
    );

  const calcularParcelas = async () => {
    setLoadingParcelas(true);

    const aplicarDescSobreTotal = verificaEntregaTotal();

    const vlrDescSobreTotal = parseFloat(infoPedido.vlr_desc_sobre_total ?? 0);

    const vlrParcelar = aplicarDescSobreTotal
      ? vlrEntrega - vlrDescSobreTotal
      : vlrEntrega;

    const [ok, ret] = await UteisService.calcularParcelasCondPag(
      vlrParcelar,
      condPag
    );

    if (ok) {
      ret.forEach((item, index) => {
        item["parcela"] = index + 1;
        item.data_vcto = moment(item.data_vcto).format("DD/MM/YYYY");
        item.valor = parseFloat(item.valor);
      });
      setPagamento(ret);
    } else {
      setPagamento([]);
    }

    setLoadingParcelas(false);
  };

  const atualizarVlrEntrega = () => {
    setVlrEntrega(
      roundFloat(
        listaItens.reduce(
          (a, b) => a + parseFloat(b.qt_entregar) * parseFloat(b.vlr_final),
          0
        ),
        2
      )
    );
  };

  const entregarTudo = () => {
    listaItens.forEach((e) => (e.qt_entregar = parseFloat(e.qt_saldo_dis)));
    atualizarVlrEntrega();
  };

  const zerarEntrega = () => {
    listaItens.forEach((e) => (e.qt_entregar = 0));
    setVlrEntrega(0);
  };

  const validar = () => {
    if (!(dataEntrega instanceof Date)) {
      toastr.error(
        "Data de Entrega Inválida",
        "Por favor, revise a Data de Entrega."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (!validar()) {
        setLoading(false);
        return false;
      }

      const entregar = listaItens
        .filter((item) => item.qt_entregar > 0)
        .map((item) => ({
          id_item: item.id,
          qtd_entregar: item.qt_entregar,
        }));

      let payload = {
        id_pedido: idPedido,
        id_transportadora: transportadora,
        obs_entrega: obs,
        data_prev_entrega: dataEntrega.toISOString().split("T")[0],
        entregar: entregar,
        gerar_nfe_agora: emitirNFeAgora,
        pagamento: pagamento.map((e) => ({
          ...e,
          data_vcto: formatDateISO(moment(e.data_vcto, "DD/MM/YYYY").toDate()),
        })),
        placa: placa,
        uf_veiculo: ufVeiculo,
        qtde_volume: qtdeVolume,
        nro_volume: nroVolume,
        marca: marca,
        especie: especie,
        mostrar_desconto_nfe: mostrarDescontoNFe,
      };

      const [ok, ret] = await CentralPedidosService.entregar(payload);
      if (ok) {
        let nfes_emitidas = true;
        if (ret?.nfes) {
          ret.nfes.forEach(async (nfe) => {
            if (nfe["nfe_emitida"]) {
              await NotaFiscalService.imprimir(nfe["id_nfe"]);
            } else {
              nfes_emitidas = false;
            }
          });
        }

        if (ret?.titulos && nfes_emitidas && ret?.gerar_boletos) {
          const ids_titulos = ret.titulos.map((titulo) => titulo.id_titulo);
          await GerarNfVendaOsService.imprimirBoletos(
            ids_titulos,
            params.id_conta_banc_fat_boleto
          );
        }

        history.replace(centralPedidosRoute.path, { refresh: true });
      }
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    buscarParametros();
    carregarDados();
  }, []);

  useEffect(() => {
    if (condPag) {
      calcularParcelas();
    }
  }, [condPag, vlrEntrega]);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  return (
    <PageContainer
      title={`Entrega do Pedido Nº ${idPedido}`}
      number="0034_1"
      canGoBack
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row className="mb-3">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(
              infoPedido.nome_cliente,
              infoPedido.id_cliente
            )}
          />
          <FixedField label="Fone" value={infoPedido.fone_cliente} />
        </Row>
        <Row>
          <FixedField label="Endereço" value={infoPedido.endereco_cliente} />
          <FixedField label="Número" value={infoPedido.numero_cliente} />
          <FixedField label="Bairro" value={infoPedido.bairro_cliente} />
          <FixedField
            label="Cidade"
            value={`${infoPedido.cidade_cliente}-${infoPedido.uf_cidade_cliente}`}
          />
          <FixedField label="Emissão" value={infoPedido.data_emi} />
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-1">Entrega</Divider>
        <Row className="mb-3">
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="transportadora"
            defaultOptions
            label="Transportadora"
            isSearchable
            name="transportadora"
            onChange={(s) => setTransportadora(s?.value)}
            defaultValue={transportadora}
            autoFocus
          />
          <MaskedInput
            mask="aaa-9*99"
            label="Placa"
            md={2}
            onChange={(e, v) => setPlaca(v)}
            value={placa}
            maskChar={null}
          />
          <MaskedInput
            mask="aa"
            md={2}
            label="UF Veíc."
            onChange={(e, v) => setUfVeiculo(v)}
            value={ufVeiculo}
            maskChar={null}
          />
          <IntegerFormInput
            md={2}
            label="Qtde. Volume"
            onChange={setQtdeVolume}
            defaultValue={qtdeVolume}
          />
          <TextInput
            label="Número"
            md={2}
            value={nroVolume}
            onChange={(e, v) => setNroVolume(v)}
            maxLength={12}
          />
        </Row>
        <Row className="mb-3">
          <TextInput
            label="Espécie"
            md={2}
            value={especie}
            onChange={(e, v) => setEspecie(v)}
            maxLength={12}
          />
          <TextInput
            label="Marca"
            md={2}
            value={marca}
            onChange={(e, v) => setMarca(v)}
            maxLength={12}
          />
          <DatePicker
            label="Data de Entrega"
            md="2"
            value={dataEntrega}
            onChange={(v) =>
              setDataEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
          />
          <FormButton md="auto" color="light" onClick={entregarTudo}>
            Entregar Tudo
          </FormButton>
          <FormButton md="auto" color="light" onClick={zerarEntrega}>
            Zerar Entrega
          </FormButton>
        </Row>
        <EntregarPedidosGrid
          data={listaItens}
          atualizarVlrEntrega={atualizarVlrEntrega}
          identificaProdutoRevenda={params.identifica_produto_revenda}
        />
      </Card>
      <Card body>
        <Divider className="mt-1">Pagamento</Divider>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "25%", marginLeft: "-15px" }}>
            <div style={{ display: "flex" }}>
              <NumberInput
                md={6}
                label="Valor da Entrega"
                value={
                  verificaEntregaTotal()
                    ? vlrEntrega - (infoPedido.vlr_desc_sobre_total ?? 0)
                    : vlrEntrega
                }
                disabled
              />
              <NumberInput
                md={6}
                label={
                  <HintLabel
                    label="Desconto Sobre Total"
                    hintText="Considerado somente em caso de entrega total do pedido."
                  />
                }
                value={parseFloat(infoPedido.vlr_desc_sobre_total ?? 0)}
                disabled
              />
            </div>
            <FixedField
              label="Forma de Pagamento"
              value={infoPedido?.pagamento?.moeda}
              divClassName="my-2"
            />
            {infoPedido?.pagamento?.moeda === "Boleto" && (
              <FixedField
                label="Condição de Pagamento"
                value={infoPedido?.pagamento?.nome_cond_pag}
              />
            )}
          </div>
          {infoPedido?.pagamento?.moeda === "Boleto" && (
            <EntregarParcelasGrid data={pagamento} loading={loadingParcelas} />
          )}
        </div>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            label="Observação"
            md={7}
            value={obs}
            onChange={(e, v) => setObs(v)}
          />
          <FormCheckbox
            label="Mostrar Desconto na NF-e"
            value={mostrarDescontoNFe}
            name="mostrar_desconto_nfe"
            onChange={() => setMostrarDescontoNFe(!mostrarDescontoNFe)}
          />
          <FormCheckbox
            label="Emitir Nota Fiscal agora"
            value={emitirNFeAgora}
            name="emitir_nfe_agora"
            onChange={() => setEmitirNFeAgora(!emitirNFeAgora)}
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loading}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
