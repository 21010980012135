import React, { useEffect, useState } from "react";
import {
  FixedField,
  LabelButton,
  ModalBase,
  NumberInput,
  Table,
} from "../../../../../components";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import UteisService from "../../../../../services/uteis/UteisService";
import { toastr } from "react-redux-toastr";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import { formatNumber, roundFloat } from "../../../../../coreUtils";

export const DescontoGeralModal = ({ itens, updateItens }) => {
  const params = useSelector((state) => state.paramsFrenteVenda);
  const stateCab = useSelector((state) => state.vendaCab);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [itensAtualizados, setItensAtualizados] = useState([]);
  const [recalcularValores, setRecalcularValores] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handlePercDesc = (v) => {
    if (v !== percDesc) {
      setRecalcularValores(true);
    }
    setPercDesc(v);
  };

  const handleVlrDesc = (v) => {
    if (v !== vlrDesc) {
      setRecalcularValores(true);
    }
    setVlrDesc(v);
  };

  const recalcularValoresDesconto = async (tipoCalculo) => {
    if (recalcularValores) {
      setLoading(true);
      try {
        const payload = {
          calcular_por: tipoCalculo,
          perc_desconto: percDesc,
          vlr_desconto: vlrDesc,
          vlr_total: stateCab.vlr_tot_prod_venda + stateCab.vlr_tot_serv_venda,
          itens: itens.map((e) => ({
            id_item: `${e.tipo}-${e.id}`,
            quantidade: parseFloat(e.quantidade ?? "0") ?? 0,
            vlr_item: parseFloat(e.vlr_item ?? "0") ?? 0,
            vlr_total: parseFloat(e.vlr_total ?? "0") ?? 0,
            vlr_desc: parseFloat(e.vlr_desc ?? "0") ?? 0,
            vlr_acre: parseFloat(e.vlr_acre ?? "0") ?? 0,
          })),
        };

        const [ok, ret] = await UteisService.calcularDescontoGeral(payload);
        if (ok) {
          if (tipoCalculo === "P") {
            setVlrDesc(parseFloat(ret.vlr_desconto ?? "0") ?? 0);
          } else {
            setPercDesc(parseFloat(ret.perc_desconto ?? "0") ?? 0);
          }
          setItensAtualizados(
            ret.itens.map((e) => {
              let [tipoItem, idItem] = e.id_item.split("-");
              idItem = parseInt(idItem);
              const itemOrig =
                itens.find((e) => e.tipo === tipoItem && e.id === idItem) ?? {};

              const novoVlrTotComis = roundFloat(
                (parseFloat(e.vlr_total ?? "0") ?? 0) *
                  ((parseFloat(itemOrig.perc_comis ?? "0") ?? 0) / 100),
                2
              );

              return {
                ...itemOrig,
                ...e,
                vlr_item: parseFloat(e.vlr_item ?? "0") ?? 0,
                vlr_total: parseFloat(e.vlr_total ?? "0") ?? 0,
                vlr_desc: parseFloat(e.vlr_desc ?? "0") ?? 0,
                vlr_acre: parseFloat(e.vlr_acre ?? "0") ?? 0,
                vlr_tot_comis: novoVlrTotComis,
              };
            })
          );
        }

        setRecalcularValores(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setItensAtualizados(itens.map((e) => e));
    setPercDesc(0);
    setVlrDesc(0);
    setRecalcularValores(false);
  }, [isOpen]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (recalcularValores) {
        toastr.info(
          "Aguarde um momento!",
          "O sistema ainda está recalculando os valores"
        );
      }
      if (percDesc > 0 || vlrDesc > 0) {
        await updateItens(itensAtualizados);
      }
      toggle();
    } finally {
      setLoading(false);
    }
  };

  const columnsGrid = [
    { dataField: "item", text: "I", align: "center" },
    {
      dataField: "tipo",
      text: "T",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "id_prod_serv",
      text: "Código",
      align: "center",
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_desc",
      text: "Vlr. Desc.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
  ];

  return (
    <>
      <LabelButton onClick={toggle} divClassName="mt-2 mb-0">
        Desconto Geral
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Desconto Geral"
        number="0079_4"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        {params.tem_lista_preco === true && (
          <Row>
            <Col>
              <p className="text-info mb-2" style={{ fontWeight: "bold" }}>
                Atenção: Produtos com lista de preço não irão sofrer desconto.
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <FixedField
            labelStyle={{ color: "inherit" }}
            md={3}
            label="Valor Total dos Produtos Atual"
            value={formatNumber(
              stateCab.vlr_tot_prod_venda + stateCab.vlr_tot_serv_venda,
              true,
              2
            )}
          />
          <FixedField
            labelStyle={{ color: "inherit" }}
            md={2}
            label="Desconto Atual"
            value={formatNumber(stateCab.vlr_tot_desc_venda, true, 2)}
          />
          <NumberInput
            divClassName="ml-auto"
            label="Percentual de Desconto"
            md={3}
            isPercentage
            onChange={handlePercDesc}
            value={percDesc}
            onBlur={() => recalcularValoresDesconto("P")}
          />
          <NumberInput
            label="Valor Total de Desconto"
            md={3}
            onChange={handleVlrDesc}
            value={vlrDesc}
            onBlur={() => recalcularValoresDesconto("V")}
          />
        </Row>
        <Row>
          <FixedField
            labelStyle={{ color: "inherit" }}
            label="Valor Total dos Produtos"
            md={3}
            value={formatNumber(
              stateCab.vlr_tot_prod_venda +
                stateCab.vlr_tot_serv_venda -
                vlrDesc,
              true,
              2
            )}
          />
          <FixedField
            labelStyle={{ color: "inherit" }}
            label="Acréscimo"
            md={3}
            value={formatNumber(0, false, 2)}
          />
          <FixedField
            labelStyle={{ color: "inherit" }}
            label="Frete"
            md={3}
            value={formatNumber(stateCab.vlr_frete, true, 2)}
          />
          <NumberInput
            divClassName="ml-auto"
            md={3}
            label="Valor Total Atualizado"
            value={
              stateCab.vlr_tot_prod_venda +
              stateCab.vlr_tot_serv_venda -
              vlrDesc +
              stateCab.vlr_frete
            }
            disabled
          />
        </Row>
        <Table
          data={itensAtualizados}
          columns={columnsGrid}
          paginated={false}
          pageSize={13}
        />
      </ModalBase>
    </>
  );
};
