import React from "react";
import { Plus, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { apiGet } from "../../../../../api";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  TextInput,
} from "../../../../../components";
import { Divider } from "../../../../../components/Divider";
import { HintLabel } from "../../../../../components/HintLabel";
import MaskedInput from "../../../../../components/MaskedInput";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import {
  setCepAvalista,
  setCnpjAvalista,
  setCodCedente,
  setCodTransmissao,
  setDiasMulta,
  setEnderecoAvalista,
  setEspecieTit,
  setIdCidadeAvalista,
  setImprObservDuplicRecMov,
  setLayout,
  setMensagem1,
  setMensagem2,
  setMensagem3,
  setMensagem4,
  setNomeAvalista,
  setNomeEmprImprBoleto,
  setPadrao,
  setPercJuros,
  setPercMulta,
  setPosto,
  setTipoCarteira,
  setTipoJuros,
  setTipoMulta,
  setVariacaoCarteira,
  setVlrJuros,
  setVlrMulta,
  setDiasJuros,
  setCobrarJurosMesDia,
  setModoIntegracao,
  setIdPlanoContaBaixaCancelBoleto,
} from "../store/cadastroContaBancSlice";
import { initialFieldsState, setupFields } from "../store/controleCamposSlice";
import { DadosCobrancaCnab } from "./DadosCobrancaCnab";
import { DadosCobrancaAPI } from "./DadosCobrancaAPI";

const layoutRemesCnab = [
  { label: "Não Especificado", value: "NAO" },
  { label: "Sicredi", value: "SIC" },
  { label: "Banco do Brasil", value: "BDB" },
  { label: "Banrisul", value: "BAN" },
  { label: "Santander", value: "SAN" },
  { label: "Itaú", value: "ITA" },
  { label: "Sicoob", value: "SIB" },
  { label: "Bradesco", value: "BRA" },
  { label: "Caixa Econômica Federal", value: "CAI" },
  { label: "Cresol", value: "CRE" },
  { label: "Grafeno", value: "GRA" },
];

const layoutRemesApi = [
  { label: "Não Especificado", value: "NAO" },
  { label: "Banco Inter", value: "INT" },
  { label: "BTG Pactual", value: "BTG" },
];

const rowClassName = "mb-2";

const CampoMensagem = ({
  count,
  value,
  onChange,
  messageCount,
  setMessageCount,
  maxMessageCount,
}) => {
  return (
    (messageCount >= count || value !== "") && (
      <Row className={rowClassName}>
        <div style={{ width: "100%" }}>
          <TextInput
            md={12}
            label={`${count}a Mensagem`}
            value={value}
            onChange={(e, v) => onChange(v)}
            maxLength={100}
            autoFocus={messageCount > 1}
          />
          {messageCount < maxMessageCount &&
            messageCount === count &&
            value !== "" && (
              <span
                className="float-right mr-3"
                onClick={() => setMessageCount(messageCount + 1)}
                style={{ cursor: "pointer" }}
              >
                Adicionar nova mensagem
              </span>
            )}
        </div>
      </Row>
    )
  );
};

export const TabInfoCobranca = ({
  action,
  selected,
  messageCount,
  setMessageCount,
  maxMessageCount,
  setMaxMessageCount,
  temInfoSacaAval,
  setTemInfoSacaAval,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);

  const handleSetLayout = (v) => {
    if (v !== store.layout) {
      dispatch(setLayout(v));
      dispatch(setPosto(""));
      dispatch(setCodCedente(""));
      dispatch(setTipoCarteira(null));
      dispatch(setVariacaoCarteira(""));
      dispatch(setCodTransmissao(null));
      dispatch(setEspecieTit(""));
    }
    configurarCampos(v);
  };

  const checkMessages = (padrao) => {
    const maxMsgCount =
      padrao === "400" || store.modo_integracao === "API" ? 4 : 2;
    if (maxMsgCount < messageCount) {
      dispatch(setMensagem3(""));
      dispatch(setMensagem4(""));
      setMessageCount(maxMsgCount);
    }
    setMaxMessageCount(maxMsgCount);
  };

  const configurarCampos = async (layout) => {
    const fields = !["NAO"].includes(layout)
      ? await apiGet(`/cadastro/conta_banc/config_tela_web/${layout}/`)
      : initialFieldsState;
    if (fields.padrao_remessa) {
      dispatch(setPadrao(fields.padrao_remessa));
      checkMessages(fields.padrao_remessa);
    }
    dispatch(setupFields(fields));
  };

  const limparSacadorAvalista = () => {
    dispatch(setNomeAvalista(""));
    dispatch(setCnpjAvalista(""));
    dispatch(setEnderecoAvalista(""));
    dispatch(setIdCidadeAvalista(0));
    dispatch(setCepAvalista(""));
    setTemInfoSacaAval(false);
  };

  return (
    <>
      <Row className={rowClassName}>
        <RadioGroup
          label="Modo de Integração"
          value={store.modo_integracao}
          onChange={(v) => {
            dispatch(setModoIntegracao(v));
            handleSetLayout("NAO");
          }}
        >
          <RadioItem label="CNAB" value="CNAB" />
          <RadioItem label="API" value="API" />
        </RadioGroup>
        <ComboBox
          options={
            store.modo_integracao === "CNAB" ? layoutRemesCnab : layoutRemesApi
          }
          md={3}
          label="Layout"
          onChange={(s) => handleSetLayout(s?.value)}
          defaultValue={store.layout}
          autoFocus
          isSearchable={false}
          isClearable={false}
        />
      </Row>
      {store.modo_integracao === "CNAB" ? (
        <DadosCobrancaCnab
          action={action}
          selected={selected}
          checkMessages={checkMessages}
        />
      ) : (
        <DadosCobrancaAPI />
      )}
      <Row className={rowClassName}>
        <RadioGroup
          label="Multa - Tipo"
          value={store.tipo_multa}
          onChange={(v) => dispatch(setTipoMulta(v))}
        >
          <RadioItem label="Percentual" value="PER" />
          <RadioItem label="Valor" value="VAL" />
        </RadioGroup>
        <NumberInput
          label="Multa por Atraso"
          md={2}
          onChange={(v) =>
            dispatch(
              store.tipo_multa === "PER" ? setPercMulta(v) : setVlrMulta(v)
            )
          }
          value={
            store.tipo_multa === "PER" ? store.perc_multa : store.vlr_multa
          }
          isPercentage={store.tipo_multa === "PER"}
        />
        <IntegerFormInput
          md={3}
          label="Dias Corridos Após o Vencimento"
          defaultValue={store.dias_multa}
          onChange={(v) => dispatch(setDiasMulta(v))}
        />
      </Row>
      <Row className={rowClassName}>
        <RadioGroup
          label="Juros - Tipo"
          value={store.tipo_juros}
          onChange={(v) => dispatch(setTipoJuros(v))}
        >
          <RadioItem label="Percentual" value="P" />
          <RadioItem label="Valor" value="V" />
        </RadioGroup>
        <NumberInput
          label={
            store.tipo_juros === "P"
              ? "Percentual de Juros"
              : "Valor de Juros ao Dia"
          }
          md={3}
          onChange={(v) =>
            dispatch(
              store.tipo_juros === "P" ? setPercJuros(v) : setVlrJuros(v)
            )
          }
          value={store.tipo_juros === "P" ? store.perc_juros : store.vlr_juros}
          isPercentage={store.tipo_juros === "P"}
        />
        <RadioGroup
          label={
            <>
              Cobrar Juros
              <HintLabel
                hintText={
                  "Quando a opção AO DIA estiver marcada, o sistema fará " +
                  "a conversão do percentual de juros em valor no " +
                  "momento da geração da remessa, devido a limitações do padrão " +
                  "CNAB, que não permite percentual de juros ao dia"
                }
              />
            </>
          }
          value={store.cobrar_juros_mes_dia}
          onChange={(v) => dispatch(setCobrarJurosMesDia(v))}
          disabled={store.tipo_juros === "V"}
        >
          <RadioItem label="Ao Dia" value="DIA" />
          <RadioItem label="Ao Mês" value="MES" />
        </RadioGroup>
        <IntegerFormInput
          md={3}
          label="Dias Corridos Após o Vencimento"
          defaultValue={store.dias_juros}
          onChange={(v) => dispatch(setDiasJuros(v))}
        />
      </Row>
      <CampoMensagem
        count={1}
        value={store.mensagem_1}
        onChange={(v) => dispatch(setMensagem1(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={2}
        value={store.mensagem_2}
        onChange={(v) => dispatch(setMensagem2(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={3}
        value={store.mensagem_3}
        onChange={(v) => dispatch(setMensagem3(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={4}
        value={store.mensagem_4}
        onChange={(v) => dispatch(setMensagem4(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      {store.modo_integracao === "CNAB" && (
        <Row className={rowClassName}>
          <TextInput
            md={12}
            label="Nome da Empresa Impresso No boleto"
            value={store.nome_empr_impr_boleto}
            onChange={(e, v) => dispatch(setNomeEmprImprBoleto(v))}
            maxLength={60}
          />
        </Row>
      )}
      <Row className={rowClassName}>
        <FormCheckbox
          label="Imprimir Mensagem do Titulo a Receber no Boleto"
          checked={store.impr_observ_duplic_rec_mov}
          onChange={() =>
            dispatch(
              setImprObservDuplicRecMov(!store.impr_observ_duplic_rec_mov)
            )
          }
          name="impr_observ_duplic_rec_mov"
          padded={false}
        />
      </Row>
      <Row className={rowClassName}>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas para Baixa/Cancelamento de Boletos"
          mode="plano_contas"
          isClearable
          onChange={(v) => dispatch(setIdPlanoContaBaixaCancelBoleto(v))}
          value={store.id_plano_conta_baixa_cancel_boleto}
          defaultOptions
        />
      </Row>
      {store.modo_integracao === "CNAB" && (
        <>
          <Divider>
            Informações do Sacador / Avalista{" "}
            {temInfoSacaAval ? (
              <X
                size={18}
                style={{ cursor: "pointer" }}
                onClick={() => limparSacadorAvalista()}
                color="#800909"
              />
            ) : (
              <Plus
                size={18}
                style={{ cursor: "pointer" }}
                onClick={() => setTemInfoSacaAval(true)}
                color="green"
              />
            )}
          </Divider>
          {temInfoSacaAval && (
            <>
              <Row className={rowClassName}>
                <TextInput
                  md={8}
                  label="Nome do Sacador Avalista"
                  value={store.nome_avalista}
                  onChange={(e, v) => dispatch(setNomeAvalista(v))}
                  maxLength={60}
                />
                <MaskedInput
                  mask="99.999.999/9999-99"
                  name="cnpj_avalista"
                  label="CNPJ"
                  md={4}
                  value={store.cnpj_avalista}
                  onChange={(e, v) => dispatch(setCnpjAvalista(v))}
                />
              </Row>
              <Row className={rowClassName}>
                <TextInput
                  md={8}
                  label="Endereço"
                  value={store.endereco_avalista}
                  onChange={(e, v) => dispatch(setEnderecoAvalista(v))}
                  maxLength={45}
                />
              </Row>
              <Row className={rowClassName}>
                <AsyncComboBox
                  onChange={(s) => dispatch(setIdCidadeAvalista(s?.value ?? 0))}
                  label="Cidade"
                  md={6}
                  isConcatField
                  concatModelName="cidade"
                  defaultOptions
                  isSearchable
                  isClearable
                  defaultValue={store.id_cidade_avalista}
                />
                <MaskedInput
                  mask="99999-999"
                  name="cep_avalista"
                  label="CEP"
                  md={3}
                  onChange={(e, v) => dispatch(setCepAvalista(v))}
                  value={store.cep_avalista}
                />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
