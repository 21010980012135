import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row } from "reactstrap";
import { AsyncComboBox } from "../../../../../components";
import { BotaoImprimir } from "../../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../../components/FiltroPeriodoDatas";
import { FormCheckbox } from "../../../../../components/FormCheckbox";
import { formatDateISO } from "../../../../../coreUtils";
import {
  init,
  setAdicSubtotalGrupos,
  setDadosRelatorio,
  setDataFim,
  setDataIni,
  setDividirDados,
  setIdCidade,
  setIdCliente,
  setIdFabricante,
  setIdProduto,
  setImprimirValores,
  setLoading,
  setLoadingImprimir,
  setRota,
  setSomarBonif,
  setSomarTroca,
} from "../store/vendas_agrupadas_por_produto_slice";
import { docPrintReport } from "../../../../../pdf";
import { apiGetV2 } from "../../../../../apiV2";

export const CardFiltros = () => {
  const state = useSelector((state) => state.vendasAgrupadasPorProduto);
  const dispatch = useDispatch();

  const limparDados = () => {
    dispatch(setDadosRelatorio({ totais: {}, registros: [], grupos: {} }));
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    dispatch(setDataIni(_dataIni));
    dispatch(setDataFim(_dataFim));
    limparDados();
  };

  const buscarDados = async () => {
    const params = {
      data_ini: state.dataIni,
      data_fim: state.dataFim,
      somar_troca: state.somarTroca,
      somar_bonif: state.somarBonif,
      id_rota: state.rota,
      adic_subtotal_grupos: state.adicSubtotalGrupos,
      id_cliente: state.idCliente,
      id_produto: state.idProduto,
      id_fabricante: state.idFabricante,
      id_cidade: state.idCidade,
    };
    return apiGetV2(`relatorios/venda/vendas_agrupado_por_produtos/`, params);
  };

  const carregarDados = async () => {
    dispatch(setLoading(true));
    limparDados();
    const [ok, ret] = await buscarDados();
    dispatch(setDadosRelatorio(ok ? ret : {}));
    dispatch(setLoading(false));
  };

  const imprimir = async () => {
    dispatch(setLoadingImprimir(true));
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/venda/vendas_agrupadas_por_produto/",
        {
          dados: ret.registros,
          totais: ret.totais,
          data_ini: formatDateISO(state.dataIni),
          data_fim: formatDateISO(state.dataFim),
          dividir_dados: state.dividirDados,
          adic_subtotal_grupos: state.adicSubtotalGrupos,
          totais_grupos: ret.grupos,
          imprimir_valores: state.imprimirValores,
        },
        "5015_1"
      );
    }
    dispatch(setLoadingImprimir(false));
  };

  useEffect(() => {
    limparDados();
  }, [
    state.rota,
    state.somarTroca,
    state.somarBonif,
    state.dividirDados,
    state.adicSubtotalGrupos,
    state.idCliente,
    state.idProduto,
    state.idCidade,
    state.idFabricante,
  ]);

  useEffect(() => {
    dispatch(init());
  }, []);

  return (
    <Card body>
      <Row>
        <FiltroPeriodoDatas
          onChange={handleSelectDate}
          disabled={state.loading || state.loadingImprimir}
        />
        <AsyncComboBox
          label="Rota"
          md={3}
          isConcatField
          concatModelName="rota_mob"
          defaultOptions
          isClearable
          isSearchable
          onChange={(selected) => dispatch(setRota(selected?.value))}
          disabled={state.loading || state.loadingImprimir}
        />
      </Row>
      <Row>
        <AsyncComboBox
          isClearable
          isConcatField
          isSearchable
          concatModelName="produto"
          md={3}
          label="Produto"
          onChange={(s) => dispatch(setIdProduto(s?.value))}
          hideShortcut
        />
        <AsyncComboBox
          isClearable
          isConcatField
          isSearchable
          concatModelName="fabricante"
          md={3}
          label="Fabricante"
          onChange={(s) => dispatch(setIdFabricante(s?.value))}
          hideShortcut
        />
        <AsyncComboBox
          isClearable
          isConcatField
          isSearchable
          concatModelName="cliente"
          md={3}
          label="Cliente"
          onChange={(s) => dispatch(setIdCliente(s?.value))}
          hideShortcut
        />
        <AsyncComboBox
          isClearable
          isConcatField
          isSearchable
          concatModelName="cidade"
          md={3}
          label="Cidade"
          onChange={(s) => dispatch(setIdCidade(s?.value))}
          hideShortcut
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Somar Troca"
          checked={state.somarTroca}
          onChange={() => dispatch(setSomarTroca(!state.somarTroca))}
          disabled={state.loading || state.loadingImprimir}
          padded={false}
        />
        <FormCheckbox
          label="Somar Bonificação"
          checked={state.somarBonif}
          onChange={() => dispatch(setSomarBonif(!state.somarBonif))}
          disabled={state.loading || state.loadingImprimir}
          padded={false}
        />
        <FormCheckbox
          label="Mostrar divisão dos dados em com série e sem série"
          checked={state.dividirDados}
          onChange={() => dispatch(setDividirDados(!state.dividirDados))}
          disabled={state.loading || state.loadingImprimir}
          padded={false}
        />
        <FormCheckbox
          label="Adicionar Subtotal por Grupo de Produto"
          checked={state.adicSubtotalGrupos}
          onChange={() =>
            dispatch(setAdicSubtotalGrupos(!state.adicSubtotalGrupos))
          }
          disabled={state.loading || state.loadingImprimir}
          padded={false}
        />
      </Row>
      <Row>
        <BotaoPesquisar
          onClick={carregarDados}
          loading={state.loading}
          disabled={
            !(state.dataIni instanceof Date) || !(state.dataFim instanceof Date)
          }
          disabledHint="Informe datas válidas de início e fim"
        />
        <BotaoImprimir
          onClick={imprimir}
          loading={state.loadingImprimir}
          disabled={
            !(state.dataIni instanceof Date) || !(state.dataFim instanceof Date)
          }
          disabledHint="Informe datas válidas de início e fim"
        />
        <FormCheckbox
          label="Imprimir Valores"
          checked={state.imprimirValores}
          onChange={() => dispatch(setImprimirValores(!state.imprimirValores))}
          disabled={state.loading || state.loadingImprimir}
        />
      </Row>
    </Card>
  );
};
