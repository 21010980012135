import React, { useState } from "react";
import { ModalBase, Table } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { Col, Row } from "reactstrap";
import CaixaLojaService from "../../../../services/financeiro/CaixaLojaService";

const columns = [
  {
    dataField: "desc_moeda",
    text: "Moeda",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "desc_tipo",
    text: "Tipo",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "qtd_parcelas",
    text: "Parcelas",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const VerifProcPagTefNovamenteModal = ({
  isOpen,
  toggle,
  idMovCaixa,
}) => {
  const [dados, setDados] = useState([]);

  const carregarDados = async () => {
    const [ok, ret] = await CaixaLojaService.verifTemRecTefPendente(idMovCaixa);
    if (ok) {
      setDados(ret.recs_pendentes);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Pagamentos TEF Pendentes"
      number="0063_19"
      onBeforeOpen={carregarDados}
      onClosed={() => setDados([])}
      onConfirm={() => toggle(true)}
      footerActions
      autoFocus
    >
      <Row className="mb-2">
        <Col>
          Existem pagamentos TEF pendentes. Você deseja tentar processá-los
          novamente?
        </Col>
      </Row>
      <Table
        data={dados ?? []}
        columns={columns}
        paginated={false}
        showRegisterCount={false}
        pageSize={5}
        growIntoPageSize
      />
    </ModalBase>
  );
};
