import { apiPost } from "../../api";
import { apiPostV2 } from "../../apiV2";

const URL_BASE = "/uteis";

const UteisService = {
  calcularDescontoGeral: async (payload) =>
    await apiPostV2(`${URL_BASE}/calcular_desconto_geral/`, payload, {
      successMessage: false,
    }),
  calcularParcelasCondPag: async (valor, condPag) =>
    await apiPostV2(
      `${URL_BASE}/calcular_parcelas_por_cond_pag/`,
      {
        id_cond_pag: condPag,
        valor: valor,
      },
      { successMessage: false }
    ),
  calcularParcelasPorQtdParcNumDias: async (payload) =>
    await apiPostV2(
      `${URL_BASE}/calcular_parcelas_por_num_dias_qtd_parc/`,
      payload, // campo data_base opcional
      { successMessage: false }
    ),
  calcularImpostosProduto: async (
    idProduto,
    idCliente,
    finalidade,
    modelo,
    dentroEstab,
    cfop,
    quantidade,
    vlrTotProd,
    vlrFrete,
    vlrOutros
  ) =>
    await apiPost(
      `${URL_BASE}/calcular_impostos_produto/`,
      {
        id_produto: idProduto,
        id_cliente: idCliente,
        finalidade: finalidade,
        modelo: modelo,
        dentro_estab: dentroEstab,
        cfop: cfop,
        quantidade: quantidade,
        vlr_tot_prod: vlrTotProd,
        vlr_frete: vlrFrete,
        vlr_outros: vlrOutros,
      },
      {}
    ),
  calcularImpostosVariosProdutos: async (payload) =>
    await apiPost(
      `${URL_BASE}/calcular_impostos_varios_produtos/`,
      payload,
      []
    ),
};

export default UteisService;
