import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id_item_regra_de",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "desc_item_regra_de",
    text: "Nome",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "prod_vai_pro_app",
    text: "APP",
    align: "center",
    formatter: (c) => <TableCheck value={c} />,
    sortable: true,
  },
  {
    dataField: "custo_prod",
    text: "Custo",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade_prod",
    text: "Quantidade",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "preco_venda_prod",
    text: "Preço Venda",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
  },
  {
    dataField: "tipo_desconto",
    text: "Tipo Desconto",
    align: "center",
    headerAlign: "center",
    formatter: (c) => (c === "PER" ? "Percentual" : "Valor"),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "perc_desconto",
    text: "% Desconto",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "preco_venda",
    text: "Preço Venda",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ItensRegraDescontoGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      showRegisterCount={false}
      pageSize={10}
    />
  );
};
