import React, { useState } from "react";
import { Row, Label } from "reactstrap";
import {
  FormButton,
  FormCheckbox,
  LabelButton,
} from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import CadastroProdutosService from "../../../../../services/cadastro/CadastroProdutosService";
import { exportDataToSheet } from "../../../../../coreUtils";

const numberFormatter = (c) => (!isNaN(c) ? parseFloat(c) : c);

export const ExportarProdutosModal = ({ usaRegraTributaria }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [somenteProdSped, setSomenteProdSped] = useState(false);
  const [loading, setLoading] = useState(false);

  const availableFields = [
    { label: "Código", value: "id" },
    { label: "Nome", value: "nome" },
    { label: "Referência", value: "referencia" },
    { label: "Código de Barras", value: "cod_bar" },
    {
      label: "Grupo",
      value: "id_grupo",
      nameLabel: "Nome Grupo",
      nameValue: "id_grupo__nome",
    },
    {
      label: "Sub-grupo",
      value: "id_sub_grupo",
      nameLabel: "Nome Sub-grupo",
      nameValue: "id_sub_grupo__nome",
    },
    {
      label: "Fabricante",
      value: "id_fabricante",
      nameLabel: "Nome Fabricante",
      nameValue: "id_fabricante__nome",
    },
    {
      label: "Quantidade",
      value: "quantidade",
      formatter: (c) => numberFormatter(c),
    },
    { label: "Local", value: "local" },
    { label: "NCM", value: "clas_fiscal" },
    ...(!usaRegraTributaria ? [{ label: "CFOP", value: "id_cfop" }] : []),
    { label: "Unidade", value: "unidade" },
    { label: "Custo", value: "custo_ue", formatter: (c) => numberFormatter(c) },
    {
      label: "Preço de Venda",
      value: "preco_venda",
      formatter: (c) => numberFormatter(c),
    },
    {
      label: "Margem de Lucro",
      value: "perc_lucro",
      formatter: (c) => numberFormatter(c),
    },
    {
      label: "Preço de Venda Atacado",
      value: "preco_atacado",
      formatter: (c) => numberFormatter(c),
    },
    {
      label: "Margem de Lucro Atacado",
      value: "perc_lucro_atacado",
      formatter: (c) => numberFormatter(c),
    },
    { label: "Observação", value: "observ" },
    { label: "Ativo", value: "ativo" },
    {
      label: "Desc. Máx. Vendedor",
      value: "perc_desc_max_vend",
      formatter: (c) => numberFormatter(c),
    },
    {
      label: "Desc. Fixo",
      value: "perc_desc_fixo",
      formatter: (c) => numberFormatter(c),
    },
  ];

  const limparDados = () => {
    setSelectedFields([]);
    setSomenteAtivos(true);
  };

  const toggle = () => setIsOpen(!isOpen);

  const exportar = async () => {
    setLoading(true);
    const [ok, ret] = await CadastroProdutosService.exportarProd(
      somenteAtivos,
      somenteProdSped
    );

    if (ok) {
      const selectedColumns = availableFields
        .filter((e) => selectedFields.includes(e.value))
        .reduce((acc, field) => {
          let res = [...acc, field];
          if (field.nameValue) {
            res = [...res, { label: field.nameLabel, value: field.nameValue }];
          }
          return res;
        }, []);

      const data = ret.map((row) =>
        selectedColumns.reduce(
          (acc, field) => ({
            ...acc,
            [field.label]: field.formatter
              ? field.formatter(row[field.value])
              : row[field.value],
          }),
          {}
        )
      );

      exportDataToSheet(data, "Produtos", { sheetTitle: "Produtos" });
    }
    setLoading(false);
  };

  const handleCheckField = (field) => {
    setSelectedFields(
      !selectedFields.includes(field)
        ? [...selectedFields, field]
        : selectedFields.filter((e) => e !== field)
    );
  };

  return (
    <>
      <LabelButton md="auto" onClick={toggle} divClassName={"ml-auto"}>
        Exportar
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={limparDados}
        title="Exportação de Cadastro de Produtos"
        number="0029_3"
        autoFocus
        footerActions={
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            padded={false}
            disabled={selectedFields.length < 2}
            disabledHint="Selecione ao menos dois campos."
            loading={loading}
            id="bt-exportar"
          >
            Exportar
          </FormButton>
        }
        hideCancelButton
      >
        <Row>
          <FormCheckbox
            name="somente_ativos"
            label="Somente Produtos Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
            padded={false}
            className="pt-0"
          />
          <FormCheckbox
            name="somente_sped"
            label="Somente Produtos SPED"
            checked={somenteProdSped}
            onChange={() => setSomenteProdSped(!somenteProdSped)}
            padded={false}
            className="pt-0"
          />
        </Row>
        <Label style={{ marginLeft: "1.1rem", marginTop: "0.5rem" }}>
          Selecione os campos que você deseja exportar
        </Label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            height: "198px",
          }}
        >
          {availableFields.map((e, index) => (
            <FormCheckbox
              name={`field-${index}`}
              label={e.label}
              checked={selectedFields.includes(e.value)}
              onChange={() => handleCheckField(e.value)}
              padded={false}
            />
          ))}
        </div>
      </ModalBase>
    </>
  );
};
