import React, { useState } from "react";
import { Row } from "reactstrap";
import { AsyncComboBox } from "../../../../../components";
import { BotaoImprimir } from "../../../../../components/BotaoImprimir";
import { ModalBase } from "../../../../../components/ModalBase";
import { BoletoService } from "../../../../../services/bancario/BoletoService";

export const ImprimirBoletosModal = ({
  selected,
  disabledButtons,
  notifyAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [conta, setConta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => {
    // Limpa conta bancária apenas na abertura do modal
    if (!isOpen) setConta(null);
    setIsOpen(!isOpen);
  };

  const onClickImprimirConta = () => {
    gerarBoletos(selected, conta);
    toggle();
  };

  const gerarBoletos = async (duplics, conta_banc_id) => {
    setIsLoading(true);
    for (var i = 0; i < duplics.length; i++) {
      await BoletoService.gerarDadosImpressao({
        id_conta_banc: ![0, null, undefined].includes(duplics[i].id_conta)
          ? duplics[i].id_conta
          : conta_banc_id,
        id_duplicata: duplics[i].id,
      });
    }
    notifyAction("print");
    setIsLoading(false);
  };

  const onClickImprimir = () => {
    if (selected.some((item) => [0, null, undefined].includes(item.id_conta))) {
      toggle();
    } else {
      gerarBoletos(selected);
    }
  };

  return (
    <>
      <BotaoImprimir
        onClick={onClickImprimir}
        disabled={disabledButtons}
        loading={isLoading}
        padded={false}
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Impressão de boletos"
        number="0001_1"
        footerActions={
          <BotaoImprimir
            padded={false}
            divClassName="pr-0"
            onClick={onClickImprimirConta}
            disabled={!conta}
            disabledHint="Selecione uma conta bancária"
          />
        }
        hideCancelButton
      >
        <Row form>
          <AsyncComboBox
            label="Conta Bancária"
            isConcatField
            concatModelName="conta_banc"
            isSearchable
            isClearable
            onChange={(s) => setConta(s?.value)}
            required
            defaultOptions
            md={12}
            autoFocus
          />
        </Row>
      </ModalBase>
    </>
  );
};
