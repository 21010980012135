import React from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { RegraDescontoGrid } from "./components/RegraDescontoGrid";
import { useState } from "react";
import { ItensRegraDescontoGrid } from "./components/ItensRegraDescontoGrid";
import { CadastroRegraDescontoModal } from "./components/CadastroRegraDescontoModal";
import { RadioItem } from "../../../../components/RadioGroup";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import CadastroRegraDescontoService from "../../../../services/cadastro/CadastroRegraDescontoService";
import { useEffect } from "react";
import { docPrintReport } from "../../../../pdf";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleRegraDesconto = "Regra de Promoção";
export const routesBaseRegraDesconto = CadastroRegraDescontoService.urlBase;

export const RegraPromocao = () => {
  const [tipoRegraDe, setTipoRegraDe] = useState("PRO");
  const [idRegraDe, setIdRegraDe] = useState(null);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [mostrarApenasValidas, setMostrarApenasValidas] = useState(true);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [produtosSelected, setProdutosSelected] = useState([]);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const carregarDados = async () => {
    const params = {
      tipo_regra_de: tipoRegraDe,
      id_regra_de: idRegraDe,
      mostrar_inativos: mostrarInativos,
      mostrar_apenas_validas: mostrarApenasValidas,
    };
    setLoading(true);
    const [ok, ret] = await CadastroRegraDescontoService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const notifyEvent = (action) => {
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
    carregarDados();
  };

  const buscarItensRegra = async () => {
    if (![0, null, undefined].includes(selected)) {
      const [ok, ret] = await CadastroRegraDescontoService.buscar(selected);
      setProdutosSelected(ok ? ret.itens : []);
    } else {
      setProdutosSelected([]);
    }
  };

  const imprimirRegra = async (id) => {
    const [ok, ret] = await CadastroRegraDescontoService.buscar(id);
    if (ok) {
      await docPrintReport(
        "/cadastro/regra_promocao/",
        { id_regra: id, dados: ret },
        "0078"
      );
    }
  };

  useEffect(() => {
    buscarItensRegra();
  }, [selected]);

  return (
    <PageContainer
      title="Cadastro de Regras de Promoção"
      number="0078"
      canGoBack
    >
      <Card body>
        <Row>
          <RadioGroup
            label="Aplicar Em"
            value={tipoRegraDe}
            onChange={setTipoRegraDe}
          >
            <RadioItem label="Produto" value="PRO" />
          </RadioGroup>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            onChange={(s) => setIdRegraDe(s?.value)}
          />
          <FormCheckbox
            label="Mostrar Inativas"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
          />
          <FormCheckbox
            label="Somente Regras Válidas"
            checked={mostrarApenasValidas}
            onChange={() => setMostrarApenasValidas(!mostrarApenasValidas)}
          />
          <BotaoIncluir toggle={toggleCadastro} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroRegraDescontoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleRegraDesconto}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0078_2"
            selected={selected}
            routeBase={routesBaseRegraDesconto}
            notifyEvent={notifyEvent}
            message="Tem certeza de que deseja excluir a Regra de Promoção selecionada?"
          />
        </Row>
      </Card>
      <Card body>
        <RegraDescontoGrid
          dados={dados}
          setSelected={setSelected}
          excluir={excluir}
          imprimirRegra={imprimirRegra}
        />
      </Card>
      <Card body>
        <ItensRegraDescontoGrid dados={produtosSelected} />
      </Card>
    </PageContainer>
  );
};
