import "moment/locale/pt-br";
import React from "react";
import "react-day-picker/lib/style.css";
import { Provider } from "react-redux";
import { CardFiltros } from "./components/CardFiltros";
import { CardOutrosValores } from "./components/CardOutrosValores";
import { CardResultado } from "./components/CardResultado";
import { CardTabelaDados } from "./components/CardTabelaDados";
import { GraficoDRE } from "./components/GraficoDRE";
import store from "./store";
import { PageContainer } from "../../../../components";
import { Card } from "reactstrap";

export const DemonstrativoResultadosPage = () => {
  return (
    <PageContainer title="DRE" number="5007" canGoBack>
      <Provider store={store}>
        <CardFiltros />
        <GraficoDRE />
        <CardTabelaDados />
        <CardResultado />
        <CardOutrosValores />
      </Provider>
    </PageContainer>
  );
};
