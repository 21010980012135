import React from "react";
import { Card } from "reactstrap";
import { DataTable } from "../../../../../../components/DataTable";
import { TableCheck } from "../../../../../../components/TableCheck";
import { TableDelete } from "../../../../../../components/TableDelete";
import { FINALIDADES_REGRA_TRIB } from "../../../regra_tributacao/components/modal/CamposChaves";

export const RegrasGrupoGrid = ({ data, handleDelete }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dentro_estabelecimento",
      text: "Dentro do Estabelecimento",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sort: true,
    },
    {
      dataField: "finalidade_oper",
      text: "Finalidade",
      align: "center",
      headerAlign: "center",
      formatter: (cell) =>
        FINALIDADES_REGRA_TRIB.find((e) => e.value === cell)?.label ?? "",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sort: true,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <Card body>
      <strong className="mt-2 mb-1">
        REGRAS TRIBUTÁRIAS ASSOCIADAS AO GRUPO
      </strong>
      <DataTable data={data} columns={columns} readOnly />
    </Card>
  );
};
