import { toastr } from "react-redux-toastr";
import { api, apiGet, apiPut, urlParams } from "../../api";
import { BoletoService } from "../bancario/BoletoService";
import { apiPostV2 } from "../../apiV2";
import { showError } from "../../components/AlertaModal";

const URL_BASE = "/faturamento";

const processaRetorno = (ret) => {
  if (ret.data.success) {
    return [true, ret.data];
  } else {
    showError(ret.data.msg, ret.data.id_err);
    return [false, ret.data];
  }
};

const processaErro = (err) => {
  showError(err.message, `SERVER_ERROR_${err.response.status}`);
  return [false, { msg: err.message, id_err: err.response.status }];
};

const apiPostFaturar = async (url, payload) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret))
    .catch((err) => processaErro(err));

const GerarNfVendaOsService = {
  listar: async (mostrarFaturadas, dataIni, dataFim, sequencia, cliente) => {
    const params = {
      mostrar_faturadas: mostrarFaturadas === true ? "true" : "false",
      data_ini: dataIni,
      data_fim: dataFim,
      sequencia: sequencia,
      id_cliente: cliente,
    };

    return await apiGet(`${URL_BASE}/listar/${urlParams(params)}`);
  },
  listarCondicoesPag: async (idCliente) =>
    await apiGet(`/cadastro/cliente/listar_condicoes_pagamento/${idCliente}/`),
  faturar: async (payload) => {
    const ret = await api
      .post(`${URL_BASE}/faturar_venda_os/`, payload)
      .catch((_err) => {
        toastr.error("Erro", _err.message);
      });
    if (ret?.data) {
      if (!ret.data.success) {
        toastr.error("Erro", ret.data.msg);
      } else {
        toastr.success("Sucesso", ret.data.msg);
        return ret?.data;
      }
    }
    return false;
  },
  defineNaoGeraNF: async (payload) =>
    apiPut(`${URL_BASE}/define_nao_gera_nf/`, payload),
  reabrirGerarNF: async (payload) =>
    apiPut(`${URL_BASE}/reabrir_gera_nf/`, payload),
  buscarParam: async () => await apiGet(`${URL_BASE}/parametros/buscar/`),
  alterarParam: async (payload) =>
    await apiPut(`${URL_BASE}/parametros/alterar/`, payload),
  imprimirBoletos: async (duplics, conta_banc_id) => {
    for (var i = 0; i < duplics.length; i++) {
      const item = duplics[i];
      const ret = await api.post(
        "/bancario/cobranca/boleto/gerar_dados_impressao/",
        {
          id_conta_banc: conta_banc_id,
          id_duplicata: item,
        }
      );

      if (ret.data.success) {
        try {
          await BoletoService.imprimirBoleto(item);
        } catch (error) {
          toastr.error("Erro ao imprimir boletos", error.message);
        }
      } else {
        toastr.error("Erro ao imprimir boletos", ret.data.msg);
      }
    }
  },
  gerarDadosFaturarVendaOs: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_dados_faturar_venda_os/`, payload, {
      successMessage: false,
    }),
  gerarDadosFaturarNfServico: async (payload) =>
    await apiPostV2(`${URL_BASE}/nf_servico/gerar_dados_faturar/`, payload, {
      successMessage: false,
    }),
  faturarVendaOs: async (payload) =>
    await apiPostFaturar(`${URL_BASE}/faturar_venda_os/`, payload),
  faturarNFSe: async (payload) =>
    await apiPostV2(`${URL_BASE}/nf_servico/faturar/`, payload),
};

export default GerarNfVendaOsService;
