import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/cadastro/regra_desconto";
const URL_BASE_ITENS = `${URL_BASE}/itens`;

const CadastroRegraDescontoService = {
  urlBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarProdsSelecao: async (idRegra, params) =>
    await apiGetV2(`${URL_BASE}/listar_prods_selecao/${idRegra}/`, params),
  buscar: async (idRegra) => await apiGetV2(`${URL_BASE}/buscar/${idRegra}/`),
  itens: {
    incluir: async (payload) =>
      await apiPostV2(`${URL_BASE_ITENS}/incluir/`, payload),
    excluir: async (idItem) =>
      await apiDeleteV2(`${URL_BASE_ITENS}/excluir/${idItem}/`),
  },
};

export default CadastroRegraDescontoService;
