import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  RadioGroup,
} from "../../../../components";
import {
  setIdContaBancRecebPix,
  setIdMeioPagtoPadTef,
  setIntegradora,
  setPixTefHabilitado,
  setFormaParcelamento,
  FORMAS_PARCELAMENTO_TEF,
} from "../store/tef_slice";
import { RadioItem } from "../../../../components/RadioGroup";
import { PersonTefPayerModal } from "./tef/PersonTefPayerModal";

export const PersonTef = () => {
  const store = useSelector((state) => state.tef);
  const dispatch = useDispatch();
  const idContaBancRecebPixRef = useRef();

  useEffect(() => {
    if (!store.pix_tef_habilitado) {
      dispatch(setIdContaBancRecebPix(null));
      if (idContaBancRecebPixRef.current) {
        idContaBancRecebPixRef.current.clearValue();
      }
    }
  }, [store.pix_tef_habilitado]);

  return (
    <>
      <Row>
        <AsyncComboBox
          md={5}
          label="Meio de Pagamento Padrão"
          isSearchable
          isConcatField
          defaultOptions
          concatModelName="meio_pagamento"
          defaultValue={store.id_meio_pagto_pad_tef}
          onChange={(s) => dispatch(setIdMeioPagtoPadTef(s?.value ?? null))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Integradora"
          value={store.integradora}
          onChange={(s) => dispatch(setIntegradora(s))}
        >
          <RadioItem label="Não Identificado" value="NAO" />
          <RadioItem label="Payer" value="PAYER" />
        </RadioGroup>
        {store.integradora === "PAYER" && <PersonTefPayerModal />}
      </Row>
      <Row>
        <FormCheckbox
          label="PIX habilitado via TEF"
          checked={store.pix_tef_habilitado}
          onChange={(s) =>
            dispatch(setPixTefHabilitado(!store.pix_tef_habilitado))
          }
        />
        <AsyncComboBox
          md={5}
          label="Conta Bancária para Recebimentos em PIX"
          isSearchable
          isConcatField
          defaultOptions
          concatModelName="conta_banc"
          defaultValue={store.id_conta_banc_receb_pix}
          onChange={(s) => dispatch(setIdContaBancRecebPix(s?.value ?? null))}
          disabled={!store.pix_tef_habilitado}
          ref={idContaBancRecebPixRef}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Forma de Parcelamento"
          value={store.forma_parcelamento}
          onChange={(s) => dispatch(setFormaParcelamento(s))}
        >
          <RadioItem
            label="Parcelado Lojista"
            value={FORMAS_PARCELAMENTO_TEF.PARCELADO_LOJISTA}
          />
          <RadioItem
            label="Parcelado Administradora"
            value={FORMAS_PARCELAMENTO_TEF.PARCELADO_ADMINISTRADORA}
          />
        </RadioGroup>
      </Row>
    </>
  );
};
