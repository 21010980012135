import React, { useState } from "react";
import { BiArrowFromLeft } from "react-icons/bi";
import { Col, Spinner, UncontrolledTooltip } from "reactstrap";

export const LabelButton = ({
  md = "auto",
  onClick,
  children,
  showIcon = true,
  style,
  divClassName,
  disabled = false,
  loading,
  hint,
  disabledHint,
  id,
}) => {
  const [internalId] = useState(
    id ?? "lb-" + Math.floor(Math.random() * Date.now())
  );
  const clickable = !disabled && !loading;
  return (
    <>
      <Col
        md={md}
        className={divClassName}
        style={{ display: "flex", alignItems: "center" }}
      >
        <label
          style={{
            fontSize: "0.75rem",
            fontWeight: 600,
            cursor: clickable ? "pointer" : "not-allowed",
            color: clickable ? "inherit" : "#bbb",
            ...style,
          }}
          onClick={clickable ? onClick : null}
          disabled={disabled}
          id={internalId}
        >
          {showIcon &&
            (loading ? (
              <Spinner
                style={{
                  width: 12,
                  height: 12,
                  marginBottom: "2px",
                  marginRight: "2px",
                }}
                size="sm"
              />
            ) : (
              <BiArrowFromLeft
                size={16}
                color={!disabled ? "#932b8c" : "#bbb"}
                style={{ marginBottom: "2px" }}
              />
            ))}
          {children}
        </label>
      </Col>
      {!disabled && hint && (
        <UncontrolledTooltip target={internalId}>{hint}</UncontrolledTooltip>
      )}
      {disabled && disabledHint && (
        <UncontrolledTooltip target={internalId}>
          {disabledHint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
