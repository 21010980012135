import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/industria/programacao_producao";

const ProgramacaoProducaoService = {
  urlBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarInsumos: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_insumos/`, params),
};

export default ProgramacaoProducaoService;
