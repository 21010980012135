import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  FormCheckbox,
  IntegerFormInput,
  TextInput,
  FormButton,
  Table,
  Divider,
  AsyncComboBox,
  DatePicker,
  FixedField,
  RadioGroup,
  UnlockToEdit,
} from "../../../../../../components";
import UteisService from "../../../../../../services/uteis/UteisService";
import {
  formatDateISO,
  formatDateLocal,
  formatarValor,
  sumDataField,
} from "../../../../../../coreUtils";
import { useDispatch, useSelector } from "react-redux";
import moment, { isMoment } from "moment";
import { setFaturas } from "../store/cabNFSlice";
import { RadioItem } from "../../../../../../components/RadioGroup";
import { FaCalculator } from "react-icons/fa";
import FormasPagNfForm from "./FormasPagNfForm";

const tipoPagtoOptions = [
  { label: "A Prazo", value: "P" },
  { label: "A Vista", value: "V" },
];

export const FaturasNfForm = ({ identFaturaPor }) => {
  const stateCab = useSelector((state) => state.cabNF);
  const valorNFe = stateCab.totaisNfe.vlrTotFatura;
  const dispatch = useDispatch();
  const [tipoPagto, setTipoPagto] = useState(tipoPagtoOptions[0].value);
  const [nroFatura, setNroFatura] = useState("");
  const [dataBase, setDataBase] = useState(new Date());
  const [qtdParcelas, setQtdParcelas] = useState(null);
  const [numeroDias, setNumeroDias] = useState(30);
  const [diaFixo, setDiaFixo] = useState(false);
  const [idCondPag, setIdCondPag] = useState(null);
  const [loadingParcelas, setLoadingParcelas] = useState(false);

  const semQtdParcelas = [0, null, undefined].includes(qtdParcelas);
  const semNumeroDias = [0, null, undefined].includes(numeroDias);
  const semDataBase = !(dataBase instanceof Date);

  const calcularFaturaAVista = (alterarTudo = false) => {
    if (valorNFe > 0) {
      const faturasAntes = [...stateCab.faturas];

      const faturasDepois = [
        {
          numero: nroFatura,
          parcela: 1,
          data_vcto: formatDateLocal(new Date()),
          valor: valorNFe,
          ...(!alterarTudo ? faturasAntes[0] ?? {} : {}),
          entrada: false,
        },
      ];
      dispatch(setFaturas(faturasDepois));
    } else {
      dispatch(setFaturas([]));
    }
    setQtdParcelas(null);
    setNumeroDias(null);
    setDiaFixo(false);
    setIdCondPag(null);
  };

  const calcularFaturasQtdParcelas = async (alterarTudo = false) => {
    if (valorNFe === 0) {
      dispatch(setFaturas([]));
      return;
    }
    setLoadingParcelas(true);

    const payload = {
      qtd_parcelas: qtdParcelas,
      num_dias: numeroDias,
      dia_fixo: diaFixo,
      valor: valorNFe,
      data_base: formatDateISO(dataBase),
    };

    const [ok, ret] = await UteisService.calcularParcelasPorQtdParcNumDias(
      payload
    );
    if (ok) {
      const faturasAntes = [...stateCab.faturas];
      const faturasDepois = ret.map((e, index) => ({
        numero: nroFatura,
        parcela: index + 1,
        data_vcto: formatDateLocal(e.data_vcto),
        entrada: e.entrada,
        ...(!alterarTudo ? faturasAntes[index] ?? {} : {}),
        valor: parseFloat(e.valor),
      }));

      dispatch(setFaturas(faturasDepois));
    }
    setLoadingParcelas(false);
  };

  const calcularFaturasCondPag = async (alterarTudo = false, idCondPagto) => {
    if (valorNFe === 0) {
      dispatch(setFaturas([]));
      return;
    }
    setLoadingParcelas(true);
    const [ok, ret] = await UteisService.calcularParcelasCondPag(
      valorNFe,
      idCondPagto
    );

    if (ok) {
      const faturasAntes = [...stateCab.faturas];
      const faturasDepois = ret.map((e, index) => ({
        numero: nroFatura,
        parcela: index + 1,
        data_vcto: formatDateLocal(e.data_vcto),
        entrada: e.entrada,
        ...(!alterarTudo ? faturasAntes[index] ?? {} : {}),
        valor: parseFloat(e.valor),
      }));

      dispatch(setFaturas(faturasDepois));
    }
    setLoadingParcelas(false);
  };

  const handleSetTipoPagto = (v) => {
    setTipoPagto(v);
    if (v === "V" && v !== tipoPagto) {
      calcularFaturaAVista(true);
    }
  };

  const handleSetNroFatura = (v) => {
    setNroFatura(v);
    dispatch(setFaturas(stateCab.faturas.map((e) => ({ ...e, numero: v }))));
  };

  const handleSetIdCondPag = (v) => {
    if (v !== idCondPag) {
      setIdCondPag(v);
      calcularFaturasCondPag(true, v);
    }
  };

  const alterarFatura = (coluna, novoValor, row) => {
    if (coluna === "data_vcto") {
      if (isMoment(novoValor)) {
        row.data_vcto = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }
    if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    }

    dispatch(
      setFaturas(
        stateCab.faturas.map((e) => (e.parcela === row.parcela ? row : e))
      )
    );
  };

  const recalcularFaturasSomenteValor = () => {
    if (identFaturaPor === "CON") {
      if (![0, null, undefined].includes(idCondPag)) {
        calcularFaturasCondPag(false, idCondPag);
      }
    } else {
      if (tipoPagto === "P") {
        if (!semQtdParcelas && !semNumeroDias && !semDataBase) {
          calcularFaturasQtdParcelas(false);
        }
      } else {
        calcularFaturaAVista(false);
      }
    }
  };

  useEffect(() => {
    recalcularFaturasSomenteValor();
  }, [valorNFe]);

  const totalLancado = sumDataField(stateCab.faturas, "valor");

  const columns = [
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "parcela",
      text: "P",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "30px",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "100px",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarFatura,
      editorType: "date",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
      fixedColWidth: true,
      colWidth: "85px",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarFatura,
      editorType: "number",
    },
  ];

  const btnCalcular = (mostrarTexto = false) => (
    <FormButton
      md={identFaturaPor === "CON" ? null : "auto"}
      color="primary"
      onClick={
        identFaturaPor === "CON"
          ? () => calcularFaturasCondPag(true, idCondPag)
          : () => calcularFaturasQtdParcelas(true)
      }
      loading={loadingParcelas}
      disabled={
        identFaturaPor === "CON"
          ? [0, null, undefined].includes(idCondPag)
          : semQtdParcelas || semNumeroDias || semDataBase
      }
      disabledHint={
        identFaturaPor === "CON"
          ? "Informe a Condição de Pagamento"
          : semQtdParcelas
          ? "Informe a Quantidade de Parcelas"
          : semNumeroDias
          ? diaFixo
            ? "Informe o Dia Fixo"
            : "Informe o Intervalo de Dias"
          : semDataBase && "Informe o Dia do Primeiro Vencimento"
      }
    >
      {mostrarTexto ? "Calcular Sugestão" : <FaCalculator />}
    </FormButton>
  );

  return (
    <>
      <Divider className="mt-0">Faturas</Divider>
      <Row className="mb-2" style={{ minHeight: "217.73px" }}>
        <Col md={5} className="px-0">
          <RadioGroup
            md={8}
            label="Tipo de Pagto"
            value={tipoPagto}
            onChange={handleSetTipoPagto}
          >
            <RadioItem label="A Prazo" value="P" />
            <RadioItem label="A Vista" value="V" />
          </RadioGroup>
          <TextInput
            md={8}
            label="Nro. Fatura"
            value={nroFatura}
            onChange={(e, v) => handleSetNroFatura(v)}
          />
          {tipoPagto === "P" && (
            <>
              {identFaturaPor === "CON" ? (
                <>
                  <AsyncComboBox
                    md={10}
                    label="Cond. Pag"
                    isConcatField
                    concatModelName="cond_pag"
                    isSearchable
                    onChange={(s) => handleSetIdCondPag(s?.value)}
                    defaultValue={idCondPag}
                    defaultOptions
                  />
                  {btnCalcular(false)}
                </>
              ) : (
                <>
                  <Row className="pl-3">
                    <IntegerFormInput
                      md={6}
                      label="Qtd Parcelas"
                      defaultValue={qtdParcelas}
                      onChange={setQtdParcelas}
                      colClassName="pr-0"
                    />
                    <IntegerFormInput
                      md={6}
                      label={diaFixo ? "Dia Fixo" : "Intervalo Dias"}
                      defaultValue={numeroDias}
                      onChange={setNumeroDias}
                      colClassName="pr-0"
                    />
                  </Row>
                  <Row className="pl-3">
                    <UnlockToEdit>
                      <DatePicker
                        md={7}
                        label="Data Base"
                        value={dataBase}
                        onChange={(v) =>
                          setDataBase(moment.isMoment(v) ? v.toDate() : v)
                        }
                        divClassName="pr-0"
                        style={{ textAlign: "center" }}
                      />
                    </UnlockToEdit>
                    <FormCheckbox
                      label="Dia Fixo"
                      checked={diaFixo}
                      onChange={() => setDiaFixo(!diaFixo)}
                      name="dia-fixo"
                      divClassName="pr-0"
                    />
                  </Row>
                  {tipoPagto === "P" && (
                    <Row className="pl-3">{btnCalcular(true)}</Row>
                  )}
                </>
              )}
            </>
          )}
        </Col>
        <Col md={7} className="pr-0">
          <Row>
            <Col md={12} className="pr-0">
              <Table
                keyField="parcela"
                data={stateCab.faturas}
                columns={columns}
                paginated={false}
                showRegisterCount={false}
                pageSize={9}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 7, offset: 5 }} className="pr-0">
          <Row className="px-3 pr-0" style={{ textAlign: "right" }}>
            <FixedField
              md={4}
              label="Total a Lançar"
              value={formatarValor(valorNFe, 2, 2, false)}
              disabled
              divClassName="pl-0"
            />
            <FixedField
              md={4}
              label="A Lançar"
              value={formatarValor(valorNFe - totalLancado, 2, 2, false)}
              disabled
            />
            <FixedField
              md={4}
              label="Lançado"
              value={formatarValor(totalLancado, 2, 2, false)}
              disabled
              divClassName="pr-0"
            />
          </Row>
        </Col>
      </Row>
      <Divider>Informações de Pagamento</Divider>
      <FormasPagNfForm />
    </>
  );
};
