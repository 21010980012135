import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { FormCheckbox, PesqPlanoCtaCentroCusto } from "../../../../components";
import {
  setIdPlanoCtaCompensacao,
  setIdPlanoCtaInclusao,
  setIdPlanoCtaTaxaAdm,
  setTaxacaoAutomaticaCaixa,
  setIdPlanoCtaTaxaAntecipacao,
} from "../store/cartao_rec_slice";

export const PersonCartaoRec = () => {
  const store = useSelector((state) => state.cartao_rec);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de contas - Inclusão de novos cartões"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtaInclusao(v))}
          value={store.id_plano_cta_inclusao}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de contas - Taxa da Administradora"
          mode="plano_contas"
          value={store.id_plano_cta_taxa_adm}
          onChange={(v) => dispatch(setIdPlanoCtaTaxaAdm(v))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de contas - Compensação de Cartões"
          mode="plano_contas"
          value={store.id_plano_cta_compensacao}
          onChange={(v) => dispatch(setIdPlanoCtaCompensacao(v))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Habilitar Taxação Automática na Inclusão de Cartões pelo Caixa Loja"
          checked={store.taxacao_automatica_caixa}
          onChange={() =>
            dispatch(setTaxacaoAutomaticaCaixa(!store.taxacao_automatica_caixa))
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de contas - Taxa de Antecipação"
          mode="plano_contas"
          value={store.id_plano_cta_taxa_antecipacao}
          onChange={(v) => dispatch(setIdPlanoCtaTaxaAntecipacao(v))}
        />
      </Row>
    </>
  );
};
