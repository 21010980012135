import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { FINALIDADES_REGRA_TRIB } from "./modal/CamposChaves";
import { naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const RegraTributacaoGrid = ({ dados, onSelect, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dentro_estabelecimento",
      text: "Dentro do Estabelecimento",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "finalidade_oper",
      text: "Finalidade",
      align: "center",
      headerAlign: "center",
      formatter: (cell) =>
        FINALIDADES_REGRA_TRIB.find((e) => e.value === cell)?.label ?? "",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={onSelect}
      onRowDoubleClick={alterar}
    />
  );
};
