import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormCheckbox,
  IconButton,
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { HintLabel } from "../../../../../components/HintLabel";
import MaskedInput from "../../../../../components/MaskedInput";
import {
  setCodCedente,
  setCodTransmissao,
  setDiasNegativacao,
  setDiasProtesto,
  setEspecieTit,
  setFormaInadimplencia,
  setMensagem3,
  setMensagem4,
  setNroUltRem,
  setPadrao,
  setPosto,
  setProtestar,
  setSeqUltNossoNro,
  setTipoCarteira,
  setVariacaoCarteira,
} from "../store/cadastroContaBancSlice";
import {
  hintsEspecieTitulo,
  hintsTipoCarteira,
  hintsVariacaoCarteira,
} from "./HintsCampos";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { LuPencilLine } from "react-icons/lu";
import { apiPutV2 } from "../../../../../apiV2";
import { routesBaseContaBanc } from "../ContaBanc";
import { BsCheck } from "react-icons/bs";
import { showWarning } from "../../../../../components/AlertaModal";
import { RadioItem } from "../../../../../components/RadioGroup";

const rowClassName = "mb-2";

const formaInad = [
  { label: "Protesto", value: "PRO" },
  { label: "Negativação", value: "NEG" },
];

export const DadosCobrancaCnab = ({ action, selected, checkMessages }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const controle = useSelector((state) => state.controleCampos);
  const [alterarSeqUltNossoNro, setAlterarSeqUltNossoNro] = useState(true);
  const [seqUltNossoNroAntes, setSeqUltNossoNroAntes] = useState(null);
  const [loadingAlterarSeqUltNossoNro, setLoadingAlterarSeqUltNossoNro] =
    useState(false);

  const seqUltNossoNroRef = useRef();

  const handleSetFormaInadimplencia = (v) => {
    if (v === store.forma_inadimplencia) return;
    if (v === "PRO") {
      dispatch(setDiasNegativacao(0));
      dispatch(setProtestar(true));
    } else {
      dispatch(setDiasProtesto(0));
      dispatch(setProtestar(false));
    }
    dispatch(setFormaInadimplencia(v));
  };

  const configAlterarSeqUltNossoNro = () => {
    setAlterarSeqUltNossoNro(true);
    setSeqUltNossoNroAntes(store.seq_ult_nosso_nro);
    setTimeout(() => {
      if (seqUltNossoNroRef.current) {
        seqUltNossoNroRef.current.focus();
      }
    }, 35);
  };

  const handleAlterarSeqUltNossoNro = async () => {
    if (loadingAlterarSeqUltNossoNro) return;

    if (store.seq_ult_nosso_nro !== seqUltNossoNroAntes) {
      if ([null, undefined].includes(store.seq_ult_nosso_nro)) {
        showWarning("Por favor, informe a nova Sequência do Nosso Número");
        return;
      }

      const payload = {
        id_conta_banc: selected,
        seq_ult_nosso_nro: store.seq_ult_nosso_nro,
      };
      setLoadingAlterarSeqUltNossoNro(true);
      const [ok] = await apiPutV2(
        `${routesBaseContaBanc}/alterar_sequencia_nosso_nro/`,
        payload
      );
      if (!ok) {
        dispatch(setSeqUltNossoNro(seqUltNossoNroAntes));
      }
      setLoadingAlterarSeqUltNossoNro(false);
    }
    setAlterarSeqUltNossoNro(false);
  };

  useEffect(() => {
    setAlterarSeqUltNossoNro(action !== MODAL_ACTIONS.EDIT);
  }, []);

  const handleSetPadrao = (v) => {
    dispatch(setPadrao(v));
    checkMessages(v);
  };

  return (
    <>
      <Row className={rowClassName}>
        <RadioGroup
          label="Padrão CNAB"
          value={store.padrao}
          onChange={(v) => handleSetPadrao(v)}
        >
          <RadioItem
            label="240 Posições"
            value="240"
            disabled={controle.padrao_remessa === "400"}
          />
          <RadioItem
            label="400 Posições"
            value="400"
            disabled={controle.padrao_remessa === "240"}
          />
        </RadioGroup>
      </Row>
      <Row className={rowClassName}>
        <MaskedInput
          label={
            <HintLabel
              label="Código do Cedente"
              hintText={"Informar Acessório Escritural"}
              enabled={store.layout === "BRA"}
            />
          }
          md={3}
          onChange={(e, v) => dispatch(setCodCedente(v))}
          value={store.cod_cedente}
          disabled={!controle.cod_cedente}
          placeholder={store.layout === "BRA" && "Acessório Escritural"}
          mask="999999999999999"
          maskChar={null}
        />
        <MaskedInput
          md={2}
          label="Posto"
          value={store.posto}
          onChange={(e, v) => dispatch(setPosto(v))}
          disabled={!controle.posto}
          mask="99999"
          maskChar={null}
        />
        <IntegerFormInput
          md={2}
          label="Seq. (Nosso Nº)"
          defaultValue={store.seq_ult_nosso_nro}
          onChange={(v) => dispatch(setSeqUltNossoNro(v))}
          disabled={!alterarSeqUltNossoNro}
          colClassName={action === MODAL_ACTIONS.EDIT ? "pr-1" : undefined}
          ref={seqUltNossoNroRef}
        />
        {action === MODAL_ACTIONS.EDIT && (
          <>
            {alterarSeqUltNossoNro ? (
              <IconButton
                icon={BsCheck}
                size={20}
                onClick={handleAlterarSeqUltNossoNro}
                color="#5fc27e"
                className="mt-4 mr-1"
              />
            ) : (
              <IconButton
                icon={LuPencilLine}
                size={20}
                className="mt-4 mr-1"
                onClick={configAlterarSeqUltNossoNro}
              />
            )}
          </>
        )}
        <DatePicker
          label="Data Últ. Remessa"
          md={2}
          value={store.dta_ult_rem && moment(store.dta_ult_rem).toDate()}
          disabled
        />
        <IntegerFormInput
          md={2}
          label="Nº Últ. Remessa"
          defaultValue={store.nro_ult_rem}
          onChange={(v) => dispatch(setNroUltRem(v))}
        />
      </Row>
      <Row className={rowClassName}>
        <MaskedInput
          md={2}
          label={
            <HintLabel
              label="Tipo de Carteira"
              hintText={hintsTipoCarteira(store.layout)}
              enabled={controle.tipo_carteira}
              tooltipPlacement="right"
            />
          }
          value={store.tipo_carteira ?? ""}
          onChange={(e, v) => dispatch(setTipoCarteira(v))}
          disabled={!controle.tipo_carteira}
          mask={["SIC", "ITA"].includes(store.layout) ? "a" : "99"}
          maskChar={null}
        />
        <TextInput
          md={3}
          label={
            <HintLabel
              label="Variação da Carteira"
              hintText={hintsVariacaoCarteira(store.layout)}
              enabled={controle.variacao_carteira}
            />
          }
          value={store.variacao_carteira}
          onChange={(e, v) => dispatch(setVariacaoCarteira(v))}
          disabled={!controle.variacao_carteira}
          maxLength={3}
        />
        <IntegerFormInput
          md={3}
          label={
            <HintLabel
              label="Código de Transmissão"
              hintText="Informação cedida pelo banco que identifica o arquivo remessa do cliente"
              enabled={controle.cod_transmissao}
            />
          }
          defaultValue={store.cod_transmissao}
          onChange={(v) => dispatch(setCodTransmissao(v))}
          disabled={!controle.cod_transmissao}
        />
        <MaskedInput
          md={2}
          label={
            <HintLabel
              label="Espécie do Título"
              hintText={hintsEspecieTitulo(store.layout)}
              tooltipPlacement="right"
            />
          }
          value={store.especie_tit}
          onChange={(e, v) => dispatch(setEspecieTit(v))}
          mask={store.layout === "SIC" ? "a" : "99"}
          maskChar={null}
        />
      </Row>
      <Row className={rowClassName}>
        <ComboBox
          options={formaInad}
          md={3}
          label="Forma - inadimplência"
          onChange={(s) => handleSetFormaInadimplencia(s?.value)}
          defaultValue={store.forma_inadimplencia}
        />
        {store.forma_inadimplencia === "PRO" && (
          <FormCheckbox
            label="Protestar Títulos"
            checked={store.protestar}
            onChange={() => dispatch(setProtestar(!store.protestar))}
            name="protestar"
          />
        )}
        {(store.protestar || store.forma_inadimplencia !== "PRO") && (
          <IntegerFormInput
            md={3}
            label={`Nº de Dias Para ${
              store.forma_inadimplencia === "PRO" ? "Protesto" : "Negativação"
            }`}
            defaultValue={
              store.forma_inadimplencia === "PRO"
                ? store.dias_protesto
                : store.dias_negativacao
            }
            onChange={(v) =>
              dispatch(
                store.forma_inadimplencia === "PRO"
                  ? setDiasProtesto(v)
                  : setDiasNegativacao(v)
              )
            }
          />
        )}
      </Row>
    </>
  );
};
