import Axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  buscarDadosEmpresaCookies,
  isDevEnv,
  isTestingEnv,
  viewDownloadPDF,
} from "./coreUtils";
import moment from "moment";

const baseURL =
  isDevEnv() && !isTestingEnv()
    ? "http://localhost:4200"
    : "https://doc-pdf.skillsoft.com.br";

let docService = Axios.create({
  baseURL: baseURL,
  responseType: "blob",
});

export const docPost = async (url, data, options) =>
  await docService
    .post(url, data)
    .then((ret) => ret.data)
    .catch(async (err) => {
      toastr.error(
        options.errorMessage ?? "Erro",
        err.response ? await err.response.data.text() : err.message
      );
      return false;
    });

export const docPrintReport = async (url, data, number) => {
  const payload = {
    dados_empresa: await buscarDadosEmpresaCookies(),
    ...data,
  };

  const arq = await docPost(url, payload, {
    errorMessage: "Erro ao Imprimir Relatório",
  });

  if (arq) {
    viewDownloadPDF(
      arq,
      `${number}_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
    );
    return true;
  } else {
    return false;
  }
};
