import React, { useEffect, useState } from "react";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import RemessaEntreEmpresasService from "../../../../services/faturamento/RemessaEntreEmpresasService";
import { Card, Row } from "reactstrap";
import { RemessaGrid } from "./components/RemessaGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { useHistory } from "react-router-dom";
import { BotaoIncluir } from "../../../../components/cadastro";
import { incluirAlterarRemessaEntreEmpresasRoute } from "../../../../routes/modules/estoque";
import GerarRemessaModal from "./components/GerarRemessaModal";
import { DetalhesRemessaModal } from "./components/DetalhesRemessaModal";

export const EnviarRemessa = ({ location }) => {
  const [somenteEmAberto, setSomenteEmAberto] = useState(false);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [gerarRemessaOpen, setGerarRemessaOpen] = useState(false);
  const [mostrarDetalhesOpen, setMostrarDetalhesOpen] = useState(false);

  const history = useHistory();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      somente_em_aberto: somenteEmAberto,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await RemessaEntreEmpresasService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const alterar = (id, row) => {
    if (row.status !== "INC") {
      return;
    }
    history.push(incluirAlterarRemessaEntreEmpresasRoute.path, {
      action: MODAL_ACTIONS.EDIT,
      selected: id,
    });
  };

  const incluir = () => {
    history.push(incluirAlterarRemessaEntreEmpresasRoute.path, {
      action: MODAL_ACTIONS.ADD,
    });
  };

  const notifyGerar = () => {
    setSelected(null);
    carregarDados();
  };

  const toggleGerar = () => {
    setGerarRemessaOpen(!gerarRemessaOpen);
  };

  const toggleMostrarDetalhes = () =>
    setMostrarDetalhesOpen(!mostrarDetalhesOpen);

  const mostrarDetalhes = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleMostrarDetalhes();
    }, 1);
  };

  useEffect(() => {
    if (location?.state?.refresh) {
      carregarDados();
    }
  }, []);

  return (
    <PageContainer title="Enviar Remessa Entre Empresas" number="0098">
      <GerarRemessaModal
        isOpen={gerarRemessaOpen}
        toggle={toggleGerar}
        selected={selected}
        notifyEvent={notifyGerar}
      />
      <DetalhesRemessaModal
        isOpen={mostrarDetalhesOpen}
        toggle={toggleMostrarDetalhes}
        selected={selected}
      />
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <FormCheckbox
            label="Somente Em Aberto"
            checked={somenteEmAberto}
            onChange={() => setSomenteEmAberto(!somenteEmAberto)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={incluir} />
          <FormButton
            md="auto"
            color="primary"
            disabled={
              [0, null, undefined].includes(selected) ||
              selectedStatus !== "INC"
            }
            onClick={toggleGerar}
            disabledHint={
              [0, null, undefined].includes(selected)
                ? "Selecione uma remessa na lista"
                : "A Remessa selecionada já foi gerada"
            }
          >
            Gerar Remessa
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <RemessaGrid
          dados={dados}
          setSelected={(selected, _, row) => {
            setSelected(selected);
            setSelectedStatus(row.status);
          }}
          alterar={alterar}
          mostrarDetalhes={mostrarDetalhes}
        />
      </Card>
    </PageContainer>
  );
};
