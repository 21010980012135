import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  Table,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import UteisService from "../../../../../services/uteis/UteisService";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
  localeDateToISO,
  sumDataField,
} from "../../../../../coreUtils";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import GerencContasReceberService from "../../../../../services/financeiro/GerencContasReceberService";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { showWarning } from "../../../../../components/AlertaModal";

export const IncluirTituloReceberModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState({});
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [dataEmi, setDataEmi] = useState(new Date());
  const [numero, setNumero] = useState("");
  const [carteira, setCarteira] = useState(false);
  const [vlrParcela, setVlrParcela] = useState(0);
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [dataBase, setDataBase] = useState(new Date());
  const [diaFixo, setDiaFixo] = useState(true);
  const [numDias, setNumDias] = useState(30);
  const [idPlanoContas, setIdPlanoContas] = useState(null);
  const [idCentroCusto, setIdCentroCusto] = useState(null);
  const [obs, setObs] = useState("");
  const [parcelas, setParcelas] = useState([]);
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const utilizaOrganizacao = params?.utiliza_organizacao;
  const utilizaCentroCusto = params?.utiliza_centro_custo;

  const limparDados = () => {
    setIdOrganizacao(null);
    setIdCliente(null);
    setNumero("");
    setDataEmi(new Date());
    setCarteira(false);
    setQtdParcelas(0);
    setDataBase(new Date());
    setDiaFixo(true);
    setNumDias(30);
    setVlrParcela(0);
    setIdPlanoContas(null);
    setIdCentroCusto(null);
    setObs("");
    setParcelas([]);
  };

  const onBeforeOpen = (par) => {
    setParams(par);
    if (par.identif_plano_contas_inc_manual) {
      setIdPlanoContas(par.id_plano_contas_pad_inc_tit);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const validarDadosParcelas = () => {
    let msg = "";
    if (["", null, undefined].includes(numero)) {
      msg = "Por favor, informe o Número do Título.";
      return [false, msg];
    }
    if ([0, null, undefined].includes(qtdParcelas)) {
      msg = "Por favor, informe a Quantidade de Parcelas.";
      return [false, msg];
    }
    if (!(dataBase instanceof Date)) {
      msg = "Por favor, revise a Data Base do cálculo das parcelas.";
      return [false, msg];
    }
    if (diaFixo) {
      if ([0, null, undefined].includes(numDias)) {
        msg = "Por favor, informe o Dia Fixo de vencimento das parcelas.";
        return [false, msg];
      }
    } else {
      if (
        qtdParcelas <= 1
          ? [null, undefined].includes(numDias)
          : [0, null, undefined].includes(numDias)
      ) {
        msg =
          "Por favor, informe o Intervalo de Dias entre os vencimentos das parcelas.";
        return [false, msg];
      }
    }

    return [true, msg];
  };

  const gerarParcelas = async (alterarTudo = false) => {
    const [okVal] = validarDadosParcelas();
    if (!okVal) {
      return;
    }

    const valorTotal = vlrParcela * qtdParcelas;
    if (valorTotal === 0) {
      setParcelas([]);
      return;
    }
    setLoadingParcelas(true);

    const payload = {
      qtd_parcelas: qtdParcelas,
      num_dias: numDias,
      dia_fixo: diaFixo,
      valor: valorTotal,
      data_base: formatDateISO(dataBase),
    };

    const [ok, ret] = await UteisService.calcularParcelasPorQtdParcNumDias(
      payload
    );

    if (ok) {
      const parcelasAntes = [...parcelas];
      const parcelasDepois = ret.map((e, index) => ({
        parcela: index + 1,
        numero: numero,
        data_vcto: formatDateLocal(e.data_vcto),
        ...(!alterarTudo ? parcelasAntes[index] ?? {} : {}),
        valor: parseFloat(e.valor),
        entrada: e.entrada,
      }));

      setParcelas(parcelasDepois);
    }
    setLoadingParcelas(false);
  };

  useEffect(() => {
    gerarParcelas(true);
  }, [qtdParcelas, dataBase, diaFixo, numDias, vlrParcela]);

  const handleSetNumero = (v) => {
    setNumero(v);
    setParcelas(parcelas.map((e) => ({ ...e, numero: v })));
  };

  const alterarParcela = (coluna, novoValor, row) => {
    if (coluna === "numero") {
      row.numero = novoValor;
    }
    if (coluna === "data_vcto") {
      if (moment.isMoment(novoValor)) {
        row[coluna] = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }
    if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    }

    setParcelas(parcelas.map((e) => (e.parcela === row.parcela ? row : e)));
    return true;
  };

  const handleSubmit = async () => {
    if (loadingParcelas) {
      return false;
    }

    if ([0, null, undefined].includes(idCliente)) {
      showWarning("Por favor, informe o Cliente e tente novamente.");
      return false;
    }

    if (utilizaOrganizacao && [0, null, undefined].includes(idOrganizacao)) {
      showWarning("Por favor, informe a Organização e tente novamente.");
      return false;
    }

    const [okParc, msgParc] = validarDadosParcelas();
    if (!okParc) {
      showWarning(msgParc);
      return false;
    }

    if (parcelas.length === 0) {
      showWarning(
        "As Parcelas do(s) título(s) não foram informadas. " +
          "Por favor, revise as informações e tente novamente."
      );
      return false;
    }

    if ([0, null, undefined].includes(idPlanoContas)) {
      showWarning("Por favor, informe o Plano de Contas e tente novamente.");
      return false;
    }

    const payload = {
      id_cliente: idCliente,
      id_plano_contas: idPlanoContas,
      id_centro_custo: idCentroCusto,
      data_emi: formatDateISO(dataBase),
      numero: numero,
      obs: obs,
      carteira: carteira,
      vlr_total: sumDataField(parcelas, "valor"),
      parcelas: parcelas.map((e) => ({
        parcela: e.parcela,
        numero: e.numero,
        data_vcto: localeDateToISO(e.data_vcto),
        valor: e.valor,
        entrada: e.entrada,
      })),
    };
    setLoadingSubmit(true);
    const [ok] = await GerencContasReceberService.incluir(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoadingSubmit(false);
  };

  const columnsParcelas = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      fixedColWidth: true,
      colWidth: "25%",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "text",
      fixedColWidth: true,
      colWidth: "25%",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "date",
      fixedColWidth: true,
      colWidth: "25%",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "number",
      fixedColWidth: true,
      colWidth: "25%",
    },
  ];

  return (
    <>
      <FormButton md="auto" padded={false} color="info" onClick={toggle}>
        Incluir Título
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Incluir Título a Receber"
        number="0120_1"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
        paramsName="inc_titulo_receber"
        onBeforeOpen={onBeforeOpen}
        onClosed={limparDados}
      >
        {utilizaOrganizacao && (
          <Row className="mb-1">
            <AsyncComboBox
              md={6}
              label="Organização"
              isConcatField
              concatModelName="organizacao"
              defaultValue={idOrganizacao}
              onChange={(s) => setIdOrganizacao(s?.value)}
              defaultOptions
            />
          </Row>
        )}
        <Row className="mb-1">
          <AsyncComboBox
            md={8}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
          />
          <FormCheckbox
            label="Carteira"
            checked={carteira}
            onChange={() => setCarteira(!carteira)}
          />
        </Row>
        <Row className="mb-3">
          <DatePicker
            md={2}
            label="Emissão"
            value={dataEmi}
            onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
          />
          <TextInput
            md={3}
            label="Número"
            value={numero}
            onChange={(e, v) => handleSetNumero(v)}
            maxLength={12}
          />
          <IntegerFormInput
            md={2}
            label="Qtd. Parcelas"
            defaultValue={qtdParcelas}
            onChange={setQtdParcelas}
          />
          <NumberInput
            md={3}
            label="Valor de Cada Parcela"
            value={vlrParcela}
            onChange={setVlrParcela}
          />
        </Row>
        <Row className="mb-3">
          <Col md={7}>
            <Table
              data={parcelas}
              columns={columnsParcelas}
              keyField="parcela"
              paginated={false}
              showRegisterCount={false}
              pageSize={8}
            />
          </Col>
          <Col md="auto" className="mx-auto">
            <RadioGroup
              md={6}
              value={diaFixo}
              onChange={(v) => setDiaFixo(v.toString() === "true")}
              divClassName="mx-auto"
            >
              <RadioItem label="Dia Fixo" value={true} />
              <RadioItem label="Intervalo de Dias" value={false} />
            </RadioGroup>
            <IntegerFormInput
              md={8}
              label={diaFixo ? "Dia Fixo" : "Intervalo de Dias"}
              defaultValue={numDias}
              onChange={setNumDias}
              colClassName="mx-auto"
            />
            <UnlockToEdit>
              <DatePicker
                md={8}
                label="Data Base"
                value={dataBase}
                onChange={(v) =>
                  setDataBase(moment.isMoment(v) ? v.toDate() : v)
                }
                divClassName="mx-auto"
              />
            </UnlockToEdit>
          </Col>
        </Row>
        <Row className="mb-1">
          <PesqPlanoCtaCentroCusto
            md={10}
            label="Plano Contas"
            mode="plano_contas"
            value={idPlanoContas}
            onChange={setIdPlanoContas}
          />
        </Row>
        {utilizaCentroCusto && (
          <Row className="mb-1">
            <PesqPlanoCtaCentroCusto
              md={10}
              label="Centro Custo"
              mode="centro_custo"
              value={idCentroCusto}
              onChange={setIdCentroCusto}
            />
          </Row>
        )}
        <Row>
          <TextInput
            md={12}
            label="Observação"
            type="textarea"
            value={obs}
            onChange={(e, v) => setObs(v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
