import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import { AsyncComboBox, FormButton } from "../../../components";
import { PageContainer } from "../../../components/PageContainer";
import { TabBody, TabController } from "../../../components/TabController";
import { PersonFrenteCaixa } from "./components/PersonFrenteCaixa";
import { PersonAppVendaDireta } from "./components/PersonAppVendaDireta";
import store from "./store";
import storeControle, {
  ControlePersonContext,
  resetControle,
} from "./store_controle";
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import ParametroService from "../../../services/config/ParametroService";
import { setupAppVendaDireta } from "./store/app_venda_direta_slice";
import { PersonCaixa } from "./components/PersonCaixa";
import { setupCaixa } from "./store/caixa_slice";
import { PersonNfEletronicaConsumidor } from "./components/PersonNfEletronicaConsumidor";
import { PersonNfServico } from "./components/PersonNfServico";
import { setupNfEletronicaConsumidor } from "./store/nf_eletronica_consumidor_slice";
import { setupNfServico } from "./store/nf_servico_slice";
import { PersonAppPedidoVenda } from "./components/PersonAppPedidoVenda";
import { setupAppPedidoVenda } from "./store/app_pedido_venda_slice";
import { PersonVenda } from "./components/PersonVenda";
import { setupVenda } from "./store/venda_slice";
import { PersonTef } from "./components/PersonTef";
import { setupTef } from "./store/tef_slice";
import { PersonBancario } from "./components/PersonBancario";
import { setupBancario } from "./store/bancario_slice";
import { PersonEntregaPedidoExt } from "./components/PersonEntregaPedidoExt";
import { setupEntregaPedidoExt } from "./store/entrega_pedido_ext_slice";
import { PersonOrcamento } from "./components/PersonOrcamento";
import { setupOrcamento } from "./store/orcamento_slice";
import { PersonCartaoRec } from "./components/PersonCartaoRec";
import { setupCartaoRec } from "./store/cartao_rec_slice";
import { setupGeral } from "./store/geral_slice";
import PersonGeral from "./components/PersonGeral";
import { PersonEmail } from "./components/PersonEmail";
import {
  setupEmail,
  initialState as initStateEmail,
} from "./store/email_slice";
import { setupOs } from "./store/os_slice";
import { PersonOS } from "./components/PersonOS";
import { setupFrenteCaixa } from "./store/frente_caixa_slice";
import { PersonCadastro } from "./components/PersonCadastro";
import { setupCadastro } from "./store/cadastro_slice";
import { setupCadastroProduto } from "./store/cadastro_produto_slice";
import { PersonCadastroProduto } from "./components/PersonCadastroProduto";
import { PersonCrediario } from "./components/PersonCrediario";
import { setupCrediario } from "./store/crediario_slice";
import { PersonContasReceber } from "./components/PersonContasReceber";
import { setupContasReceber } from "./store/contas_receber_slice";
import { PersonFaturamento } from "./components/PersonFaturamento";
import { PersonPDV } from "./components/PersonPDV";
import { setupFaturamento } from "./store/faturamento_slice";
import { setupPdv } from "./store/pdv_slice";
import { PersonPedidoVenda } from "./components/PersonPedidoVenda";
import { setupPedidoVenda } from "./store/pedido_venda_slice";
import { setupNfEletronicaGeral } from "./store/nf_eletronica_geral_slice";
import { PersonNfEletronicaGeral } from "./components/PersonNfEletronicaGeral";
import { setupPedidoExterno } from "./store/pedido_externo_slice";
import { PersonPedidoExterno } from "./components/PersonPedidoExterno";

const PersonalizarContainerInternal = ({ controleDispatch }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [empresa, setEmpresa] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await ParametroService.buscar();
    if (ok) {
      dispatch(setupAppPedidoVenda(ret.param_app_pedido_venda));
      dispatch(setupAppVendaDireta(ret.param_app_venda_direta));
      dispatch(setupBancario(ret.param_bancario));
      dispatch(setupCadastro(ret.param_cadastro));
      dispatch(setupCadastroProduto(ret.param_cadastro_produto));
      dispatch(setupCaixa(ret.param_caixa));
      dispatch(setupCartaoRec(ret.param_cartao_rec));
      dispatch(setupContasReceber(ret.param_contas_receber));
      dispatch(setupCrediario(ret.param_crediario));
      dispatch(
        setupEmail(
          ret.param_geral.respons_por_envio_de_email !== "SKILL"
            ? ret.param_email
            : initStateEmail
        )
      );
      dispatch(setupEntregaPedidoExt(ret.param_entrega_pedido_ext));
      dispatch(setupFaturamento(ret.param_faturamento));
      dispatch(setupFrenteCaixa(ret.param_frente_venda));
      dispatch(setupGeral(ret.param_geral));
      dispatch(setupNfEletronicaConsumidor(ret.param_nf_eletronica_consumidor));
      dispatch(setupNfEletronicaGeral(ret.param_nf_eletronica_geral));
      dispatch(setupNfServico(ret.param_nf_servico));
      dispatch(setupOrcamento(ret.param_orcamento));
      dispatch(setupOs(ret.param_os));
      dispatch(setupPdv(ret.param_pdv));
      dispatch(setupPedidoExterno(ret.param_pedido_externo));
      dispatch(setupPedidoVenda(ret.param_pedido_venda));
      dispatch(setupTef(ret.param_tef));
      dispatch(setupVenda(ret.param_venda));
      controleDispatch(resetControle());
      setLoaded(true);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      const payload = Object.entries(store).reduce(
        (acc, [key, value]) => ({ ...acc, [`p_${key}`]: value }),
        {}
      );

      const [ok] = await ParametroService.atualizarParams(payload);

      if (ok) {
        carregarDados();
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <Card body>
        <Row>
          <AsyncComboBox
            onChange={(s) => {
              setEmpresa(s?.value);
              setLoaded(false);
            }}
            label="Empresa"
            name="empresa"
            md={6}
            isConcatField
            concatModelName="empresa"
            defaultOptions
            isSearchable
          />
          <FormButton
            md={2}
            color="primary"
            onClick={carregarDados}
            disabled={[0, null, undefined].includes(empresa)}
            loading={loading}
            disabledHint="Informe a Empresa"
          >
            Carregar
          </FormButton>
          <FormButton
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
            color="success"
            disabled={!loaded}
          >
            Aplicar
          </FormButton>
        </Row>
      </Card>
      {loaded && (
        <Card body className="params-screen">
          <TabController numbered defaultActiveTab="1">
            <TabBody title="Geral" name="geral">
              <PersonGeral />
            </TabBody>
            <TabBody title="APP Pedido Externo" name="app_pedido_venda">
              <PersonAppPedidoVenda />
            </TabBody>
            <TabBody title="APP Venda Direta" name="app_venda_direta">
              <PersonAppVendaDireta />
            </TabBody>
            <TabBody title="Bancário" name="bancario">
              <PersonBancario />
            </TabBody>
            <TabBody title="Cadastro" name="cadastro">
              <PersonCadastro />
            </TabBody>
            <TabBody title="Cadastro de Produto" name="cadastro_produto">
              <PersonCadastroProduto />
            </TabBody>
            <TabBody title="Caixa" name="caixa">
              <PersonCaixa />
            </TabBody>
            <TabBody title="Cartão de Crédito" name="cartao_rec">
              <PersonCartaoRec />
            </TabBody>
            <TabBody title="Contas a Receber" name="contas_receber">
              <PersonContasReceber />
            </TabBody>
            <TabBody title="Crediário" name="crediario" disabled>
              <PersonCrediario />
            </TabBody>
            <TabBody title="E-Mail" name="email" disabled>
              <PersonEmail />
            </TabBody>
            <TabBody
              title="Entrega de Pedido Externo"
              name="entrega_pedido_ext"
            >
              <PersonEntregaPedidoExt />
            </TabBody>
            <TabBody title="Faturamento" name="faturamento">
              <PersonFaturamento />
            </TabBody>
            <TabBody title="Frente de Venda" name="frente_venda">
              <PersonFrenteCaixa />
            </TabBody>
            <TabBody title="NF-e Geral" name="nf_eletronica_geral">
              <PersonNfEletronicaGeral />
            </TabBody>
            <TabBody title="NFC-e" name="nf_eletronica_consumidor">
              <PersonNfEletronicaConsumidor />
            </TabBody>
            <TabBody title="NFS-e" name="nf_servico">
              <PersonNfServico />
            </TabBody>
            <TabBody title="Orçamento" name="orcamento">
              <PersonOrcamento />
            </TabBody>
            <TabBody title="Ordem de Serviço" name="os">
              <PersonOS />
            </TabBody>
            <TabBody title="PDV" name="pdv">
              <PersonPDV />
            </TabBody>
            <TabBody title="Pedido Externo" name="pedido_externo">
              <PersonPedidoExterno />
            </TabBody>
            <TabBody title="Pedido Interno" name="pedido_interno">
              <PersonPedidoVenda />
            </TabBody>
            <TabBody title="TEF" name="tef">
              <PersonTef />
            </TabBody>
            <TabBody title="Venda" name="venda">
              <PersonVenda />
            </TabBody>
          </TabController>
        </Card>
      )}
    </>
  );
};

const PersonalizarContainer = connect(
  null,
  (dispatch) => ({ controleDispatch: dispatch }),
  null,
  {
    context: ControlePersonContext,
  }
)(PersonalizarContainerInternal);

export const Personalizar = () => {
  return (
    <PageContainer title="Personalizar" number="conf_02">
      <Provider store={store}>
        <Provider store={storeControle} context={ControlePersonContext}>
          <PersonalizarContainer />
        </Provider>
      </Provider>
    </PageContainer>
  );
};
