import React, { useEffect, useState } from "react";
import AvisoService from "../../../../services/AvisoService";
import { ListGroup } from "reactstrap";
import { GrupoAvisos } from "./components/GrupoAvisos";

export const Avisos = ({}) => {
  const [avisos, setAvisos] = useState([]);

  const buscarAvisos = async () => {
    const [ok, ret] = await AvisoService.buscarAvisosColab();
    setAvisos(ok ? ret : []);
  };

  useEffect(() => {
    buscarAvisos();
  }, []);

  return (
    <div
      style={{
        width: "400px",
        position: "absolute",
        right: 7,
        paddingTop: "0.5rem",
      }}
    >
      <ListGroup>
        {avisos.map((aviso) => (
          <>
            <GrupoAvisos dados={aviso} /> <div style={{ height: "10px" }} />
          </>
        ))}
      </ListGroup>
    </div>
  );
};
