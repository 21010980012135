import React, { useEffect } from "react";
import { Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setIdClienteDestNfeRemessa,
  setIdMotivoNaoAtendEncerJornada,
  setIdPlanoCtasPadBonificacao,
  setIdPlanoCtasPadDuplicRec,
  setIdPlanoCtasPadPagComis,
  setIdPlanoCtasPadRecebDinheiro,
  setIdPlanoCtasPadTroca,
  setIdTipoDocNfeBonificacao,
  setIdTipoDocNfeVenda,
  setIdTipoDocRecarga,
  setIdTipoDocRemessaVenda,
  setIdTipoDocRetornoRemessa,
  setUtilizaBonificacao,
  setVlrMinimoVdaBoleto,
  setPermiteAbertJornVariosDiasMesmaData,
  setPermiteQuantidadesDecimais,
  setNumCasasDecimaisQuantidade,
  setQuantidadeMaximaPorProdutoSolicRecarga,
  setMdfeAutomaticoAbreFechDia,
  setBloquearVendaBoletoParaClienteInadimplente,
  setNumDiasBloqueiaBoleto,
  setSacaComissaoPor,
  setTranspareceDescAcreNf,
  setPermiteRecebimentoCredRotativo,
  setPermiteRecebimentoPix,
  setPermiteRecargaInstantanea,
  setHabilitaCadastroClienteApp,
  setPermiteEscolhaEmiNfeFinalVenda,
  setEmiteNfeComplementarDifValor,
  setIdTipoDocNfeComplementarDifValor,
  setHabilitaDescSobreTotal,
  setOrdenarPesqProdPor,
  setFormaDefinePrecoBonificacao,
  setVinculaMultiplosVeiculosPorRota,
  setPermiteIncluirClienteMaisDeUmaRota,
  setImprimirEstoqueNoEncerramento,
  setBloqSolicRecargaSemEstoque,
  setVerCadastroProdutoApp,
  setGerarNfeComplementarDifValorPor,
  setImprimirCanhotoBoletoNfTopo,
  setExisteRedutorVlrTroca,
  setPermiteUsarSaldoFlexNaTrocaPaga,
  setZerarFlexAoVirarMes,
} from "../store/app_venda_direta_slice";
import { RadioItem } from "../../../../components/RadioGroup";
import { useRef } from "react";

export const PersonAppVendaDireta = () => {
  const store = useSelector((state) => state.app_venda_direta);
  const idTipoDocNfeComplementarDifValorRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!store.permite_quantidades_decimais) {
      dispatch(setNumCasasDecimaisQuantidade(0));
    }
  }, [store.permite_quantidades_decimais]);

  useEffect(() => {
    if (!store.bloquear_venda_boleto_para_cliente_inadimplente) {
      dispatch(setNumDiasBloqueiaBoleto(0));
    }
  }, [store.bloquear_venda_boleto_para_cliente_inadimplente]);

  useEffect(() => {
    if (!store.emite_nfe_complementar_dif_valor) {
      dispatch(setIdTipoDocNfeComplementarDifValor(null));
      if (idTipoDocNfeComplementarDifValorRef.current) {
        idTipoDocNfeComplementarDifValorRef.current.clearValue();
      }
    }
  }, [store.emite_nfe_complementar_dif_valor]);

  return (
    <>
      <Row>
        <AsyncComboBox
          md={6}
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          label="Tipo de Documento para emissão de NFe de VENDA"
          isSearchable
          onChange={(s) => dispatch(setIdTipoDocNfeVenda(s?.value ?? null))}
          defaultValue={store.id_tipo_doc_nfe_venda}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          label="Tipo de Documento Para Emissão de NFe de BONIFICAÇÃO"
          isSearchable
          onChange={(s) =>
            dispatch(setIdTipoDocNfeBonificacao(s?.value ?? null))
          }
          defaultValue={store.id_tipo_doc_nfe_bonificacao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          label="Tipo de Documento para Emissão de NFe de Remessa de Saída para Venda"
          isSearchable
          onChange={(s) => dispatch(setIdTipoDocRemessaVenda(s?.value ?? null))}
          defaultValue={store.id_tipo_doc_remessa_venda}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          label="Tipo de Documento para Emissão de NFe de Remessa de Saída - Recarga"
          isSearchable
          onChange={(s) => dispatch(setIdTipoDocRecarga(s?.value ?? null))}
          defaultValue={store.id_tipo_doc_recarga}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={7}
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          label="Tipo de Documento para Emissão de NFe de Remessa de Retorno"
          isSearchable
          onChange={(s) =>
            dispatch(setIdTipoDocRetornoRemessa(s?.value ?? null))
          }
          defaultValue={store.id_tipo_doc_retorno_remessa}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={7}
          isConcatField
          concatModelName="motivo_web"
          defaultOptions
          label="Motivo de Não Atendimento de Clientes em Espera ao Encerrar Jornada"
          isSearchable
          onChange={(s) =>
            dispatch(setIdMotivoNaoAtendEncerJornada(s?.value ?? null))
          }
          defaultValue={store.id_motivo_nao_atend_encer_jornada}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="cliente"
          label="Cliente para Emissão de NFe para as Remessas de Saída e Retorno de estoque"
          isSearchable
          onChange={(s) =>
            dispatch(setIdClienteDestNfeRemessa(s?.value ?? null))
          }
          defaultValue={store.id_cliente_dest_nfe_remessa}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano de Contas Padrão para Recebimento de Vendas em Contas a Receber (Boletos)"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtasPadDuplicRec(v))}
          value={store.id_plano_ctas_pad_duplic_rec}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Plano de Contas Padrão para Recebimentos de Venda em Dinheiro"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtasPadRecebDinheiro(v))}
          value={store.id_plano_ctas_pad_receb_dinheiro}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={9}
          label="Plano de Contas Padrão para Pagamento de Comissão Oriunda de Valor em Dinheiro da Jornada"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtasPadPagComis(v))}
          value={store.id_plano_ctas_pad_pag_comis}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão para Troca"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtasPadTroca(v))}
          value={store.id_plano_ctas_pad_troca}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão para Bonificação"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoCtasPadBonificacao(v))}
          value={store.id_plano_ctas_pad_bonificacao}
        />
      </Row>
      <Row>
        <NumberInput
          label="Valor Mínimo para Venda em Boleto"
          md={7}
          className="mb-4"
          onChange={(v) => dispatch(setVlrMinimoVdaBoleto(v))}
          value={store.vlr_minimo_vda_boleto}
          mdInput={3}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Utiliza Bonificação"
          checked={store.utiliza_bonificacao}
          onChange={() =>
            dispatch(setUtilizaBonificacao(!store.utiliza_bonificacao))
          }
          name="utiliza_bonificacao"
          padded={false}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Abertura de Múltiplas Jornadas Iniciando no Mesmo Dia"
          checked={store.permite_abert_jorn_varios_dias_mesma_data}
          onChange={() =>
            dispatch(
              setPermiteAbertJornVariosDiasMesmaData(
                !store.permite_abert_jorn_varios_dias_mesma_data
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Quantidades Decimais"
          checked={store.permite_quantidades_decimais}
          onChange={() =>
            dispatch(
              setPermiteQuantidadesDecimais(!store.permite_quantidades_decimais)
            )
          }
        />
        <Label md="auto" className="pt-2 pr-0">
          Casas Decimais
        </Label>
        <IntegerFormInput
          md={2}
          defaultValue={store.num_casas_decimais_quantidade}
          onChange={(v) => dispatch(setNumCasasDecimaisQuantidade(v))}
          disabled={!store.permite_quantidades_decimais}
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={7}
          label="Quantidade Máxima por Produto para Solicitação de Recarga"
          defaultValue={store.quantidade_maxima_por_produto_solic_recarga}
          onChange={(v) =>
            dispatch(setQuantidadeMaximaPorProdutoSolicRecarga(v))
          }
          mdInput={3}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="MDFe Automático de Abertura/Fechamento do Dia"
          checked={store.mdfe_automatico_abre_fech_dia}
          onChange={() =>
            dispatch(
              setMdfeAutomaticoAbreFechDia(!store.mdfe_automatico_abre_fech_dia)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Venda em Boleto Para Cliente Inadimplente"
          checked={store.bloquear_venda_boleto_para_cliente_inadimplente}
          onChange={() =>
            dispatch(
              setBloquearVendaBoletoParaClienteInadimplente(
                !store.bloquear_venda_boleto_para_cliente_inadimplente
              )
            )
          }
        />
        <Label md="auto" className="pt-0 pr-0" style={{ lineHeight: 1.7 }}>
          Dias para Bloqueio
        </Label>
        <IntegerFormInput
          md={2}
          defaultValue={store.num_dias_bloqueia_boleto}
          onChange={(v) => dispatch(setNumDiasBloqueiaBoleto(v))}
          disabled={!store.bloquear_venda_boleto_para_cliente_inadimplente}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Sacar Comissão Por"
          value={store.saca_comissao_por}
          onChange={(v) => dispatch(setSacaComissaoPor(v))}
        >
          <RadioItem label="Duração de Jornada" value="DURACAO" />
          <RadioItem label="Dia" value="DIA" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Transparecer Desconto/Acréscimo na Nota Fiscal"
          checked={store.transparece_desc_acre_nf}
          onChange={() =>
            dispatch(setTranspareceDescAcreNf(!store.transparece_desc_acre_nf))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Recebimento em Crédito Rotativo"
          checked={store.permite_recebimento_cred_rotativo}
          onChange={() =>
            dispatch(
              setPermiteRecebimentoCredRotativo(
                !store.permite_recebimento_cred_rotativo
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Recebimento em Pix"
          checked={store.permite_recebimento_pix}
          onChange={() =>
            dispatch(setPermiteRecebimentoPix(!store.permite_recebimento_pix))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Recarga Instantânea"
          checked={store.permite_recarga_instantanea}
          onChange={() =>
            dispatch(
              setPermiteRecargaInstantanea(!store.permite_recarga_instantanea)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Habilitar Inclusão de Clientes pelo App"
          checked={store.habilita_cadastro_cliente_app}
          onChange={() =>
            dispatch(
              setHabilitaCadastroClienteApp(
                !store.habilita_cadastro_cliente_app
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Escolha do Emissor da NFe Final de Venda"
          checked={store.permite_escolha_emi_nfe_final_venda}
          onChange={() =>
            dispatch(
              setPermiteEscolhaEmiNfeFinalVenda(
                !store.permite_escolha_emi_nfe_final_venda
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded
          label="Emitir NFe Complementar de Diferença de Valor"
          checked={store.emite_nfe_complementar_dif_valor}
          onChange={() =>
            dispatch(
              setEmiteNfeComplementarDifValor(
                !store.emite_nfe_complementar_dif_valor
              )
            )
          }
        />
        <AsyncComboBox
          md={4}
          label="Tipo de Documento"
          isConcatField
          concatModelName="tipo_documento"
          defaultOptions
          isSearchable
          onChange={(s) =>
            dispatch(setIdTipoDocNfeComplementarDifValor(s?.value ?? null))
          }
          defaultValue={store.id_tipo_doc_nfe_complementar_dif_valor}
          disabled={!store.emite_nfe_complementar_dif_valor}
          ref={idTipoDocNfeComplementarDifValorRef}
        />
        <RadioGroup
          label="Emitir NF-e Complementar por"
          value={store.gerar_nfe_complementar_dif_valor_por}
          onChange={(v) => dispatch(setGerarNfeComplementarDifValorPor(v))}
          disabled={!store.emite_nfe_complementar_dif_valor}
        >
          <RadioItem label="Venda" value="VENDA" />
          <RadioItem label="Dia" value="DIA" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Habilitar Desconto Sobre Total"
          checked={store.habilita_desc_sobre_total}
          onChange={() =>
            dispatch(
              setHabilitaDescSobreTotal(!store.habilita_desc_sobre_total)
            )
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Ordenar Pesquisa de Produtos Por"
          value={store.ordenar_pesq_prod_por}
          onChange={(v) => dispatch(setOrdenarPesqProdPor(v))}
        >
          <RadioItem label="Nome" value="NOM" />
          <RadioItem label="Código" value="COD" />
          <RadioItem label="Classe de Produto" value="CLS" />
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Definição de Preço de Bonificação"
          value={store.forma_define_preco_bonificacao}
          onChange={(v) => dispatch(setFormaDefinePrecoBonificacao(v))}
        >
          <RadioItem label="Fixo Preço de Venda" value="FPVD" />
          <RadioItem label="Fixo Preço Última Venda do Cliente" value="FUVC" />
          <RadioItem
            label="Alterável - Valor maior que o Preço MÍNIMO"
            value="ALTE"
          />
          <RadioItem
            label="Alterável - Valor maior que o Preço de VENDA"
            value="MAPV"
          />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite vincular múltiplos veículos por rota"
          checked={store.vincula_multiplos_veiculos_por_rota}
          onChange={() =>
            dispatch(
              setVinculaMultiplosVeiculosPorRota(
                !store.vincula_multiplos_veiculos_por_rota
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite incluir o cliente em mais de uma rota"
          checked={store.permite_incluir_cliente_mais_de_uma_rota}
          onChange={() =>
            dispatch(
              setPermiteIncluirClienteMaisDeUmaRota(
                !store.permite_incluir_cliente_mais_de_uma_rota
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir estoque da rota no fechamento do dia"
          checked={store.imprimir_estoque_no_encerramento}
          onChange={() =>
            dispatch(
              setImprimirEstoqueNoEncerramento(
                !store.imprimir_estoque_no_encerramento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear solicitação de recarga sem estoque"
          checked={store.bloq_solic_recarga_sem_estoque}
          onChange={() =>
            dispatch(
              setBloqSolicRecargaSemEstoque(
                !store.bloq_solic_recarga_sem_estoque
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Vendedor pode ver o cadastro de produtos através do aplicativo"
          checked={store.ver_cadastro_produto_app}
          onChange={() =>
            dispatch(setVerCadastroProdutoApp(!store.ver_cadastro_produto_app))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir canhoto de entrega da NF-e e Boleto no início da impressão"
          checked={store.imprimir_canhoto_boleto_nf_topo}
          onChange={() =>
            dispatch(
              setImprimirCanhotoBoletoNfTopo(
                !store.imprimir_canhoto_boleto_nf_topo
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Existe redutor no valor de troca"
          checked={store.existe_redutor_vlr_troca}
          onChange={() =>
            dispatch(setExisteRedutorVlrTroca(!store.existe_redutor_vlr_troca))
          }
          hint="Este redutor é utilizado para empresas que cobram o valor de troca dos seus vendedores"
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite utilizar saldo de flex na troca paga"
          checked={store.permite_usar_saldo_flex_na_troca_paga}
          onChange={() =>
            dispatch(
              setPermiteUsarSaldoFlexNaTrocaPaga(
                !store.permite_usar_saldo_flex_na_troca_paga
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Zerar saldo de flex das rotas ao virar o mês"
          checked={store.zerar_flex_ao_virar_mes}
          onChange={() =>
            dispatch(setZerarFlexAoVirarMes(!store.zerar_flex_ao_virar_mes))
          }
        />
      </Row>
    </>
  );
};
