import { apiGet, apiPost, apiPut } from "../../api";
import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/pedido_externo";

const URL_BASE_CENTRAL = `${URL_BASE}/central`;

const CentralPedidosService = {
  listar: async (params) =>
    await apiGetV2(`${URL_BASE_CENTRAL}/listar_pedidos/`, params),
  listarItensEntrega: async (idPedido) =>
    await apiGet(`${URL_BASE_CENTRAL}/listar_itens_entrega/${idPedido}/`),
  entregar: async (payload) =>
    await apiPostV2(`${URL_BASE_CENTRAL}/entregar/`, payload),
  liberar: async (idPedido) =>
    await apiPut(`${URL_BASE_CENTRAL}/liberacao_pedidos/liberar/`, {
      id_pedido: idPedido,
    }),
  buscarDadosLiberacao: async (idPedido) =>
    await apiGet(`${URL_BASE_CENTRAL}/liberacao_pedidos/dados/${idPedido}/`),
  buscarParametrosLiberacao: async () =>
    await apiGet("/tela/central_pedidos/", {}),
  verificarConfiguracoesEntrega: async () =>
    await apiGetV2(
      `${URL_BASE_CENTRAL}/entrega_pedidos/verificar_configuracoes_uso/`
    ),
  buscarDadosAlteracao: async (idPedido) =>
    await apiGet(`${URL_BASE}/buscar/${idPedido}/`),
  naoEntregarRestante: async (payload) =>
    await apiPutV2(`${URL_BASE}/nao_entregar_restante/`, payload),
  verifPodeAlterar: async (id) =>
    await apiGetV2(`${URL_BASE}/verif_pode_alterar/${id}/`),
  alterar: async (payload) => await apiPut(`${URL_BASE}/alterar/`, payload),
  buscarDadosImpressao: async (idPedido) =>
    await apiGet(`${URL_BASE_CENTRAL}/buscar_dados_impressao/${idPedido}/`),
  incluir: async (payload) => await apiPost(`${URL_BASE}/incluir/`, payload),
  cancelar: async (payload) => await apiPut(`${URL_BASE}/cancelar/`, payload),
  enviarEmail: async (idPedido) =>
    await apiPost(`${URL_BASE}/enviar_email/${idPedido}/`),
};

export default CentralPedidosService;
