import moment from "moment";
import unidecode from "unidecode";
import { docPost } from "../../pdf";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/nfe_servico";

const NotaFiscalServicoService = {
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscar: async (idNFSe) => await apiGetV2(`${URL_BASE}/buscar/${idNFSe}/`),
  buscarAlertas: async (idNFSe) =>
    await apiGetV2(`${URL_BASE}/buscar_alertas/${idNFSe}/`),
  buscarDadosCliNfse: async (idCliente) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_cli_nfse/${idCliente}/`),
  buscarNaturezasOperacao: async () =>
    await apiGetV2(
      `${URL_BASE}/buscar_naturezas_operacao/`,
      {},
      { errorMessageType: "toastr" }
    ),
  calcularImpostos: async (vlrServico, idNaturezaOperacao) =>
    await apiPostV2(
      `${URL_BASE}/calcular_impostos/`,
      {
        vlr_servico: vlrServico,
        id_natureza_operacao: idNaturezaOperacao,
      },
      { successMessage: false }
    ),
  cancelarNFSe: async (id, observ) =>
    await apiPutV2(`${URL_BASE}/cancelar/`, { id_nfse: id, observ: observ }),
  descartarDigitacao: async (id) =>
    await apiDeleteV2(`${URL_BASE}/descartar_digitacao/${id}/`),
  emitirNFSe: async (id) =>
    await apiPostV2(`${URL_BASE}/emitir/`, { id_nfse: id }),
  enviarEmail: async (idNfse, formData) =>
    await apiPostV2(`${URL_BASE}/email/enviar/${idNfse}/`, formData),
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  imprimirNFSe: async (id) => {
    const [ok, ret] = await apiGetV2(`${URL_BASE}/dados_impressao/${id}/`, {});

    if (!ok) return [false, false];

    if (ret.link_download_pdf) {
      window.open(ret.link_download_pdf, "_blank");
      return [false, false];
    }

    const arq = await docPost(
      "/danfse/",
      { ...ret, layout: 1 },
      {
        errorMessage: "Erro ao Gerar DANFSe",
      }
    );

    if (!arq) return [false, false];

    const fileName = `NFSe_${ret.numero_nfse}_${unidecode(
      ret.tomador.razao_social
    ).replaceAll(" ", "_")}_${moment(
      ret.data_hora_emissao.split(" ")[0],
      "DD/MM/YYYY"
    ).format("YYYYMMDD")}.pdf`;

    return [arq, fileName];
  },
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
};

export default NotaFiscalServicoService;
