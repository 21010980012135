import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FiltroPeriodoDatas,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { formatDateISO } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

const formasRec = [
  { label: "Boleto", value: "BOLE" },
  { label: "Dinheiro", value: "DINH" },
  { label: "Crédito Rotativo", value: "ROTA" },
  { label: "PIX", value: "PIX" },
];

export const VendasFormaRec = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [cliente, setCliente] = useState(null);
  const [rota, setRota] = useState(null);
  const [formaRec, setFormaRec] = useState(null);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);

    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      id_rota: rota,
      forma_rec: formaRec,
    };

    const [ok, ret] = await apiGetV2(
      `/relatorios/venda_direta/vendas_forma_recebimento/`,
      params
    );

    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/vendas_forma_rec/",
        {
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          dados: ret,
        },
        "5039"
      );
    }

    setLoadingImprimir(false);
  };

  return (
    <PageContainer
      title="Vendas por Forma de Recebimento"
      number="5039"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={5}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setCliente(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Rota"
            isConcatField
            concatModelName="rota_mob"
            isSearchable
            isClearable
            onChange={(s) => setRota(s?.value)}
          />
          <ComboBox
            md={3}
            label="Forma de Recebimento"
            options={formasRec}
            onChange={(s) => setFormaRec(s?.value)}
            isSearchable
            isClearable
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
    </PageContainer>
  );
};
