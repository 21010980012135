import React, { useRef, useState } from "react";
import {
  DatePicker,
  IntegerFormInput,
  ModalBase,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import PesqCliente from "../../../../../components/form/pesq_cliente/PesqCliente";
import {
  buscarDadosEmpresaCookies,
  formatDateLocal,
  viewDownloadPDF,
} from "../../../../../coreUtils";
import { docPost } from "../../../../../pdf";
import moment from "moment";
import { showWarning } from "../../../../../components/AlertaModal";

export const ImprimirLayout36Modal = ({ isOpen, toggle }) => {
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState(null);
  const [qtdProduto, setQtdProduto] = useState(null);
  const [dataFabric, setDataFabric] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [loading, setLoading] = useState(false);
  const [qtdEtiquetas, setQtdEtiquetas] = useState(1);

  const pesqProdRef = useRef();
  const qtdEtiquetasRef = useRef();

  const limparDados = () => {
    setIdProduto(null);
    setNomeProduto(null);
    setQtdProduto(null);
    setDataFabric(null);
    setIdCliente(null);
    setNomeCliente("");
    setQtdEtiquetas(1);
  };

  const handleSelectProduto = async (id, nome, referencia) => {
    if (id) {
      setIdProduto(id);
      setNomeProduto(nome);
      if (pesqProdRef.current) {
        pesqProdRef.current.setId(String(id));
        pesqProdRef.current.setDescricao(nome);
        pesqProdRef.current.setReferencia(String(referencia));
      }
    } else {
      setIdProduto(null);
      setNomeProduto(null);
      if (pesqProdRef.current) {
        pesqProdRef.current.clear();
      }
    }
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, informe o Produto");
      if (pesqProdRef.current) {
        pesqProdRef.current.focus();
      }
      return;
    }

    if ([0, null, undefined].includes(qtdEtiquetas)) {
      showWarning(
        "Por favor, informe a Quantidade de Etiquetas a serem impressas"
      );
      if (qtdEtiquetasRef.current) {
        qtdEtiquetasRef.current.focus();
      }
      return;
    }

    setLoading(true);
    const payload = {
      layout: 36,
      dados_empresa: await buscarDadosEmpresaCookies(),
      etiquetas: [
        {
          id_cliente: idCliente,
          nome_cliente: nomeCliente,
          id: idProduto,
          nome: nomeProduto,
          data_fabric:
            dataFabric instanceof Date ? formatDateLocal(dataFabric) : null,
          qtd_produto: qtdProduto,
          qtd_etiquetas: qtdEtiquetas,
        },
      ],
    };

    const res = await docPost("/produto/etiqueta/", payload, {
      errorMessage: "Falha ao gerar impressão de etiquetas",
    });
    if (res) {
      viewDownloadPDF(
        res,
        `etq_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
      );
    }

    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Impressão de Etiqueta"
      number="0073_2"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <PesqCliente
          md={12}
          onConfirm={(id, nome) => {
            setIdCliente(id);
            setNomeCliente(nome);
          }}
          selectNextField={() => {}}
          onChangeDescricao={setNomeCliente}
          idCliente={idCliente}
          nomeCliente={nomeCliente}
        />
      </Row>
      <Row>
        <PesqProduto
          md={12}
          label="Produto"
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          selectNextField={() => {}}
          onChangeDescricao={() => {}}
          mdIdent={3}
          mdDesc={7}
          mdAux={2}
        />
      </Row>
      <Row>
        <TextInput
          md={4}
          label="Qtd/Unidades Produto"
          value={qtdProduto}
          onChange={(e, v) => setQtdProduto(v)}
        />
        <DatePicker
          md={4}
          label="Data de Fabricação"
          value={dataFabric}
          onChange={(v) => setDataFabric(moment.isMoment(v) ? v.toDate() : v)}
        />
        <IntegerFormInput
          md={4}
          label="Qtd. Etiquetas"
          defaultValue={qtdEtiquetas}
          onChange={setQtdEtiquetas}
          ref={qtdEtiquetasRef}
        />
      </Row>
    </ModalBase>
  );
};
