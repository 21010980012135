import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  FixedField,
  FormButton,
  NumberInput,
} from "../../../../../../components";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import { formatNumber, roundFloat } from "../../../../../../coreUtils";
import { Row } from "reactstrap";
import { useEffect } from "react";

export const IncluirProdutoOrdemCompraForm = forwardRef(
  ({ incluirItem, semFornecedor }, ref) => {
    // Dados do Produto
    const [idProduto, setIdProduto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [referencia, setReferencia] = useState("");
    const [unidade, setUnidade] = useState("");
    const [quantidade, setQuantidade] = useState(1);
    const [custoUe, setCustoUe] = useState(0);
    const [vlrItem, setVlrItem] = useState(0);
    const [vlrTotal, setVlrTotal] = useState(0);
    //Controle
    const quantidadeRef = useRef();
    const refPesqProd = useRef();
    const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
    const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(2);

    const [loadingIncluir, setLoadingIncluir] = useState(false);
    const semProduto = [0, null, undefined].includes(idProduto);

    const limparDadosProduto = () => {
      setIdProduto(0);
      setDescricao("");
      setReferencia("");
      setUnidade("");
      setQuantidade(1);
      setCustoUe(0);
      setVlrItem(0);
      setVlrTotal(0);
      setQtdCasasDecimaisQtd(2);
      setQtdCasasDecimaisVlrs(2);
      if (refPesqProd.current) {
        refPesqProd.current.clear();
      }
    };

    const selectNextField = () => {
      setTimeout(() => {
        if (quantidadeRef.current) {
          quantidadeRef.current.focus();
        }
      }, 35);
    };

    const handleSelectProduto = (
      id,
      nome,
      referencia,
      precoVenda,
      unidade,
      custoUe,
      cfop,
      clasFiscal,
      curinga,
      quantidade,
      codBar,
      qtdCasasDecQtdProd = 2,
      qtdCasasDecVlrsProd = 2
    ) => {
      if (idProduto !== id) {
        if (["", 0, null, undefined].includes(id)) {
          limparDadosProduto();
          return;
        }

        setIdProduto(id);
        setDescricao(nome);
        setQuantidade(1);
        setUnidade(unidade);
        setReferencia(referencia);
        setCustoUe(custoUe);
        setVlrItem(custoUe);
        setVlrTotal(custoUe);
        setQtdCasasDecimaisQtd(qtdCasasDecQtdProd);
        setQtdCasasDecimaisVlrs(qtdCasasDecVlrsProd);

        selectNextField();
      }
    };

    const handleSetQuantidade = async (v) => {
      setQuantidade(v);
      setVlrTotal(roundFloat(vlrItem * v, 2));
    };

    const handleSetVlrItem = async (v) => {
      setVlrItem(v);
      setVlrTotal(roundFloat(v * quantidade, 2));
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      },
    }));

    useEffect(() => {
      if (refPesqProd.current && idProduto) {
        refPesqProd.current.setId(String(idProduto));
        refPesqProd.current.setDescricao(descricao);
        refPesqProd.current.setReferencia(String(referencia));
      }
    }, [refPesqProd.current, idProduto]);

    const inclusaoItem = async () => {
      setLoadingIncluir(true);
      try {
        const payload = {
          id_produto: idProduto,
          nome_produto: descricao,
          quantidade: quantidade,
          vlr_item_compra: vlrItem,
          vlr_total_compra: vlrTotal,
        };

        if (await incluirItem(payload)) {
          limparDadosProduto();
          if (refPesqProd.current) {
            refPesqProd.current.focus();
          }
        }
      } finally {
        setLoadingIncluir(false);
      }
    };

    return (
      <>
        <Row>
          <PesqProduto
            md={8}
            onConfirm={handleSelectProduto}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={setDescricao}
            mdIdent={3}
            mdDesc={7}
            mdIdentAux={2}
            disabled={semFornecedor}
            auxAsLabel
          />
          <FixedField
            divClassName="pt-4"
            md="auto"
            label="Custo"
            value={formatNumber(custoUe, true, 2)}
            horizontal
          />
          <FixedField
            divClassName="pt-4"
            md="auto"
            label="Unidade"
            value={unidade}
            horizontal
          />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Quantidade"
            value={quantidade}
            onChange={handleSetQuantidade}
            disabled={semProduto}
            decimalPlaces={qtdCasasDecimaisQtd}
            ref={quantidadeRef}
          />
          <NumberInput
            md={2}
            label="Custo do Produto"
            value={vlrItem}
            onChange={handleSetVlrItem}
            disabled={semProduto}
            decimalPlaces={qtdCasasDecimaisVlrs}
          />
          <NumberInput md={2} label="Vlr. Total" value={vlrTotal} disabled />
          <FormButton
            color="info"
            onClick={inclusaoItem}
            loading={loadingIncluir}
            disabled={semProduto || semFornecedor}
            disabledHint="Informe o Fornecedor e o Produto"
          >
            Incluir
          </FormButton>
        </Row>
      </>
    );
  }
);
