import React, { useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  Table,
  TableCheck,
} from "../../../../../components";
import FormButton from "../../../../../components/FormButton";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalExcluir } from "../../../../../components/ModalExcluir";
import CadastroListaPrecosService from "../../../../../services/cadastro/CadastroListaPrecosService";
import { optionsListaPrecoPara } from "../ListaPrecos";

export const DetalhesListaPara = ({
  idCab,
  nomeLista,
  tipoListaPara,
  definirTipoListaPara,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [para, setPara] = useState(null);
  const [idPara, setIdPara] = useState(null);
  const [permiteAlterarPreco, setPermiteAlterarPreco] = useState(false);
  const [listaCliente, setListaCliente] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [alterouAlgo, setAlterouAlgo] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    setPara(tipoListaPara);
  }, [tipoListaPara]);

  const columns = [
    {
      dataField: "desc_para",
      text: "Tipo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_para",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_para",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "permite_alterar_preco",
      text: "Permite Alterar Preço",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "act_delete",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <ModalExcluir
          notifyEvent={() => {
            setAlterouAlgo(true);
            buscarDados();
          }}
          route={`/cadastro/lista_preco/para/excluir/${row.id}/`}
          selected={row.id}
          title="Lista de Preços"
          number="0013_71"
          table
          message={`Tem certeza de que deseja excluir o registro da lista ${nomeLista}?`}
        />
      ),
    },
  ];

  const limparDados = () => {
    setListaCliente([]);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      setIdPara(null);
      if (alterouAlgo) {
        notifyEvent();
      }
    }
    setIsOpen(!isOpen);
    setAlterouAlgo(false);
    limparDados();
  };

  const buscarDados = async () => {
    const [ok, ret] = await CadastroListaPrecosService.para.listar(idCab);
    if (ok) {
      setListaCliente(ret);
      if (ret?.length === 0) {
        definirTipoListaPara(null);
      }
    }
  };

  const handleSubmit = async () => {
    if (idCab !== null) {
      let data = {
        id_cab: idCab,
        para: para,
        id_para: idPara,
        permite_alterar_preco: permiteAlterarPreco,
      };

      setLoadingAdd(true);

      if (await CadastroListaPrecosService.para.incluir(data)) {
        buscarDados();
        definirTipoListaPara(para);
        setAlterouAlgo(true);
      }

      setLoadingAdd(false);
      selectRef.current.clearValue();
      selectRef.current.setFocus();
    }
  };

  const descricaoItens =
    {
      CLIENTE: "Clientes da Lista",
      ROTA: "Rotas (APP Pronta Entrega) da Lista",
      VENDEDOR: "Vendedores (APP Pedidos) da Lista",
      LISTA_SELECT: "Lista Selecionável (APP Pedidos)",
    }[tipoListaPara] ?? "Itens";

  const labelPara =
    {
      CLIENTE: "Cliente",
      ROTA: "Rota",
      VENDEDOR: "Vendedor",
      LISTA_SELECT: "Lista Selecionável",
    }[para] ?? "Selecione o Tipo";

  const concatModelNamePara = {
    LISTA_SELECT: null,
    CLIENTE: "cliente",
    ROTA: "rota_mob",
    VENDEDOR: "colaborador",
  }[para];

  return (
    <React.Fragment>
      <FormButton
        md="auto"
        color="info"
        disabled={[0, null, undefined].includes(idCab)}
        onClick={toggle}
      >
        {descricaoItens}
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={descricaoItens}
        number="0013_7"
      >
        <Row>
          <ComboBox
            md={4}
            label="Tipo"
            options={optionsListaPrecoPara}
            onChange={(s) => {
              setPara(s?.value);
              setIdPara(null);
              selectRef.current.clearValue();
              if (s?.value === "CLIENTE") {
                setPermiteAlterarPreco(false);
              }
            }}
            defaultValue={para}
            isSearchable={false}
            isClearable
            isDisabled={tipoListaPara}
          />
        </Row>
        <Row className="mb-3">
          <AsyncComboBox
            label={labelPara}
            md={7}
            isConcatField
            concatModelName={concatModelNamePara}
            isSearchable
            isClearable
            onChange={(v) => {
              setIdPara(v?.value);
            }}
            autoFocus
            ref={selectRef}
            disabled={para === "LISTA_SELECT"}
            hideShortcut
            clearOnDisable
          />
          <FormCheckbox
            label="Permite Alteração de Preço"
            checked={permiteAlterarPreco}
            onChange={() => setPermiteAlterarPreco(!permiteAlterarPreco)}
            disabled={para === "CLIENTE"}
          />
          <FormButton
            color="success"
            loading={loadingAdd}
            onClick={handleSubmit}
            disabled={[null, undefined].includes(idPara)}
            disabledHint="Selecione um item no campo à esquerda"
          >
            Adicionar à lista
          </FormButton>
        </Row>
        <Table data={listaCliente} columns={columns} paginated={false} />
      </ModalBase>
    </React.Fragment>
  );
};
