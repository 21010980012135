import React from "react";
import { Info } from "react-feather";
import { Card, Row, UncontrolledTooltip } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { EmailBoletosModal } from "./EmailBoletosModal";
import { ImprimirBoletosModal } from "./ImprimirBoletosModal";
import { Table, TableCheck } from "../../../../../components";

export const ImpressaoBoletosGrid = ({
  dados,
  selectedIDs,
  handleOnSelect,
  handleOnSelectAll,
  selectedDuplics,
  notifyAction,
  disabledButtons,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "id_cab__id_cliente__nome",
      text: "Cliente",
      sortable: true,
      align: "left",
      cellContentTag: null,
      formatter: (cell, row) =>
        ["", null, undefined].includes(row.id_cab__id_cliente__email) ? (
          <>
            <span>
              {cell}
              <Info
                size={12}
                color={"red"}
                id={`cliente-titulo-${row.id}`}
                style={{ marginTop: "-2px" }}
              />
            </span>{" "}
            <UncontrolledTooltip
              placement="top"
              target={`cliente-titulo-${row.id}`}
            >
              {["", null, undefined].includes(row.id_cab__id_cliente__email)
                ? "Cliente sem e-mail"
                : row.id_cab__id_cliente__email}
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <span id={`cliente-titulo-${row.id}`}>{cell}</span>
            <UncontrolledTooltip
              placement="top"
              target={`cliente-titulo-${row.id}`}
            >
              {row.id_cab__id_cliente__email}
            </UncontrolledTooltip>
          </>
        ),
    },
    {
      dataField: "numero",
      text: "Número/Parcela",
      sortable: true,
      align: "center",
      formatter: (cell, row) => `${cell}-${row.parcela}`,
    },
    {
      dataField: "id_cab__carteira",
      text: "Carteira",
      sortable: true,
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "data_emi",
      text: "Emissão",
      sortable: true,
      align: "center",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      sortable: true,
      align: "center",
    },
    {
      dataField: "data_impr",
      text: "Impressão",
      sortable: true,
      align: "center",
    },
    {
      dataField: "banco_impr",
      text: "Banco",
      sortable: true,
      align: "center",
      formatter: (cell, row) => {
        return cell ? cell + " - " + row.conta_banc : "";
      },
    },
    {
      dataField: "vlr_receber",
      text: "Valor",
      sortable: true,
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortFunc: naturalSort,
    },
  ];

  return (
    <Card body>
      <Row className="mb-2">
        <ImprimirBoletosModal
          selected={selectedDuplics}
          disabledButtons={disabledButtons}
          notifyAction={notifyAction}
        />
        <EmailBoletosModal
          duplics={selectedIDs}
          disabledButtons={
            disabledButtons ||
            selectedDuplics.some((item) =>
              [0, null, undefined].includes(item.id_conta)
            )
          }
          notifyAction={notifyAction}
        />
      </Row>
      <Table
        data={dados}
        columns={columns}
        multiselect
        onSelect={handleOnSelect}
        onSelectAll={handleOnSelectAll}
        selected={selectedIDs}
      />
    </Card>
  );
};
