import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap/lib";
import {
  PageContainer,
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  LabelButton,
} from "../../../../components";
import NotaFiscalConsumidorService from "../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { NotaFiscalConsumidorGrid } from "./components/NotaFiscalConsumidorGrid";
import { MODAL_ACTIONS, downloadFileFromBlob } from "../../../../coreUtils";
import { AjustarDuplicidadeNFCeModal } from "./components/AjustarDuplicidadeNFCeModal";
import { toastr } from "react-redux-toastr";
import { AlertasNFCeModal } from "./components/AlertasNFCeModal";
import { BotaoIncluir } from "../../../../components/cadastro";
import { incluirAlterarNFConsRoute } from "../../../../routes/modules/docsEletron";
import { useHistory } from "react-router-dom";
import { DetalhesNFCeModal } from "./components/DetalhesNFCeModal";
import { CancelarNFCeModal } from "./components/CancelarNFCeModal";
import { InutilizarNFCeModal } from "./components/InutilizarNFCeModal";
import { DescartarNFCeModal } from "./components/DescartarNFCeModal";
import { ExportarNfConsModal } from "./components/ExportarNfConsModal";

const situacoes = [
  { label: "Todas as situações", value: "ALL" },
  { label: "Aguardando Emissão", value: "AGE" },
  { label: "Enviadas e Validadas SEFAZ", value: "EMI" },
  { label: "Canceladas", value: "CAN" },
  { label: "Inutilizadas", value: "INU" },
  { label: "Denegadas", value: "DEN" },
  { label: "Digitação Incompleta", value: "DIG" },
];

const periodos = [
  { label: "Emissão", value: "EMI" },
  { label: "Emissão na SEFAZ", value: "SEF" },
];

const origens = [
  { label: "Venda", value: "VFX" },
  { label: "Inclusão Manual", value: "MAN" },
  { label: "Ordem de Serviço", value: "VOS" },
  { label: "Lote de Faturamento", value: "LOT" },
];

export const NotaFiscalConsumidor = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [cliente, setCliente] = useState(null);
  const [numero, setNumero] = useState(null);
  const [numeroOrigem, setNumeroOrigem] = useState(null);
  const [origem, setOrigem] = useState(origens.value);
  const [situacao, setSituacao] = useState(situacoes[1].value);
  const [periodo, setPeriodo] = useState(periodos[0].value);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  // Controle
  const [selected, setSelected] = useState([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingEmitir, setLoadingEmitir] = useState(false);
  const [lastSelected, setLastSelected] = useState(null);
  const [alertasOpen, setAlertasOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const [ajustarDuplicidadeOpen, setAjustarDuplicidadeOpen] = useState(false);
  const [descartarOpen, setDescartarOpen] = useState(false);
  const [inutilizarOpen, setInutilizarOpen] = useState(false);
  const [exportarIsOpen, setExportarIsOpen] = useState(false);
  const history = useHistory();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setSelected([]);
    setLoadingPesquisar(true);
    const [ok, ret] = await NotaFiscalConsumidorService.gerenciamento.listar({
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      numero: numero,
      situacao: situacao,
      periodo: periodo,
      origem: origem,
      nro_origem: numeroOrigem,
    });
    setDados(ok ? ret.notas : []);
    setTotais(ok ? ret.totais : {});
    setLoadingPesquisar(false);
  };

  const emitir = async () => {
    setLoadingEmitir(true);
    const sel = dados.filter((e) => selected.includes(e.id)).map((e) => e.id);
    for (let i = 0; i < sel.length; i++) {
      const [ok] = await NotaFiscalConsumidorService.gerenciamento.emitir(
        sel[i]
      );
      if (ok) {
        await NotaFiscalConsumidorService.imprimir(sel[i]);
      }
    }
    await carregarDados();
    setLoadingEmitir(false);
  };

  const downloadXML = async (id) => {
    const ret = await NotaFiscalConsumidorService.gerenciamento.downloadXML(id);

    if (ret) {
      const sel = dados.find((e) => e.id === id);
      const filename = sel.numero + "-" + sel.serie;
      downloadFileFromBlob(ret, filename);
    }
  };

  const imprimir = async (id) =>
    await NotaFiscalConsumidorService.imprimir(id, false);

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
  };

  const toggleAjustarDuplicidade = async (id) =>
    setAjustarDuplicidadeOpen(!ajustarDuplicidadeOpen);

  const toggleAlertas = () => setAlertasOpen(!alertasOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const toggleInutilizar = () => setInutilizarOpen(!inutilizarOpen);

  const toggleDescartar = () => setDescartarOpen(!descartarOpen);

  const toggleExportar = () => setExportarIsOpen(!exportarIsOpen);

  const alertasNFCe = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleAlertas();
    }, 1);
  };

  const verDetalhesNFCe = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const ajustarDuplicidade = async (id) => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarStatus(id);
    if (!ok) return;

    if (ret.status === "A") {
      setLastSelected(id);
      setTimeout(() => {
        toggleAjustarDuplicidade();
      }, 1);
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel Ajustar Duplicidade de NFC-e's não emitidas."
      );
    }
  };

  const incluirNFCe = () => {
    history.push(incluirAlterarNFConsRoute.path, {
      action: MODAL_ACTIONS.ADD,
    });
  };

  const alterarNFCe = async (id) => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarStatus(id);
    if (!ok) return;

    if (["A", "I", "S"].includes(ret.status)) {
      history.push(incluirAlterarNFConsRoute.path, {
        action: MODAL_ACTIONS.EDIT,
        selected: id,
      });
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel Alterar NFC-e's não emitidas."
      );
    }
  };

  const cancelarNFCe = async (id) => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarStatus(id);
    if (!ok) return;

    if (ret.status === "E") {
      setLastSelected(id);
      setTimeout(() => {
        toggleCancelar();
      }, 1);
    } else if (ret.status === "C") {
      toastr.warning("Atenção", "A NFC-e já foi cancelada.");
    } else {
      toastr.warning("Atenção", "Somente é possivel cancelar NFCe-s emitidas.");
    }
  };

  const inutilizarNFCe = async (id) => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarStatus(id);
    if (!ok) return;

    if (["A", "S"].includes(ret.status)) {
      setLastSelected(id);
      setTimeout(() => {
        toggleInutilizar();
      }, 1);
    } else if (ret.status === "U") {
      toastr.warning("Atenção", "A NFC-e já foi inutilizada.");
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel inutilizar NFC-e's Aguardando Emissão."
      );
    }
  };

  const descartarNFCe = async (id) => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarStatus(id);
    if (!ok) return;

    if (["S", "I"].includes(ret.status)) {
      setLastSelected(id);
      setTimeout(() => {
        toggleDescartar();
      }, 1);
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel descartar NFC-e's Em Digitação (Salvas) " +
          "ou com Digitação Incompleta."
      );
    }
  };

  return (
    <PageContainer title="Nota Fiscal Consumidor" number="0066" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            name="cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setCliente(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="Número NFC-e"
            value={numero}
            onChange={setNumero}
          />
          <ComboBox
            options={origens}
            md={2}
            label="Origem"
            onChange={(s) => setOrigem(s?.value)}
            defaultValue={origem}
          />
          <IntegerFormInput
            md={2}
            label="Número de origem"
            value={numeroOrigem}
            onChange={setNumeroOrigem}
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            options={periodos}
            label="Período"
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={periodo}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            options={situacoes}
            md={3}
            label="Situação"
            onChange={(s) => setSituacao(s?.value)}
            defaultValue={situacao}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} padded={false} />
          <BotaoIncluir toggle={incluirNFCe} padded={false} />
          <FormButton
            md="auto"
            color="success"
            padded={false}
            onClick={emitir}
            loading={loadingEmitir}
            disabled={
              selected?.length === 0 ||
              dados.find((e) => selected.includes(e.id) && e.status !== "A")
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFCe da lista."
                : dados.find((e) => selected.includes(e.id) && e.status !== "A")
                ? "Selecione somente NFCe's Aguardando Emissão."
                : ""
            }
          >
            Emitir
          </FormButton>
          <LabelButton divClassName="ml-auto pt-1" onClick={toggleExportar} padded={false}>
            Exportar
          </LabelButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade"
          value={totais?.quantidade}
          checkFloat={true}
          className="col-md-2 ml-auto"
        />
        <CardTotaisItem
          label="Valor Total"
          value={totais?.valor_total}
          className="col-md-2"
        />
      </CardTotais>
      <Card body>
        <NotaFiscalConsumidorGrid
          data={dados}
          selected={selected}
          setSelected={handleSetSelected}
          onSelectAll={handleSelectAll}
          downloadXML={downloadXML}
          imprimir={imprimir}
          ajustarDuplicidade={ajustarDuplicidade}
          alterarNFCe={alterarNFCe}
          mostrarAlertas={alertasNFCe}
          verDetalhesNFCe={verDetalhesNFCe}
          cancelarNFCe={cancelarNFCe}
          inutilizarNFCe={inutilizarNFCe}
          descartarNFCe={descartarNFCe}
        />
        <AjustarDuplicidadeNFCeModal
          isOpen={ajustarDuplicidadeOpen}
          toggle={toggleAjustarDuplicidade}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <DetalhesNFCeModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          selected={lastSelected}
        />
        <CancelarNFCeModal
          isOpen={cancelarOpen}
          toggle={toggleCancelar}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <InutilizarNFCeModal
          isOpen={inutilizarOpen}
          toggle={toggleInutilizar}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <DescartarNFCeModal
          isOpen={descartarOpen}
          toggle={toggleDescartar}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <AlertasNFCeModal
          isOpen={alertasOpen}
          toggle={toggleAlertas}
          selected={lastSelected}
        />
        <ExportarNfConsModal isOpen={exportarIsOpen} toggle={toggleExportar} />
      </Card>
    </PageContainer>
  );
};
