import React from "react";
import { useState } from "react";
import {
  modalTitleCartaoTaxasAdm,
  routesBaseCartaoTaxasAdm,
} from "../CartaoTaxasAdm";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  NumberInput,
  TabController,
  TextInput,
} from "../../../../../components";
import { Col, Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { TabBody } from "../../../../../components/TabController";

const prefixoKeyParc = "perc_parc_";
const prefixoKeyAntecipa = "perc_antecipa_parc_";

const gerarObjParc = (prefixo) =>
  Array(35)
    .fill(0)
    .reduce((acc, e, index) => ({ ...acc, [`${prefixo}${index + 2}`]: e }), {});

const objParcLimpo = gerarObjParc(prefixoKeyParc);
const objAntecipaLimpo = gerarObjParc(prefixoKeyAntecipa);

const CamposPercs = ({
  percCredVista,
  setPercCredVista,
  percDebito,
  setPercDebito,
  objPercsLimpo,
  prefixoPercs,
  percs,
  setPercs,
}) => (
  <Row>
    <Col md={10}>
      <Row>
        <Col md={12}>
          <h5>Crédito</h5>
        </Col>
        <NumberInput
          label="À Vista"
          md={2}
          onChange={setPercCredVista}
          value={percCredVista}
          isPercentage
        />
        {Object.keys(objPercsLimpo).map((key) => (
          <NumberInput
            md={2}
            label={`${key.replace(prefixoPercs, "")}x`}
            value={percs[key]}
            onChange={(v) => setPercs({ ...percs, [key]: v })}
          />
        ))}
      </Row>
    </Col>
    <Col md={2}>
      <Col md={12} className="pl-2 pr-0">
        <h5>Débito</h5>
      </Col>
      <NumberInput
        label="À Vista"
        md={12}
        onChange={setPercDebito}
        value={percDebito}
        isPercentage
      />
    </Col>
  </Row>
);

const tipoOptions = [
  { label: "Normal", value: "NOR" },
  { label: "Taxa sobre Taxa", value: "TST" },
];

export const CadastroCartaoTaxasAdmModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [idMeioPagto, setIdMeioPagto] = useState(null);
  const [idBandeira, setIdBandeira] = useState(null);
  const [tipo, setTipo] = useState(tipoOptions[0].value);
  const [percCredVista, setPercCredVista] = useState(0);
  const [percDebito, setPercDebito] = useState(0);
  const [percParc, setPercParc] = useState(objParcLimpo);
  const [percAntecipaCredVista, setPercAntecipaCredVista] = useState(0);
  const [percAntecipaDebito, setPercAntecipaDebito] = useState(0);
  const [percAntecipa, setPercAntecipa] = useState(objAntecipaLimpo);

  const limparDados = () => {
    setNome("");
    setIdMeioPagto(null);
    setIdBandeira(null);
    setTipo(tipoOptions[0].value);
    setPercCredVista(0);
    setPercDebito(0);
    setPercParc(objParcLimpo);
    setPercAntecipaCredVista(0);
    setPercAntecipaDebito(0);
    setPercAntecipa(objAntecipaLimpo);
  };

  const fetchData = (data) => {
    const assignObject = (objLimpo) =>
      Object.keys(objLimpo).reduce(
        (acc, key) => ({ ...acc, [key]: parseFloat(data[key] ?? 0) }),
        {}
      );

    setNome(data.nome);
    setIdMeioPagto(data.id_meio_pagto);
    setIdBandeira(data.id_bandeira);
    setPercCredVista(parseFloat(data.perc_cred_vista ?? 0));
    setPercDebito(parseFloat(data.perc_debito ?? 0));
    setPercParc(assignObject(objParcLimpo));
    setPercAntecipaCredVista(parseFloat(data.perc_antecipa_cred_vista ?? 0));
    setPercAntecipaDebito(parseFloat(data.perc_antecipa_debito ?? 0));
    setPercAntecipa(assignObject(objAntecipaLimpo));
    handleSetTipo(data.tipo);
  };

  const handleSetTipo = (v) => {
    setTipo(v);
    if (v === "NOR") {
      setPercAntecipaCredVista(0);
      setPercAntecipaDebito(0);
      setPercAntecipa(objAntecipaLimpo);
    }
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Por favor, informe o Nome da Taxa");
      return false;
    }

    if ([0, null, undefined].includes(idBandeira)) {
      toastr.warning("Atenção", "Por favor, informe a Bandeira");
      return false;
    }

    if ([0, null, undefined].includes(idMeioPagto)) {
      toastr.warning("Atenção", "Por favor, informe o Meio de Pagamento");
      return false;
    }

    const payload = {
      nome: nome,
      id_meio_pagto: idMeioPagto,
      id_bandeira: idBandeira,
      tipo: tipo,
      perc_cred_vista: percCredVista,
      perc_debito: percDebito,
      ...percParc,
      perc_antecipa_cred_vista: percAntecipaCredVista,
      perc_antecipa_debito: percAntecipaDebito,
      ...percAntecipa,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_taxa: selected, ...payload };
  };

  const camposPercParc = (
    <CamposPercs
      percCredVista={percCredVista}
      setPercCredVista={setPercCredVista}
      percDebito={percDebito}
      setPercDebito={setPercDebito}
      objPercsLimpo={objParcLimpo}
      prefixoPercs={prefixoKeyParc}
      percs={percParc}
      setPercs={setPercParc}
    />
  );

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCartaoTaxasAdm}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseCartaoTaxasAdm}
      number="0003_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={7}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={60}
          autoFocus
        />
        <FormCheckbox
          label="Tem antecipação"
          checked={tipo === "TST"}
          onChange={() => setTipo(tipo === "TST" ? "NOR" : "TST")}
        />
      </Row>
      <Row className="mb-3">
        <AsyncComboBox
          md={4}
          label="Bandeira"
          isConcatField
          concatModelName="administradora_cartao"
          defaultOptions
          isSearchable
          onChange={(s) => setIdBandeira(s?.value)}
          defaultValue={idBandeira}
        />
        <AsyncComboBox
          md={4}
          label="Meio de Pagamento"
          isConcatField
          concatModelName="meio_pagamento"
          defaultOptions
          isSearchable
          onChange={(s) => setIdMeioPagto(s?.value)}
          defaultValue={idMeioPagto}
        />
      </Row>
      <div style={{ minHeight: "414px" }}>
        {tipo === "TST" ? (
          <TabController>
            <TabBody title="Parcelamento">{camposPercParc}</TabBody>
            <TabBody title="Antecipação">
              <CamposPercs
                percCredVista={percAntecipaCredVista}
                setPercCredVista={setPercAntecipaCredVista}
                percDebito={percAntecipaDebito}
                setPercDebito={setPercAntecipaDebito}
                objPercsLimpo={objAntecipaLimpo}
                prefixoPercs={prefixoKeyAntecipa}
                percs={percAntecipa}
                setPercs={setPercAntecipa}
              />
            </TabBody>
          </TabController>
        ) : (
          <div className="px-2">{camposPercParc}</div>
        )}
      </div>
    </ModalCadastroV2>
  );
};
