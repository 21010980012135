import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import CamposPesq from "./CamposPesq";
import ModalPesqProduto from "./ModalPesqProduto";
import CadastroProdutosService from "../../../services/cadastro/CadastroProdutosService";
import { apiGetV2 } from "../../../apiV2";

const PesqProduto = forwardRef(
  (
    {
      onConfirm,
      selectNextField,
      md = 6,
      onChangeDescricao,
      autoFocus,
      mdIdent = 4,
      mdDesc = 6,
      mdIdentAux = 2,
      showShortcut,
      selected,
      disabled,
      disabledHint,
      auxAsLabel,
      label,
      divClassName,
      mostrarAux = true,
      habilitaPesqReferencia = true,
      podeAlterarNomeProdCuringa = true,
      idCliente,
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pesqPorReferencia, setPesqPorReferencia] = useState(false);
    const [sistAutomotivo, setSistAutomotivo] = useState(false);
    const [utilizaCorProd, setUtilizaCorProd] = useState(false);
    const [utilizaTamanhoProd, setUtilizaTamanhoProd] = useState(false);
    const [utilizaPrecoAtacado, setUtilizaPrecoAtacado] = useState(false);
    const [tipoVendaCliente, setTipoVendaCliente] = useState(null);
    const [pistaPesq, setPistaPesq] = useState("");
    const [prodCuringa, setProdCuringa] = useState(false);
    const refDescricao = useRef();
    const refIdentificador = useRef();
    const refReferencia = useRef();
    const refIdentificadorAux = useRef();
    const refReferenciaAux = useRef();

    const buscarParametros = async () => {
      const params = { id_cliente: idCliente };
      const [ok, ret] = await apiGetV2("/tela/pesq_produto/", params);
      if (ok) {
        setPesqPorReferencia(
          habilitaPesqReferencia && ret.priorizar_pesq_prod === "REF"
        );
        setSistAutomotivo(ret.tipo_sistema === "MECANICA");
        setUtilizaCorProd(ret.utiliza_cor_prod === true);
        setUtilizaTamanhoProd(ret.utiliza_tamanho_prod === true);
        setUtilizaPrecoAtacado(ret.usa_preco_atacado === true);
        setTipoVendaCliente(ret.tipo_venda_cliente);
      }
    };

    const buscarDadosProduto = async (pista, idProduto) => {
      const searchById = ![0, null, undefined].includes(idProduto);
      if (pista || searchById) {
        setLoading(true);
        const [ok, ret] = await CadastroProdutosService.pesquisa(
          pista,
          idProduto
        );

        if (ok) {
          if (ret.length === 0) {
            onConfirm(
              "",
              "Não Encontrado",
              "",
              0,
              "",
              0,
              "",
              false,
              2,
              2,
              null,
              null
            );
            setProdCuringa(false);
          } else if (ret.length > 1) {
            setPistaPesq(pista);
            setModalOpen(true);
          } else {
            const prod = ret[0];
            setProdCuringa(prod.curinga);
            onConfirm(
              prod.id,
              prod.nome,
              prod.referencia,
              parseFloat(prod.preco_venda),
              prod.unidade,
              parseFloat(prod.custo_ue),
              prod.id_cfop,
              prod.clas_fiscal,
              prod.curinga,
              parseFloat(prod.quantidade),
              prod.cod_bar,
              prod.qtd_casas_decimais_qtd,
              prod.qtd_casas_decimais_vlrs,
              prod.tamanho,
              prod.cor
            );
            if (!prod.curinga && selectNextField) {
              selectNextField();
            }
            setLoading(false);
            return true;
          }
        } else {
          onConfirm("", "", "", 0, "", 0, "", "", false, 2, 2, null, null);
          setProdCuringa(false);
        }
        setLoading(false);
      } else {
        onConfirm("", "", "", 0, "", 0, "", "", false, 2, 2, null, null);
        setProdCuringa(false);
      }
      return false;
    };

    const focusIdentif = () => {
      if (prodCuringa) {
        if (refDescricao.current) {
          refDescricao.current.focus();
        }
      } else {
        if (pesqPorReferencia) {
          if (refReferencia.current) {
            refReferencia.current.focus();
          }
        } else {
          if (refIdentificador.current) {
            refIdentificador.current.focus();
          }
        }
      }
    };

    const setDescricao = (v) => refDescricao.current.setValue(v);

    const setId = (v) => {
      if (refIdentificador.current) {
        refIdentificador.current.setValue(v);
      }
      if (refIdentificadorAux.current) {
        refIdentificadorAux.current.setValue(v);
      }
    };

    const setReferencia = (v) => {
      if (refReferencia.current) {
        refReferencia.current.setValue(v);
      }
      if (refReferenciaAux.current) {
        refReferenciaAux.current.setValue(v);
      }
    };

    const limparCampos = () => {
      setDescricao("");
      setId("");
      setReferencia("");
      setProdCuringa(false);
    };

    useImperativeHandle(ref, () => ({
      setDescricao: setDescricao,
      setId: setId,
      setReferencia: setReferencia,
      setValueByID: (v) => buscarDadosProduto(null, v),
      focus: focusIdentif,
      clear: limparCampos,
    }));

    useEffect(() => {
      if (podeAlterarNomeProdCuringa && prodCuringa) {
        refDescricao.current.focus();
      }
    }, [podeAlterarNomeProdCuringa, prodCuringa]);

    useEffect(() => {
      buscarParametros();
    }, [modalOpen]);

    const handleOpenModal = (pista) => {
      setPistaPesq(pista);
      setModalOpen(true);
    };

    return (
      <>
        <CamposPesq
          md={md}
          onClickPesq={() => setModalOpen(true)}
          pesqPorReferencia={pesqPorReferencia}
          onExitIdentificador={(_, pista) => buscarDadosProduto(pista)}
          refIdentificador={refIdentificador}
          refDescricao={refDescricao}
          refReferencia={refReferencia}
          refIdentificadorAux={refIdentificadorAux}
          refReferenciaAux={refReferenciaAux}
          labelIdentificador={
            label ? label : pesqPorReferencia ? "Referência" : "Código"
          }
          loadingPesq={loading}
          disabledDescricao={!(podeAlterarNomeProdCuringa && prodCuringa)}
          openModal={handleOpenModal}
          onChangeDescricao={onChangeDescricao}
          autoFocus={autoFocus}
          mdIdent={mdIdent}
          mdDesc={null}
          mdIdentAux={mdIdentAux}
          showShortcut={showShortcut}
          selected={selected}
          disabled={disabled}
          disabledHint={disabledHint}
          auxAsLabel={auxAsLabel}
          pistaPesq={pistaPesq}
          setPistaPesq={setPistaPesq}
          divClassName={divClassName}
          mostrarAux={mostrarAux}
        />
        <ModalPesqProduto
          pistaPadrao={pistaPesq}
          isOpen={modalOpen}
          toggle={(selecionouProd) => {
            setModalOpen(!modalOpen);
            if (!modalOpen) {
              setPistaPesq("");
            } else {
              if (selecionouProd) {
                if (selectNextField) selectNextField();
              } else {
                focusIdentif();
              }
            }
          }}
          selectProd={(
            id,
            nome,
            refer,
            precoVda,
            unidade,
            curinga,
            custoUe,
            cfop,
            clasFiscal,
            quantidade,
            codBar,
            qtdCasasDecimaisQtd,
            qtdCasasDecimaisVlrs,
            tamanhoProd,
            corProd
          ) => {
            setProdCuringa(curinga);
            onConfirm(
              id,
              nome,
              refer,
              precoVda,
              unidade,
              custoUe,
              cfop,
              clasFiscal,
              curinga,
              quantidade,
              codBar,
              qtdCasasDecimaisQtd,
              qtdCasasDecimaisVlrs,
              tamanhoProd,
              corProd
            );
          }}
          sistAutomotivo={sistAutomotivo}
          utilizaCorProd={utilizaCorProd}
          utilizaTamanhoProd={utilizaTamanhoProd}
          utilizaPrecoAtacado={utilizaPrecoAtacado}
          tipoVendaCliente={tipoVendaCliente}
        />
      </>
    );
  }
);

export default PesqProduto;
