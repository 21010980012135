import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap/lib";
import { PageContainer } from "../../../../components/PageContainer";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  LabelButton,
} from "../../../../components";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { NotaFiscalGrid } from "./components/NotaFiscalGrid";
import { MODAL_ACTIONS, downloadFileFromBlob } from "../../../../coreUtils";
import { AjustarDuplicidadeModal } from "./components/AjustarDuplicidadeModal";
import { toastr } from "react-redux-toastr";
import { ProdutorRuralNFModal } from "./produtor_rural/ProdutorRuralNFModal";
import { incluirAlterarNFRoute } from "../../../../routes/modules/docsEletron";
import { NfCredDiferimentoModal } from "./components/NfCredDiferimentoModal";
import { useEffect } from "react";
import { apiGetV2 } from "../../../../apiV2";
import { CartaCorrecaoNfModal } from "./components/CartaCorrecaoNfModal";
import { CancelarNfModal } from "./components/CancelarNfModal";
import { DetalhesNfModal } from "./components/DetalhesNfModal";
import { InutilizarNFeModal } from "./components/InutilizarNFeModal";
import { AlertasNFeModal } from "./components/AlertasNFeModal";
import { ExportarModal } from "./components/ExportarModal";

const situacoes = [
  { label: "Todas as situações", value: "ALL" },
  { label: "Aguardando Emissão", value: "AGE" },
  { label: "Enviadas e Validadas SEFAZ", value: "EMI" },
  { label: "Canceladas", value: "CAN" },
  { label: "Inutilizadas", value: "INU" },
  { label: "Denegadas", value: "DEN" },
  { label: "Digitação Incompleta", value: "DIG" },
];

export const NotaFiscalEletronica = ({ history }) => {
  // Parâmetros
  const [emiteNfeCreditoDiferimento, setEmiteNfeCreditoDiferimento] =
    useState(false);
  // Filtros
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [numero, setNumero] = useState(null);
  const [serie, setSerie] = useState(null);
  const [situacao, setSituacao] = useState(situacoes[1].value);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  // Controle
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loadingTela, setLoadingTela] = useState(true);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingEmitir, setLoadingEmitir] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [cartaCorrecaoOpen, setCartaCorrecaoOpen] = useState(false);
  const [ajustarDuplicidadeOpen, setAjustarDuplicidadeOpen] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [produtorRuralOpen, setProdutorRuralOpen] = useState(false);
  const [inutilizarOpen, setInutilizarOpen] = useState(false);
  const [alertasOpen, setAlertasOpen] = useState(false);
  const [exportarIsOpen, setExportarIsOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2(`/tela/nf_eletronica/`);
    if (ok) {
      setEmiteNfeCreditoDiferimento(ret.emite_nfe_credito_diferimento);
    }
    setLoadingTela(false);
  };

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    try {
      if (situacao !== "AGE" && (!dataIni || !dataFim)) {
        toastr.warning(
          "Atenção",
          "Por favor, informe o período para buscar os dados"
        );
        return;
      }

      const [ok, ret] = await NotaFiscalService.gerenciamento.listar({
        data_ini: dataIni,
        data_fim: dataFim,
        id_cliente: cliente,
        numero: numero,
        serie: serie,
        situacao: situacao,
        ordenar_por: "NUM",
      });
      setDados(ok ? ret.notas : []);
      setTotais(ok ? ret.totais : {});
    } finally {
      setLoadingPesquisar(false);
    }
  };

  const emitir = async () => {
    setLoadingEmitir(true);
    try {
      const sel = dados.filter((e) => selected.includes(e.id)).map((e) => e.id);
      for (let i = 0; i < sel.length; i++) {
        const [ok] = await NotaFiscalService.gerenciamento.emitir(sel[i]);
        if (ok) {
          await NotaFiscalService.imprimir(sel[i]);
          await NotaFiscalService.gerenciamento.enviarEmail(sel[i]);
        }
      }
    } finally {
      await carregarDados();
      setLoadingEmitir(false);
    }
  };

  const enviarEmail = async () => {
    setLoadingEmail(true);
    try {
      const sel = dados.filter((e) => selected.includes(e.id)).map((e) => e.id);
      for (let i = 0; i < sel.length; i++) {
        await NotaFiscalService.gerenciamento.enviarEmail(sel[i]);
      }
    } finally {
      setLoadingEmail(false);
    }
  };

  const downloadXML = async (id) => {
    const ret = await NotaFiscalService.gerenciamento.downloadXML(id);

    if (ret) {
      const sel = dados.find((e) => e.id === id);
      const filename = sel.numero + "-" + sel.serie;
      downloadFileFromBlob(ret, filename);
    }
  };

  const imprimir = async (id) => await NotaFiscalService.imprimir(id);

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const toggleCartaCorrecao = () => setCartaCorrecaoOpen(!cartaCorrecaoOpen);

  const cartaCorrecaoNFe = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleCartaCorrecao();
    }, 1);
  };

  const toggleAjustarDuplicidade = async (id) =>
    setAjustarDuplicidadeOpen(!ajustarDuplicidadeOpen);

  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleProdutorRural = () => setProdutorRuralOpen(!produtorRuralOpen);

  const toggleInutilizar = () => setInutilizarOpen(!inutilizarOpen);

  const toggleAlertas = () => setAlertasOpen(!alertasOpen);

  const ajustarDuplicidade = async (id) => {
    const [ok, ret] = await NotaFiscalService.buscarStatusNFe(id);
    if (!ok) return;

    if (ret.status === "A") {
      setLastSelected(id);
      setTimeout(() => {
        toggleAjustarDuplicidade();
      }, 1);
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel Ajustar Duplicidade de NFe's não emitidas."
      );
    }
  };

  const incluirNFe = () => {
    history.push(incluirAlterarNFRoute.path, {
      action: MODAL_ACTIONS.ADD,
    });
  };

  const alterarNFe = async (id) => {
    const [ok, ret] = await NotaFiscalService.buscarStatusNFe(id);
    if (!ok) return;

    if (["A", "I"].includes(ret.status)) {
      history.push(incluirAlterarNFRoute.path, {
        action: MODAL_ACTIONS.EDIT,
        selected: id,
      });
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel Alterar NFe's não emitidas."
      );
    }
  };

  const vincularNFProdRural = async (id) => {
    const [ok, ret] = await NotaFiscalService.buscarStatusNFe(id);
    if (!ok) return;

    if (ret.status === "A") {
      setLastSelected(id);
      setTimeout(() => {
        toggleProdutorRural();
      }, 1);
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel alterar NFe's não emitidas."
      );
    }
  };

  const cancelarNFe = async (id) => {
    const [ok, ret] = await NotaFiscalService.buscarStatusNFe(id);
    if (!ok) return;

    if (ret.status === "E") {
      setLastSelected(id);
      setTimeout(() => {
        toggleCancelar();
      }, 1);
    } else if (ret.status === "C") {
      toastr.warning("Atenção", "A NF-e já foi cancelada.");
    } else {
      toastr.warning("Atenção", "Somente é possivel cancelar NFe-s emitidas.");
    }
  };

  const inutilizarNFe = async (id) => {
    const [ok, ret] = await NotaFiscalService.buscarStatusNFe(id);
    if (!ok) return;

    if (ret.status === "A") {
      setLastSelected(id);
      setTimeout(() => {
        toggleInutilizar();
      }, 1);
    } else if (ret.status === "U") {
      toastr.warning("Atenção", "A NF-e já foi inutilizada.");
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possivel inutilizar NFe's Aguardando Emissão."
      );
    }
  };

  const verDetalhesNFe = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const duplicarNFe = async (id) => {
    const [ok] = await NotaFiscalService.gerenciamento.duplicarNfe(id);
    if (ok) {
      await carregarDados();
    }
  };

  const alertasNFe = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleAlertas();
    }, 1);
  };

  const toggleExportar = () => setExportarIsOpen(!exportarIsOpen);

  useEffect(() => {
    carregarParametros();
    carregarDados();
  }, []);

  return (
    <PageContainer
      title="Nota Fiscal Eletrônica"
      number="0055"
      canGoBack
      loading={loadingTela}
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            name="cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setCliente(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="Número NF-e"
            value={numero}
            onChange={setNumero}
          />
          <IntegerFormInput
            md={1}
            label="Série"
            value={serie}
            onChange={setSerie}
          />
          <ComboBox
            options={situacoes}
            md={3}
            label="Situação"
            onChange={(s) => setSituacao(s?.value)}
            defaultValue={situacao}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={null}
            defaultStart={null}
            defaultEnd={null}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <FormButton md="auto" onClick={incluirNFe} color="info">
            Incluir
          </FormButton>
          <FormButton
            md="auto"
            color="success"
            onClick={emitir}
            loading={loadingEmitir}
            disabled={
              selected?.length === 0 ||
              dados.find((e) => selected.includes(e.id) && e.status !== "A")
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFe da lista."
                : dados.find((e) => selected.includes(e.id) && e.status !== "A")
                ? "Selecione somente NFe's Aguardando Emissão."
                : ""
            }
          >
            Emitir
          </FormButton>
          <FormButton
            md="auto"
            color="secondary"
            onClick={enviarEmail}
            loading={loadingEmail}
            disabled={
              selected?.length === 0 ||
              dados.find((e) => selected.includes(e.id) && e.status !== "E")
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFe da lista."
                : dados.find((e) => selected.includes(e.id) && e.status !== "E")
                ? "Selecione somente NFe's emitidas."
                : ""
            }
          >
            Enviar E-Mail
          </FormButton>
          {emiteNfeCreditoDiferimento && (
            <NfCredDiferimentoModal notifyEvent={carregarDados} />
          )}
          <LabelButton divClassName="ml-auto" onClick={toggleExportar}>
            Exportar
          </LabelButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade"
          value={totais?.quantidade}
          checkFloat={true}
          className="col-md-2 ml-auto"
        />
        <CardTotaisItem
          label="Valor Total"
          value={totais?.valor_total}
          className="col-md-2"
        />
      </CardTotais>
      <Card body>
        <NotaFiscalGrid
          data={dados}
          selected={selected}
          setSelected={handleSetSelected}
          onSelectAll={handleSelectAll}
          downloadXML={downloadXML}
          imprimir={imprimir}
          ajustarDuplicidade={ajustarDuplicidade}
          alterarNFe={alterarNFe}
          vincularNFProdRural={vincularNFProdRural}
          cancelarNFe={cancelarNFe}
          cartaCorrecaoNFe={cartaCorrecaoNFe}
          verDetalhesNFe={verDetalhesNFe}
          inutilizarNFe={inutilizarNFe}
          duplicarNFe={duplicarNFe}
          alertasNFe={alertasNFe}
        />
        <AjustarDuplicidadeModal
          isOpen={ajustarDuplicidadeOpen}
          toggle={toggleAjustarDuplicidade}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <CartaCorrecaoNfModal
          isOpen={cartaCorrecaoOpen}
          toggle={toggleCartaCorrecao}
          selected={lastSelected}
        />
        <CancelarNfModal
          isOpen={cancelarOpen}
          toggle={toggleCancelar}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <DetalhesNfModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          selected={lastSelected}
        />
        <ProdutorRuralNFModal
          isOpen={produtorRuralOpen}
          toggle={toggleProdutorRural}
          selected={lastSelected}
        />
        <InutilizarNFeModal
          isOpen={inutilizarOpen}
          toggle={toggleInutilizar}
          selected={lastSelected}
          notifyEvent={carregarDados}
        />
        <AlertasNFeModal
          isOpen={alertasOpen}
          toggle={toggleAlertas}
          selected={lastSelected}
        />
        <ExportarModal isOpen={exportarIsOpen} toggle={toggleExportar} />
      </Card>
    </PageContainer>
  );
};
