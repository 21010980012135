import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { GerenciamentoVendasGrid } from "./components/GerenciamentoVendasGrid";
import GerenciamentoVendasService from "../../../../services/vendas/GerenciamentoVendasService";
import { SenhaModal } from "../../../../components/SenhaModal";
import { DetalheVendaModal } from "../../../financeiro/caixa_loja/components/DetalheVendaModal";

const optsRecebidoCaixa = [
  { label: "Todas", value: "ALL" },
  { label: "Não Recebidas", value: "N" },
  { label: "Recebidas em Caixa", value: "S" },
];

export const GerenciamentoVendas = () => {
  const [idVenda, setIdVenda] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [nroOrigem, setNroOrigem] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [recebidoCaixa, setRecebidoCaixa] = useState();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_venda: idVenda,
      id_cliente: idCliente,
      id_produto: idProduto,
      id_vendedor: idVendedor,
      nro_origem: nroOrigem,
      data_ini: dataIni,
      data_fim: dataFim,
      recebido_caixa: recebidoCaixa,
    };
    setLoading(true);
    const [ok, ret] = await GerenciamentoVendasService.gerenciamento.listar(
      params
    );
    if (ok) {
      setTotais({
        num_vendas: ret.num_vendas,
        total_bruto: ret.total_bruto,
        total_desconto: ret.total_desconto,
        total_acrescimo: ret.total_acrescimo,
        total_frete: ret.total_frete,
        total_devolvido: ret.total_devolvido,
        total_liquido: ret.total_liquido,
      });
      setDados(ret.vendas);
    }
    setLoading(false);
  };

  const handleSelect = (s, isSelected) => {
    setSelected(
      isSelected ? [...selected, s] : selected.filter((e) => e !== s)
    );
    setLastSelected(isSelected ? s : null);
  };

  const solicitarSenhaEstornoVenda = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleSenhaEstornar();
    }, 1);
  };

  const estornarVenda = async (senha) => {
    const payload = { id_venda: lastSelected, senha_estorno: senha };
    const [ok] = await GerenciamentoVendasService.estornarRecCaixa(payload);
    if (ok) {
      carregarDados();
    }
    return ok;
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  return (
    <PageContainer title="Gerenciamento de Vendas" number="0067" canGoBack>
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nro. Venda"
            defaultValue={idVenda}
            onChange={setIdVenda}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isClearable
            isConcatField
            isSearchable
            hideShortcut
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            md={4}
            label="Produto"
            isClearable
            isConcatField
            isSearchable
            hideShortcut
            concatModelName="produto"
            onChange={(s) => setIdProduto(s?.value)}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            label="Vendedor"
            isClearable
            isConcatField
            isSearchable
            hideShortcut
            concatModelName="colaborador"
            onChange={(s) => setIdVendedor(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="Nro. Origem"
            defaultValue={nroOrigem}
            onChange={setNroOrigem}
          />
          <ComboBox
            options={optsRecebidoCaixa}
            md={2}
            label="Mostrar"
            onChange={(s) => setRecebidoCaixa(s?.value)}
            defaultValue={recebidoCaixa}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem label="Valor Total Bruto" value={totais?.total_bruto} />
        <CardTotaisItem label="Desconto" value={totais?.total_desconto} />
        <CardTotaisItem label="Acréscimo" value={totais?.total_acrescimo} />
        <CardTotaisItem label="Frete" value={totais?.total_frete} />
        <CardTotaisItem label="Devolvido" value={totais?.total_devolvido} />
        <CardTotaisItem label="Líquido" value={totais?.total_liquido} />
      </CardTotais>
      <Card body>
        <GerenciamentoVendasGrid
          dados={dados}
          onSelect={handleSelect}
          selected={selected}
          estornarVenda={solicitarSenhaEstornoVenda}
          mostrarDetalhes={mostrarDetalhes}
        />
      </Card>
      <SenhaModal
        isOpen={senhaEstornarOpen}
        toggle={toggleSenhaEstornar}
        title="Estorno de Venda"
        onConfirm={estornarVenda}
      />
      <DetalheVendaModal
        isOpen={detalhesOpen}
        toggle={toggleDetalhes}
        idVenda={lastSelected}
      />
    </PageContainer>
  );
};
