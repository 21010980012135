import React from "react";
import { Col, Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarcaTodosDocSangria,
  setImprReciboTodosReceb,
  setPermiteDescCaixa,
  setDescMaxCaixa,
  setLayoutFechCaixa,
  setEmiteNfe,
  setTipoNfeProd,
  setNfeAutoRecCartao,
  setObrigaObservSangria,
  setPermitePagtoDuplicPag,
  setPermiteReceberTransfBanc,
  setIdPlanoContaRecebTransfBanc,
  setIdPlanoContaRecebDinheiro,
  setImprimir,
  setLayoutSangria,
  setNomeImpressora,
  setIdPlanoCtaDescontoCredRotat,
  setSaldoAberturaIgualUltimoFechamento,
  setCalculaEMostraSaldoNoFechamento,
  setTemTef,
  setVlrAvisoSangria,
  setIdContaBancRecebTransfBanc,
  setNfeAutoRecPix,
  setSistemaGerencAberturaGaveta,
} from "../store/caixa_slice";
import { RadioItem } from "../../../../components/RadioGroup";
import { useRef } from "react";
import { useEffect } from "react";

const layoutsSangria = [
  { label: "Modelo 1 - Impressão em folha A4", value: 1 },
  { label: "Modelo 2 -  impressora não fiscal - 80mm", value: 2 },
];
const layoutsFechCaixa = [
  { label: "Modelo 1 - Impressão em folha A4", value: 1 },
  { label: "Modelo 2 - Impressão em impressora não fiscal - 80mm", value: 2 },
  {
    label:
      "Modelo 3 - Impressão em impressora não fiscal - 80mm - Imprime itens cancelados",
    value: 3,
  },
];

export const PersonCaixa = () => {
  const store = useSelector((state) => state.caixa);
  const dispatch = useDispatch();
  const idPlanoContaRecebTransfBancRef = useRef();
  const idContaBancRecebTransfBancRed = useRef();

  useEffect(() => {
    if (!store.permite_receber_transf_banc) {
      dispatch(setIdPlanoContaRecebTransfBanc(null));
      if (idPlanoContaRecebTransfBancRef.current) {
        idPlanoContaRecebTransfBancRef.current.clearValue();
      }
      dispatch(setIdContaBancRecebTransfBanc(null));
      if (idContaBancRecebTransfBancRed.current) {
        idContaBancRecebTransfBancRed.current.clearValue();
      }
    }
  }, [store.permite_receber_transf_banc]);

  useEffect(() => {
    if (!store.emite_nfe) {
      dispatch(setNfeAutoRecCartao(false));
      dispatch(setNfeAutoRecPix(false));
    }
  }, [store.emite_nfe]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Impressões"
          value={store.imprimir}
          onChange={(v) => dispatch(setImprimir(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Marcar Todos os Documentos (Cartão, Cheque, Crediário) ao Entrar na Tela de Sangrias de Caixa"
          checked={store.marca_todos_doc_sangria}
          onChange={() =>
            dispatch(setMarcaTodosDocSangria(!store.marca_todos_doc_sangria))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir um Recibo Sempre Que For Realizado um Recebimento no Caixa Loja"
          checked={store.impr_recibo_todos_receb}
          onChange={() =>
            dispatch(setImprReciboTodosReceb(!store.impr_recibo_todos_receb))
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={5}
          label="Layout de Impressão de Sangria"
          options={layoutsSangria}
          onChange={(s) => dispatch(setLayoutSangria(s?.value))}
          defaultValue={store.layout_sangria}
        />
      </Row>
      <Row>
        <ComboBox
          md={7}
          label="Layout de Impressão do Fechamento de Caixa"
          options={layoutsFechCaixa}
          onChange={(s) => dispatch(setLayoutFechCaixa(s?.value))}
          defaultValue={store.layout_fech_caixa}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Nome da Impressora"
          value={store.nome_impressora}
          onChange={(e, v) => dispatch(setNomeImpressora(v))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gerenciar Abertura da Gaveta pelo Sistema"
          checked={store.sistema_gerenc_abertura_gaveta}
          onChange={() =>
            dispatch(
              setSistemaGerencAberturaGaveta(
                !store.sistema_gerenc_abertura_gaveta
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigatório Informar Motivo da Sangria"
          checked={store.obriga_observ_sangria}
          onChange={() =>
            dispatch(setObrigaObservSangria(!store.obriga_observ_sangria))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Emitir Nota Fiscal Após o Recebimento no Caixa Loja"
          checked={store.emite_nfe}
          onChange={() => dispatch(setEmiteNfe(!store.emite_nfe))}
        />
        <Label md="auto" className="py-0 pr-0">
          Tipo de NFe de produtos
        </Label>
        <RadioGroup
          value={store.tipo_nfe_prod}
          onChange={(v) => dispatch(setTipoNfeProd(v))}
          disabled={!store.emite_nfe}
          optionsClassName="pt-0"
        >
          <RadioItem label="NFC-e (NF Consumidor)" value="NFCE" />
          <RadioItem label="NF-e" value="NFE" />
          <RadioItem label="Perguntar" value="PERG" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Emitir NFe Automaticamente Quando Recebimento For em Cartão"
          checked={store.nfe_auto_rec_cartao}
          onChange={() =>
            dispatch(setNfeAutoRecCartao(!store.nfe_auto_rec_cartao))
          }
          disabled={!store.emite_nfe}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Emitir NFe Automaticamente Quando Recebimento For em PIX"
          checked={store.nfe_auto_rec_pix}
          onChange={() => dispatch(setNfeAutoRecPix(!store.nfe_auto_rec_pix))}
          disabled={!store.emite_nfe}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir pagamento de títulos a pagar"
          checked={store.permite_pagto_duplic_pag}
          onChange={() =>
            dispatch(setPermitePagtoDuplicPag(!store.permite_pagto_duplic_pag))
          }
        />
      </Row>
      <Row>
        <Col className="no-gutters">
          <FormCheckbox
            padded={false}
            label="Permitir Recebimentos por Transferência Bancária"
            checked={store.permite_receber_transf_banc}
            onChange={() =>
              dispatch(
                setPermiteReceberTransfBanc(!store.permite_receber_transf_banc)
              )
            }
          />
          <Row className="mx-0 mt-2">
            <PesqPlanoCtaCentroCusto
              md={6}
              mode="plano_contas"
              label="Plano de Contas para Recebimento de Transferência Bancária"
              value={store.id_plano_conta_receb_transf_banc}
              onChange={(v) =>
                dispatch(setIdPlanoContaRecebTransfBanc(v ?? null))
              }
              ref={idPlanoContaRecebTransfBancRef}
              disabled={!store.permite_receber_transf_banc}
              divClassName="pl-0"
            />
            <AsyncComboBox
              md={6}
              label="Conta Bancária Recebimento de Transferência Bancária"
              isSearchable
              isConcatField
              defaultOptions
              concatModelName="conta_banc"
              defaultValue={store.id_conta_banc_receb_transf_banc}
              onChange={(s) =>
                dispatch(setIdContaBancRecebTransfBanc(s?.value ?? null))
              }
              disabled={!store.permite_receber_transf_banc}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Conceder Descontos no Recebimento no Caixa Loja"
          checked={store.permite_desc_caixa}
          onChange={() =>
            dispatch(setPermiteDescCaixa(!store.permite_desc_caixa))
          }
        />
        <Label md="auto" className="pt-2 pr-0">
          Percentual Máximo de Desconto
        </Label>
        <NumberInput
          md={2}
          onChange={(v) => dispatch(setDescMaxCaixa(v))}
          value={store.desc_max_caixa}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          isClearable
          mode="plano_contas"
          label="Plano de Contas para Recebimentos em Dinheiro"
          value={store.id_plano_conta_receb_dinheiro}
          onChange={(v) => dispatch(setIdPlanoContaRecebDinheiro(v ?? null))}
          defaultOptions
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          isClearable
          mode="plano_contas"
          label="Plano de Contas para Descontos no Crédito Rotativo"
          value={store.id_plano_cta_desconto_cred_rotat}
          onChange={(v) => dispatch(setIdPlanoCtaDescontoCredRotat(v ?? null))}
          defaultOptions
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Saldo de Abertura Deve ser Igual ao Último Saldo de Fechamento"
          checked={store.saldo_abertura_igual_ultimo_fechamento}
          onChange={() =>
            dispatch(
              setSaldoAberturaIgualUltimoFechamento(
                !store.saldo_abertura_igual_ultimo_fechamento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Calcular e Mostrar Saldo do Caixa No Fechamento"
          checked={store.calcula_e_mostra_saldo_no_fechamento}
          onChange={() =>
            dispatch(
              setCalculaEMostraSaldoNoFechamento(
                !store.calcula_e_mostra_saldo_no_fechamento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="TEF Habilitado"
          checked={store.tem_tef}
          onChange={() => dispatch(setTemTef(!store.tem_tef))}
        />
      </Row>
      <Row>
        <NumberInput
          md={3}
          label="Limite para Aviso de Sangria"
          onChange={(v) => dispatch(setVlrAvisoSangria(v))}
          value={store.vlr_aviso_sangria}
        />
      </Row>
    </>
  );
};
