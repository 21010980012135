import React, { useState } from "react";
import { Row } from "reactstrap";
import RoteirizacaoService from "../../../../../../services/cadastro/RoteirizacaoService";
import { FormButton } from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";

export const ExcluirRoteirizacaoModal = ({
  nomeClienteSelected,
  selected,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    setLoading(true);
    const [ok] = await RoteirizacaoService.excluir(selected);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        padded={false}
        divClassName="pr-0"
        md="auto"
        color="danger"
        onClick={toggle}
        disabled={!selected}
      >
        Excluir da Roteirização
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Exclusão da Roteirização"
        number="0010_12"
        autoFocus
        confirmButtonText="Excluir"
        onConfirm={handleDelete}
        loadingConfirm={loading}
      >
        <p style={{ textAlign: "center" }}>
          Tem certeza de que deseja excluir o cliente {nomeClienteSelected()} da
          roteirização?
        </p>
      </ModalBase>
    </>
  );
};
