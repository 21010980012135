import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../ModalBase";
import { Table } from "../../Table";
import SearchInput from "../../SearchInput";
import { debounce, naturalSort } from "../../../coreUtils";
import CadastroClienteService from "../../../services/cadastro/CadastroClienteService";
import { TableCheck } from "../../TableCheck";
import { ClassifCli } from "../../ClassifCli";
import VeiculosClienteGrid from "../../../pages/cadastro/clientes/cliente/components/VeiculosClienteGrid";
import { FormCheckbox } from "../../FormCheckbox";
import AsyncComboBox from "../../AsyncComboBox";
import TextInput from "../../TextInput";

const _columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
    sortable: true,
    formatter: (c, row) => (
      <div style={{ display: "flex" }}>
        <span className="mr-2">{c}</span>
        {![0, null, undefined].includes(row.classif_cli) && (
          <ClassifCli
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginBlock: "auto",
            }}
            value={row.classif_cli}
            table
          />
        )}
      </div>
    ),
  },
  {
    dataField: "endereco",
    text: "Endereço",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "bairro",
    text: "Bairro",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "cidade__nome",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "fone1",
    text: "Fone",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "status",
    text: "Ativo",
    align: "center",
    headerAlign: "center",
    formatter: (cell) => <TableCheck value={cell} />,
  },
];

const ModalPesqCliente = ({
  isOpen,
  toggle,
  selectCli,
  pistaPadrao,
  onClose,
  sistAutomotivo,
}) => {
  const [pista, setPista] = useState("");
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [placa, setPlaca] = useState(null);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedNome, setSelectedNome] = useState("");
  const [selectedCuringa, setSelectedCuringa] = useState(false);
  const [selectedCpfCnpj, setSelectedCpfCnpj] = useState(false);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(false);
  const [selectedVeiculos, setSelectedVeiculos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [tableId] = useState("table-" + Math.floor(Math.random() * Date.now()));

  const internalToggle = (selecionouCli) => {
    setClientes([]);

    toggle(selecionouCli);
    setPista("");
    setIdVeiculo(null);
    setPlaca(null);
    setMostrarInativos(false);
  };

  const handlePistaChange = debounce(setPista, 1500);

  const handlePlacaChange = debounce(setPlaca, 1500);

  const handleSelectCliente = (value, checked, row) => {
    if (checked) {
      setSelected(value);
      setSelectedNome(row.nome);
      setSelectedCuringa(row?.curinga);
      setSelectedCpfCnpj(row?.cpf_cnpj);
      setSelectedWhatsapp(row?.whatsapp);
      if (sistAutomotivo === true) {
        setSelectedVeiculos(row.veiculos);
      }
    } else {
      setSelected(null);
      setSelectedNome("");
      setSelectedCuringa(false);
      setSelectedCpfCnpj("");
      setSelectedWhatsapp("");
      if (sistAutomotivo === true) {
        setSelectedVeiculos([]);
      }
    }
  };

  const carregarDados = useCallback(async () => {
    setLoading(true);

    const getFunc = sistAutomotivo
      ? CadastroClienteService.listarAutomotivo
      : CadastroClienteService.listar;

    const getParams = {
      pista: pista,
      apenas_ativos: !mostrarInativos,
      placa: placa,
      id_veiculo: idVeiculo,
    };

    const [ok, ret] = await getFunc(getParams);

    setClientes(ok ? ret : []);
    setLoading(false);
  }, [pista, mostrarInativos, placa, idVeiculo]);

  useEffect(() => {
    if (isOpen) {
      carregarDados();
    }
  }, [carregarDados, isOpen]);

  useEffect(() => {
    setPista(pistaPadrao);
  }, [pistaPadrao]);

  const handleConfirm = () => {
    internalToggle(true);
    if (selectCli) {
      selectCli(selected, selectedNome, selectedCuringa, selectedCpfCnpj, selectedWhatsapp);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={() => internalToggle(false)}
      size="xl"
      centered={false}
      title="Pesquisa de Clientes"
      number="PesqCli"
      onConfirm={handleConfirm}
      onClosed={onClose}
      confirmButtonText="Importar"
    >
      <Row className="mb-2">
        <SearchInput
          label="Pista"
          onChange={handlePistaChange}
          loading={loading}
          value={pistaPadrao}
        />
        {sistAutomotivo && (
          <>
            <TextInput
              label="Placa"
              md={2}
              onChange={(e, v) => handlePlacaChange(v)}
              value={placa}
            />
            <AsyncComboBox
              md={3}
              label="Veículo"
              onChange={(s) => setIdVeiculo(s?.value)}
              isSearchable
              isClearable
              isConcatField
              concatModelName="veiculo"
              defaultValue={idVeiculo}
              hideShortcut
            />
          </>
        )}
        <FormCheckbox
          label="Mostrar Inativos"
          name="mostrar_inativos"
          checked={mostrarInativos}
          onChange={() => setMostrarInativos(!mostrarInativos)}
          tabIndex={100}
        />
      </Row>
      <Table
        id={tableId}
        columns={_columns}
        data={clientes}
        paginated={false}
        pageSize={12}
        onSelect={handleSelectCliente}
        clipText
        clearSelectionOnUpdate
        onRowEnterPress={handleConfirm}
        onRowDoubleClick={handleConfirm}
      />
      {sistAutomotivo === true && (
        <VeiculosClienteGrid veiculos={selectedVeiculos} />
      )}
    </ModalBase>
  );
};

export default ModalPesqCliente;
