import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AsyncComboBox,
  FixedField,
  NumberInput,
} from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import {
  formatNumber,
  roundFloat,
  roundNumber,
} from "../../../../../coreUtils";
import UteisService from "../../../../../services/uteis/UteisService";
import { Badge, Row } from "reactstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showWarning } from "../../../../../components/AlertaModal";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";

export const IncluirProdutoForm = forwardRef(
  ({ incluirItem, semCliente }, ref) => {
    const stateCab = useSelector((state) => state.vendaCab);
    const params = useSelector((state) => state.paramsFrenteVenda);
    const simplesNacional = params.regime_tribut === "Simples Nacional";
    const utilizaAmbiente = params.utiliza_ambiente;
    // Dados do Produto
    const [idProduto, setIdProduto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [referencia, setReferencia] = useState("");
    const [unidade, setUnidade] = useState("");
    const [quantidade, setQuantidade] = useState(1);
    const [idAmbiente, setIdAmbiente] = useState(0);
    const [cfop, setCfop] = useState("");
    const [estoqueProd, setEstoqueProd] = useState(0);
    const [curinga, setCuringa] = useState(false);
    const [tamanhoProd, setTamanhoProd] = useState(null);
    const [corProd, setCorProd] = useState(null);
    const [listaPreco, setListaPreco] = useState(false);
    const [custoUe, setCustoUe] = useState(0);
    const [vlrUnit, setVlrUnit] = useState(0);
    const [vlrItem, setVlrItem] = useState(0);
    const [percDesc, setPercDesc] = useState(0);
    const [percComis, setPercComis] = useState(0);
    const [vlrTotComis, setVlrTotComis] = useState(0);
    const [vlrTotal, setVlrTotal] = useState(0);
    const [vlrDesc, setVlrDesc] = useState(0);
    const [vlrAcre, setVlrAcre] = useState(0);
    const [vlrTotProd, setVlrTotProd] = useState(0);
    const [vlrIcmsStUnit, setVlrIcmsStUnit] = useState(0);
    const [vlrIcmsSt, setVlrIcmsSt] = useState(0);
    const [percIpi, setPercIpi] = useState(0);
    const [vlrIpi, setVlrIpi] = useState(0);
    //Controle
    const quantidadeRef = useRef();
    const ambienteRef = useRef();
    const refPesqProd = useRef();
    const [executarInclusao, setExecutarInclusao] = useState(false);

    const semProduto = [0, null, undefined].includes(idProduto);

    const limparDadosProduto = () => {
      setIdProduto(0);
      setDescricao("");
      setUnidade("");
      setQuantidade(1);
      setListaPreco(false);
      setIdAmbiente(0);
      setCfop("");
      setCustoUe(0);
      setEstoqueProd(0);
      setVlrUnit(0);
      setVlrItem(0);
      setPercDesc(0);
      setVlrTotal(0);
      setVlrDesc(0);
      setVlrAcre(0);
      setVlrTotProd(0);
      setVlrIcmsStUnit(0);
      setVlrIcmsSt(0);
      setPercIpi(0);
      setVlrIpi(0);
      setCorProd(null);
      setTamanhoProd(null);
      setCuringa(false);
      if (refPesqProd.current) {
        refPesqProd.current.clear();
      }
      if (ambienteRef.current) {
        ambienteRef.current.clearValue();
      }
    };

    const selectNextField = () => {
      setTimeout(() => {
        if (utilizaAmbiente) {
          if (ambienteRef.current) {
            ambienteRef.current.setFocus();
          }
        } else {
          if (quantidadeRef.current) {
            quantidadeRef.current.focus();
          }
        }
      }, 35);
    };

    const handleSelectProduto = async (
      id,
      nome,
      referencia,
      precoVenda,
      unidade,
      custoUe,
      cfop,
      clasFiscal,
      curinga,
      quantidade,
      cod_bar,
      qtd_casas_decimais_qtd,
      qtd_casas_decimais_vlrs,
      tamanho,
      cor
    ) => {
      if (idProduto !== id) {
        let listaPreco = false;
        if (["", 0, null, undefined].includes(id)) {
          limparDadosProduto();
          return;
        } else {
          if (![0, null, undefined].includes(stateCab.id_cliente)) {
            const [ok, ret] = await FrenteVendaService.buscarPrecoListaPreco(
              id,
              stateCab.id_cliente
            );
            if (ok && ret?.preco_venda) {
              precoVenda = parseFloat(ret.preco_venda);
              listaPreco = true;
            }
          }
        }

        setIdProduto(id);
        setDescricao(nome);
        setQuantidade(1);
        setVlrUnit(precoVenda);
        setUnidade(unidade);
        setVlrItem(precoVenda);
        setReferencia(referencia);
        setEstoqueProd(quantidade);
        setPercDesc(0);
        setVlrDesc(0);
        setVlrAcre(0);
        setVlrTotProd(precoVenda);
        setVlrTotal(precoVenda);
        setCustoUe(custoUe);
        setCfop(cfop);
        setCuringa(curinga);
        setListaPreco(listaPreco);
        setTamanhoProd(tamanho);
        setCorProd(cor);

        selectNextField();
      }
    };

    const calcularImpostos = async (idProduto, quantidade, vlrItem, cfop) => {
      const _vlrTotProd = vlrItem * quantidade;

      if (_vlrTotProd > 0) {
        const vlrTotComisCalc = roundFloat(_vlrTotProd * (percComis / 100), 2);

        setVlrTotComis(vlrTotComisCalc);
      } else {
        setVlrTotComis(0);
      }

      // Se o regime da empresa for Simples Nacional, não calcula impostos
      if (simplesNacional) {
        setVlrTotProd(roundNumber(_vlrTotProd));
        setVlrTotal(roundNumber(_vlrTotProd));

        return true;
      } else {
        const trib = await UteisService.calcularImpostosProduto(
          idProduto,
          stateCab.id_cliente ?? null,
          "VENDA",
          55,
          true,
          cfop,
          quantidade,
          _vlrTotProd,
          stateCab.vlr_frete
        );

        if (trib && Object.keys(trib).length > 0) {
          const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
          const _vlrIpi = parseFloat(trib.vlr_ipi);
          const _percIpi = parseFloat(trib.perc_ipi);
          const _vlrTotal = parseFloat(trib.vlr_total_com_impostos);

          setVlrIcmsStUnit(
            quantidade > 0 ? roundFloat(_vlrIcmsSt / quantidade, 2) : 0
          );
          setVlrIcmsSt(roundFloat(_vlrIcmsSt, 2));
          setPercIpi(roundFloat(_percIpi, 2));
          setVlrIpi(roundFloat(_vlrIpi, 2));
          setVlrTotProd(roundFloat(_vlrTotProd, 2));
          setVlrTotal(roundFloat(_vlrTotal, 2));
          return true;
        } else {
          return false;
        }
      }
    };

    const handleSetQuantidade = async (v) => {
      if (await calcularImpostos(idProduto, v, vlrItem, cfop)) {
        setQuantidade(v);

        if (vlrItem <= vlrUnit) {
          setVlrDesc(roundFloat(vlrUnit * (percDesc / 100) * v, 2));
          setVlrAcre(0);
        } else {
          setPercDesc(0);
          setVlrDesc(0);
          setVlrAcre(roundFloat((vlrItem - vlrUnit) * v, 2));
        }
      }
    };

    const handleSetVlrItem = async (v) => {
      if (await calcularImpostos(idProduto, quantidade, v, cfop)) {
        setVlrItem(v);

        if (v <= vlrUnit) {
          const vDesc = vlrUnit - v;
          setPercDesc(vlrUnit > 0 ? roundFloat((vDesc / vlrUnit) * 100, 2) : 0);
          setVlrDesc(roundFloat(vDesc * quantidade, 2));
          setVlrAcre(0);
        } else {
          setPercDesc(0);
          setVlrDesc(0);
          setVlrAcre(roundFloat((v - vlrUnit) * quantidade, 2));
        }
      }
    };

    const handleSetPercDesc = async (v) => {
      if (v === percDesc) return;
      if (vlrUnit > 0) {
        const vDesc = roundFloat((v / 100) * vlrUnit, 2);
        const vItem = roundFloat(((100 - v) / 100) * vlrUnit, 4);

        if (await calcularImpostos(idProduto, quantidade, vItem, cfop)) {
          setPercDesc(v);
          setVlrItem(roundFloat(vItem, 4));
          setVlrDesc(roundFloat(vDesc * quantidade, 2));
          setVlrAcre(0);
        }
      } else {
        setPercDesc(0);
        setVlrDesc(0);
        setVlrAcre(vlrItem);
      }
    };

    const handleSetPercComis = async (v) => {
      if (v === percComis) return;
      setPercComis(v);

      if (vlrTotProd > 0) {
        const vlrTotComisCalc = roundFloat(vlrTotProd * (v / 100), 2);

        setVlrTotComis(vlrTotComisCalc);
      } else {
        setVlrTotComis(0);
      }
    };

    const handleSetVlrTotProd = async (v) => {
      const vItem = quantidade > 0 ? v / quantidade : 0;
      if (await calcularImpostos(idProduto, quantidade, vItem, cfop)) {
        setVlrTotProd(v);
        setVlrItem(roundFloat(vItem, 4));

        const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);
        const vDesc = vlrTotBruto > 0 ? vlrTotBruto - v : 0;
        const pDesc =
          vlrTotBruto > 0 ? roundFloat((vDesc / vlrTotBruto) * 100, 2) : 0;

        if (v <= vlrTotBruto) {
          setPercDesc(roundFloat(pDesc, 2));
          setVlrDesc(roundFloat(vDesc, 2));
          setVlrAcre(0);
        } else {
          setPercDesc(0);
          setVlrDesc(0);
          setVlrAcre(roundFloat(v - vlrTotBruto, 2));
        }
      }
    };

    const handleIncluirProd = async () => {
      if (utilizaAmbiente && [0, null, undefined].includes(idAmbiente)) {
        showWarning("Por favor, informe o Ambiente");
        if (ambienteRef.current) {
          ambienteRef.current.setFocus();
        }
        return;
      }

      await handleSetVlrTotProd(vlrTotProd);

      setExecutarInclusao(true);
    };

    const vlrTotProdKeyDown = (e) => {
      if (["Enter", "Tab"].includes(e.key) && !semProduto) {
        handleIncluirProd();
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      },
      limpar: () => {
        limparDadosProduto();
      },
    }));

    useEffect(() => {
      if (refPesqProd.current && idProduto) {
        refPesqProd.current.setId(String(idProduto));
        refPesqProd.current.setDescricao(descricao);
        refPesqProd.current.setReferencia(String(referencia));
      }
    }, [refPesqProd.current, idProduto]);

    const inclusaoItem = async () => {
      try {
        const payload = {
          tipo: "P",
          id_prod_serv: idProduto,
          descricao: descricao,
          unidade: unidade,
          id_ambiente: idAmbiente,
          quantidade: quantidade,
          vlr_unit: vlrUnit,
          vlr_item: vlrItem,
          perc_desc: percDesc,
          vlr_tot_prod_serv: vlrTotProd,
          vlr_total: vlrTotal,
          vlr_desc: vlrDesc,
          vlr_acre: vlrAcre,
          perc_ipi: percIpi,
          vlr_ipi: vlrIpi,
          vlr_icms_st: vlrIcmsSt,
          vlr_icms_st_unit: vlrIcmsStUnit,
          perc_comis: percComis ?? 0,
          vlr_tot_comis: vlrTotComis ?? 0,
          observ: "",
        };

        if (await incluirItem(payload)) {
          limparDadosProduto();
          if (refPesqProd.current) {
            refPesqProd.current.focus();
          }
        }
      } finally {
        setExecutarInclusao(false);
      }
    };

    useEffect(() => {
      if (executarInclusao) {
        inclusaoItem();
      }
    }, [executarInclusao]);

    useEffect(() => {
      if (!semCliente) {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      }
    }, [semCliente]);

    return (
      <>
        <Row>
          <PesqProduto
            md={8}
            onConfirm={handleSelectProduto}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={setDescricao}
            mdIdent={3}
            mdDesc={7}
            mdIdentAux={2}
            disabled={!simplesNacional && semCliente}
            auxAsLabel
            mostrarAux={
              !params.utiliza_cor_prod && !params.utiliza_tamanho_prod
            }
            idCliente={stateCab.id_cliente}
          />
          {(params.utiliza_cor_prod || params.utiliza_tamanho_prod) && (
            <>
              {params.utiliza_cor_prod && (
                <FixedField
                  divClassName="pt-4"
                  label="Tamanho"
                  md="auto"
                  value={tamanhoProd}
                  horizontal
                />
              )}
              {params.utiliza_tamanho_prod && (
                <FixedField
                  divClassName="pt-4"
                  label="Cor"
                  md="auto"
                  value={corProd}
                  horizontal
                />
              )}
            </>
          )}
          <FixedField
            divClassName="pt-4"
            md={"auto"}
            label="Estoque"
            value={formatNumber(estoqueProd, true, 2, true)}
            horizontal
          />
          <FixedField
            divClassName="pt-4"
            md={2}
            label="Vlr. Unitário"
            value={formatNumber(vlrUnit, true, 2)}
            horizontal
          />
        </Row>
        {utilizaAmbiente && (
          <Row>
            <AsyncComboBox
              md={4}
              isConcatField
              concatModelName="ambiente"
              label="Ambiente"
              isSearchable
              onChange={(s) => setIdAmbiente(s?.value ?? 0)}
              defaultValue={idAmbiente}
              defaultOptions
              ref={ambienteRef}
              disabled={semProduto}
            />
          </Row>
        )}
        <Row>
          <NumberInput
            md={2}
            label="Quantidade"
            value={quantidade}
            onChange={handleSetQuantidade}
            disabled={semProduto}
            decimalPlaces={2}
            ref={quantidadeRef}
          />
          <NumberInput
            md={2}
            label={
              <>
                Vlr. Item{" "}
                {listaPreco && (
                  <Badge
                    pill
                    color="info"
                    style={{ transform: "translateY(-1px)" }}
                  >
                    Preço Tabelado
                  </Badge>
                )}
              </>
            }
            value={vlrItem}
            onChange={handleSetVlrItem}
            disabled={semProduto || listaPreco}
            decimalPlaces={4}
          />
          <NumberInput
            md={1}
            label="% Desc."
            value={percDesc}
            onChange={handleSetPercDesc}
            disabled={semProduto}
            isPercentage
          />
          {params.informar_perc_comis_frente_venda === true && (
            <NumberInput
              md={1}
              label="% Comis."
              value={percComis}
              onChange={handleSetPercComis}
              disabled={semProduto}
              isPercentage
            />
          )}
          <NumberInput
            md={2}
            label="Vlr. Tot. Prod."
            value={vlrTotProd}
            onChange={handleSetVlrTotProd}
            disabled={semProduto}
            decimalPlaces={2}
            onKeyDown={vlrTotProdKeyDown}
          />
          <NumberInput md={2} label="Vlr. Total" value={vlrTotal} disabled />
        </Row>
      </>
    );
  }
);
