import React from "react";
import { FixedTable, HintLabel, HintValue } from "../../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import cellEditFactory from "react-bootstrap-table2-editor";
import { toastr } from "react-redux-toastr";

const columns = [
  {
    dataField: "produto",
    text: "Produto",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
    editable: false,
  },
  {
    dataField: "vlr_unit",
    text: "Vlr. Unitário",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "perc_desc",
    text: "% Desconto",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_unit_ipi",
    text: "Vlr. Unit. IPI",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "qtd_devol",
    text: (
      <HintLabel
        label="Qtd. Devolvida"
        hintText="Quantidade devolvida em devoluções anteriores"
      />
    ),
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    editable: false,
  },
  {
    dataField: "vlr_devol",
    text: (
      <HintLabel
        label="Vlr. Devolvido"
        hintText="Valor devolvido em devoluções anteriores"
      />
    ),
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "qtd_atual",
    text: "Qtd. Restante",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    editable: false,
  },
  {
    dataField: "qtd_devolver",
    text: "Qtd. Devolver",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => (
      <HintValue
        enabled={parseFloat(row.qtd_atual) === 0}
        hint="O item já foi totalmente devolvido."
        value={formatNumber(c, true, 2, true)}
      />
    ),
    headerStyle: () => ({ width: "10%" }),
    editCellStyle: () => ({ padding: 0, verticalAlign: "middle" }),
    editorStyle: { textAlign: "center", width: "50%", marginInline: "auto" },
    editable: (c, row) => parseFloat(row.qtd_atual) > 0,
    validator: (newValue, row) => {
      if (newValue.toString().match(/^[0-9]+([\.|,]?[0-9]+)?$/)) {
        const newValueFloat = parseFloat(newValue.replace(",", "."));
        const qtdAtual = parseFloat(row.qtd_atual);
        if (newValueFloat > qtdAtual) {
          toastr.warning(
            "Atenção",
            "A quantidade informada não pode ser superior à quantidade atual do item."
          );
          return { valid: false, message: "" };
        }

        return true;
      } else {
        toastr.warning("Atenção", "Por favor, informe um valor válido.");
        return { valid: false, message: "" };
      }
    },
  },
];

export const DevolverItensVendaGrid = ({ itens, atualizarVlrDevol }) => {
  return (
    <FixedTable
      columns={columns}
      data={itens}
      style={{ heigh: "300px" }}
      cellEdit={cellEditFactory({
        mode: "click",
        afterSaveCell: (oldValue, newValue, row, column) => {
          row.qtd_devolver = parseFloat(newValue.toString().replace(",", "."));
          atualizarVlrDevol();
        },
        blurToSave: true,
        autoSelectText: true,
        timeToCloseMessage: 0,
      })}
    />
  );
};
