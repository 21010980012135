import React from "react";
import { Card, Label, Row } from "reactstrap";
import { PageContainer } from "../../../../components/PageContainer";
import { useState } from "react";
import { ControleAcessoGrid } from "./components/ControleAcessoGrid";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  SearchInput,
} from "../../../../components";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { apiGetV2, apiPutV2 } from "../../../../apiV2";

export const ControleAcesso = () => {
  const [telas, setTelas] = useState([]);
  const [moduloOptions, setModuloOptions] = useState([]);
  const [modulo, setModulo] = useState(null);
  const [perfil, setPerfil] = useState(null);
  const [pista, setPista] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p = pista) => {
    const params = { pista: p, id_perfil: perfil };
    const [ok, ret] = await apiGetV2("/cadastro/menu/listar/", params);
    setTelas(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const liberarBloquearAcesso = async (row) => {
    const payload = {
      id_acesso: row.id,
      liberado: !row.liberado,
    };

    const [ok, _] = await apiPutV2(
      "/cadastro/menu/liberar_bloquear_acesso/",
      payload,
      { successMessage: false }
    );
    if (ok) {
      carregarDados();
    }
  };

  return (
    <PageContainer title="Controle de Acesso" number="0088">
      <Card body>
        <Row>
          <SearchInput
            md={4}
            value={pista}
            onChange={handlePista}
            loading={loadingPista}
          />
          <AsyncComboBox
            md={3}
            label="Perfil"
            isConcatField
            concatModelName="perfil"
            isSearchable
            isClearable
            onChange={(s) => setPerfil(s?.value)}
            defaultValue={perfil}
            defaultOptions
          />
          <ComboBox
            md={3}
            label="Módulo"
            options={moduloOptions}
            isClearable
            defaultValue={modulo}
            onChange={(s) => setModulo(s?.value ?? null)}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          {/* <FormButton md="auto" color="primary">
            Ver Perfis / Colaboradores
          </FormButton> */}
        </Row>
        <Row>
          <Label md="auto">
            Usuários MASTER possuem acesso a todas as funcionalidades do sistema
            conforme o plano contratado.
          </Label>
        </Row>
      </Card>
      <ControleAcessoGrid
        dados={telas}
        liberarBloquearAcesso={liberarBloquearAcesso}
      />
    </PageContainer>
  );
};
